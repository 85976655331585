import { useSelector } from "react-redux";

function useIsMentor() {
  const { user } = useSelector((state) => state.user);
  const projects = useSelector((state) => state.projects.projects);
  const projectId = useSelector((state) => state.projects.selectedProject);
  const currentProject = projects.find((project) => project.pk === projectId);
  const isMentor =
    (currentProject?.role === "mentor" || (currentProject?.role==="owner")) &&
    currentProject?.owner !== user.pk;
  const isSupervisor=currentProject?.role==="supervisor"

  const isOwner=currentProject?.owner === user.pk

  return {isMentor,isSupervisor,isOwner};
}

export default useIsMentor;
