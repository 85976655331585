import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "src/libs/axios";

const initialState = {
  secteurElements: [],
  swot: [],
  currentStep: "presentation",
  savingStatus: "done",
  loading: true,
};

export const getSecteur = createAsyncThunk(
  "secteur/getSecteur",
  async (pk, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/secteur/secteurpresentation-get/${pk}/`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateSecteurAction = createAsyncThunk(
  "secteur/updatePresentationAction",
  async (data, { rejectWithValue }) => {
    try {
      let response;
      if (data.id) {
        response = await axiosInstance.post(
          `/secteur/secteurpresentation-update/${data.id}/`,
          data
        );
      }
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const deleteSecteurAction = createAsyncThunk(
  "secteur/deleteSecteurAction",
  async (data, { rejectWithValue }) => {
    try {
      let response;
      if (data) {
        response = await axiosInstance.delete(
          `/secteur/secteurpresentation-delete/${data}/`,
          data
        );
      }
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const createSecteurAction = createAsyncThunk(
  "secteur/createPresentationAction",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.post(
        `/secteur/secteurpresentation-create/`,
        data
      );
      dispatch(getSecteur(data.project_id));
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
const secteurSlice = createSlice({
  name: "secteur",
  initialState,
  extraReducers: {
    [getSecteur.pending]: (state, action) => {
      state.loading = true;
    },
    [getSecteur.fulfilled]: (state, action) => {
      state.secteurElements = action.payload.filter(
        (element) => element.presentation !== "analyseswot"
      );
      state.swot = action.payload.filter(
        (element) => element.presentation === "analyseswot"
      );
      state.loading = false;
    },
    [updateSecteurAction.pending]: (state) => {
      state.savingStatus = "saving";
    },
    [updateSecteurAction.fulfilled]: (state) => {
      state.savingStatus = "done";
    },
    [updateSecteurAction.rejected]: (state) => {
      state.savingStatus = "error";
    },
  },
  reducers: {
    setStepSecteur: (state, action) => {
      state.currentStep = action.payload;
    },
    setSavingSecteurStatus: (state, action) => {
      state.savingStatus = action.payload;
    },
  },
});
export default secteurSlice.reducer;
export const { setStepSecteur, setSavingSecteurStatus } = secteurSlice.actions;
