import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useIsMentor from "src/hooks/useIsMentor";
import { updateProject } from "src/stores/projects/projectsSlice";
import { DELEGATIONS } from "src/utils/constants";

function Step3() {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects.projects);
  const projectId = useSelector((state) => state.projects.selectedProject);
  const currentProject = projects.find((project) => project.pk === projectId);
  const {isMentor,isSupervisor}=useIsMentor()

  const handleChangeGovernorate = (event) => {
    dispatch(
      updateProject({ ...currentProject, governorate: event.target.value })
    );
  };
  const handleChangeDelegation = (event) => {
    dispatch(
      updateProject({ ...currentProject, delegation: event.target.value })
    );
  };
  const govArray=DELEGATIONS.map((delegation)=>delegation.governorat)
  const delegationArray=DELEGATIONS.filter((delegation)=>delegation.governorat===currentProject.governorate)[0]?.delegations||[]
  return (
    <>
      <FormControl sx={{ minWidth: "300px", mt: 3 }}>
        <InputLabel htmlFor="Governorat">Governorat</InputLabel>
        <Select
          readOnly={isMentor||isSupervisor}
          labelId="Governorat-label"
          id="Governorat"
          value={currentProject ? currentProject.governorate : ""}
          label="Governorat"
          onChange={handleChangeGovernorate}
        >
          {govArray.map((governorate) => (
            <MenuItem value={governorate} key={governorate}>
              {governorate}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ minWidth: "300px", ml: 1, mt: 3 }}>
        <InputLabel htmlFor="delegation">Delegation</InputLabel>
        <Select
          readOnly={isMentor||isSupervisor}
          labelId="delegation-label"
          id="delegation"
          value={currentProject ? currentProject.delegation : ""}
          label="Delegation"
          onChange={handleChangeDelegation}
        >
          {delegationArray.map((delegation) => (
            <MenuItem value={delegation} key={delegation}>
              {delegation}
            </MenuItem>
          ))}
        </Select>
       
      </FormControl>
    </>
  );
}

export default Step3;
