import { LoadingButton } from "@mui/lab";

import {
  Box,
  Button,
  Card,
  CardContent,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

function ConfirmDeleteModal({ open, setModalOpen, action }) {
  const [isSubmitting, setisSubmitting] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "500px",
  };
  const handleClose = () => {
    setModalOpen(false);
    setisSubmitting(false);
  };
  const handleDelete = async () => {
    setisSubmitting(true);
    await action();
    handleClose();
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Card>
          <CardContent>
            <Typography variant="p" component="p">
              Est-vous sur de vouloir supprimer cette Post-it ?
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                sx={{ mt: 3, mr: 1 }}
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Annuler
              </Button>
              <LoadingButton
                sx={{ mt: 3 }}
                type="submit"
                variant="contained"
                color="error"
                loading={isSubmitting}
                onClick={handleDelete}
              >
                Supprimer
              </LoadingButton>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
}

export default ConfirmDeleteModal;
