import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "src/libs/axios";

const initialState = {
  cohorts: {
    data: [],
    loading: true,
  },
  selectedCohort: parseInt(localStorage.getItem("cohortSelected"), 10) || -1,
  cohort: {
    data: null,
    loading: true,
  },
  invitations: {
    data: [],
    loading: true,
  },
};
export const getMyInvitations = createAsyncThunk(
  "cohort/getMyInvitations",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/cohort/myinvitations/");
      return response.data;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const getCohortsAction = createAsyncThunk(
  "cohorts/getCohorts",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("cohort/cohorts/");
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getCohortByIdAction = createAsyncThunk(
  "cohorts/getCohortById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`cohort/cohorts/${id}/`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const createCohortAction = createAsyncThunk(
  "cohorts/createCohort",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("cohort/cohorts/", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const cohortSlice = createSlice({
  name: "cohorts",
  initialState,
  reducers: {
    selectCohort: (state, action) => {
      state.selectedCohort = action.payload;
      localStorage.setItem("cohortSelected", action.payload);
    },
  },
  extraReducers: {
    [getCohortsAction.pending]: (state) => {
      state.cohorts.loading = true;
    },
    [getCohortsAction.fulfilled]: (state, action) => {
      state.cohorts.data = action.payload;
      state.cohorts.loading = false;
    },
    [getCohortsAction.rejected]: (state, action) => {
      state.cohorts.loading = false;
    },
    [getCohortByIdAction.pending]: (state) => {
      state.cohort.loading = true;
    },
    [getCohortByIdAction.fulfilled]: (state, action) => {
      state.cohort.data = action.payload;
      state.cohort.loading = false;
    },
    [getCohortByIdAction.rejected]: (state, action) => {
      state.cohort.loading = false;
    },
    [getMyInvitations.pending]: (state) => {
      state.invitations.loading = true;
    },
    [getMyInvitations.fulfilled]: (state, action) => {
        const supervisor_invitations=action.payload.supervisor_invitations.map((item)=>({...item, type:"supervisor"}));
        const mentor_invitations=action.payload.mentor_invitations.map((item)=>({...item, type:"mentor"}));
        const porteur_invitations=action.payload.porteur_invitations.map((item)=>({...item, type:"porteur"}));
        const invitations=[...supervisor_invitations, ...mentor_invitations, ...porteur_invitations].sort((a,b)=>new Date(b.created_at)-new Date(a.created_at));

      state.invitations.data = invitations;
      state.invitations.loading = false;
    },
    [getMyInvitations.rejected]: (state, action) => {
      state.invitations.loading = false;
    },
  },
});

export const { selectCohort } = cohortSlice.actions;
export default cohortSlice.reducer;
