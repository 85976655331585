import { Box, FormControl, IconButton, InputLabel, OutlinedInput } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Iconify from "src/components/Iconify";
import useIsMentor from "src/hooks/useIsMentor";
import axiosInstance from "src/libs/axios";
import { updateProject } from "src/stores/projects/projectsSlice";
import { BASE_URL } from "src/utils/constants";

function Step1() {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects.projects);
  const projectId = useSelector((state) => state.projects.selectedProject);
  const currentProject = projects.find((project) => project.pk === projectId);
  const {isMentor,isSupervisor}=useIsMentor()



  const handleChange = (event) => {
    dispatch(updateProject({ ...currentProject, name: event.target.value }));
  };
  const handleImageChange = async(img) => {
    const formData=new FormData();
    formData.append("picture", img);
    const res= await axiosInstance.patch(`/projects/${currentProject.pk}/`, formData)
    dispatch(updateProject(res.data));

  };
  return (
    <FormControl sx={{ minWidth: "300px", mt: 3 }}>
      <InputLabel htmlFor="component-outlined">Dénomination social</InputLabel>
      <OutlinedInput
        readOnly={isMentor||isSupervisor}
        id="DS"
        value={currentProject ? currentProject.name : ""}
        onChange={handleChange}
        label="Dénomination social"
      />
      <ImageUploader imgURL={currentProject?.picture}  handleImageChange={handleImageChange}/>

    </FormControl>
  );
}

export default Step1;


const ImageUploader = ({ imgURL,handleImageChange }) => {

  const {isMentor,isSupervisor}=useIsMentor()


  const avatarSrc =  `${BASE_URL}${imgURL}`;

  const previewUploadedImage = (e) => {
    
    handleImageChange(e.target.files[0]);
  };

  return (
    <Box
      className="funder-avatar-container"
      sx={{
        position: "relative",
        mt: 5,
        width: "100%",
        backgroundColor: "rgba(0,0,0,0.1)",
      }}
    >
      {!isMentor&&!isSupervisor&&<IconButton
        className="funder-avatar-btn"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          opacity: 0,
          zIndex: 1,
          width: "100%",
          height: "100%",
          borderRadius: "0",
        }}
        color="primary"
        aria-label="upload picture"
        component="label"
      >
        <input
          hidden
          accept="image/*"
          type="file"
          onChange={previewUploadedImage}
        />
        <Iconify icon="ic:outline-add-photo-alternate" />
      </IconButton>}
      <img
        className="funder-avatar"
        src={avatarSrc}
        alt="your profile"
        style={{
          width: "100%",
          height: "200px",
          objectFit: "contain",
          mx: "auto",
          borderRadius: "0",
        }}
      />
     
    </Box>
  );
};