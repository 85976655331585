import { Box, Button, IconButton, Popover } from "@mui/material";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCanvasEntryAction,
  updateCanvasEntryAction,
} from "src/stores/canvas/canvasSlice";

import { COLORS, getAccentColor } from "src/utils/constants";
import ColorCircle from "../common/ColorCircle";
import Iconify from "../Iconify";
import ConfirmDeleteModal from "../stickynotes/ConfirmDeleteModal";
// import ConfirmDeleteModal from "./ConfirmDeleteModal";

export default function Note({ note }) {
  const [explication, setExplication] = useState(note.explication);
  const [noteColor, setNoteColor] = useState(note.color);
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  //   const {isMentor,isSupervisor} = useIsMentor();

  const update = (e) => {
    setExplication(e.target.value);
  };
  const debouncedUpdate = useRef(
    debounce((note, newData) => {
      dispatch(updateCanvasEntryAction({ ...note, ...newData }));
    }, 500)
  ).current;

  const changeNoteColor = (color) => {
    setNoteColor(color.primary);
  };
  useEffect(() => {
    debouncedUpdate(note, { explication, color:noteColor });
    return () => {
      debouncedUpdate.cancel();
    };
  }, [explication, noteColor, note, debouncedUpdate]);
  const handleDelete = async (note) => {
    await dispatch(
      deleteCanvasEntryAction({
        canvasType: note.canvasType,
        id: note.id,
      })
    ).unwrap();
  };
  const anchorEl = useRef();
  const [open, setopen] = useState(false);
  return (
    <Box
      className="note"
      sx={{
        background: noteColor,
        color: getAccentColor(noteColor),
        p: 2,
        m: 1,
        fontSize: "0.8em",
        height: "150px",
        overflow: "hidden",
        position: "relative",

        boxShadow:
          "0px 3px 2px rgba(0, 0, 0, 0.16), 0px 3px 2px rgba(0, 0, 0, 0.23); ",
      }}
    >
      <Box
        ref={anchorEl}
        className="note-action"
        sx={{
          position: "absolute",
          top: "0",
          right: "0",
          width: "0",
          height: "0",
          borderLeft: "25px solid  transparent",
          borderRight: "25px solid rgba(255, 255, 255, 0.5)",
          borderTop: "25px solid rgba(255, 255, 255, 0.5)",
          borderBottom: "25px solid transparent",
        }}
      >
        <IconButton
          color="default"
          sx={{
            position: "absolute",
            top: "-25px",
            right: "-25px",
          }}
          onClick={() => setopen(true)}
        >
          <Iconify
            icon="akar-icons:more-horizontal"
            sx={{ width: 16, height: 16 }}
          />
        </IconButton>
      </Box>

      <textarea
        // readOnly={isMentor||isSupervisor}
        value={explication}
        onChange={update}
        maxLength={150}
        style={{
          color: "inherit",
          background: "transparent",
          border: "none",
          outline: "none",
          height: "100%",
          width: "100%",
          resize: "none",
          fontFamily: "inherit",
          fontSize: "inherit",
          fontWeight: "inherit",
          fontStyle: "inherit",
          wordBreak: "break-word",
        }}
      />
      <ConfirmDeleteModal
        open={modalOpen}
        setModalOpen={setModalOpen}
        action={() => handleDelete(note)}
      />
      <Popover
        id="notes"
        open={open}
        anchorEl={anchorEl.current}
        onClose={() => setopen(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            display: "grid",
            p: 0.5,
            gridTemplateColumns: "1fr 1fr 1fr",
          }}
        >
          {COLORS.map((color) => (
            <IconButton
              sx={{ m: 0, p: 0.5 }}
              onClick={() => {
                setopen(false);
                changeNoteColor(color);
              }}
              key={color.id}
            >
              <ColorCircle primary={color.primary} accent={color.accent} />
            </IconButton>
          ))}
        </Box>
        <Button
          fullWidth
          color="error"
          onClick={() => {
            setopen(false);
            setModalOpen(true);
          }}
        >
          <Iconify icon="heroicons-outline:trash" sx={{ mr: 1 }} />
        </Button>
      </Popover>
    </Box>
  );
}
