import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {  Card, CardContent, Grid } from "@mui/material";

import Page from "src/components/Page";

import { updateProfile } from "src/stores/auth/userSlice";

import AvatarChanger from "src/components/profile/AvatarChanger";
import ProfileForm from "src/components/profile/ProfileForm";

function Profile() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [newImage, setNewImage] = useState(null);

  const handleSubmit = async (data) => {
    const updateObj = {
      id: user.pk,
      ...data,
    };
    if (newImage) {
      updateObj.picture = newImage;
    }

    await dispatch(updateProfile(updateObj)).unwrap();
  };
  return (
    <Page title="Profile" sx={{ height: "100%" }}>
      <Grid container spacing={1} sx={{ height: "100%" }}>
        <Grid item xs={12}>
          <Card sx={{ height: "100%" }}>
            <CardContent sx={{ height: "100%" }}>
              <ProfileForm submit={handleSubmit}>
                <AvatarChanger setNewImage={setNewImage} />
              </ProfileForm>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
}

export default Profile;
