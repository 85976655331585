import { Badge, Box, Grid, IconButton } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useIsMentor from "src/hooks/useIsMentor";
import BoxLoader from "src/router/components/BoxLoader";
import { updateBP, updateBPWithoutGet } from "src/stores/bp/bpSlice";
import { textDataObject } from "src/utils/bp/CA";
import BPEditor from "../BP/BPEditor";
import SuggestionCard from "../common/SuggestionCard";
import Iconify from "../Iconify";

function TextBP({ currentStep }) {
  const dispatch = useDispatch();

  const textDataArray = useSelector((state) => state.bp.textDataArray);
  const loading = useSelector((state) => state.bp.loading);
  const [hasSuggestions, setHasSuggestions] = useState(false);

  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );

  const stepData = useMemo(
    () => textDataArray.find((step) => step.type === currentStep),
    [currentStep, textDataArray]
  );

  const textDataHandler = useCallback(
    (data) => {
      const obj = textDataObject(textDataArray, data, currentStep);
      dispatch(
        updateBPWithoutGet({ projectId: selectedProject, payload: obj })
      );
    },
    [currentStep, dispatch, selectedProject, textDataArray]
  );
  const suggHandler = useCallback(
    (data) => {
      const obj = textDataObject(textDataArray, data, currentStep);
      dispatch(updateBP({ projectId: selectedProject, payload: obj }));
    },
    [currentStep, dispatch, selectedProject, textDataArray]
  );

  const [showSuggestion, setShowSuggestion] = useState(false);
  const { isSupervisor, isOwner } = useIsMentor();
  const handleSuggestion = (v) => {
    suggHandler(v);
  };
  return loading ? (
    <BoxLoader />
  ) : (
    <Grid container sx={{ px: 3 }}>
      <Grid
        item
        xs={6}
        sx={{ pr: "1rem",display: showSuggestion || isSupervisor ? "" : "none" }}
      >
        {isSupervisor ? (
          <strong>Votre suggestion:</strong>
        ) : (
          <strong>Suggestion du superviseur:</strong>
        )}
        <Box>
          <SuggestionCard
            mainContent={stepData?.value}
            setHasSuggestions={setHasSuggestions}
            type={`txt_${currentStep}`}
            handleSuggestion={handleSuggestion}
          />
        </Box>
      </Grid>

      <Grid
        item
        xs={showSuggestion || isSupervisor ? 6 : 12}
        sx={{ position: "relative", pl: "1rem" }}
      >
        {(isOwner || isSupervisor) && (
          <>
            <strong> Contenu:</strong>
          </>
        )}
        {isOwner && (
          <>
            <IconButton
              onClick={() => setShowSuggestion((c) => !c)}
              sx={{
                position: "absolute",
                top: "50%",
                left: "-1.5rem",
              }}
              color="primary"
              title="Voir la suggestion"
            >
              <Badge badgeContent={hasSuggestions ? "!" : null} color="error">
                <Iconify
                  icon={
                    showSuggestion
                      ? "material-symbols:chevron-left-rounded"
                      : "material-symbols:chevron-right-rounded"
                  }
                />
              </Badge>
            </IconButton>
          </>
        )}
        <Box>
          <BPEditor dataHandler={textDataHandler} stepData={stepData} />
        </Box>
      </Grid>
    </Grid>
  );
}

export default TextBP;
