import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { useDispatch, useSelector } from "react-redux";
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Snackbar,
  Alert,
  Slide,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import Iconify from "../../../components/Iconify";
import { loginAction } from "src/stores/auth/userSlice";

// ----------------------------------------------------------------------

export default function LoginForm() {
  /**
   * hooks instanciation
   */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /**
   * selectores
   */
  const user = useSelector((state) => state.user);

  /**
   * states
   */
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);

  /**
   * effects
   */
  useEffect(() => {
    if (user.status === "success") {
      navigate("/");
    }
  }, [navigate, user]);

  /**
   * validators
   */
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email invalide")
      .required("Email requis"),
    password: Yup.string().required("Mot de passe requis"),
  });
  /**
   * formik
   */
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      try {
        await dispatch(
          loginAction({ ...values, username: values.email })
        ).unwrap();
      } catch (error) {
        setError(error);
      }
    },
  });

  /**
   * helpers
   */
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleCloseSnackbar = () => {
    setError(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!error}
        onClose={handleCloseSnackbar}
        message={error}
        autoHideDuration={3000}
        TransitionProps={{ onExited: handleCloseSnackbar }}
        TransitionComponent={(props) => <Slide {...props} direction="down" />}
        key={"topcenter"}
      >
        <Alert
          variant="filled"
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email"
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label="Mot de passe"
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            <FormControlLabel
              sx={{ visibility: "hidden" }}
              control={
                <Checkbox
                  {...getFieldProps("remember")}
                  checked={values.remember}
                />
              }
              label="Remember me"
            />

            <Link
              component={RouterLink}
              variant="subtitle2"
              to="/reinitialiser-mot-de-passe"
              underline="hover"
            >
              Vous avez oublié votre mot de passe ?{" "}
            </Link>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Login
          </LoadingButton>
        </Form>
      </FormikProvider>
    </>
  );
}
