import React, { useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';

import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import axiosInstance from "src/libs/axios";
import useIsMentor from "src/hooks/useIsMentor";
import { useLocation } from "react-router-dom";

const editorConfig = {
  extraPlugins: [uploadPlugin],
  
};
function Editor({
  stepData,
  selectedProject,
  currentStep,
  createAction,
  setSavingStatus,
  updateAction,
}) {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const { isMentor, isSupervisor } = useIsMentor();
  const location = useLocation()
  const [editor, setEditor] = useState(null);
  useEffect(() => {
    if (!stepData) {
      dispatch(
        createAction({
          project_id: selectedProject,
          explication: "",
          presentation: currentStep,
        })
      );
    } else {
      if(editor) editor.setData(stepData.explication)
      setValue(stepData.explication);
    }
  }, [createAction, currentStep, dispatch, editor, selectedProject, stepData]);
  const handleEditorData = useRef(
    debounce((value, oldData) => {
      dispatch(
        updateAction({
          ...oldData,
          explication: value,
        })
      );
    }, 500)
  ).current;

  const handleChange = (value) => {
    if (value === stepData?.explication) return;
    setValue(value);
    dispatch(setSavingStatus("unsaved"));
    handleEditorData(value, {
      ...stepData,
      project_id: selectedProject,
      presentation: currentStep,
    });
  };
  return (
    <>
      {isMentor || isSupervisor ? (
        <div
          style={{ maxHeight: "350px", overflow: "auto" }}
          dangerouslySetInnerHTML={{ __html: value }}
        ></div>
      ) : (
        <CKEditor
          config={editorConfig}
          editor={ClassicEditor}
          onReady={(editor) => {
            setEditor(editor);
            console.log(editor.config.toolbar)
            editor.editing.view.change((writer) => {
              writer.setStyle(
                "height",
                "300px",
                editor.editing.view.document.getRoot()
              );
            });
          }}
          onChange={(event, editor) => {
            const data = editor.getData();

            handleChange(data);
          }}
        />
      )}
    </>
  );
}

export default Editor;

function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        const body = new FormData();
        loader.file.then((file) => {
          body.append("file", file);
          axiosInstance
            .post("/files/upload/", body)
            .then((res) => res.data)
            .then((res) => {
              resolve({
                default: res.file,
              });
            })
            .catch((err) => {
              reject(err);
            });
        });
      });
    },
  };
}
function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}
