import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { IconButton } from "@mui/material";
import Iconify from "./Iconify";
import ColorPicker from "./common/ColorPicker";

export default function ToolBar() {
  // const ToolbarButton = styled(Button)({
  //   backgroundColor: "#fff",
  //   color: "#3f3f3f",
  //   "&:hover": {
  //     backgroundColor: "#FFF",
  //   },
  // });

  return (
    <></>
    // <Box sx={{ flexGrow: 1 }}>
    //   <Toolbar variant="dense">
    //     {/* <ToolbarButton>
    //       <Typography variant="p">Insertion</Typography>
    //     </ToolbarButton>
    //     <ToolbarButton>
    //       <Typography variant="p">Format</Typography>
    //     </ToolbarButton>
    //     <ToolbarButton>
    //       <Typography variant="p">Outils</Typography>
    //     </ToolbarButton> */}

    //     <ColorPicker />

    //     <IconButton title="Voir video demo">
    //       <Iconify icon="akar-icons:video" sx={{ width: 18, height: 18 }} />
    //     </IconButton>
    //     <IconButton title="Voir exemple BMC">
    //       <Iconify icon="ci:grid" sx={{ width: 18, height: 18 }} />
    //     </IconButton>
    //   </Toolbar>
    // </Box>
  );
}
