import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Iconify from "../Iconify";
import * as Yup from "yup";
import { tableCellClasses } from "@mui/material/TableCell";
import { updateBP } from "src/stores/bp/bpSlice";
import { deleteRHArrayObject, updateRHArrayObject } from "src/utils/bp/CA";
import useIsMentor from "src/hooks/useIsMentor";
import RMSalary from "../SalarySubTables/RMSalary";
import DCSalary from "../SalarySubTables/DCSalary";
import Tasks from "../SalarySubTables/Tasks";
import { FormatCurrency } from "src/utils/formatNumber";

function RH({ currentStep }) {
  const typeRH =
    currentStep === "Ressource humaine affecté au département Marketing"
      ? "marketing"
      : currentStep === "Ressource humaine affecté au département commercial"
      ? "commercial"
      : currentStep ===
        "Ressource Humaine affectées au département recherche et développement"
      ? "rnd"
      : "production";
  const [year, setYear] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const handleAjouter = () => {
    setSelectedItem({
      isNew: true,
      name: "",
      contract_type: 1,
      ["number_months_" + year]: 12,
      ["number_" + year]: 1,
      ["gross_salary_" + year]: 1000,
    });
  };
  return (
    <>
      {selectedItem && (
        <ProductModal
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          year={year}
          typeRH={typeRH}
        />
      )}
      <Grid container spacing={1}>
        <Grid item xs={1}>
          <YearSelector currentYear={year} setYear={setYear} />
        </Grid>
        <Grid item xs={11}>
          <TableData
            year={year}
            handleAjouter={handleAjouter}
            setSelectedItem={setSelectedItem}
            typeRH={typeRH}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default RH;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    lineHeight: 1.4,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: theme.palette.common.white,
    fontWeight: "bold",
  },
}));
const TableData = ({ year, setSelectedItem, handleAjouter, typeRH }) => {
  const { isMentor, isSupervisor } = useIsMentor();

  const RH = useSelector((state) => state.bp.RH);
  const [itemToDelete, setItemToDelete] = useState(null);
  const rhDataArray = RH[typeRH];

  return (
    <TableContainer
      sx={{ maxHeight: "400px", overflow: "auto", position: "relative" }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <StyledTableRow sx={{ position: "sticky", top: 0, zIndex: 100 }}>
            <StyledTableCell>Post</StyledTableCell>
            <StyledTableCell align="center">Type de contrat</StyledTableCell>
            <StyledTableCell align="center">
              Nombre de mois à payer
            </StyledTableCell>
            <StyledTableCell align="center">Nombre d'employés</StyledTableCell>
            <StyledTableCell align="center">Salaire brut</StyledTableCell>
            {!isMentor && !isSupervisor && (
              <StyledTableCell align="center">Actions</StyledTableCell>
            )}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rhDataArray.map((item) => (
            <TableRowExtended
              item={item}
              setItemToDelete={setItemToDelete}
              setSelectedItem={setSelectedItem}
              key={item.pk}
              year={year}
            />
          ))}
          <StyledTableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              position: "sticky",
              bottom: 0,
              zIndex: 100,
              backgroundColor: "#fff",
            }}
          >
            <StyledTableCell>
              {!isMentor && !isSupervisor && year === 1 && (
                <IconButton
                  variant="outlined"
                  color="primary"
                  onClick={handleAjouter}
                >
                  <Iconify icon="ant-design:plus-outlined" />
                </IconButton>
              )}
            </StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="center">
              Total:{" "}
              {FormatCurrency(
                rhDataArray.reduce((acc, cur) => {
                  return (
                    acc +
                    parseFloat(
                      cur["gross_salary_" + year] *
                        cur["number_" + year] *
                        cur["number_months_" + year]
                    )
                  );
                }, 0)
              )}{" "}
              DT
            </StyledTableCell>
            {!isMentor && !isSupervisor && (
              <StyledTableCell align="right"></StyledTableCell>
            )}
          </StyledTableRow>
        </TableBody>
      </Table>
      <DeleteDialaog
        setItemToDelete={setItemToDelete}
        itemToDelete={itemToDelete}
      />
    </TableContainer>
  );
};

const YearSelector = ({ currentYear, setYear }) => {
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const projects = useSelector((state) => state.projects.projects);
  const project = projects.find((p) => p.pk === selectedProject);
  const years = useMemo(() => {
    const years = [];
    for (let i = 1; i <= project?.project_study_duration; i++) {
      years.push(i);
    }
    return years;
  }, [project?.project_study_duration]);
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {years.map((year) => {
        return (
          <Button
            key={year}
            size="small"
            color="primary"
            variant={currentYear === year ? "contained" : "outlined"}
            onClick={() => setYear(year)}
            sx={{
              py: 0.5,
              px: 0,
              mb: 0.5,
              lineHeight: 1.4,
              fontSize: "0.75em",
            }}
          >
            Année <br />
            {year}
          </Button>
        );
      })}
    </Box>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
};
const ProductModal = ({ selectedItem, setSelectedItem, year, typeRH }) => {
  const dispatch = useDispatch();
  const allRHdataObj = useSelector((state) => state.bp.RH);
  const allRHdataArray = [
    ...allRHdataObj.commercial,
    ...allRHdataObj.marketing,
    ...allRHdataObj.production,
    ...allRHdataObj.rnd,
  ];

  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const projects = useSelector((state) => state.projects.projects);
  const project = projects.find((p) => p.pk === selectedProject);
  const years = useMemo(() => {
    const years = [];
    for (let i = 1; i <= project.project_study_duration; i++) {
      years.push(i);
    }
    return years;
  }, [project.project_study_duration]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const ValidationSchema = Yup.object().shape({
    contract_type: Yup.string().required("Ce champ est obligatoire"),
    number_months: Yup.number().required("Ce champ est obligatoire"),
    number: Yup.number().required("Ce champ est obligatoire"),
    gross_salary: Yup.number().required("Ce champ est obligatoire"),
    name: Yup.string().required("Ce champ est obligatoire"),
  });
  const open = selectedItem !== null;
  const isNewProduct = !!selectedItem.isNew;
  const closeModal = () => {
    setSelectedItem(null);
  };
  const formik = useFormik({
    initialValues: {
      name: selectedItem.name,
      contract_type: selectedItem.contract_type,
      number_months: parseFloat(selectedItem["number_months_" + year]),
      number: parseFloat(selectedItem["number_" + year]),
      gross_salary: parseFloat(selectedItem["gross_salary_" + year]),
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      const newDataObj = {
        ...selectedItem,
        name: values.name,
        contract_type: values.contract_type,
        type: typeRH,
        ["number_months_" + year]: values.number_months,
        ["number_" + year]: values.number,
        ["gross_salary_" + year]: values.gross_salary,
      };
      if (isNewProduct) {
        years.forEach((year) => {
          if (year > 1) {
            newDataObj["number_months_" + year] = newDataObj["number_months_1"];
            newDataObj["number_" + year] = newDataObj["number_1"];
            newDataObj["gross_salary_" + year] = newDataObj["gross_salary_1"];
          }
        });
      }
      setIsSubmitting(true);
      await dispatch(
        updateBP({
          projectId: selectedProject,
          payload: updateRHArrayObject(allRHdataArray, newDataObj),
        })
      ).unwrap();
      setIsSubmitting(false);
      closeModal();
    },
  });
  const { handleSubmit, getFieldProps, touched, errors } = formik;

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Card>
          <CardContent>
            <FormikProvider value={formik}>
              <Typography variant="h6">
                {isNewProduct ? "Nouvelle entrée" : "Modifier entrée"}
              </Typography>
              <Divider sx={{ mb: 5, mt: 1 }} />
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <TextField
                    sx={{ mb: 2 }}
                    autoComplete="post-name"
                    type="text"
                    label="Post"
                    {...getFieldProps("name")}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <FormControl sx={{ mb: 2 }}>
                    <InputLabel id="Type-contrat-label">
                      Type de contrat
                    </InputLabel>
                    <Select
                      labelId="Type-contrat-label"
                      id="Type-contrat"
                      value={formik.values.contract_type}
                      label="Type de contrat"
                      onChange={(e) => {
                        formik.setFieldValue("contract_type", e.target.value);
                      }}
                    >
                      <MenuItem value={1}>CDI</MenuItem>
                      <MenuItem value={2}>CIVP</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    sx={{ mb: 2 }}
                    autoComplete="product-quantity"
                    type="number"
                    label="Nombre de mois"
                    {...getFieldProps("number_months")}
                    error={Boolean(
                      touched.number_months && errors.number_months
                    )}
                    helperText={touched.number_months && errors.number_months}
                  />
                  <TextField
                    sx={{ mb: 2 }}
                    type="number"
                    autoComplete="product-uprice"
                    label="Nombre d'employés"
                    {...getFieldProps("number")}
                    error={Boolean(touched.number && errors.number)}
                    helperText={touched.number && errors.number}
                  />
                  <TextField
                    type="number"
                    autoComplete="product-uprice"
                    label="Salaire"
                    {...getFieldProps("gross_salary")}
                    error={Boolean(touched.gross_salary && errors.gross_salary)}
                    helperText={touched.gross_salary && errors.gross_salary}
                  />
                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <LoadingButton
                      sx={{ mt: 3 }}
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      {isNewProduct ? "Ajouter" : "Modifier"}
                    </LoadingButton>
                  </Box>
                </Box>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};
const DeleteDialaog = ({ setItemToDelete, itemToDelete }) => {
  const [isSubmitting, setisSubmitting] = useState(false);
  const dispatch = useDispatch();
  const allRHdataObj = useSelector((state) => state.bp.RH);
  const allRHdataArray = [
    ...allRHdataObj.commercial,
    ...allRHdataObj.marketing,
    ...allRHdataObj.production,
    ...allRHdataObj.rnd,
  ];

  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );

  const open = itemToDelete !== null;
  const handleClose = () => {
    setItemToDelete(null);
  };
  const deleteProduct = async () => {
    setisSubmitting(true);
    await handleDelete(itemToDelete);
    setisSubmitting(false);
    handleClose();
  };
  const handleDelete = async (id) => {
    await dispatch(
      updateBP({
        projectId: selectedProject,
        payload: deleteRHArrayObject(allRHdataArray, id),
      })
    ).unwrap();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Supprimer l'entrée"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Êtes-vous sûr de vouloir supprimer cette entrée ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isSubmitting}>
          Annuler
        </Button>
        <Button
          color="error"
          onClick={deleteProduct}
          autoFocus
          disabled={isSubmitting}
        >
          Effacer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TableRowExtended = ({ item, setItemToDelete, setSelectedItem, year }) => {
  const { isMentor, isSupervisor } = useIsMentor();
  const getContractType = (contract_type) => {
    switch (contract_type) {
      case 1:
        return "CDI";
      case 2:
        return "CIVP";
      default:
        return "N/A";
    }
  };
  const [open, setOpen] = useState(false);

  return (
    <>
      <StyledTableRow>
        <StyledTableCell>{item.name}</StyledTableCell>
        <StyledTableCell align="center">
          {getContractType(item.contract_type)}
        </StyledTableCell>
        <StyledTableCell align="center">
          {parseFloat(item["number_months_" + year])}
        </StyledTableCell>
        <StyledTableCell align="center">
          {parseFloat(item["number_" + year])}
        </StyledTableCell>
        <StyledTableCell align="center">
          {parseFloat(item["gross_salary_" + year])} DT
        </StyledTableCell>
        <StyledTableCell align="right">
          <IconButton
            sx={{ mr: 1 }}
            title="plus"
            onClick={() => setOpen((o) => !o)}
          >
            <Iconify
              icon={open ? "mdi:chevron-up" : "mdi:chevron-down"}
              sx={{ height: 18, width: 18 }}
            />
          </IconButton>
          {!isMentor && !isSupervisor && (
            <>
              <IconButton
                title="Effacer"
                color="error"
                sx={{ mr: 1 }}
                onClick={() => {
                  setItemToDelete(item.pk);
                }}
              >
                <Iconify
                  icon="fa6-solid:trash-can"
                  sx={{ height: 16, width: 16 }}
                />
              </IconButton>
              <IconButton
                title="modifier"
                onClick={() => setSelectedItem(item)}
              >
                <Iconify
                  icon="akar-icons:edit"
                  sx={{ height: 18, width: 18 }}
                />
              </IconButton>
            </>
          )}
        </StyledTableCell>
      </StyledTableRow>
      <RHRowModal item={item} open={open} setOpen={setOpen} year={year} />
    </>
  );
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const styleModalTable = {
  mx: "auto",
  mt: 4,
  backgroundColor: "white",
  width: "80vw", border: "1px solid #D3D3D3",
  p: 2,
};
const RHRowModal = ({ open, setOpen, item, year }) => {
  const [value, setValue] = useState(0);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  return (
    <Modal
      open={open}
      
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleModalTable }>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Tâches" {...a11yProps(0)} />
          <Tab label="Investissement matériel et immatériel" {...a11yProps(1)} />
          <Tab label="Dépenses courantes" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Tasks data={item.tasks} post={item.pk} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <RMSalary data={item.rm} post={item.pk} year={year}/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <DCSalary data={item.dc} post={item.pk} year={year} />
        </TabPanel>
        <Box sx={{display:'flex',justifyContent:"flex-end",p:2}}>
        <Button onClick={()=>setOpen(false)} 
          variant="contained"
          color="primary"
         
        >
        Fermer
      </Button>
      </Box>
      </Box>
      
    </Modal>
  );
};
