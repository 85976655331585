import * as Yup from "yup";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { useDispatch } from "react-redux";
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
  Slide,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import { confirmPasswordReset } from "src/stores/auth/userSlice";
import Iconify from "src/components/Iconify";

// ----------------------------------------------------------------------

export default function ResetPasswordConfirmForm() {
  /**
   * hooks instanciation
   */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uid, token } = useParams();
  /**
   * selectores
   */

  /**
   * states
   */
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);

  /**
   * validators
   */
  const LoginSchema = Yup.object().shape({
    password1: Yup.string()
      .required("Mot de passe requis")
      .min(
        8,
        "Mot de passe trop court - doit contenir au moins 8 caractères. Au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial"
      )
      .minLowercase(1, "Doit contenir au moins une lettre minuscule")
      .minUppercase(1, "Doit contenir au moins une lettre majuscule")
      .minNumbers(1, "Doit contenir au moins un chiffre")
      .minSymbols(1, "Doit contenir au moins un caractère spécial"),
    password2: Yup.string().oneOf(
      [Yup.ref("password1"), null],
      "Les mots de passe ne correspondent pas"
    ),
  });
  /**
   * formik
   */
  const formik = useFormik({
    initialValues: {
      password1: "",
      password2: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      try {
        await dispatch(
          confirmPasswordReset({ ...values, uid, token })
        ).unwrap();
        navigate("/", { replace: true });
      } catch (error) {
        setError(error);
      }
    },
  });

  /**
   * helpers
   */
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleCloseSnackbar = () => {
    setError(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!error}
        onClose={handleCloseSnackbar}
        message={error}
        autoHideDuration={3000}
        TransitionProps={{ onExited: handleCloseSnackbar }}
        TransitionComponent={(props) => <Slide {...props} direction="down" />}
        key={"topcenter"}
      >
        <Alert
          variant="filled"
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label="Mot de passe"
              {...getFieldProps("password1")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password1 && errors.password1)}
              helperText={touched.password1 && errors.password1}
            />
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label="Confirmation du mot de passe"
              {...getFieldProps("password2")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password2 && errors.password2)}
              helperText={touched.password2 && errors.password2}
            />
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ mt: 3 }}
          >
            Changer le mot de passe
          </LoadingButton>
        </Form>
      </FormikProvider>
    </>
  );
}
