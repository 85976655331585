import {
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CommentWrapper from "src/components/comments/CommentWrapper";
import Description from "src/components/common/Description";
import FicheProjetView from "src/components/FicheProjet/FicheProjetView";
import Synthesis from "src/components/FicheProjet/Synthesis";
import Iconify from "src/components/Iconify";
import Page from "src/components/Page";
import useIsMentor from "src/hooks/useIsMentor";
import {
  setStepFiche,
  updateProjectAction,
} from "src/stores/projects/projectsSlice";
import { FICHE_STEPS } from "src/utils/constants";
import decriptions from "../utils/descriptions.json";

function FicheProjet() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [saving, setSaving] = useState(false);
  const { isMentor, isSupervisor } = useIsMentor();


  const projects = useSelector((state) => state.projects.projects);
  const projectId = useSelector((state) => state.projects.selectedProject);
  const currentProject = projects.find((project) => project.pk === projectId);
  const steps = useMemo(
    () => (isMentor || isSupervisor ? [] :["SARL","SA"].includes(currentProject?.legal_form)? FICHE_STEPS: FICHE_STEPS.filter((step) => step.value !== "team" && step.value !== "Investisseurs")),
    [currentProject, isMentor, isSupervisor]
  );
  const currentStep = useSelector((state) => state.projects.ficheProjetStep);
  const activeStep =
    currentStep === -1
      ? steps.length
      : steps.findIndex((step) => step.value === currentStep);

  const setActiveStep = (step) => {
    if (step === steps.length) dispatch(setStepFiche(-1));
    else dispatch(setStepFiche(steps[step].value));
  };
  const handleNext = async () => {
    await dispatch(updateProjectAction(currentProject)).unwrap();
    setActiveStep(activeStep + 1);
  };
  const save = async () => {
    setSaving(true);
    await dispatch(updateProjectAction(currentProject)).unwrap();
    setSaving(false);
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  useEffect(() => {
    if (projectId !== -1) {
      if (isMentor || isSupervisor) dispatch(setStepFiche(-1));
      else{ 
        if (location.state?.step) {
          dispatch(setStepFiche(location.state.step));
        }else
       { dispatch(setStepFiche(steps[0].value));}}
    }
  }, [dispatch, isMentor, isSupervisor, location, projectId]);
  return (
    <Page
      title="Fiche projet"
      style={{
        border: "1px solid #ECECEC",
        borderRadius: "10px",
        padding: " 1rem ",
        height: "98%",
      }}
    >
      <Grid container spacing={1} sx={{ height: "100%" }}>
        <Grid
          item
          md={activeStep === steps.length ? 12 : 9}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box sx={{ pt: 1, px: 3 }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => {
                return (
                  <Step key={index}>
                    <StepLabel
                      style={{ cursor: "pointer" }}
                      onClick={() => setActiveStep(index)}
                      title={label.title}
                    />
                  </Step>
                );
              })}
            </Stepper>
          </Box>
          {activeStep === steps.length ? (
            <Box sx={{ p: 3 }}>
              <CommentWrapper currentPage={`ficheprojet_${currentStep}`} />

              <Synthesis
                setActiveStep={
                  isMentor || isSupervisor ? setActiveStep : function () {}
                }
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  pt: 1,
                  justifyContent: "space-between",
                }}
              >
                <Button
                  size="small"
                  color="inherit"
                  variant="outlined"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1, borderRadius: "0" }}
                >
                  Précédent
                </Button>
                <Button
                  onClick={() => navigate("/description-projet")}
                  size="small"
                  variant="contained"
                  sx={{ borderRadius: "0" ,textTransform: "none"}}
                >
                  Passer à l'étape suivante
                  <Iconify icon="ant-design:caret-right-filled" />
                </Button>
              </Box>
            </Box>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0.5rem",
                  marginRight: "2rem",
                }}
              >
                <Typography sx={{ my: 1, px: 3, fontSize: "0.8em" }}>
                  <strong style={{ color: "#2196F3" }}>
                    Etape {activeStep + 1}
                  </strong>
                  {" " + steps[activeStep]?.title}
                </Typography>
                {activeStep !== 2 && (
                  <CommentWrapper currentPage={`ficheprojet_${currentStep}`} />
                )}
              </div>

              <Description text={decriptions.ficheProjet[currentStep]} />
              <Box sx={{ flex: 1, px: 3 }}>
                <FicheProjetView />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 1 }}>
                <Button
                  size="small"
                  color="inherit"
                  variant="outlined"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1, borderRadius: "0" }}
                >
                  Précédent
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {/* {activeStep !== steps.length - 1 && ( */}
                {/* <> */}{" "}
                {/* <Button
                  variant="outlined"
                  color="inherit"
                  onClick={handleNext}
                  sx={{ mr: 1, borderRadius: "0" }}
                  size="small"
                >
                  Ignorer
                </Button> */}
                <Button
                  onClick={handleNext}
                  size="small"
                  variant="outlined"
                  sx={{ borderRadius: "0" }}
                >
                  {activeStep === steps.length - 1 ? "Terminer" : "Suivant"}
                </Button>
                {/* </> */}
                {/* )} */}
                {/* {activeStep === steps.length - 1 && (
                  <Button
                    onClick={save}
                    size="small"
                    variant="outlined"
                    sx={{ borderRadius: "0" }}
                    disabled={saving}
                  >
                    {saving ? "En cours ..." : "Terminer"}
                  </Button>
                )} */}
              </Box>
            </>
          )}
        </Grid>
        {activeStep !== steps.length && (
          <Grid item md={3}>
            <Synthesis setActiveStep={setActiveStep} />
          </Grid>
        )}
      </Grid>
    </Page>
  );
}

export default FicheProjet;
