import {
  Alert,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { FormatCurrency } from "src/utils/formatNumber";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    lineHeight: 1.4,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
}));
function Finacement({ data, investissements }) {
  const problem =
    data.reduce((acc, el) => acc + parseFloat(el.percentage), 0) === 0;
  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <TableContainer
          sx={{ maxHeight: "500px", overflow: "auto", position: "relative" }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <StyledTableRow sx={{ position: "sticky", top: 0, zIndex: 100 }}>
                <StyledTableCell>Nom</StyledTableCell>
                <StyledTableCell align="center">Valeur (DT)</StyledTableCell>
                <StyledTableCell align="center">
                  Pourcentage (%)
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {investissements.map((item, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{item.name}</StyledTableCell>
                  <StyledTableCell align="center">
                    {FormatCurrency(parseFloat(item.amount))}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {FormatCurrency(parseFloat(item.percentage))}
                  </StyledTableCell>
                </StyledTableRow>
              ))}

              <StyledTableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  position: "sticky",
                  bottom: 0,
                  zIndex: 100,
                  backgroundColor: "#000",
                  color: "#fff",
                }}
              >
                <StyledTableCell
                  sx={{
                    backgroundColor: "#000",
                    color: "#fff",
                  }}
                ></StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    backgroundColor: "#000",
                    color: "#fff",
                  }}
                >
                  Total:{" "}
                  {FormatCurrency(investissements.reduce(
                    (curr, item) => parseFloat(item.amount) + curr,
                    0
                  ))}{" "}
                  DT
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  sx={{
                    backgroundColor: "#000",
                    color: "#fff",
                  }}
                ></StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={6}>
        {problem ? (
          <Alert severity="error">
            Si le total de financement de votre projet est supérieur à 150 000
            et sa forme juridique est SARL ou personne physique: Vous devez
            changer de forme juridique pour passer a SARL ou SA ou réduire
            l'investissement à 150 000
          </Alert>
        ) : (
          <TableContainer
            sx={{ maxHeight: "500px", overflow: "auto", position: "relative" }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <StyledTableRow
                  sx={{ position: "sticky", top: 0, zIndex: 100 }}
                >
                  <StyledTableCell>Nom</StyledTableCell>
                  <StyledTableCell align="center">Valeur (DT)</StyledTableCell>
                  <StyledTableCell align="center">
                    Pourcentage (%)
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{item.name}</StyledTableCell>
                    <StyledTableCell align="center">
                      {FormatCurrency(parseFloat(item.amount))}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {FormatCurrency(parseFloat(item.percentage))}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}

                <StyledTableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    position: "sticky",
                    bottom: 0,
                    zIndex: 100,
                    backgroundColor: "#000",
                    color: "#fff",
                  }}
                >
                  <StyledTableCell
                    sx={{
                      backgroundColor: "#000",
                      color: "#fff",
                    }}
                  ></StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      backgroundColor: "#000",
                      color: "#fff",
                    }}
                  >
                    Total:{" "}
                    {FormatCurrency(data.reduce(
                      (curr, item) => parseFloat(item.amount) + curr,
                      0
                    ))}{" "}
                    DT
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    sx={{
                      backgroundColor: "#000",
                      color: "#fff",
                    }}
                  ></StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
}

export default Finacement;
