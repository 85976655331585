export const getDCData = (data) => {
  return {
    "other":data.filter((item) => item.post),
    "d-marketing": data
      .filter((item) => item.type === "d-marketing"&&!item.post)
      .map((item, idx) => ({
        ...item,
        id: "m" + idx,
      })),
    "d-commercial": data
      .filter((item) => item.type === "d-commercial"&&!item.post)
      .map((item, idx) => ({
        ...item,
        id: "c" + idx,
      })),
    "d-production": data
      .filter((item) => item.type === "d-production"&&!item.post)
      .map((item, idx) => ({
        ...item,
        id: "p" + idx,
      })),
    "d-rnd": data
      .filter((item) => item.type === "d-rnd"&&!item.post)
      .map((item, idx) => ({
        ...item,
        id: "rnd" + idx,
      })),

    rest: data.filter(
      (item) => item.type !== "d-rnd" && item.type !== "d-marketing" && item.type !== "d-commercial"&& item.type !== "d-production"&&!item.post
    ),
  };
};

export const updateDCArrayObject = (array, newData) => {
  const newArray = [...array];

  if (newData.isNew) {
    newArray.push(newData);
  } else {
    const index = newArray.findIndex((item) => item.pk === newData.pk);
    newArray[index] = newData;
  }

  return {
    sheet: "Autres charges dexploitation",
    table: "Autres charges",
    data: newArray.map((item) => ({
      pk: item.pk,
      name: item.name,
      type: item.type,
      percentage_of_ca_1: item.percentage_of_ca_1,
      fixed_amount_1: item.fixed_amount_1,
      percentage_of_ca_2: item.percentage_of_ca_2,
      fixed_amount_2: item.fixed_amount_2,
      percentage_of_ca_3: item.percentage_of_ca_3,
      fixed_amount_3: item.fixed_amount_3,
      percentage_of_ca_4: item.percentage_of_ca_4,
      fixed_amount_4: item.fixed_amount_4,
      percentage_of_ca_5: item.percentage_of_ca_5,
      fixed_amount_5: item.fixed_amount_5,
      percentage_of_ca_6: item.percentage_of_ca_6,
      fixed_amount_6: item.fixed_amount_6,
      percentage_of_ca_7: item.percentage_of_ca_7,
      fixed_amount_7: item.fixed_amount_7,
      is_editable: true,
      post:item.post,
    })),
  };
};

export const deleteDCArrayObject = (array, id) => {
  const newArray = [...array].filter((item) => item.pk !== id);

  return {
    sheet: "Autres charges dexploitation",
    table: "Autres charges",
    data: newArray.map((item) => ({
      pk: item.pk,
      name: item.name,
      type: item.type,
      percentage_of_ca_1: item.percentage_of_ca_1,
      fixed_amount_1: item.fixed_amount_1,
      percentage_of_ca_2: item.percentage_of_ca_2,
      fixed_amount_2: item.fixed_amount_2,
      percentage_of_ca_3: item.percentage_of_ca_3,
      fixed_amount_3: item.fixed_amount_3,
      percentage_of_ca_4: item.percentage_of_ca_4,
      fixed_amount_4: item.fixed_amount_4,
      percentage_of_ca_5: item.percentage_of_ca_5,
      fixed_amount_5: item.fixed_amount_5,
      percentage_of_ca_6: item.percentage_of_ca_6,
      fixed_amount_6: item.fixed_amount_6,
      percentage_of_ca_7: item.percentage_of_ca_7,
      fixed_amount_7: item.fixed_amount_7,
      is_editable: true,
      post:item.post,
    })),
  };
};
