import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./auth/userSlice";
import bmcSlice from "./bmc/bmcSlice";
import bpSlice from "./bp/bpSlice";
import canvasSlice from "./canvas/canvasSlice";
import cohortSlice from "./cohort/cohortSlice";
import commentSlice from "./comments/commentSlice";
import NavConfigSlice from "./global/NavConfigSlice";
import mentorshipSlice from "./mentorship/mentorshipSlice";
import projectPresentationSlice from "./project-presentation/projectPresentationSlice";
import projectsSlice from "./projects/projectsSlice";
import secteurSlice from "./secteur/secteurSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    projects: projectsSlice,
    bmc: bmcSlice,
    projectPresentation: projectPresentationSlice,
    secteur: secteurSlice,
    bp: bpSlice,
    mentorship: mentorshipSlice,
    comments: commentSlice,
    layout: NavConfigSlice,
    cohort:cohortSlice,
    canvas:canvasSlice
  },
});
