// @mui
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect } from "react";
// components

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCohortsAction, selectCohort } from "src/stores/cohort/cohortSlice";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function CohortPopover() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cohorts,selectedCohort } = useSelector(
    (state) => state.cohort
  );
  useEffect(() => {
    if (
      !cohorts.loading  &&
      !cohorts.data.find((p) => p.id === selectedCohort)
    ) {
      dispatch(selectCohort(-1));
    }
  }, [cohorts, dispatch, selectedCohort]);

  useEffect(() => {
    if (selectedCohort < 0) {
      navigate("/organisation/cohortes");
    }
  }, [dispatch, navigate, selectedCohort]);
  useEffect(() => {
    dispatch(getCohortsAction());
  }, [dispatch]);
  const handleChange = (e) => {
    dispatch(selectCohort(e.target.value));
  };
  return (
    <>
      <FormControl sx={{ minWidth: 200 }} size="small">
        <InputLabel id="project-select">Projet actuel</InputLabel>
        <Select
          labelId="project-select-label"
          id="project-select"
          value={selectedCohort}
          label="Projet actuel"
          onChange={handleChange}
        >
          {cohorts.data.map((c) => (
            <MenuItem key={c.id} value={c.id}>
              {c.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
