import { EMPATHY_STEPS } from "src/utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import Iconify from "../Iconify";
import { setStep } from "src/stores/canvas/canvasSlice";
import { EmpathyViewItem, EmpathyViewItemInline } from "./EmpathyViewItem";
import useIsMentor from "src/hooks/useIsMentor";

export default function EmpathyView() {
  const dispatch = useDispatch();
  const { isMentor, isSupervisor } = useIsMentor();

  const currentStep = useSelector((state) => state.canvas.currentStep);
  const changeStep = (step) => {
    if (!isMentor && !isSupervisor) {
      dispatch(setStep(step));
    }
  };
  const isCurrentStep = (step) => {
    if (!isMentor && !isSupervisor) {
      return currentStep === step;
    }
    return false;
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>
      <Box sx={{ width: "700px", height: "350px", position: "relative" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
            zIndex: "1",
            pointerEvents: "none",
          }}
        >
          <Step1Notes />
          <Step2Notes />
          <Step3Notes />
          <Step4Notes />
        </Box>
        <Box
          onClick={() => {
            changeStep(0);
          }}
          sx={{
            width: "100%",
            height: "100%",
            background: "black",
            position: "absolute",
            top: "2px",
            left: "0",
            clipPath: "polygon(0% 0%,0% 100%,50% 50%)",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              background: isCurrentStep(0) ? "#F7DE3A" : "white",

              clipPath: "polygon(0.5% 1%,0.5% 99%,49.5% 50%)",
            }}
          ></Box>
        </Box>
        <Box
          onClick={() => {
            changeStep(1);
          }}
          sx={{
            width: "100%",
            height: "100%",
            background: "black",
            position: "absolute",
            top: "2px",
            left: "0",
            clipPath: "polygon(0% 0%,100% 0%,50% 50%)",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              background: isCurrentStep(1) ? "#F7DE3A" : "white",

              clipPath: "polygon(0.5% 1%,99% 0.5%,49.5% 50%)",
            }}
          ></Box>
        </Box>
        <Box
          onClick={() => {
            changeStep(2);
          }}
          sx={{
            width: "100%",
            height: "100%",
            background: "black",
            position: "absolute",
            top: "2px",
            left: "0",
            clipPath: "polygon(0% 100%,100% 100%,50% 50%)",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              background: isCurrentStep(2) ? "#F7DE3A" : "white",

              clipPath: "polygon(0.5% 99%,99% 99.5%,49.5% 50%)",
            }}
          ></Box>
        </Box>
        <Box
          onClick={() => {
            changeStep(3);
          }}
          sx={{
            width: "100%",
            height: "100%",
            background: "black",
            position: "absolute",
            top: "2px",
            left: "0",
            clipPath: "polygon(100% 100%,100% 0%,50% 50%)",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              background: isCurrentStep(3) ? "#F7DE3A" : "white",

              clipPath: "polygon(99.5% 99.5%,99.5% 0.5%,49.5% 50%)",
            }}
          ></Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            width: "80px",
            height: "80px",
            top: "calc( 50% - 40px)",
            left: "calc( 50% - 40px)",
            borderRadius: "50%",
            background: "white",
          }}
        >
          <Iconify
            icon="iconoir:emoji-look-right"
            sx={{
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          width: "700px",
          height: "100px",
          position: "relative",
          border: "3px solid #000",
          display: "flex",
        }}
      >
        <Box
          onClick={() => {
            changeStep(4);
          }}
          sx={{
            width: "350px",
            height: "100%",
            borderRight: "1px solid black",
            cursor: "pointer",
            background: isCurrentStep(4) ? "#F7DE3A" : "white",
          }}
        >
          <small style={{ display: "flex", justifyContent: "center" }}>
            <p
              style={{
                border: "1px solid black",
                borderTop: "none",
                padding: "3px",
              }}
            >
              PROBLEMES
            </p>
          </small>
          <Step5Notes />
        </Box>
        <Box
          onClick={() => {
            changeStep(5);
          }}
          sx={{
            width: "350px",
            height: "100%",
            borderLeft: "1px solid black",
            cursor: "pointer",
            background: isCurrentStep(5) ? "#F7DE3A" : "white",
          }}
        >
          <small style={{ display: "flex", justifyContent: "center" }}>
            <p
              style={{
                border: "1px solid black",
                borderTop: "none",
                padding: "3px",
              }}
            >
              BESOINS
            </p>
          </small>
          <Step6Notes />
        </Box>
      </Box>
    </Box>
  );
}

const Step1Notes = () => {
  const { data } = useSelector((state) => state.canvas);
  const dataArray = data.filter((item) => item.type === EMPATHY_STEPS[0].value);
  const locations = [
    // { top: "15%", left: "-3em" },
    // { top: "calc(15% + 12em)", left: "-3em" },
    // { top: "30%", left: "7.1em" },
    { top: "calc(50% - 5em)", right: "calc( 50% + 11em)" },
    { top: "calc(50% - 10em)", right: "calc( 50% + 22em)" },
    { top: "calc(50% + 0.5em)", right: "calc( 50% + 22em)" },
  ];
  return (
    <>
      {dataArray.slice(0, locations.length).map((item, index) => {
        const location = locations[index];
        return (
          <EmpathyViewItem key={item.id} note={item} location={location} />
        );
      })}
    </>
  );
};
const Step2Notes = () => {
  const { data } = useSelector((state) => state.canvas);
  const dataArray = data.filter((item) => item.type === EMPATHY_STEPS[1].value);
  const locations = [
    { top: "-1em", right: "13em" },
    { top: "-1em", left: "13em" },
    { top: "2em", left: "24em" },
    { top: "2em", right: "24em" },
  ];
  return (
    <>
      {dataArray.slice(0, locations.length).map((item, index) => {
        const location = locations[index];
        return (
          <EmpathyViewItem key={item.id} note={item} location={location} />
        );
      })}
    </>
  );
};
const Step3Notes = () => {
  const { data } = useSelector((state) => state.canvas);
  const dataArray = data.filter((item) => item.type === EMPATHY_STEPS[2].value);
  const locations = [
    // { top: "15%", left: "-3em" },
    // { top: "calc(15% + 12em)", left: "-3em" },
    // { top: "30%", left: "7.1em" },
    { top: "calc(50% - 5em)", left: "calc( 50% + 11em)" },
    { top: "calc(50% - 10em)", left: "calc( 50% + 22em)" },
    { top: "calc(50% + 0.5em)", left: "calc( 50% + 22em)" },
  ];
  return (
    <>
      {dataArray.slice(0, locations.length).map((item, index) => {
        const location = locations[index];
        return (
          <EmpathyViewItem key={item.id} note={item} location={location} />
        );
      })}
    </>
  );
};
const Step4Notes = () => {
  const { data } = useSelector((state) => state.canvas);
  const dataArray = data.filter((item) => item.type === EMPATHY_STEPS[3].value);
  const locations = [
    { bottom: "-1em", right: "13em" },
    { bottom: "-1em", left: "13em" },
    { bottom: "2em", left: "24em" },
    { bottom: "2em", right: "24em" },
  ];
  return (
    <>
      {dataArray.slice(0, locations.length).map((item, index) => {
        const location = locations[index];
        return (
          <EmpathyViewItem key={item.id} note={item} location={location} />
        );
      })}
    </>
  );
};

const Step5Notes = () => {
  const { data } = useSelector((state) => state.canvas);
  const dataArray = data.filter((item) => item.type === EMPATHY_STEPS[4].value);

  return (
    <Box sx={{ display: "flex", gap: 1, mt: 1, px: 1 }}>
      {dataArray.slice(3).map((item, index) => {
        return <EmpathyViewItemInline key={item.id} note={item} />;
      })}
    </Box>
  );
};
const Step6Notes = () => {
  const { data } = useSelector((state) => state.canvas);
  const dataArray = data.filter((item) => item.type === EMPATHY_STEPS[5].value);

  return (
    <Box sx={{ display: "flex", gap: 1, mt: 1, px: 1 }}>
      {dataArray.slice(3).map((item, index) => {
        return <EmpathyViewItemInline key={item.id} note={item} />;
      })}
    </Box>
  );
};
