import axios from "axios";
import { refreshTokenAction } from "src/stores/auth/userSlice";
import { BASE_URL } from "src/utils/constants";
const axiosInstance = axios.create({
  baseURL: BASE_URL+"/",
  withCredentials: true,
});
export default axiosInstance;

export const setUpInterceptor = (store) => {
  const { dispatch } = store;
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (
        originalConfig.url !== "/rest-auth/login/" &&
        originalConfig.url !== "/rest-auth/token/refresh/" &&
        err.response
      ) {
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            await dispatch(refreshTokenAction()).unwrap();
            return axiosInstance(originalConfig);
          } catch (error) {
            return Promise.reject(error);
          }
        }
      }
      return Promise.reject(err);
    }
  );
};

// const setUpInterceptor = (store) => {
//   const handleError = async (error) => {
//     return Promise.reject(error);
//   };
//   console.log(store.getState());
//   instance.interceptors.request.use(async (config) => {
//     const token = store.getState();
//     if (token) {
//       // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
//       config.headers["Authorization"] = `Token ${token.api.token.key}`; // for Node.js Express back-end
//     }
//     /* your logic here */
//     return config;
//   });

//   instance.interceptors.response.use((response) => response, handleError);
// };

// instance.interceptors.request.use(
//   (config) => {
//     const token = useSelector((store)=>store.api.token)
//     if (token) {
//       // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
//       config.headers["authorization"] = `Token ${token}`; // for Node.js Express back-end
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// instance.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   async (err) => {<
//     const originalConfig = err.config;
//     if (originalConfig.url !== "/auth/signin" && err.response) {
//       // Access Token was expired
//       if (err.response.status === 401 && !originalConfig._retry) {
//         originalConfig._retry = true;
//         try {
//           const rs = await instance.post("/auth/refreshtoken", {
//             refreshToken: TokenService.getLocalRefreshToken(),
//           });
//           const { accessToken } = rs.data;
//           TokenService.updateLocalAccessToken(accessToken);
//           return instance(originalConfig);
//         } catch (_error) {
//           return Promise.reject(_error);
//         }
//       }
//     }
//     return Promise.reject(err);
//   }
// );

// export default instance;
