import { Box } from "@mui/material";
import React from "react";
import Chart from "react-apexcharts";

function NatDistribution({ data }) {
  const dataArray=[...data.countByNatCat,...data.countByNatScat]
 const labels=[]
 const series=[]
  dataArray.forEach((item)=>{
    labels.push(item.nat_category?item.nat_category:item.NAT_title)
    series.push(item.count)
  })
  console.log(labels,series)
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center",height:"100%" }}
    >
       <Box sx={{ display: "flex", gap: 4, p: 2 }}>
            <h4>
              <span style={{ color: "#900C3F", fontSize: "3em" }}>
                {data.projectsCount}
              </span>{" "}
              Projets accompagnés
            </h4>
            <h4>
              <span style={{ color: "#900C3F", fontSize: "3em" }}>
                {data.SupervisorsCount}
              </span>{" "}
              Superviseurs
            </h4>
            <h4>
              <span style={{ color: "#900C3F", fontSize: "3em" }}>
                {data.mentorsCount}
              </span>{" "}
              Mentors
            </h4>
          </Box>
      <Box 
        sx={{ flex: "1",width:{xs:"100%",lg:"55%"} }}
        >
      <Chart
        options={{
          labels: labels,
          dataLabels:{
            enabled:true,
            formatter: function (val, opts) {
              return opts.w.config.series[opts.seriesIndex]+" ("+val+"%)";
            }
          }
        }}
        type="pie"
        series={series}
      />
      </Box>
      <h3 style={{ margin: "1rem 0 " }}>
        Répartition des projets accompagnés selon secteur d'activité NAT 2009
      </h3>
    </Box>
  );
}

export default NatDistribution;
