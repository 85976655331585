import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import Iconify from "../Iconify";
import { tableCellClasses } from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import { FormatCurrency } from "src/utils/formatNumber";

function DCrecap() {
  const navigate = useNavigate();
  const [typeRM, setTypeRM] = useState("d-marketing");
  const handleEdit = () => {
    if (typeRM === "d-marketing") {
      navigate("/strategie-de-commercialisation", {
        state: { step: "Dépenses courantes du département Marketing" },
      });
    } else if (typeRM === "d-commercial") {
      navigate("/strategie-de-commercialisation", {
        state: { step: "Dépenses courantes du département commercial" },
      });
    } else if (typeRM === "d-production") {
      navigate("/plan-de-production-et-d-organisation", {
        state: { step: "Dépenses courantes du département Production" },
      });
    } else if (typeRM === "d-rnd") {
      navigate("/recherche-et-developpement", {
        state: {
          step: "Dépenses courantes communes du département recherche et développement",
        },
      });
    }
  };
  const [year, setYear] = useState(1);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <FormControl sx={{ minWidth: "200px" }}>
          <InputLabel htmlFor="component-outlined">Département</InputLabel>
          <Select
            size="small"
            value={typeRM}
            label="Département"
            onChange={(e) => setTypeRM(e.target.value)}
          >
            <MenuItem value="d-marketing">Marketing</MenuItem>
            <MenuItem value="d-commercial">Commercial</MenuItem>
            <MenuItem value="d-production">Production</MenuItem>
            <MenuItem value="d-rnd">R&D</MenuItem>
            {/* <MenuItem value="rest">Autre</MenuItem> */}
          </Select>
        </FormControl>
        <IconButton color="primary" title="Modifier" onClick={handleEdit}>
          <Iconify icon="akar-icons:edit" />
        </IconButton>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={1}>
          <YearSelector currentYear={year} setYear={setYear} />
        </Grid>
        <Grid item xs={11}>
          <TableData typeRM={typeRM} year={year} />
        </Grid>
      </Grid>
    </>
  );
}

export default DCrecap;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    lineHeight: 1.4,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: theme.palette.common.white,
    fontWeight: "bold",
  },
}));
const TableData = ({ typeRM, year }) => {
  const DC = useSelector((state) => state.bp.DC);
  const RH = useSelector((state) => state.bp.RH);
  const rmDataArray = useMemo(() => {
    let arr=DC[typeRM];
    RH[typeRM.replace("d-", "")]?.forEach((item) => {
      arr=[...arr, ...item.dc]
    });
    return arr;
  }, [DC, RH, typeRM]);


  return (
    <TableContainer
      sx={{ maxHeight: "400px", overflow: "auto", position: "relative" }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <StyledTableRow sx={{ position: "sticky", top: 0, zIndex: 100 }}>
            <StyledTableCell>Nature des charges</StyledTableCell>
            <StyledTableCell align="center">
              Montant charge fixe
            </StyledTableCell>
            <StyledTableCell align="center">
              Pourcentage chiffre d'affaire
            </StyledTableCell>
            <StyledTableCell align="center">Total</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rmDataArray.map((item) => (
            <StyledTableRow key={item.id}>
              <StyledTableCell>{item.name}</StyledTableCell>
              <StyledTableCell align="center">
                {parseFloat(item["fixed_amount_" + year])}
              </StyledTableCell>
              <StyledTableCell align="center">
                {parseFloat(item["percentage_of_ca_" + year])}% (
                {FormatCurrency(parseFloat(item["variable_amount_" + year]))})
              </StyledTableCell>
              <StyledTableCell align="center">
                {FormatCurrency(parseFloat(item["total_" + year]))}
              </StyledTableCell>
            </StyledTableRow>
          ))}
          <StyledTableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              position: "sticky",
              bottom: 0,
              zIndex: 100,
              backgroundColor: "#fff",
            }}
          >
            <StyledTableCell></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="center">
              Total:{" "}
              {FormatCurrency(
                rmDataArray.reduce((acc, cur) => {
                  return acc + parseFloat(cur["total_" + year]);
                }, 0)
              )}
              DT
            </StyledTableCell>
            {/* <StyledTableCell align="right"></StyledTableCell> */}
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const YearSelector = ({ currentYear, setYear }) => {
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const projects = useSelector((state) => state.projects.projects);
  const project = projects.find((p) => p.pk === selectedProject);
  const years = useMemo(() => {
    const years = [];
    for (let i = 1; i <= project?.project_study_duration; i++) {
      years.push(i);
    }
    return years;
  }, [project?.project_study_duration]);
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {years.map((year) => {
        return (
          <Button
            key={year}
            size="small"
            color="primary"
            variant={currentYear === year ? "contained" : "outlined"}
            onClick={() => setYear(year)}
            sx={{
              py: 0.5,
              px: 0,
              mb: 0.5,
              lineHeight: 1.4,
              fontSize: "0.75em",
            }}
          >
            Année <br />
            {year}
          </Button>
        );
      })}
    </Box>
  );
};
