import { Box } from "@mui/system";
import React, { useMemo } from "react";
import parse from "html-react-parser";
import { Typography } from "@mui/material";
import { getAccentColor, LEAN_STEPS } from "src/utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { setStep } from "src/stores/canvas/canvasSlice";
import useIsMentor from "src/hooks/useIsMentor";
import Iconify from "../Iconify";

export const LeanViewItem = ({
  content,
  borderStyle,
  limit = 2,
  orientation = "column",
  icon,
}) => {
  const dispatch = useDispatch();
  const { isMentor, isSupervisor } = useIsMentor();
  const steps = useMemo(
    () =>
      isMentor || isSupervisor
        ? [LEAN_STEPS[LEAN_STEPS.length - 1]]
        : LEAN_STEPS,
    [isMentor, isSupervisor]
  );
  const currentStep = useSelector((state) => state.canvas.currentStep);
  const data = useSelector((state) => state.canvas.data);
  const dataArray = useMemo(
    () => data.filter((item) => item.type === LEAN_STEPS[content.index].value),
    [content.index, data]
  );
  const chooseStep = () => {
    if (isMentor || isSupervisor) return;

    dispatch(setStep(content.index));
  };
  const isFinalStep = currentStep === steps.length - 1;
  const isActive = currentStep === content.index && !isFinalStep;
  return (
    <div
      onClick={chooseStep}
      style={{
        height: content.height,
        boxShadow: isActive
          ? "0px 0px 3px 3px rgba(3, 102, 214, 0.7) "
          : "none",
        position: "relative",
        zIndex: isActive ? "20" : "1",
        ...borderStyle,
        overflow: "hidden",
        overflowY: "auto",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          padding: "5px",
          background: "transparent",
          height: content.titleheight,
          width: content.titlewidth,
          fontSize: "10px",
          fontStyle: "light",
          fontWeight: "bold",
          fill: "#000000",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span style={{ color: icon ? "inherit" : "#a9a9a9" }}>
          {content.title}
        </span>
        {icon && (
          <Iconify
            icon={icon}
            sx={{ width: 14, height: 14, m: 0, p: 0, color: "#a9a9a9" }}
          />
        )}
      </div>
      {isFinalStep ? (
        <div
          style={{
            maxWidth: "#960px",
            padding: "5px 10px",
            display: "grid",
            gridTemplateColumns:
              orientation === "row"
                ? `repeat(${dataArray.length},1fr)`
                : limit === "1"
                ? `repeat(${dataArray.length > 4 ? 3 : 2},1fr)`
                : "1fr 1fr",
            gap: "5px",
          }}
        >
          {dataArray.map((item, i) => (
            <Box
              key={item.id}
              sx={{
                background: item.color,
                color: getAccentColor(item.color),
                p: 1,
                wordBreak: "break-word",

                fontSize: "10px",
                height:
                  orientation === "row"
                    ? "7.5em"
                    : limit !== "1"
                    ? "9.5em"
                    : "6em",
                overflow: "hidden",
                overflowY: "auto",
                margin:
                  orientation === "column" ? "0 0 0.5em 0" : "0 0.5em 0 0",
                boxShadow:
                  "  0px 3px 2px rgba(0, 0, 0, 0.16), 0px 3px 2px rgba(0, 0, 0, 0.23); ",
              }}
            >
              <p> {parse(item.explication)}</p>
            </Box>
          ))}
        </div>
      ) : (
        <div
          style={{
            maxWidth: "#960px",
            padding: "5px 10px",
            display: "flex",
            flexDirection: orientation,
          }}
        >
          {dataArray.slice(0, limit).map((item, i) => (
            <Box
              key={item.id}
              sx={{
                background: item.color,
                color: getAccentColor(item.color),
                p: 1,
                wordBreak: "break-word",

                fontSize: "10px",
                height: orientation === "column" ? "5.6em" : "6em",
                width: orientation === "column" ? "100%" : "30%",
                overflowY: "auto",
                margin:
                  orientation === "column" ? "0 0 0.5em 0" : "0 0.5em 0 0",
                boxShadow:
                  "  0px 3px 2px rgba(0, 0, 0, 0.16), 0px 3px 2px rgba(0, 0, 0, 0.23); ",
              }}
            >
              <p> {parse(item.explication)}</p>
            </Box>
          ))}
          {dataArray.length > limit && (
            <Typography variant="p" component="p" sx={{ fontSize: "10px" }}>
              +{dataArray.length - limit}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};
