import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import axiosInstance from "src/libs/axios";

function Reinvestissement() {
  const [year, setYear] = useState(2);
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const projects = useSelector((state) => state.projects.projects);
  const project = projects.find((p) => p.pk === selectedProject);
  const years = useMemo(() => {
    const years = [];
    for (let i = 2; i <= project.project_study_duration; i++) {
      years.push(i);
    }
    return years;
  }, [project.project_study_duration]);
  const shownYears = useMemo(() => {
    const years = [];
    for (let i = year; i <= project.project_study_duration; i++) {
      years.push(i);
    }
    return years;
  }, [project.project_study_duration, year]);

  const [data, setData] = useState([]);
  useEffect(() => {
    axiosInstance
      .get(`/business/getreinvestmentsummary/${selectedProject}/${year}/`)
      .then((res) => {
        const dataArray = [];
        Object.keys(res.data).forEach((key) => {
          dataArray.push({
            name: key,
            years: res.data[key].years,
            total: res.data[key].total,
          });
        });
        setData(dataArray);
      });
  }, [selectedProject, year]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <FormControl sx={{ minWidth: "200px" }}>
          <InputLabel htmlFor="component-outlined">Année</InputLabel>
          <Select
            size="small"
            value={year}
            label="Année"
            onChange={(e) => setYear(e.target.value)}
          >
            {years.map((year) => (
              <MenuItem value={year}>Année {year}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TableData years={shownYears} data={data} />
        </Grid>
      </Grid>
    </>
  );
}

export default Reinvestissement;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    lineHeight: 1.4,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const TableData = ({ years, data }) => {
  return (
    <TableContainer
      sx={{ maxHeight: "400px", overflow: "auto", position: "relative" }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <StyledTableRow sx={{ position: "sticky", top: 0, zIndex: 100 }}>
            <StyledTableCell>Nom</StyledTableCell>
            {years.map((year) => (
              <StyledTableCell align="center">Année {year}</StyledTableCell>
            ))}
            <StyledTableCell align="center">Total</StyledTableCell>
            <StyledTableCell align="center">VCN</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <StyledTableRow key={item.name}>
              <StyledTableCell>{item.name}</StyledTableCell>
              {years.map((year) => (
                <StyledTableCell align="center">
                  {Math.round(getYearValue(item, year, years))}
                </StyledTableCell>
              ))}
              <StyledTableCell align="center">{Math.round(getItemTotal(item, years))}</StyledTableCell>
              <StyledTableCell align="center">{Math.round(getItemVCN(item, years))}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const getYearValue = (item, year, years) => {
  if (years.length < item.years) {
    return (item.total / item.years);
  }

  if (years.indexOf(year) < item.years) {
    return (item.total / item.years);
  }
  return 0;
};
const getItemTotal = (item, years) => {
  let total = 0;
    years.forEach((year) => {
        total += getYearValue(item, year, years);
        });
    return total;
};
const getItemVCN = (item, years) => {
    return item.total-getItemTotal(item, years)
};
