import PropTypes from "prop-types";
// material
import { alpha, styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton } from "@mui/material";
// components
import Iconify from "../../components/Iconify";
//
import AccountPopover from "./AccountPopover";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getMyProjects } from "src/stores/projects/projectsSlice";
import ProjectPopover from "./ProjectPopover";
import Logo from "src/components/Logo";
import NotificationsPopover from "./NotificationsPopover";
import Notifications from "./Notifications";
import CohortPopover from "./CohortPopover";
import NotificationsOrg from "./NotificationsOrg";
import { useNavigate } from "react-router-dom";



// const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 64;

const RootStyle = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  // boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(2, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar, NoSideBar,canvaspicker }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(getMyProjects());
  }, [dispatch]);

  return (
    <RootStyle>
      <ToolbarStyle>
        {!NoSideBar && (
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: "text.primary", display: { lg: "none" } }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
        )}
        <Box
          sx={{
            height: "100%",
            width: "unset",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Logo
            sx={{
              height: "100%",
              width: "unset",
              display: "flex",
              alignItems: "center",

            }}
          />
        </Box>

        {/* {!NoSideBar && <Searchbar />} */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
          {canvaspicker&& <IconButton title="Canvas" onClick={()=>navigate("/canvas")}>
              <Iconify icon="codicon:table" />
            </IconButton>}
          {user.role!=="ORGANIZATION"&& <IconButton title="Aide" onClick={()=>navigate("/info")}>
              <Iconify icon="bi:info-circle" />
            </IconButton>}
          {!NoSideBar &&user.role!=="ORGANIZATION"&& <ProjectPopover />}
          {!NoSideBar &&user.role==="ORGANIZATION"&& <CohortPopover />}
          {user?.mentor&&<NotificationsPopover />}
          {user.role!=="ORGANIZATION"&&
          <>
          <NotificationsOrg />
          <Notifications />

          </>
          }
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
