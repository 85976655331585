import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "src/libs/axios";
import { getMyProjects } from "../projects/projectsSlice";
const initialState = {
  loadingStatus: "idle",
  mentorshipRequests: [],
  mentors:[],
};

export const getMentorshiprequests = createAsyncThunk(
  "mentorship/getMentorshiprequests",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/mentorship/requests/`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getMentors = createAsyncThunk(
  "mentorship/getMentors",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/mentorship/mentors/`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const inviteMentor = createAsyncThunk(
  "mentorship/inviteMentor",
  async (data, { rejectWithValue ,dispatch}) => {
    try {
      const response = await axiosInstance.post(`/mentorship/invite/`,data);
      dispatch(getMyProjects())
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const inviteMentorWithDelete = createAsyncThunk(
  "mentorship/inviteMentorWithDelete",
  async (data, { rejectWithValue ,dispatch}) => {
    try {
      await axiosInstance.delete(`/mentorship/invite/?project_pk=${data.project_pk}`);
      await axiosInstance.post(`/mentorship/invite/`,data);
      dispatch(getMyProjects())
      return "done";
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const deleteMentorInvitation = createAsyncThunk(
  "mentorship/deleteMentorInvitation",
  async (data, { rejectWithValue ,dispatch}) => {
    try {
      await axiosInstance.delete(`/mentorship/invite/?project_pk=${data.project_pk}`);
      dispatch(getMyProjects())
      return "done";
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const handleMentorRequest= createAsyncThunk(
  "mentorship/handleMentorRequest",
  async (data, { rejectWithValue ,dispatch}) => {
    try {
      await axiosInstance.post(`/mentorship/requests/${data.pk}/manage/`,{action:data.action});
      dispatch(getMentorshiprequests())
      dispatch(getMyProjects())
      return "done";
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const mentorshipSlice = createSlice({
  name: "mentorship",
  initialState,
  extraReducers: {
    [getMentorshiprequests.pending]: (state, action) => {
      state.loadingStatus = "loading";
    },
    [getMentorshiprequests.fulfilled]: (state, action) => {
      state.loadingStatus = "success";
      state.mentorshipRequests = action.payload;
    },
    [getMentorshiprequests.rejected]: (state, action) => {
      state.loadingStatus = "failed";
    },
    [getMentors.pending]: (state, action) => {
      state.loadingStatus = "loading";
    }
    ,
    [getMentors.fulfilled]: (state, action) => {
      state.loadingStatus = "success";
      state.mentors = action.payload;
    }
    ,
    [getMentors.rejected]: (state, action) => {
      state.loadingStatus = "failed";
    }
      
  },
});
export default mentorshipSlice.reducer;
