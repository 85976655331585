import React from "react";
import { useSelector } from "react-redux";

import {
  Box,
  
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import Iconify from "../Iconify";
import { useNavigate } from "react-router-dom";
import { FormatCurrency } from "src/utils/formatNumber";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    lineHeight: 1.4,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function Rentabilite({ data }) {
  const navigate = useNavigate();
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const projects = useSelector((state) => state.projects.projects);
  const currentProject = projects.find(
    (project) => project.pk === selectedProject
  );
  const duration = currentProject.project_study_duration;
  const years = Array.from(Array(duration).keys());
  const handleEdit = () => {
    navigate("/strategie-de-commercialisation", {
      state: { step: "CA" },
    });
  };
  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <h3>Compte d'exploitation prévisionel</h3>
        <IconButton color="primary" title="Modifier votre chiffre d'affaires"  onClick={handleEdit}>
          <Iconify icon="akar-icons:edit" />
        </IconButton>
      </Box>
      <TableContainer
        sx={{ maxHeight: "400px", overflow: "auto", position: "relative" }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <StyledTableRow sx={{ position: "sticky", top: 0, zIndex: 100 }}>
              <StyledTableCell>Nom</StyledTableCell>
              {years.map((item, index) => (
                <StyledTableCell key={index} align="center">
                  Année {item + 1}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {data.cep.map((item, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{item.name}</StyledTableCell>
                {years.map((year, index) => (
                  <StyledTableCell key={index} align="center">
                    {FormatCurrency(parseFloat(item["year_" + (year + 1)]))}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <h3 style={{ marginTop: "2rem" }}>Indicateurs de rentabilité:</h3>
      <p>
        VAN: {FormatCurrency(parseFloat(data.van.value))} <br/>
        TRI: {FormatCurrency(parseFloat(data.tri.value))}
      </p>
    </div>
  );
}

export default Rentabilite;
