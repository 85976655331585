import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// import RichTextEditor from "src/components/RichText";
import ToolBar from "src/components/Toolbar";
import Notes from "src/components/Notes";
import BmcView from "src/components/BmcView";
import Page from "src/components/Page";
import Description from "src/components/common/Description";
import { getBMCByProjectIdAction, setStep } from "src/stores/bmc/bmcSlice";
import { useEffect } from "react";
import { BMC_STEPS } from "src/utils/constants";
import decriptions from "../utils/descriptions.json";
import SavingStatus from "src/components/SavingStatus";
import useIsMentor from "src/hooks/useIsMentor";
import CommentWrapper from "src/components/comments/CommentWrapper";
import { useNavigate } from "react-router-dom";
import Iconify from "src/components/Iconify";
import palette from "src/theme/palette";
// import App from 'src/components/test';

export default function BmcApp() {
  const navigate = useNavigate();
  const { isMentor, isSupervisor } = useIsMentor();
  const dispatch = useDispatch();
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const steps = isMentor || isSupervisor ? [] : BMC_STEPS;
  const savingStatus = useSelector((state) => state.bmc.savingStatus);
  const currentStep = useSelector((state) => state.bmc.currentStep);
  const activeStep =
    currentStep === -1
      ? steps.length
      : steps.findIndex((step) => step.value === currentStep);

  const setActiveStep = (step) => {
    if (step === steps.length) dispatch(setStep(-1));
    else dispatch(setStep(steps[step].value));
  };
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  useEffect(() => {
    if (selectedProject !== -1) {
      dispatch(getBMCByProjectIdAction(selectedProject));
      if (isMentor || isSupervisor) dispatch(setStep(-1));
      else dispatch(setStep(steps[0].value));
    }
  }, [dispatch, isMentor, isSupervisor, selectedProject, steps]);

  return (
    <Page
      title="BMC"
      style={{
        border: "1px solid #ECECEC",
        borderRadius: "10px",
        padding: " 1rem ",
        height: "98%",
      }}
    >
      <SavingStatus savingStatus={savingStatus}>
        <CommentWrapper currentPage={`bmc_${currentStep}`} />
      </SavingStatus>
      <Box sx={{ px: 3 }}>
        <Stepper activeStep={activeStep}
        
        >
          {steps.map((label, index) => {
            return (
              <Step key={index}>
                <StepLabel
                  style={{ cursor: "pointer"}}
                  onClick={() => setActiveStep(index)}
                  title={label.title}
                ></StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>

      <>
        {activeStep !== steps.length && (
          <>
            <Typography sx={{ my: 1, px: 3, fontSize: "0.8em" }}>
              <strong style={{ color: "#2196F3" }}>
                Etape {activeStep + 1}
              </strong>
              {" " + steps[activeStep]?.title}
            </Typography>
            <Description text={decriptions.bmc[currentStep]} />
          </>
        )}

        <Grid container spacing={1} sx={{ mt: currentStep === -1 ? 1 : 0 }}>
          {currentStep !== -1 && (
            <Grid item md={5}>
              {!isMentor && !isSupervisor && <ToolBar />}
              <Notes activeStep={activeStep} />
            </Grid>
          )}
          <Grid item md={currentStep !== -1 ? 7 : 12}>
            <BmcView
              activeStep={steps[activeStep] ? steps[activeStep].title : null}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 1 }}>
          <Button
            size="small"
            color="inherit"
            variant="outlined"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1, borderRadius: "0" }}
          >
            Précédent
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          {currentStep !== -1 && (
            <>
              {" "}
              {/* <Button
                variant="outlined"
                color="inherit"
                onClick={handleNext}
                sx={{ mr: 1, borderRadius: "0" }}
                size="small"
              >
                Ignorer
              </Button> */}
              <Button
                onClick={handleNext}
                size="small"
                variant="outlined"
                sx={{ borderRadius: "0" }}
              >
                {activeStep === steps.length - 1 ? "Terminer" : "Suivant"}
              </Button>
            </>
          )}
          {/* {currentStep === -1 && (
            <Button
              onClick={() => navigate("/fiche-projet")}
              size="small"
              variant="contained"
              sx={{ borderRadius: "0",textTransform:"none" }}
            >
              Passer à la fiche projet
              <Iconify icon="ant-design:caret-right-filled" sx={{ ml:1}} />
            </Button>
          )} */}
        </Box>
      </>
    </Page>
  );
}
