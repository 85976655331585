import PropTypes from "prop-types";
import { useState, useRef, useEffect } from "react";
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Divider,
  Typography,
  IconButton,
  ListItemButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
// utils
// components
import Iconify from "../../components/Iconify";
import Scrollbar from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";

import { BASE_URL } from "src/utils/constants";
import axiosInstance from "src/libs/axios";
import { getMyProjects } from "src/stores/projects/projectsSlice";
import { useDispatch } from "react-redux";

export default function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const fetchNotifications = () => {
    axiosInstance.get("/projects/invitations/").then((res) => {
      
      setNotifications(res.data.filter((notification) => notification.acceptedStatus === "PENDING"));
    });
  };
  useEffect(() => {
    fetchNotifications();
  }, []);
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{ width: 46, height: 46 }}
      >
        <Badge badgeContent={notifications.length} color="error">
          <Iconify icon="clarity:notification-line" width={28} height={28} />
        </Badge>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Vous avez {notifications.length} notifications
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
          <List disablePadding>
            {notifications.map((request) => (
              <NotificationItem
                key={request.pk}
                notification={request}
                refresh={fetchNotifications}
              />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: "dashed" }} />
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification, refresh }) {
  const [open, setOpen] = useState(false);
  const dispatch=useDispatch();
  const handleClose = () => {
    setOpen(false);
  };
  const handleAccepter = () => {
    axiosInstance
      .patch(`/projects/invitations/${notification.pk}/`, {
        acceptedStatus: "ACCEPTED",
      })
      .then((res) => {
        refresh();
        dispatch(
          getMyProjects()
        );
        handleClose();
      });
  };
  const handleRefuser = () => {
    axiosInstance
      .patch(`/projects/invitations/${notification.pk}/`, {
        acceptedStatus: "REJECTED",
      })
      .then((res) => {
        refresh();
        dispatch(
          getMyProjects()
        );
        handleClose();
      });
  };

  return (
    <>
      <ListItemButton
        onClick={() => setOpen(true)}
        sx={{
          py: 1.5,
          px: 2.5,
          mt: "1px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <img
            style={{ width: "60px", objectFit: "cover", marginRight: "1em" }}
            alt={notification.project.name}
            src={
              notification.project.picture.includes(BASE_URL)
                ? notification.project.picture
                : BASE_URL + notification.project.picture
            }
          />
          <small>
            <strong>{notification.project.owner_email}</strong> vous a envoyé
            une invitation pour rejoindre le projet{" "}
            <strong>{notification.project.name}</strong> en tant que{" "} <strong>{notification.type==="MENTOR"?"mentor":"membre"}</strong>
          </small>
        </Box>
      </ListItemButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Voulez-vous accepter cette invitation ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <strong>{notification.project.owner_email}</strong> vous a envoyé
            une invitation pour rejoindre le projet{" "}
            <strong>{notification.project.name}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRefuser}>Annuler</Button>
          <Button onClick={handleAccepter} autoFocus>
            Accepter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
