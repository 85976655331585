import { Box, Grid } from "@mui/material";
import React from "react";
import MapOfTunisia from "./MapOfTunisia";

function WorkImpact({ data }) {
  return (
    <Box sx={{  display:"flex",height: "100%" }}>
      
          <div style={{ padding: "1em",width:"50%" }}>
          <Box sx={{ display: "flex", gap: 4, p: 2 }}>
            <h4>
              <span style={{ color: "#900C3F", fontSize: "3em" }}>
                {data.projectsCount}
              </span>{" "}
              Projets accompagnés
            </h4>
            <h4>
              <span style={{ color: "#900C3F", fontSize: "3em" }}>
                {data.SupervisorsCount}
              </span>{" "}
              Superviseurs
            </h4>
            <h4>
              <span style={{ color: "#900C3F", fontSize: "3em" }}>
                {data.mentorsCount}
              </span>{" "}
              Mentors
            </h4>
          </Box>
          <h3>Impact employabilité : prévision des emplois permanents à créer par régions</h3>

          <ul style={{ padding: "1em 2em" }}>
            {data.countWorkerByRegion.map((region, index) => (
              <li key={region.governorate}>
                {region.governorate} : {region.count}
              </li>
            ))}
          </ul>
          </div>
        <MapOfTunisia countByRegion={data.countWorkerByRegion} />
        
    </Box>
    
  );
}

export default WorkImpact;
