const DICT = {
  ram: "Mémoire",
  cpu: "Processeur",
  graphics_card: "Carte graphique",
  screen_size: "Taille d'écran",
  screen_type: "Type d'écran",
  network: "Réseau",
  type: "Type",
  size: "Taille",
  brand: "Marque",
};

export const getWordFromDict = (word) => {
  return DICT[word] ?? word;
};
