// @mui
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect } from "react";
// components

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectProject } from "src/stores/projects/projectsSlice";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function ProjectPopover() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projects, selectedProject, status } = useSelector(
    (state) => state.projects
  );
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    if (
      status === "success" &&
      !projects.find((p) => p.pk === selectedProject)
    ) {
      dispatch(selectProject(-1));
    }
  }, [dispatch, projects, selectedProject, status]);

  useEffect(() => {
    if (selectedProject < 0) {
      navigate("/projets");
    }
  }, [dispatch, navigate, selectedProject, user]);
  const handleChange = (e) => {
    dispatch(selectProject(e.target.value));
  };
  return (
    <>
      <FormControl sx={{ minWidth: 200 }} size="small">
        <InputLabel id="project-select">Projet actuel</InputLabel>
        <Select
          labelId="project-select-label"
          id="project-select"
          value={selectedProject}
          label="Projet actuel"
          onChange={handleChange}
        >
          {projects.map((project) => (
            <MenuItem key={project.pk} value={project.pk}>
              {project.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
