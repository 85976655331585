import { Box } from "@mui/system";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { FormatCurrency } from "src/utils/formatNumber";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    lineHeight: 1.4,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function EcheancierComponent({ data }) {
  const [year, setYear] = useState(1);
  const dataPage = useMemo(() => {
    return data.filter((item) => {
      return Math.ceil(parseFloat(item.versement_num) / 12) === year;
    }).sort((a, b) => {
      return parseFloat(a.versement_num) - parseFloat(b.versement_num)
    });
  }, [data, year]);
  return (
    <Grid container spacing={1}>
      <Grid item xs={1}>
        <YearSelector currentYear={year} setYear={setYear} />
      </Grid>
      <Grid item xs={11}>
        <TableContainer
          sx={{ maxHeight: "500px", overflow: "auto", position: "relative" }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <StyledTableRow sx={{ position: "sticky", top: 0, zIndex: 100 }}>
                <StyledTableCell>versement_num</StyledTableCell>
                <StyledTableCell>capital</StyledTableCell>
                <StyledTableCell>remboursement</StyledTableCell>
                <StyledTableCell>interet</StyledTableCell>
                <StyledTableCell>amortissement</StyledTableCell>
                <StyledTableCell>amortissement_cumule</StyledTableCell>
                <StyledTableCell>capital_restant</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {dataPage.map((item, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    {parseFloat(item.versement_num)}
                  </StyledTableCell>
                  <StyledTableCell>{FormatCurrency(parseFloat(item.capital))}</StyledTableCell>
                  <StyledTableCell>
                    {FormatCurrency(parseFloat(item.remboursement))}
                  </StyledTableCell>
                  <StyledTableCell>{FormatCurrency(parseFloat(item.interet))}</StyledTableCell>
                  <StyledTableCell>
                    {FormatCurrency(parseFloat(item.amortissement))}
                  </StyledTableCell>
                  <StyledTableCell>
                    {FormatCurrency(parseFloat(item.amortissement_cumule))}
                  </StyledTableCell>
                  <StyledTableCell>
                    {FormatCurrency(parseFloat(item.capital_restant))}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default EcheancierComponent;
const YearSelector = ({ currentYear, setYear }) => {
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const projects = useSelector((state) => state.projects.projects);
  const project = projects.find((p) => p.pk === selectedProject);
  const years = useMemo(() => {
    const years = [];
    for (let i = 1; i <= project.project_study_duration; i++) {
      years.push(i);
    }
    return years;
  }, [project.project_study_duration]);
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {years.map((year) => {
        return (
          <Button
            key={year}
            size="small"
            color="primary"
            variant={currentYear === year ? "contained" : "outlined"}
            onClick={() => setYear(year)}
            sx={{
              py: 0.5,
              px: 0,
              mb: 0.5,
              lineHeight: 1.4,
              fontSize: "0.75em",
            }}
          >
            Année <br />
            {year}
          </Button>
        );
      })}
    </Box>
  );
};
