import React, { useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';


import { debounce } from "lodash";
import axiosInstance from "src/libs/axios";
import { useDispatch } from "react-redux";
import { setSavingStatusBP } from "src/stores/bp/bpSlice";

const editorConfig = {
  extraPlugins: [uploadPlugin],
  
};
function CAEditor({ initialData, dataHandler }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(initialData);

  const handleEditorData = useRef(
    debounce((value) => {
      dataHandler(value);
    }, 500)
  ).current;

  const handleChange = (value) => {
    setValue(value);
    dispatch(setSavingStatusBP("unsaved"));

    handleEditorData(value);
  };

  return (
    <CKEditor
      config={editorConfig}
      editor={ClassicEditor}
      data={value}
      onReady={(editor) => {
        editor.editing.view.change((writer) => {
          writer.setStyle(
            "height",
            "300px",
            editor.editing.view.document.getRoot()
          );
        });
      }}
      onChange={(event, editor) => {
        const data = editor.getData();
        handleChange(data);
      }}
    />
  );
}

export default CAEditor;

function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        const body = new FormData();
        loader.file.then((file) => {
          body.append("file", file);
          axiosInstance
            .post("/files/upload/", body)
            .then((res) => res.data)
            .then((res) => {
              resolve({
                default: res.file,
              });
            })
            .catch((err) => {
              reject(err);
            });
        });
      });
    },
  };
}
function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}
