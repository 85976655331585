// routes
import Router from "./router/Routes";
// theme
import ThemeProvider from "./theme";
import "./styles.css";
// components
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/chart/BaseOptionChart";
import { useDispatch } from "react-redux";
import { getUserDataAction } from "./stores/auth/userSlice";
import { useEffect } from "react";

// ----------------------------------------------------------------------

export default function App() {
  const dispatch = useDispatch();
  useEffect(() => {
      dispatch(getUserDataAction());
  }, [dispatch]);

  return (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Router />
    </ThemeProvider>
  );
}
