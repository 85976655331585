import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateBP } from "src/stores/bp/bpSlice";
import { updateSubProductArrayObject } from "src/utils/bp/CA";
import Iconify from "../Iconify";
import * as Yup from "yup";
import { tableCellClasses } from "@mui/material/TableCell";
import useIsMentor from "src/hooks/useIsMentor";
import { FormatCurrency } from "src/utils/formatNumber";

const generateProduct = (overrides) => ({
  name: "",
  quantity_1: 0,
  unit_price_1: 0,
  quantity_2: 0,
  unit_price_2: 0,
  quantity_3: 0,
  unit_price_3: 0,
  quantity_4: 0,
  unit_price_4: 0,
  quantity_5: 0,
  unit_price_5: 0,
  quantity_6: 0,
  unit_price_6: 0,
  quantity_7: 0,
  unit_price_7: 0,
  price_evolution_2: 0,
  quantity_evolution_2: 0,
  price_evolution_3: 0,
  quantity_evolution_3: 0,
  price_evolution_4: 0,
  quantity_evolution_4: 0,
  price_evolution_5: 0,
  quantity_evolution_5: 0,
  price_evolution_6: 0,
  quantity_evolution_6: 0,
  price_evolution_7: 0,
  quantity_evolution_7: 0,
  start_year: 1,
  end_year: 7,
  ...overrides,
});
function ACP() {
  const { productArray } = useSelector((state) => state.bp.OPM);
  const [parenId, setParentId] = useState(productArray[0]?.pk);
  const parent = productArray.find((item) => item.pk === parenId);
  const [year, setYear] = useState(parent?.start_year);
  const [product, setProduct] = useState(null);
  useEffect(() => {
    setYear(parent?.start_year);
  }, [parenId]);

  return productArray.length > 0 ? (
    <>
      {product && (
        <ProductModal product={product} setProduct={setProduct} year={year} />
      )}
      <SelectParent parentId={parenId} setParentId={setParentId} />
      {parenId && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container columns={20} spacing={4}>
              <Grid item xs={2}>
                <YearSelector
                  currentYear={year}
                  setYear={setYear}
                  parent={parent}
                />
              </Grid>
              <Grid item xs={18}>
                <TableProducts
                  year={year}
                  parenId={parenId}
                  handleAjouter={() =>
                    setProduct(
                      generateProduct({
                        parent: parenId,
                        start_year: parent.start_year,
                        end_year: parent.end_year,
                      })
                    )
                  }
                  setProduct={setProduct}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  ) : (
    <Alert severity="error">
      Vous devez ajouter au moins un produit au niveau des prévisions des ventes
      pour pouvoir accéder à cette section
    </Alert>
  );
}

export default ACP;

const TableProducts = ({ year, handleAjouter, setProduct, parenId }) => {
  const dispatch = useDispatch();
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const OPM = useSelector((state) => state.bp.OPM);
  const { productArray } = useSelector((state) => state.bp.OPM);

  const productsToShow = useMemo(
    () =>
      OPM.ACP.filter(
        (item) =>
          item.parent === parenId &&
          year >= item.start_year &&
          year <= item.end_year
      ),
    [OPM.ACP, parenId, year]
  );
  const [productToDelete, setProductToDelete] = useState(null);
  const handleDelete = async (id) => {
    const newArr = OPM.ACP.filter((p) => p.pk !== id);

    const obj = updateSubProductArrayObject([...newArr]);
    await dispatch(
      updateBP({ projectId: selectedProject, payload: obj })
    ).unwrap();
  };
  const { isMentor, isSupervisor } = useIsMentor();
const findParent=(id)=>{
  return productArray.find((item)=>item.pk===id)
}
  return (
    <TableContainer
      sx={{ maxHeight: "400px", overflow: "auto", position: "relative" }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <StyledTableRow sx={{ position: "sticky", top: 0, zIndex: 100 }}>
            <StyledTableCell>Nom</StyledTableCell>
            <StyledTableCell align="center">
              Prix de vente unitaire (Evolution%)
            </StyledTableCell>
            <StyledTableCell align="center">
              Quantité (Evolution%)
            </StyledTableCell>
            <StyledTableCell align="center">
              Coût d'une unité produite
            </StyledTableCell>
            <StyledTableCell align="center">Coût total</StyledTableCell>
            {!isMentor && !isSupervisor && (
              <StyledTableCell align="center">Actions</StyledTableCell>
            )}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {productsToShow.map((product) => (
            <StyledTableRow key={product.pk}>
              <StyledTableCell>{product.name}</StyledTableCell>
              <StyledTableCell align="center">
                {parseFloat(product["unit_price_" + year])} (
                {year === product.start_year
                  ? 0
                  : parseFloat(product["price_evolution_" + year] ?? 0)}
                %)
              </StyledTableCell>
              <StyledTableCell align="center">
                {parseFloat(product["quantity_" + year])} (
                {year === product.start_year
                  ? 0
                  : parseFloat(product["quantity_evolution_" + year] ?? 0)}
                %)
              </StyledTableCell>
              <StyledTableCell align="center">
                {FormatCurrency(parseFloat(product["total_price_" + year])/findParent(product.parent)["quantity_"+year])}
              </StyledTableCell>
              <StyledTableCell align="center">
                {FormatCurrency(parseFloat(product["total_price_" + year]))}
              </StyledTableCell>
              {!isMentor && !isSupervisor && (
                <StyledTableCell align="center">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <IconButton
                      title="Effacer"
                      color="error"
                      sx={{ mr: 1 }}
                      onClick={() => {
                        setProductToDelete(product.pk);
                      }}
                    >
                      <Iconify
                        icon="fa6-solid:trash-can"
                        sx={{ height: 16, width: 16 }}
                      />
                    </IconButton>
                    <IconButton
                      title="modifier"
                      onClick={() => {
                        setProduct(product);
                      }}
                    >
                      <Iconify
                        icon="akar-icons:edit"
                        sx={{ height: 18, width: 18 }}
                      />
                    </IconButton>
                  </div>
                </StyledTableCell>
              )}
            </StyledTableRow>
          ))}
          <StyledTableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              position: "sticky",
              bottom: 0,
              zIndex: 100,
              backgroundColor: "#fff",
            }}
          >
            <StyledTableCell>
              {!isMentor && !isSupervisor && (
                <IconButton
                  variant="outlined"
                  color="primary"
                  onClick={handleAjouter}
                >
                  <Iconify icon="ant-design:plus-outlined" />
                </IconButton>
              )}
            </StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="center">
              Total: {getPrixTotalYear(productsToShow, year)}
            </StyledTableCell>
            {!isMentor && !isSupervisor && (
              <StyledTableCell align="right"></StyledTableCell>
            )}
          </StyledTableRow>
        </TableBody>
      </Table>
      <DeleteDialaog
        productToDelete={productToDelete}
        setProductToDelete={setProductToDelete}
        handleDelete={handleDelete}
      />
    </TableContainer>
  );
};

const YearSelector = ({ currentYear, setYear, parent }) => {
  const years = useMemo(() => {
    const years = [];
    for (let i = parent.start_year; i <= parent.end_year; i++) {
      years.push(i);
    }
    return years;
  }, [parent]);
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {years.map((year) => {
        return (
          <Button
            key={year}
            size="small"
            color="primary"
            variant={currentYear === year ? "contained" : "outlined"}
            onClick={() => setYear(year)}
            sx={{
              py: 0.5,
              px: 0,
              mb: 0.5,
              lineHeight: 1.4,
              fontSize: "0.75em",
            }}
          >
            Année <br />
            {year}
          </Button>
        );
      })}
    </Box>
  );
};

const getPrixTotalYear = (products, year) => {
  return FormatCurrency(
    products.reduce((acc, product) => {
      return acc + parseFloat(product["total_price_" + year]);
    }, 0)
  );
};

const ProductModal = ({ product, setProduct, year }) => {
  const { productArray } = useSelector((state) => state.bp.OPM);
  const parent = productArray.find((item) => item.pk === product.parent);

  const isNewProduct = !product.pk;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
  };
  const dispatch = useDispatch();
  const open = product !== null;
  const OPM = useSelector((state) => state.bp.OPM);

  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const handleAdd = async (values) => {
    let obj;
    obj = updateSubProductArrayObject([
      ...OPM.ACP,
      {
        ...product,
        name: values.name,
        ["quantity_" + values.start_year]: values.quantity,
        ["unit_price_" + values.start_year]: values.unitPrice,
        start_year: values.start_year,
        end_year: values.end_year,
      },
    ]);

    await dispatch(
      updateBP({ projectId: selectedProject, payload: obj })
    ).unwrap();
  };
  const handleUpdate = async (values) => {
    const newArr = OPM.ACP.filter((p) => p.pk !== product.pk);
    let obj;
    if (year === product.start_year) {
      obj = updateSubProductArrayObject([
        ...newArr,
        {
          ...product,
          name: values.name,
          ["quantity_" + values.start_year]: values.quantity,
          ["unit_price_" + values.start_year]: values.unitPrice,
          start_year: values.start_year,
          end_year: values.end_year,
        },
      ]);
    } else {
      obj = updateSubProductArrayObject([
        ...newArr,
        {
          ...product,
          name: values.name,
          ["quantity_evolution_" + year]: values.quantity,
          ["price_evolution_" + year]: values.unitPrice,
          ["quantity_" + values.start_year]:
            product["quantity_" + product.start_year],
          ["unit_price_" + values.start_year]:
            product["unit_price_" + product.start_year],
          start_year: values.start_year,
          end_year: values.end_year,
        },
      ]);
    }
    await dispatch(
      updateBP({ projectId: selectedProject, payload: obj })
    ).unwrap();
  };
  const productToShow = useMemo(() => {
    if (isNewProduct)
      return {
        name: "",
        quantity: 0,
        unitPrice: 0,
        start_year: product.start_year,
        end_year: product.end_year,
      };
    if (year === product.start_year) {
      return {
        name: product.name,
        quantity: product["quantity_" + product.start_year],
        unitPrice: product["unit_price_" + product.start_year],
        start_year: product.start_year,
        end_year: product.end_year,
      };
    } else {
      return {
        name: product.name,
        quantity: product["quantity_evolution_" + year],
        unitPrice: product["price_evolution_" + year],
        start_year: product.start_year,
        end_year: product.end_year,
      };
    }
  }, [isNewProduct, product, year]);
  const [isSubmitting, setisSubmitting] = useState(false);

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Ce champs est obligatoire"),
    quantity: Yup.number().required("Ce champs est obligatoire"),
    unitPrice: Yup.number().required("Ce champs est obligatoire"),
    start_year: Yup.number()
      .required("Ce champs est obligatoire")
      .min(
        parent.start_year,
        "L'année de début doit être supérieure ou égale à l'année de début du produit parent"
      )
      .max(
        parent.start_year,
        "L'année de début doit être inférieure ou égale à l'année de fin du produit parent"
      ),

    end_year: Yup.number()
      .when("start_year", (start_year) => {
        if (start_year) {
          return Yup.number().min(
            start_year,
            "L'année de fin doit être supérieure ou égale à l'année de début du produit"
          );
        }
      })
      .max(
        parent.end_year,
        "L'année de fin doit être inférieure ou égale à l'année de fin du produit parent"
      )
      .required("Ce champs est obligatoire"),
  });

  const formik = useFormik({
    initialValues: {
      name: productToShow.name,
      quantity: productToShow.quantity,
      unitPrice: productToShow.unitPrice,
      start_year: productToShow.start_year,
      end_year: productToShow.end_year,
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      setisSubmitting(true);
      if (isNewProduct) {
        await handleAdd(values);
      } else {
        await handleUpdate(values);
      }
      setisSubmitting(false);
      closeModal();
    },
  });
  const { handleSubmit, getFieldProps, touched, errors } = formik;
  const closeModal = () => {
    setProduct(null);
    formik.resetForm();
  };
  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Card>
          <CardContent>
            <FormikProvider value={formik}>
              <Typography variant="h6">
                {isNewProduct ? "Nouveau" : "Modifier"}
              </Typography>
              <Divider sx={{ mb: 5, mt: 1 }} />
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <TextField
                    sx={{ mb: 2 }}
                    autoComplete="product-name"
                    type="text"
                    label="Nom"
                    {...getFieldProps("name")}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <TextField
                    sx={{ mb: 2 }}
                    autoComplete="product-quantity"
                    type="number"
                    label={
                      year === product.start_year
                        ? "Quantité"
                        : "Evolution de la quantité (%)"
                    }
                    {...getFieldProps("quantity")}
                    error={Boolean(touched.quantity && errors.quantity)}
                    helperText={touched.quantity && errors.quantity}
                  />
                  <TextField
                    type="number"
                    sx={{ mb: 2 }}
                    autoComplete="product-uprice"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    label={
                      year === product.start_year
                        ? "Prix unitaire"
                        : "Evolution du prix unitaire (%)"
                    }
                    {...getFieldProps("unitPrice")}
                    error={Boolean(touched.unitPrice && errors.unitPrice)}
                    helperText={touched.unitPrice && errors.unitPrice}
                  />
                  <Stack direction="row" spacing={2}>
                    <TextField
                      type="number"
                      sx={{ mb: 2 }}
                      autoComplete="start_year"
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      label="Année de début"
                      {...getFieldProps("start_year")}
                      error={Boolean(touched.start_year && errors.start_year)}
                      helperText={touched.start_year && errors.start_year}
                    />
                    <TextField
                      type="number"
                      autoComplete="end_year"
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      label="Année de fin"
                      {...getFieldProps("end_year")}
                      error={Boolean(touched.end_year && errors.end_year)}
                      helperText={touched.end_year && errors.end_year}
                    />
                  </Stack>
                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <LoadingButton
                      sx={{ mt: 3 }}
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      {isNewProduct ? "Ajouter" : "Modifier"}
                    </LoadingButton>
                  </Box>
                </Box>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

const DeleteDialaog = ({
  handleDelete,
  setProductToDelete,
  productToDelete,
}) => {
  const [isSubmitting, setisSubmitting] = useState(false);
  const open = productToDelete !== null;
  const handleClose = () => {
    setProductToDelete(null);
  };
  const deleteProduct = async () => {
    setisSubmitting(true);
    await handleDelete(productToDelete);
    setisSubmitting(false);
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Supprimer le produit"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Êtes-vous sûr de vouloir supprimer ce produit ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isSubmitting}>
          Annuler
        </Button>
        <Button
          color="error"
          onClick={deleteProduct}
          autoFocus
          disabled={isSubmitting}
        >
          Effacer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SelectParent = ({ parentId, setParentId }) => {
  const { productArray } = useSelector((state) => state.bp.OPM);
  const handleChange = (e) => {
    setParentId(e.target.value);
  };
  return (
    <FormControl sx={{ my: 1, minWidth: 250 }}>
      <InputLabel id="select-parent-label">Produit parent</InputLabel>
      <Select
        labelId="select-parent-label"
        id="select-parent"
        value={parentId}
        label="Produit parent"
        onChange={handleChange}
      >
        {productArray.map((product) => (
          <MenuItem key={product.pk} value={product.pk}>
            {product.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

//styling
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    lineHeight: 1.4,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: theme.palette.common.white,
    fontWeight: "bold",
  },
}));
