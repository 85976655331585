import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Iconify from "../Iconify";
import * as Yup from "yup";
import { tableCellClasses } from "@mui/material/TableCell";
import { deleteRMArrayObject, updateRMArrayObject } from "src/utils/bp/RM";
import { updateBP } from "src/stores/bp/bpSlice";
import useIsMentor from "src/hooks/useIsMentor";
import { debounce } from "lodash";
import axiosInstance from "src/libs/axios";
import { getWordFromDict } from "src/utils/dict";
import { FormatCurrency } from "src/utils/formatNumber";
import useReinvest from "src/hooks/useReinvest";

function RMSalary({ data,post,year}) {

  const [selectedItem, setSelectedItem] = useState(null);
  const [whatModalOpen, setWhatModalOpen] = useState(false);
  const handleAjouter = () => {
    setWhatModalOpen("product");
    setSelectedItem({
      isNew: true,
      name: "",
      table: "",
      unit_price: 0,
      quantity: 0,
      type: "",
      post: post,

    });
  };
  return (
    <>
      <ProductModal
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        setWhatModalOpen={setWhatModalOpen}
        whatModalOpen={whatModalOpen}
       
      />

      <EstimationsModal
        open={whatModalOpen === "estimator2"}
        setOpen={setWhatModalOpen}
        setSelectedItem={setSelectedItem}
        type={selectedItem?.product_type_name}
      />

      <Grid container spacing={1}>

        <Grid item xs={12}>
          {year === 1 ? (
            <TableData
             data={data}
              handleAjouter={handleAjouter}
              setSelectedItem={setSelectedItem}
              setWhatModalOpen={setWhatModalOpen}
             
            />
          ) : (
            <TableReinvest typeRM={""} year={year} post={post} />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default RMSalary;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    lineHeight: 1.4,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: theme.palette.common.white,
    fontWeight: "bold",
  },
}));
const TableData = ({
  setSelectedItem,
  handleAjouter,
  setWhatModalOpen,
  data
}) => {
  const {isMentor,isSupervisor} = useIsMentor();

  const rmDataArray = data;
  const [itemToDelete, setItemToDelete] = useState(null);

  return (
    <TableContainer
      sx={{ maxHeight: "400px", overflow: "auto", position: "relative" }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <StyledTableRow sx={{ position: "sticky", top: 0, zIndex: 100 }}>
            <StyledTableCell>Nom</StyledTableCell>
            <StyledTableCell align="center">Type matériel</StyledTableCell>
            <StyledTableCell align="center">Prix unitaire</StyledTableCell>
            <StyledTableCell align="center">Quantité</StyledTableCell>
            <StyledTableCell align="center">Prix total</StyledTableCell>
            {!isMentor &&!isSupervisor&& (
              <StyledTableCell align="center">Actions</StyledTableCell>
            )}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rmDataArray.map((item) => (
            <StyledTableRow key={item.id}>
              <StyledTableCell>{item.name}</StyledTableCell>
              <StyledTableCell align="center">{item.table}</StyledTableCell>
              <StyledTableCell align="center">
                {parseFloat(item.unit_price)}
              </StyledTableCell>
              <StyledTableCell align="center">
                {parseFloat(item.quantity)}
              </StyledTableCell>
              <StyledTableCell align="center">
                {FormatCurrency(parseFloat(item.unit_price) * parseFloat(item.quantity))}
              </StyledTableCell>
              {!isMentor &&!isSupervisor&& (
                <StyledTableCell align="right">
                  <IconButton
                    title="Effacer"
                    color="error"
                    sx={{ mr: 1 }}
                    onClick={() => {
                      setItemToDelete(item);
                    }}
                  >
                    <Iconify
                      icon="fa6-solid:trash-can"
                      sx={{ height: 16, width: 16 }}
                    />
                  </IconButton>
                  <IconButton
                    title="modifier"
                    onClick={() => {
                      setWhatModalOpen("product");
                      setSelectedItem(item);
                    }}
                  >
                    <Iconify
                      icon="akar-icons:edit"
                      sx={{ height: 18, width: 18 }}
                    />
                  </IconButton>
                </StyledTableCell>
              )}
            </StyledTableRow>
          ))}
          <StyledTableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              position: "sticky",
              bottom: 0,
              zIndex: 100,
              backgroundColor: "#fff",
            }}
          >
            <StyledTableCell>
              {!isMentor&&!isSupervisor && (
                <IconButton
                  variant="outlined"
                  color="primary"
                  onClick={handleAjouter}
                >
                  <Iconify icon="ant-design:plus-outlined" />
                </IconButton>
              )}
            </StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right">
              Total:{" "}
              {FormatCurrency(rmDataArray.reduce((acc, cur) => {
                return (
                  acc + parseFloat(cur.unit_price) * parseFloat(cur.quantity)
                );
              }, 0))}
              DT
            </StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
      <DeleteDialaog
        setItemToDelete={setItemToDelete}
        itemToDelete={itemToDelete}
      />
    </TableContainer>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
};
const ProductModal = ({
  selectedItem,
  setSelectedItem,
  setWhatModalOpen,
}) => {
  const [dpopen, setdpOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (!inputValue) {
      debouncedFetchData("all", (res) => {
        setOptions(res.data);
        setLoading(false);
      });
      return;
    }
    setLoading(true);
    if (value?.word !== inputValue) setValue(null);

    if (dpopen)
      debouncedFetchData(inputValue, (res) => {
        setOptions(res.data);
        setLoading(false);
      });
    else setLoading(false);
  }, [inputValue]);
  const dispatch = useDispatch();
  const allRMdataObj = useSelector((state) => state.bp.RM);
  const allRMdataArray = [
    ...allRMdataObj.commercial,
    ...allRMdataObj.marketing,
    ...allRMdataObj.production,
    ...allRMdataObj.local,
    ...allRMdataObj.other,
    ...allRMdataObj.rnd,

  ];

  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Ce champ est obligatoire").nullable(),
    table: Yup.string().required("Ce champ est obligatoire"),
    unit_price: Yup.number().required("Ce champ est obligatoire"),
    quantity: Yup.number().required("Ce champ est obligatoire"),
  });
  const open = selectedItem !== null;
  const isNewProduct = !!selectedItem?.isNew;
  const closeModal = () => {
    formik.resetForm();
    setValue(null);
    setInputValue("");
    setSelectedItem(null);
    setWhatModalOpen(null);
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      table: "",
      unit_price: "",
      quantity:"",
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      const newDataObj = {
        ...values,
        post: selectedItem.post,
        pk: selectedItem.pk,
      };
      setIsSubmitting(true);
      const updateArray = updateRMArrayObject(allRMdataArray, newDataObj);
      const queries = [];
      Object.keys(updateArray).forEach((key) => {
        queries.push(
          dispatch(
            updateBP({
              projectId: selectedProject,
              payload: {
                sheet: "Investissements",
                table: key,
                data: updateArray[key],
              },
            })
          ).unwrap()
        );
      });
      await Promise.all(queries);
      setIsSubmitting(false);

      closeModal();
    },
  });
  const { handleSubmit, getFieldProps, touched, errors } = formik;
  useEffect(() => {
    if (selectedItem) {
      formik.setValues({
        name: selectedItem.name,
        unit_price: parseFloat(selectedItem.unit_price ?? 0),
        quantity: parseFloat(selectedItem.quantity ?? 0),
        table: selectedItem.table,
      });
      setValue({
        word: selectedItem.name,
        table: selectedItem.table,
      });
      setInputValue(selectedItem.name);
    }
  }, [selectedItem]);
  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Card>
          <CardContent>
            <FormikProvider value={formik}>
              <Typography variant="h6">
                {isNewProduct ? "Nouvelle entrée" : "Modifier entrée"}
              </Typography>
              <Divider sx={{ mb: 5, mt: 1 }} />
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <Autocomplete
                  freeSolo
                  fullWidth
                  id="asynchronous-demo"
                  open={dpopen}
                  onOpen={() => {
                    setdpOpen(true);
                  }}
                  onClose={() => {
                    setdpOpen(false);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.word === value.word
                  }
                  getOptionLabel={(option) => option.word ?? option}
                  options={options}
                  loading={loading}
                  value={value}
                  onChange={(event, newValue) => {
                    if (!newValue?.word) {
                      formik.setFieldValue("name", newValue);
                      setInputValue(newValue ? newValue : "");
                      setValue(null);
                      return;
                    }
                    setOptions(newValue ? [newValue, ...options] : options);
                    setValue(newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue ? newInputValue : "");
                    formik.setFieldValue("name", newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Nom"
                      sx={{ mb: 2 }}
                      placeholder="Ordinateur, Voiture, ..."
                      InputProps={{
                        fullWidth: true,
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      {...getFieldProps("name")}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  )}
                />

                <FormControl sx={{ mb: 2 }} fullWidth>
                  <InputLabel id="Type-contrat-label">Type matériel</InputLabel>
                  <Select
                    labelId="Type-contrat-label"
                    id="Type-contrat"
                    value={formik.values.table}
                    label="Type matériel"
                    onChange={(e) => {
                      formik.setFieldValue("table", e.target.value);
                    }}
                  >
                    {TYPE_ARRAY.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  sx={{ mb: 2 }}
                  type="number"
                  autoComplete="product-uprice"
                  label="Prix unitaire"
                  {...getFieldProps("unit_price")}
                  error={Boolean(touched.unit_price && errors.unit_price)}
                  helperText={touched.unit_price && errors.unit_price}
                />
                <TextField
                  fullWidth
                  sx={{ mb: 2 }}
                  autoComplete="product-quantity"
                  type="number"
                  label="Quantité"
                  {...getFieldProps("quantity")}
                  error={Boolean(touched.quantity && errors.quantity)}
                  helperText={touched.quantity && errors.quantity}
                />

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <LoadingButton
                    sx={{ mt: 3 }}
                    variant="contained"
                    disabled={!value?.product_type_name}
                    onClick={() => {
                      setSelectedItem((item) => ({
                        ...item,
                        ...formik.values,
                        product_type_name: value.product_type_name,
                      }));

                      setWhatModalOpen("estimator2");
                    }}
                  >
                    Estimer
                  </LoadingButton>
                  <LoadingButton
                    sx={{ mt: 3 }}
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    {isNewProduct ? "Ajouter" : "Modifier"}
                  </LoadingButton>
                </Box>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

const TYPE_ARRAY = [
  { title: "Matériel de transport", value: "Materiel de transport" },
  {
    title: "Matériel et Mobilier de Bureau",
    value: "Materiel et Mobilier de Bureau",
  },
  { title: "Matériel informatique", value: "Materiel informatique" },
  { title: "Actifs incorporels", value: "Actifs incorporels" },
  { title: "Frais d'établissement", value: "Frais detablissement" },
  {
    title: "Equipements de production et outillages",
    value: "Equipements de production et outillages",
  },
  {
    title: "Amenagements, agencements et installations",
    value: "Amenagements, agencements et installations",
  },
  { title: "Terrain", value: "Terrain" },
  { title: "Fonds de roulement", value: "Fonds de roulement" },
  { title: "Génie civil", value: "Genie civil" },
];

const DeleteDialaog = ({ setItemToDelete, itemToDelete }) => {
  const [isSubmitting, setisSubmitting] = useState(false);
  const dispatch = useDispatch();
  const allRMdataObj = useSelector((state) => state.bp.RM);
  const allRMdataArray = [
    ...allRMdataObj.commercial,
    ...allRMdataObj.marketing,
    ...allRMdataObj.production,
    ...allRMdataObj.local,
    ...allRMdataObj.other,
    ...allRMdataObj.rnd,

  ];

  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );

  const open = itemToDelete !== null;
  const handleClose = () => {
    setItemToDelete(null);
  };
  const deleteProduct = async () => {
    setisSubmitting(true);
    await handleDelete(itemToDelete);
    setisSubmitting(false);
    handleClose();
  };
  const handleDelete = async (id) => {
    dispatch(
      updateBP({
        projectId: selectedProject,
        payload: deleteRMArrayObject(allRMdataArray, id),
      })
    ).unwrap();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Supprimer l'entrée"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Êtes-vous sûr de vouloir supprimer cette entrée ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isSubmitting}>
          Annuler
        </Button>
        <Button
          color="error"
          onClick={deleteProduct}
          autoFocus
          disabled={isSubmitting}
        >
          Effacer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const fetchData = async (query, cb) => {
  let q = query.trim();
  const res = await axiosInstance.get(`/synonym/${q}/`);
  cb(res);
};
const debouncedFetchData = debounce((query, cb) => {
  fetchData(query, cb);
}, 500);
const estimationModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  maxHeight: "80vh",
  overflow: "hidden",
};
const EstimationsModal = ({ type, open, setOpen, setSelectedItem }) => {
  const [options, setOptions] = useState([]);
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState("");
  const [filterValues, setFilterValues] = useState({});
  const filteredOptions = options
    .filter((option) => {
      return Object.keys(filterValues).every((key) => {
        if (filterValues[key] === "all") return true;
        return filterValues[key] === option[key];
      });
    })
    .filter((option) => {
      return option.name.toLowerCase().includes(search.toLowerCase());
    });
  useEffect(() => {
    const fetchData = async () => {
      if (!type) return;
      const res = await axiosInstance.get(`/estimator/${type}/`);
      setOptions(res.data.data);
      setFilters(res.data.filter_fields);
    };
    fetchData();
  }, [type]);

  const closeModal = () => {
    setOpen(null);
  };
  return (
    <Modal open={open} onClose={closeModal}>

      <Box sx={estimationModalStyle}>
       
        <Card>
          <CardContent>
            <Typography variant="h6">Chosir un produit</Typography>
            <Divider sx={{ mb: 2, mt: 1 }} />
            <Box sx={{ pb: 2 }}>
              <FormControl
                variant="outlined"
                sx={{ mb: 3 }}
                size="small"
                fullWidth
              >
                <InputLabel htmlFor="recherche">Rechercher</InputLabel>
                <OutlinedInput
                  id="recherche"
                  variant="outlined"
                  fullWidth
                  size="small"
                  label="Rechercher"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  endAdornment={
                    search ? (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setSearch("")}
                          size="small"
                          title="Effacer"
                        >
                          <Iconify icon="ic:round-clear" />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      <></>
                    )
                  }
                />
              </FormControl>
              <Grid container spacing={2}>
                {Object.keys(filters).map((key) => (
                  <Grid item xs={6} md={4} lg={3} key={key}>
                    <FormControl fullWidth size="small">
                      <InputLabel id={key + "-label"}>
                        {getWordFromDict(key)}
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId={key + "-label"}
                        id={key}
                        label={getWordFromDict(key)}
                        value={filterValues[key]}
                        onChange={(e) => {
                          setFilterValues({
                            ...filterValues,
                            [key]: e.target.value,
                          });
                        }}
                      >
                        {filters[key].map((option) => (
                          <MenuItem value={option}>
                            {option === "all" ? "Tous" : option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box sx={{ maxHeight: "40vh", overflow: "auto", width: "100%" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Produit</TableCell>
                    <TableCell>Prix</TableCell>
                    <TableCell>Lien</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredOptions.map((option) => (
                    <TableRow key={option.id + "op"}>
                      <TableCell>{option.name}</TableCell>
                      <TableCell>{option.price} TND</TableCell>
                      <TableCell>
                        <a href={option.url} target="_blank" rel="noreferrer">
                          Voir produit
                        </a>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setSelectedItem((item) => {
                              
                              return {
                                ...item,
                                name: option.name,
                                unit_price: option.price,
                              };
                            });
                            setOpen("product");
                          }}
                        >
                          Choisir
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

const TableReinvest = ({ typeRM, year,post }) => {
  const { data, deleteReinvestItem,handleReinvestItem} = useReinvest({ type: typeRM, year,post });
  const { isMentor, isSupervisor } = useIsMentor();
  const [itemToDelete, setItemToDelete] = useState(null);
  const [openModal, setOpenModal] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <>
      <TableContainer
        sx={{ maxHeight: "400px", overflow: "auto", position: "relative" }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <StyledTableRow sx={{ position: "sticky", top: 0, zIndex: 100 }}>
              <StyledTableCell>Nom</StyledTableCell>
              <StyledTableCell align="center">Type matériel</StyledTableCell>
              <StyledTableCell align="center">Prix unitaire</StyledTableCell>
              <StyledTableCell align="center">Quantité</StyledTableCell>
              <StyledTableCell align="center">Prix total</StyledTableCell>
              {!isMentor && !isSupervisor && (
                <StyledTableCell align="center">Actions</StyledTableCell>
              )}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <StyledTableRow key={item.id}>
                <StyledTableCell>{item.name}</StyledTableCell>
                <StyledTableCell align="center">{item.table}</StyledTableCell>
                <StyledTableCell align="center">
                  {parseFloat(item.unit_price)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {parseFloat(item.quantity)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {FormatCurrency(
                    parseFloat(item.unit_price) * parseFloat(item.quantity)
                  )}
                </StyledTableCell>
                {!isMentor && !isSupervisor && (
                  <StyledTableCell align="right">
                    <IconButton
                      title="Effacer"
                      color="error"
                      sx={{ mr: 1 }}
                      onClick={() => {
                        setItemToDelete(item);
                      }}
                    >
                      <Iconify
                        icon="fa6-solid:trash-can"
                        sx={{ height: 16, width: 16 }}
                      />
                    </IconButton>
                    <IconButton
                      title="modifier"
                      onClick={() => {
                        setOpenModal("product");
                        setSelectedItem(item);
                      }}
                    >
                      <Iconify
                        icon="akar-icons:edit"
                        sx={{ height: 18, width: 18 }}
                      />
                    </IconButton>
                  </StyledTableCell>
                )}
              </StyledTableRow>
            ))}
            <StyledTableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                position: "sticky",
                bottom: 0,
                zIndex: 100,
                backgroundColor: "#fff",
              }}
            >
              <StyledTableCell>
                {!isMentor && !isSupervisor && (
                  <IconButton
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setOpenModal("product");
                    }}
                  >
                    <Iconify icon="ant-design:plus-outlined" />
                  </IconButton>
                )}
              </StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
              <StyledTableCell align="center">
                Total:{" "}
                {FormatCurrency(
                  data.reduce((acc, cur) => {
                    return (
                      acc +
                      parseFloat(cur.unit_price) * parseFloat(cur.quantity)
                    );
                  }, 0)
                )}
                DT
              </StyledTableCell>
              {!isMentor && !isSupervisor && (
                <StyledTableCell align="right"></StyledTableCell>
              )}
            </StyledTableRow>
          </TableBody>
        </Table>
        <DeleteReinvestDialaog
          deleteReinvestItem={deleteReinvestItem}
          setItemToDelete={setItemToDelete}
          itemToDelete={itemToDelete}
        />
      </TableContainer>
      <ReinvestModal
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        setOpenModal={setOpenModal}
        open={openModal === "product"}
        typeRM={typeRM}
        handleReinvestItem={handleReinvestItem}
      />

      <EstimationsModal
        open={openModal === "estimator"}
        setOpen={setOpenModal}
        setSelectedItem={setSelectedItem}
        type={selectedItem?.product_type_name}
      />
    </>
  );
};

const DeleteReinvestDialaog = ({
  setItemToDelete,
  itemToDelete,
  deleteReinvestItem,
}) => {
  const [isSubmitting, setisSubmitting] = useState(false);
  const open = Boolean(itemToDelete);
  const handleClose = () => {
    setItemToDelete(null);
  };
  const deleteProduct = async () => {
    setisSubmitting(true);
    try {
      deleteReinvestItem(itemToDelete.pk);
      setItemToDelete(null);
    } catch (error) {
      console.log(error);
    }
    setisSubmitting(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Supprimer l'entrée"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Êtes-vous sûr de vouloir supprimer cette entrée ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isSubmitting}>
          Annuler
        </Button>
        <Button
          color="error"
          onClick={deleteProduct}
          autoFocus
          disabled={isSubmitting}
        >
          Effacer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ReinvestModal = ({
  selectedItem,
  setSelectedItem,
  handleReinvestItem,
  setOpenModal,
  open,
  typeRM,
}) => {
  const [dpopen, setdpOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (!inputValue) {
      debouncedFetchData("all", (res) => {
        setOptions(res.data);
        setLoading(false);
      });
      return;
    }
    setLoading(true);
    if (value?.word !== inputValue) setValue(null);

    if (dpopen)
      debouncedFetchData(inputValue, (res) => {
        setOptions(res.data);
        setLoading(false);
      });
    else setLoading(false);
  }, [inputValue]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Ce champ est obligatoire").nullable(),
    table: Yup.string().required("Ce champ est obligatoire"),
    unit_price: Yup.number().required("Ce champ est obligatoire"),
    quantity: Yup.number().required("Ce champ est obligatoire"),
  });
  const isNewProduct = !selectedItem?.pk;
  const closeModal = () => {
    formik.resetForm();
    setValue(null);
    setInputValue("");
    setSelectedItem(null);
    setOpenModal(null);
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      table: "",
      unit_price: "",
      quantity: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      const newDataObj = {
        ...values,
        sheet: "Investissements",
        type: typeRM,
        pk: selectedItem?.pk,
      };
      setIsSubmitting(true);
      await handleReinvestItem(newDataObj);
      setIsSubmitting(false);
      closeModal();
    },
  });
  const { handleSubmit, getFieldProps, touched, errors } = formik;
  useEffect(() => {
    if (selectedItem) {
      formik.setValues({
        name: selectedItem.name,
        unit_price: parseFloat(selectedItem.unit_price ?? 0),
        quantity: parseFloat(selectedItem.quantity ?? 0),
        table: selectedItem.table,
      });
      setValue({
        word: selectedItem.name,
        table: selectedItem.table,
      });
      setInputValue(selectedItem.name);
    }
  }, [selectedItem]);
  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Card>
          <CardContent>
            <FormikProvider value={formik}>
              <Typography variant="h6">
                {isNewProduct ? "Nouvelle entrée" : "Modifier entrée"}
              </Typography>
              <Divider sx={{ mb: 5, mt: 1 }} />
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <Autocomplete
                  freeSolo
                  fullWidth
                  id="asynchronous-demo"
                  open={dpopen}
                  onOpen={() => {
                    setdpOpen(true);
                  }}
                  onClose={() => {
                    setdpOpen(false);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.word === value.word
                  }
                  getOptionLabel={(option) => option.word ?? option}
                  options={options}
                  loading={loading}
                  value={value}
                  onChange={(event, newValue) => {
                    if (!newValue?.word) {
                      formik.setFieldValue("name", newValue);
                      setInputValue(newValue ? newValue : "");
                      setValue(null);
                      return;
                    }
                    setOptions(newValue ? [newValue, ...options] : options);
                    setValue(newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue ? newInputValue : "");
                    formik.setFieldValue("name", newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Nom"
                      sx={{ mb: 2 }}
                      placeholder="Ordinateur, Voiture, ..."
                      InputProps={{
                        fullWidth: true,
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      {...getFieldProps("name")}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  )}
                />

                <FormControl sx={{ mb: 2 }} fullWidth>
                  <InputLabel id="Type-contrat-label">Type matériel</InputLabel>
                  <Select
                    labelId="Type-contrat-label"
                    id="Type-contrat"
                    value={formik.values.table}
                    label="Type matériel"
                    onChange={(e) => {
                      formik.setFieldValue("table", e.target.value);
                    }}
                  >
                    {REINVEST_TYPE_ARRAY.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  sx={{ mb: 2 }}
                  type="number"
                  autoComplete="product-uprice"
                  label="Prix unitaire"
                  {...getFieldProps("unit_price")}
                  error={Boolean(touched.unit_price && errors.unit_price)}
                  helperText={touched.unit_price && errors.unit_price}
                />
                <TextField
                  fullWidth
                  sx={{ mb: 2 }}
                  autoComplete="product-quantity"
                  type="number"
                  label="Quantité"
                  {...getFieldProps("quantity")}
                  error={Boolean(touched.quantity && errors.quantity)}
                  helperText={touched.quantity && errors.quantity}
                />

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <LoadingButton
                    sx={{ mt: 3 }}
                    variant="contained"
                    disabled={!value?.product_type_name}
                    onClick={() => {
                      setSelectedItem((item) => ({
                        ...item,
                        ...formik.values,
                        product_type_name: value.product_type_name,
                      }));

                      setOpenModal("estimator");
                    }}
                  >
                    Estimer
                  </LoadingButton>
                  <LoadingButton
                    sx={{ mt: 3 }}
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    {isNewProduct ? "Ajouter" : "Modifier"}
                  </LoadingButton>
                </Box>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

const REINVEST_TYPE_ARRAY = [
  { title: "Matériel de transport", value: "Materiel de transport" },
  {
    title: "Matériel et Mobilier de Bureau",
    value: "Materiel et Mobilier de Bureau",
  },
  { title: "Matériel informatique", value: "Materiel informatique" },
  { title: "Actifs incorporels", value: "Actifs incorporels" },
  {
    title: "Equipements de production et outillages",
    value: "Equipements de production et outillages",
  },
  {
    title: "Amenagements, agencements et installations",
    value: "Amenagements, agencements et installations",
  },
  { title: "Terrain", value: "Terrain" },
  { title: "Génie civil", value: "Genie civil" },
];