import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Page from "src/components/Page";
import SwotEditor from "src/components/SwotView/SwotEditor";
import { getCanvasEntriesAction } from "src/stores/canvas/canvasSlice";

function SwotCanvas() {
  const dispatch = useDispatch();
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  useEffect(() => {
    if (selectedProject !== -1) {
      dispatch(
        getCanvasEntriesAction({
          canvasType: "swot",
          project_id: selectedProject,
        })
      );
    }
  }, [selectedProject, dispatch]);
  return (
    <Page
      title="SWOT"
      style={{
        border: "1px solid #ECECEC",
        borderRadius: "10px",
        padding: " 1rem ",
        height: "98%",
      }}
    >
      <SwotEditor />
    </Page>
  );
}

export default SwotCanvas;
