import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import axiosInstance from "src/libs/axios";

function fetchReinvestByPost(postId) {
  return axiosInstance.get(`/business/getreinvestmentpost/${postId}/`);
}

function fetchReinvest(selectedProject, type) {
  return axiosInstance.get(
    `/business/getreinvestment/${selectedProject}/${type}/`
  );
}

function deleteReinvest(id) {
  return axiosInstance.delete(`/business/reinvestment/${id}/`);
}

function addReinvest(data) {
  return axiosInstance.post(`/business/reinvestment/`, data);
}
function updateReinvest(data) {
  return axiosInstance.patch(`/business/reinvestment/${data.pk}/`, data);
}

function useReinvest({ type, year, post }) {
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const [data, setData] = useState([]);
  const filteredData = useMemo(() => {
    return data.filter((d) => d.year === year);
  }, [data, year]);
  useEffect(() => {
    if (!post) {
      fetchReinvest(selectedProject, type).then((res) => setData(res.data));
    } else {
      fetchReinvestByPost(post).then((res) => setData(res.data));
    }
  }, [selectedProject, type, post]);
  const deleteReinvestItem = (pk) => {
    deleteReinvest(pk).then((res) => {
      setData((old) => old.filter((d) => d.pk !== pk));
    });
  };
  const handleReinvestItem = async (data) => {
    if (data.pk) {
      const res = await updateReinvest({
        ...data,
        year,
        project: selectedProject,
      });
      setData((old) => old.map((d) => (d.pk === data.pk ? res.data : d)));
    } else {
      const res = await addReinvest({
        ...data,
        post,
        year,
        project: selectedProject,
      });

      setData((old) => [...old, res.data]);
    }
  };
  return { data: filteredData, deleteReinvestItem, handleReinvestItem };
}

export default useReinvest;
