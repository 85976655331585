import { Box } from "@mui/system";
import React from "react";
import parse from "html-react-parser";
import { Typography } from "@mui/material";
import Iconify from "./Iconify";
import { BMC_STEPS, getAccentColor } from "src/utils/constants";
import { useDispatch } from "react-redux";
import { setStep } from "src/stores/bmc/bmcSlice";
import useIsMentor from "src/hooks/useIsMentor";

export const GridViewItem = ({
  content,
  borderStyle,
  activeStep,
  limit = 2,
  orientation = "column",
  icon,
}) => {
  const dispatch = useDispatch();
  const {isMentor,isSupervisor}=useIsMentor()
  const chooseStep = () => {
    if (isMentor||isSupervisor) return ;
    const newStep = BMC_STEPS.find(
      (s) => s.title.toLowerCase() === content.title.toLowerCase()
    ).value;
    dispatch(setStep(newStep));
  };
  const isFinalStep = !activeStep;
  return (
    <div
      onClick={chooseStep}
      style={{
        height: content.height,
        boxShadow:
          String(activeStep).toLowerCase() ===
          String(content.title).toLowerCase()
            ? "0px 0px 3px 3px rgba(3, 102, 214, 0.7) "
            : "none",
        position: "relative",
        zIndex:
          String(activeStep).toLowerCase() ===
          String(content.title).toLowerCase()
            ? "20"
            : "1",
        ...borderStyle,
        overflow: "auto",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          padding: "5px",
          background: content.color,
          height: content.titleheight,
          width: content.titlewidth,
          fontSize: "10px",
          fontStyle: "light",
          fontWeight: "bold",
          fill: "#000000",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>{content.title}</span>
        <Iconify icon={icon} sx={{ width: 14, height: 14, m: 0, p: 0 }} />
      </div>
      {isFinalStep ? (
        <div
          style={{
            maxWidth: "#960px",
            padding: "5px 10px",
            display: "grid",
            gridTemplateColumns: orientation==="row"?`repeat(${content.text.length},1fr)`: limit==="1"?`repeat(${content.text.length>4?3:2},1fr)`:"1fr 1fr",
            gap: "5px",
          }}
        >
          {content.text.map((item, i) => (
            <Box
              key={item.id}
              sx={{
                background: item.color,
                color: getAccentColor(item.color),
                p: 1,
                wordBreak: "break-word",

                fontSize: "10px",
                height:   orientation === "row"? "7.5em":limit!=="1" ?"9.5em":"6em",
                overflow: "auto",
                margin:
                  orientation === "column" ? "0 0 0.5em 0" : "0 0.5em 0 0",
                boxShadow:
                  "  0px 3px 2px rgba(0, 0, 0, 0.16), 0px 3px 2px rgba(0, 0, 0, 0.23); ",
              }}
            >
              <p > {parse(item.text)}</p>
            </Box>
          ))}
        </div>
      ) : (
        <div
          style={{
            maxWidth: "#960px",
            padding: "5px 10px",
            display: "flex",
            flexDirection: orientation,
          }}
        >
          {content.text.slice(0, limit).map((item, i) => (
            <Box
              key={item.id}
              sx={{
                background: item.color,
                color: getAccentColor(item.color),
                p: 1,
                wordBreak: "break-word",

                fontSize: "10px",
                height: orientation === "column" ? "5.6em" : "6em",
                width: orientation === "column" ? "100%" : "30%",
                overflow: "hidden",
                overflowY: "auto",
                margin:
                  orientation === "column" ? "0 0 0.5em 0" : "0 0.5em 0 0",
                boxShadow:
                  "  0px 3px 2px rgba(0, 0, 0, 0.16), 0px 3px 2px rgba(0, 0, 0, 0.23); ",
              }}
            >
              <p > {parse(item.text)}</p>
            </Box>
          ))}
          {content.text.length > limit && (
            <Typography variant="p" component="p" sx={{ fontSize: "10px" }}>
              +{content.text.length - limit}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};
