import { Grid, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles"; // components
import Page from "../components/Page";

export default function HomePage() {
  const navigate = useNavigate();
  const theme = createTheme({
    status: {
      danger: "#e53e3e",
    },
    palette: {
      primary: {
        main: "#2196F3",
        darker: "#053e85",
      },
      neutral: {
        main: "#2196F3",
        contrastText: "#FFFFFF",
      },
    },
  });

  return (
    <Page title="Dashboard" sx={{ height: "100%" }}>
      <Grid container spacing={1} sx={{ height: "100%", alignItems: "center" }}>
        <Grid item xs={6}>
          <div>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/hEA0fPlW8Do"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </Grid>
        <Grid item xs={6} height={315}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "start",
              height: "100%",
            }}
          >
            <Box>
              <h1 style={{ color: "#2196F3" }}>
                <strong>Comprendre le business Model Canvas</strong>
              </h1>
              <p>
                lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              </p>
            </Box>
            <ThemeProvider theme={theme}>
              <Button
                onClick={() => {
                  navigate("/projets");
                }}
                uppercase="false"
                color="neutral"
                variant="contained"
                style={{ top: 6 }}
              >
                <strong>Créer un projet</strong>
              </Button>
            </ThemeProvider>{" "}
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
}
