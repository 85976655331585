import { Button, Card, CardActionArea, Chip, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Page from "src/components/Page";
import axiosInstance from "src/libs/axios";
import { setUser } from "src/stores/auth/userSlice";

function FirstLogin({height,withRedirect}) {
  const { user } = useSelector((state) => state.user);
  const [role, setRole] = useState(user.role);
  const [loading, setLoading] = useState(false);
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const handleCompte = async () => {
    setLoading(true);
    try {
      const {data}=await axiosInstance.patch("/me/update-my-profile/" + user.pk+"/", {
        role: role,
        is_first_login: false,
      });
      dispatch(setUser(data))
      if(withRedirect) navigate("/")
      
      else if(role!=="ORGANIZATION") navigate("/info")
      setLoading(false);
    } catch (error) {
      alert("Une erreur est survenue");
      setLoading(false);
    }
  };
  return (
    <Page title="Choisir le type de votre compte">
      <Box
        sx={{
          height: height?height:"100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2>
          <strong>Choisir le type de votre compte</strong>
        </h2>
        <Grid
          container
          spacing={1}
          sx={{
            mt: 3,
            width: "70vw",
            mx: "auto",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item xs={4} sx={{ height: "100%" }}>
            <Card sx={{ height: "100%", position: "relative" }}>
              {role === "USER" && (
                <Chip
                  label="Sélectionné"
                  color="success"
                  variant="outlined"
                  sx={{
                    position: "absolute",
                    top: 12,
                    right: 12,
                    zIndex: 1,
                  }}
                />
              )}

              <CardActionArea
                sx={{ height: "100%", width: "100%", p: 0 }}
                onClick={() => setRole("USER")}
              >
                <img
                  src="/images/user.jpg"
                  alt="user"
                  style={{
                    height: "150px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    p: 2,
                    mt: 2,
                  }}
                >
                  <h3>Compte utilisateur</h3>
                  <small style={{marginTop:"1rem"}}>
                    Vous êtes un porteur de projet, un membre d'équipe ou un employé d'une structure d'accompagnement/établissement
                    d'enseignement supérieur.
                  </small>
                </Box>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={4} sx={{ height: "100%" }}>
            <Card sx={{ height: "100%" }}>
              {role === "MENTOR" && (
                <Chip
                  label="Sélectionné"
                  color="success"
                  variant="outlined"
                  sx={{
                    position: "absolute",
                    top: 12,
                    right: 12,
                    zIndex: 1,
                  }}
                />
              )}
              <CardActionArea
                sx={{ height: "100%", width: "100%", p: 0 }}
                onClick={() => setRole("MENTOR")}
              >
                <img
                  src="/images/mentor.jpg"
                  alt="user"
                  style={{
                    height: "150px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    p: 2,
                    mt: 2,
                  }}
                >
                  <h3>Compte mentor</h3>
                  <small style={{marginTop:"1rem"}}>
                    Vous êtes un mentor independant et vous souhaitez vous inscrire sur la plateforme pour accompagner les porteurs de projet.
                  </small>
                </Box>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={4} sx={{ height: "100%" }}>
            <Card sx={{ height: "100%" }}>
              {role === "ORGANIZATION" && (
                <Chip
                  label="Sélectionné"
                  color="success"
                  variant="outlined"
                  sx={{
                    position: "absolute",
                    top: 12,
                    right: 12,
                    zIndex: 1,
                  }}
                />
              )}
              <CardActionArea
                sx={{ height: "100%", width: "100%", p: 0 }}
                onClick={() => setRole("ORGANIZATION")}
              >
                <img
                  src="/images/company.jpg"
                  alt="user"
                  style={{
                    height: "150px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    p: 2,
                    mt: 2,
                  }}
                >
                  <h3>
                    Compte structure d'accompagnement / établissement
                    d'enseignement supérieur{" "}
                  </h3>
                  <small style={{marginTop:"1rem"}}>
                    Vous êtes une structure d'accompagnement ou un établissement d'enseignement supérieur et vous souhaitez vous inscrire sur la plateforme pour accompagner les porteurs de projet.

                  </small>
                </Box>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
        <Button
          sx={{ mt: 4 }}
          size="large"
          variant="contained"
          onClick={handleCompte}
          disabled={loading}
        >
          <h3>Valider</h3>
        </Button>
      </Box>
    </Page>
  );
}

export default FirstLogin;
