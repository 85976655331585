import { Box } from '@mui/material';
import { Container } from '@mui/system';
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import Page from 'src/components/Page'
import axiosInstance from 'src/libs/axios';
import PageLoader from 'src/router/components/PageLoader';

function ConfirmationEmail() {
      let { token } = useParams();
      const [state, setState] = useState("loading");
      const verifyEmail = useCallback(
        async () => {
          try{
               await axiosInstance.post("/rest-auth/registration/verify-email/" ,{
                  key:token
               });
               setState("success");
          }
          catch(error){
              setState("error");
          }
        },
        [token],
      )
      
      useEffect(() => {
        setState("loading");
        verifyEmail();
      }, [verifyEmail]);
  return (
    <Page title="Confirmer votre compte">
       {state==="loading"&&<PageLoader/>}

         <Container maxWidth="sm">
            <Box sx={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",height:"100vh"}}>
         {state==="success"&&<h1>Votre compte a été confirmé avec succès</h1>}
            {state==="error"&&<h1>Ce lien n'est plus valide</h1>}
                <Link to="/" style={{marginTop:"2rem"}}>Retour à la page de connexion</Link>
            </Box>
            </Container>
        </Page>
  )
}

export default ConfirmationEmail