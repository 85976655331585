/**
 * libraries
 */
import { Navigate, useRoutes } from "react-router-dom";

/**
 * layouts
 */
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";

/**
 * pages
 */
import Login from "../pages/Login";
import NotFound from "../pages/Page404";
import Register from "../pages/Register";
import HomePage from "../pages/Home";

/**
 * components
 */
import ProtectedRoute from "./components/ProtectedRoute";
import AuthLoader from "./components/AuthLoader";
import ListProjects from "src/pages/ListProjects";
import NoSideBarLayout from "src/layouts/NoSideBarLayout";
import BmcApp from "src/pages/Bmc";
import FicheProjet from "src/pages/FicheProjet";
import ProjectDescription from "src/pages/ProjectDescription";
import Profile from "src/pages/Profile";
import SecteurPresentation from "src/pages/SecteurPresentation";
import StratDeCommercialisation from "src/pages/StratDeCommercialisation";
import PlanPO from "src/pages/PlanPO";
import PF from "src/pages/PF";
import ConfirmationEmail from "src/pages/ConfirmationEmail";
import MentorshipProject from "src/pages/MentorshipProject";
import MentorRequests from "src/pages/MentorRequests";
import MentorSwitcher from "src/pages/MentorSwitcher";
import ResetPassword from "src/pages/ResetPassword";
import ResetPasswordConfirm from "src/pages/ResetPasswordConfirm";
import Cohortes from "src/pages/Cohortes";
import Cohort from "src/pages/Cohort";
import Settings from "src/pages/Settings";
import Stats from "src/pages/Stats";
import CanvasPicker from "src/pages/CanvasPicker";
import LeanCanvas from "src/pages/LeanCanvas";
import VPCanvas from "src/pages/VPCanvas";
import SwotCanvas from "src/pages/SwotCanvas";
import PersonaCanvas from "src/pages/PersonaCanvas";
import EmpathyCanvas from "src/pages/EmpathyCanvas";
import RND from "src/pages/RND";
import Cgu from "src/pages/Cgu";

/**
 * React component for routing
 */
export default function Router() {
  const commonRoutes = [
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        // { path: "/", element: <Navigate to="/Home/" /> },
        { path: "confirmer-compte/:token", element: <ConfirmationEmail /> },
        { path: "reinitialiser-mot-de-passe", element: <ResetPassword /> },
        {
          path: "reinitialiser-mot-de-passe/:uid/:token",
          element: <ResetPasswordConfirm />,
        },
        {
          path: "login",
          element: (
            <AuthLoader>
              <Login />
            </AuthLoader>
          ),
        },
        {
          path: "register",
          element: (
            <AuthLoader>
              <Register />
            </AuthLoader>
          ),
        },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ];
  const userAndMentorRoutes = [
    {
      path: "/",
      element: (
        <ProtectedRoute roles={["USER", "MENTOR"]}>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        { path: "fiche-projet", element: <FicheProjet /> },
        { path: "description-projet", element: <ProjectDescription /> },
        {
          path: "analyse-strategique-du-marche",
          element: <SecteurPresentation />,
        },
        {
          path: "strategie-de-commercialisation",
          element: <StratDeCommercialisation />,
        },
        { path: "plan-de-production-et-d-organisation", element: <PlanPO /> },
        { path: "recherche-et-developpement", element: <RND /> },

        { path: "etude-de-faisabilite", element: <PF /> },
      ],
    },
    {
      path: "/",
      element: (
        <ProtectedRoute roles={["USER", "MENTOR"]}>
          <DashboardLayout noNavbar canvaspicker />
        </ProtectedRoute>
      ),
      children: [
        { path: "bmc", element: <BmcApp /> },
        { path: "lean", element: <LeanCanvas /> },
        { path: "vp", element: <VPCanvas /> },
        { path: "swot", element: <SwotCanvas /> },
        { path: "canvas", element: <CanvasPicker /> },
        { path: "persona", element: <PersonaCanvas /> },
        { path: "empathie", element: <EmpathyCanvas /> },
      ],
    },
    {
      path: "/",
      element: (
        <ProtectedRoute roles={["USER", "MENTOR"]}>
          <NoSideBarLayout />
        </ProtectedRoute>
      ),
      children: [
        { path: "projets", element: <ListProjects /> },
        { path: "profile", element: <Profile /> },
      ],
    },
  ];
  const userOnlyRoutes = [
    {
      path: "/",
      element: (
        <ProtectedRoute roles={["USER"]}>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "mentorship",
          element: <MentorshipProject />,
        },
      ],
    },
  ];

  const mentorOnlyRoutes = [
    {
      path: "/",
      element: (
        <ProtectedRoute roles={["MENTOR"]}>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "mentor",
          element: (
            <MentorSwitcher
              userComponent={<Navigate to="/" />}
              mentorComponent={<MentorRequests />}
            />
          ),
        },
      ],
    },
  ];
  const organisationRoutes = [
    {
      path: "/organisation",
      element: (
        <ProtectedRoute roles={["ORGANIZATION"]}>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "",
          element: <Navigate to="/organisation/cohorte" />,
        },
        {
          path: "rapport-cohorte",
          element: <Stats />,
        },
        {
          path: "cohorte",
          element: <Cohort />,
        },
      ],
    },
    {
      path: "/organisation",
      element: (
        <ProtectedRoute roles={["ORGANIZATION"]}>
          <NoSideBarLayout />
        </ProtectedRoute>
      ),

      children: [{ path: "cohortes", element: <Cohortes /> }],
    },
  ];
  const alluserRoutes = [
    {
      path: "/",
      element: (
        <ProtectedRoute roles={["USER", "MENTOR", "ORGANIZATION"]}>
          <NoSideBarLayout />
        </ProtectedRoute>
      ),
      children: [
        { path: "parametres", element: <Settings /> },
        { path: "info", element: <HomePage /> },

      ],
    },
  ];
  return useRoutes([
    { path: "/", element: <Navigate replace to="/projets" /> },

    ...userAndMentorRoutes,
    ...userOnlyRoutes,
    ...mentorOnlyRoutes,
    ...organisationRoutes,
    ...commonRoutes,
    ...alluserRoutes,
  ]);
}
