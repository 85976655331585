import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Slide,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Page from "src/components/Page";
import axiosInstance from "src/libs/axios";
import {
  deleteMentorInvitation,
  getMentors,
  inviteMentor,
  inviteMentorWithDelete,
} from "src/stores/mentorship/mentorshipSlice";
import { getMyProjects } from "src/stores/projects/projectsSlice";
import { BASE_URL } from "src/utils/constants";
const DEFAULT_MENTOR = process.env.REACT_APP_DEFAULT_MENTOR;

function MentorshipProject() {
  const dispatch = useDispatch();
  const { projects, selectedProject } = useSelector((state) => state.projects);
  const { mentors } = useSelector((state) => state.mentorship);
  const projectInvitations = useMemo(
    () =>
      projects.find((project) => project.pk === selectedProject)?.invitations,
    [projects, selectedProject]
  );
  const mentorsArray = useMemo(
    () =>
      mentors
        .filter((mentor) => mentor.email !== DEFAULT_MENTOR)
        .map((mentor) => {
          const invitation = projectInvitations?.find(
            (invitation) => invitation.email === mentor.email
          );
          return {
            ...mentor,
            invitation,
          };
        })
        .sort((a, b) => {
          if (a.invitation && !b.invitation) {
            return -1;
          }
          if (!a.invitation && b.invitation) {
            return 1;
          }
          return 0;
        }),
    [mentors, projectInvitations]
  );
  useEffect(() => {
    dispatch(getMentors());
  }, [dispatch]);

  // const HandleAddMentorDefault = (mentorPk) => {
  //   dispatch(
  //     inviteMentorWithDelete({
  //       mentor_pk: mentorPk,
  //       project_pk: selectedProject,
  //     })
  //   );
  // };
  // const HandleAddMentor = (mentorPk) => {
  //   if (projectMentorship?.mentor?.email === DEFAULT_MENTOR) {
  //     HandleAddMentorDefault(mentorPk);
  //   } else {
  //     dispatch(
  //       inviteMentor({
  //         mentor_pk: mentorPk,
  //         project_pk: selectedProject,
  //       })
  //     );
  //   }
  // };
  // const HandleDeleteMentorReq = () => {
  //   dispatch(
  //     deleteMentorInvitation({
  //       project_pk: selectedProject,
  //     })
  //   );
  // };
  const inviteMemberAsMentor = (email) => {
    axiosInstance
      .post("/projects/invite/", {
        email: email,
        project: selectedProject,
        type: "MENTOR",
      })
      .then((res) => {
        dispatch(getMyProjects());
        alert("Invitation envoyée avec succès");
      })
      .catch((err) => {
        alert("La personne a déjà été invitée");
      });
  };
  const DeleteMentorInvitation = (selectedInvitation) => {
    axiosInstance
      .delete(`/projects/invitations/${selectedInvitation}/`)
      .then((res) => {
        dispatch(getMyProjects());
        alert("Invitation supprimée avec succès");
      })
      .catch((err) => {
        alert("Une erreur s'est produite");
      })
      
  };
  const getStatus=(invitation)=>{
    switch (invitation) {
      case "ACCEPTED":
        return {
          value:"Accepté",
          color:"success"
        };
      case "PENDING":
        return {
          value:"En attente",
          color:"warning"
        };
      case "REJECTED":
        return {
          value:"Rejeté",
          color:"error"
        };
      default:
        return {  
          value:"Non invité",
          color:"info"
          }
        }
  }
  const [dialogData, setDialogData] = useState({
    title: "",
    description: "",
    open: false,
    handleConfirm: () => {},
    handleClose: () => {},
  });
  return (
    <Page
      title="Mentorship"
      style={{
        border: "1px solid #ECECEC",
        borderRadius: "10px",
        padding: " 1rem ",
        height: "98%",
      }}
    >
      <ConfirmDialog {...dialogData} />
      <Grid container spacing={1}>
        {mentorsArray.map((mentor) => (
          <Grid item xs={6} md={3} key={mentor.pk}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                sx={{ objectFit: "contain" }}
                image={mentor.picture}
                alt={mentor.email}
              />
              <CardContent>
                <h5><b>Email: </b>{mentor.email}</h5>
                <h5>
                  <b>Nom complet: </b>
                  {mentor.first_name??"-"} {mentor.last_name??"-"}
                </h5>
                <h5>
                <b>Statut: </b>

                {mentor.invitation&&<Chip size="small" label={getStatus(mentor.invitation.acceptedStatus).value} color={getStatus(mentor.invitation.acceptedStatus).color}/>}
                </h5>
              </CardContent>
              <CardActions>
                <Box
                  sx={{
                    p: 1,
                    pt: 0,
                    display: "flex",
                    justifyContent: "end",
                    width: "100%",
                  }}
                >
                  <Button
                    color={mentor.invitation?"error":"primary"}
                    onClick={() =>
                      mentor.invitation?
                      setDialogData({
                        title: "Effacer l'invitation",
                        description: `Voulez-vous vraiment effacer l'invitation du mentor ${mentor.email} ?`,
                        open: true,
                        handleConfirm: () => DeleteMentorInvitation(mentor.invitation.pk),
                        handleClose: () =>
                          setDialogData(curr=>({ ...curr, open: false })),
                      }):
                      setDialogData({
                        title: "Inviter un mentor",
                        description: `Voulez-vous vraiment inviter ${mentor.email} ?`,
                        open: true,
                        handleConfirm: () => inviteMemberAsMentor(mentor.email),
                        handleClose: () =>
                          setDialogData(curr=>({ ...curr, open: false })),
                      })
                    }
                  >
                   {mentor.invitation?"Effacer": "Inviter"}
                  </Button>
                </Box>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Page>
  );
}

export default MentorshipProject;

const STATUS = {
  PENDING: { value: "En attente", color: "warning" },
  ACCEPTED: { value: "Accepté", color: "success" },
  REJECTED: { value: "Rejeté", color: "error" },
};

const ConfirmDialog = ({
  title,
  description,
  open,
  handleClose,
  handleConfirm,
}) => {
  const confirm = () => {
    handleConfirm();
    handleClose();
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="error">
          Annuler
        </Button>
        <Button onClick={confirm} variant="contained" color="primary">
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
