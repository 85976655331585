import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "src/libs/axios";

const initialState = {
  data: [],
  currentStep: "presentation",
  savingStatus: "done",
  loading: true,
};

export const getPresentaionAction = createAsyncThunk(
  "bmc/getPresentaionAction",
  async (pk, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/project/projectpresentation-get/${pk}/`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updatePresentationAction = createAsyncThunk(
  "bmc/updatePresentationAction",
  async (data, { rejectWithValue }) => {
    try {
      let response;
      if (data.id) {
        response = await axiosInstance.post(
          `/project/projectpresentation-update/${data.id}/`,
          data
        );
      }
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const createPresentationAction = createAsyncThunk(
  "bmc/createPresentationAction",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.post(
        `/project/projectpresentation-create/`,
        data
      );
      dispatch(getPresentaionAction(data.project_id));
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
const projectPresentationSlice = createSlice({
  name: "projectPresentation",
  initialState,
  extraReducers: {
    [getPresentaionAction.pending]: (state, action) => {
      state.loading = true;
    },
    [getPresentaionAction.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [updatePresentationAction.pending]: (state) => {
      state.savingStatus = "saving";
    },
    [updatePresentationAction.fulfilled]: (state) => {
      state.savingStatus = "done";
    },
    [updatePresentationAction.rejected]: (state) => {
      state.savingStatus = "error";
    },
  },
  reducers: {
    setStepPPt: (state, action) => {
      state.currentStep = action.payload;
    },
    setSavingStatus: (state, action) => {
      state.savingStatus = action.payload;
    },
  },
});
export default projectPresentationSlice.reducer;
export const { setStepPPt, setSavingStatus } = projectPresentationSlice.actions;
