import React, { useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";

import { debounce } from "lodash";
import axiosInstance from "src/libs/axios";
import useIsMentor from "src/hooks/useIsMentor";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import Iconify from "../Iconify";

const editorConfig = {
  extraPlugins: [uploadPlugin],
};
function SuggestionEditor({
  type, //suggestion step type
  project, //project id
  content, //suggestion step content
  handleSuggestion,
  hasSuggestions,
}) {
  const [value, setValue] = useState("");
  const { isOwner, isSupervisor } = useIsMentor();
  const [status, setStatus] = useState("success");
  const [editor, setEditor] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setValue(content);
    editor?.setData(content);
  }, [content, editor]);
  const saveData = (data) => {
    setStatus("loading");
    axiosInstance
      .post(`/comments/suggestions/`, data, 500)
      .then((res) => {
        setStatus("success");
      })
      .catch((err) => {
        setStatus("error");
      });
  };

  const handleEditorData = useRef(
    debounce((data) => {
      saveData(data);
    }, 500)
  ).current;

  const handleChange = (value) => {
    if (value === content) return;
    setStatus("unsaved");

    setValue(value);
    handleEditorData({
      project: project,
      type: type,
      content: value,
    });
  };
  return (
    <>
      {isOwner && (
        <>
          <Button
            onClick={() => setOpen(true)}
            size="small"
            color="primary"
            variant="contained"
            disabled={!hasSuggestions}
            sx={{ my: 2 }}
          >
            Accepter la suggestion
          </Button>
          {content && (
            <div
              style={{
                maxHeight: "300px",

                overflow: "auto",
                border: "1px solid #ccc",
                padding: "10px",
              }}
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          )}
        </>
      )}
      {isSupervisor && (
        <>
          <Status status={status} />
          <CKEditor
            config={editorConfig}
            editor={ClassicEditor}
            data={value}
            onReady={(editor) => {
              setEditor(editor);
              editor.editing.view.change((writer) => {
                writer.setStyle(
                  "height",
                  "300px",
                  editor.editing.view.document.getRoot()
                );
              });
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              handleChange(data);
            }}
          />
        </>
      )}

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Accepter la suggestion
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            On acceptant la suggestion de votre accompagnateur votre texte sera
            remplacé par la suggestion de votre accompagnateur cette opération
            est irréversible !
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Annuler</Button>
          <Button
            onClick={() => {
              handleSuggestion(content);
              setOpen(false);
            }}
            autoFocus
            variant="contained"
            color="error"
          >
            Accepter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SuggestionEditor;

function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        const body = new FormData();
        loader.file.then((file) => {
          body.append("file", file);
          axiosInstance
            .post("/files/upload/", body)
            .then((res) => res.data)
            .then((res) => {
              resolve({
                default: res.file,
              });
            })
            .catch((err) => {
              reject(err);
            });
        });
      });
    },
  };
}
function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}

const Status = ({ status }) => {
  switch (status) {
    case "loading":
      return (
        <Typography
          variant="p"
          component="p"
          sx={{ p: 0.5 }}
          color="primary"
          display="flex"
          alignItems="center"
        >
          Enregistrement en cours
          <CircularProgress color="inherit" size={16} sx={{ ml: 0.5 }} />
        </Typography>
      );
    case "success":
      return (
        <Typography
          variant="p"
          component="p"
          sx={{ p: 0.5 }}
          color="green"
          display="flex"
          alignItems="center"
        >
          Enregistré
          <Iconify
            icon="akar-icons:check"
            sx={{ ml: 0.5, width: "1.2em", height: "1.2em" }}
          />
        </Typography>
      );
    case "error":
      return (
        <Typography
          variant="p"
          component="p"
          sx={{ p: 0.5 }}
          color="red"
          display="flex"
          alignItems="center"
        >
          Erreur
          <Iconify
            icon="bi:x-circle"
            sx={{ ml: 0.5, width: "1.2em", height: "1.2em" }}
          />
        </Typography>
      );
    case "unsaved":
      return (
        <Typography
          variant="p"
          component="p"
          sx={{ p: 0.5 }}
          color="gray"
          display="flex"
          alignItems="center"
        >
          Non enregistré
          <Iconify
            icon="ci:error-outline"
            sx={{ ml: 0.5, width: "1.2em", height: "1.2em" }}
          />
        </Typography>
      );
    default:
      return <></>;
  }
};
