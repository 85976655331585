import {
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Description from "src/components/common/Description";
import Page from "src/components/Page";
import Editor from "src/components/project-description/Editor";
import SavingStatus from "src/components/SavingStatus";
import {
  getPresentaionAction,
  setStepPPt,
} from "src/stores/project-presentation/projectPresentationSlice";
import { PROJECT_PRESENTATION_STEPS } from "src/utils/constants";
import decriptions from "../utils/descriptions.json";
import {
  createPresentationAction,
  setSavingStatus,
  updatePresentationAction,
} from "src/stores/project-presentation/projectPresentationSlice";
import BoxLoader from "src/router/components/BoxLoader";
import CommentWrapper from "src/components/comments/CommentWrapper";
import Iconify from "src/components/Iconify";
import { useLocation, useNavigate } from "react-router-dom";
import SuggestionCard from "src/components/common/SuggestionCard";
import useIsMentor from "src/hooks/useIsMentor";
function ProjectDescription() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);

  const { currentStep, data, savingStatus } = useSelector(
    (state) => state.projectPresentation
  );

  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const stepData = useMemo(
    () => data.find((item) => item.presentation === currentStep),
    [currentStep, data]
  );

  useEffect(() => {
    if (selectedProject !== -1) {
      dispatch(getPresentaionAction(selectedProject));
      if (location.state?.step) {
        dispatch(setStepPPt(location.state.step));
      } else {
        dispatch(setStepPPt(PROJECT_PRESENTATION_STEPS[0].value));
      }
    }
  }, [dispatch, location, selectedProject]);

  const activeStep =
    currentStep === -1
      ? PROJECT_PRESENTATION_STEPS.length
      : PROJECT_PRESENTATION_STEPS.findIndex(
          (step) => step.value === currentStep
        );

  const setActiveStep = (step) => {
    if (step === PROJECT_PRESENTATION_STEPS.length) dispatch(setStepPPt(-1));
    else dispatch(setStepPPt(PROJECT_PRESENTATION_STEPS[step].value));
  };
  const handleNext = async () => {
    setLoading(true);
    await dispatch(getPresentaionAction(selectedProject)).unwrap();
    setActiveStep(activeStep + 1);
    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  const handleBack = async () => {
    setLoading(true);
    await dispatch(getPresentaionAction(selectedProject)).unwrap();
    setTimeout(() => {
      setLoading(false);
    }, 100);
    setActiveStep(activeStep - 1);
  };
  const changeStep = async (step) => {
    setLoading(true);
    await dispatch(getPresentaionAction(selectedProject)).unwrap();
    setTimeout(() => {
      setLoading(false);
    }, 100);
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const { isSupervisor, isOwner } = useIsMentor();
  const [hasSuggestions, setHasSuggestions] = useState(false);

  const handleSuggestion = async (value) => {
    await dispatch(
      updatePresentationAction({
        ...stepData,
        project_id: selectedProject,
        presentation: currentStep,
        explication: value,
      })
    ).unwrap();
    dispatch(getPresentaionAction(selectedProject));
  };
  return (
    <Page
      title="Description du projet"
      style={{
        border: "1px solid #ECECEC",
        borderRadius: "10px",
        padding: " 1rem ",
        height: "98%",
      }}
    >
      <Grid container spacing={1} sx={{ height: "100%" }}>
        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <SavingStatus savingStatus={savingStatus}>
            <CommentWrapper currentPage={`projectdescription_${currentStep}`} />
          </SavingStatus>

          <Box sx={{ pt: 1, px: 3 }}>
            <Stepper activeStep={activeStep}>
              {PROJECT_PRESENTATION_STEPS.map((label, index) => {
                return (
                  <Step key={index}>
                    <StepLabel
                      style={{ cursor: "pointer" }}
                      onClick={() => changeStep(index)}
                      title={label.title}
                    />
                  </Step>
                );
              })}
            </Stepper>
          </Box>
          {activeStep === PROJECT_PRESENTATION_STEPS.length ? (
            <Box sx={{ p: 3 }}>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </Box>
          ) : (
            <>
              <Typography sx={{ my: 1, px: 3, fontSize: "0.8em" }}>
                <strong style={{ color: "#2196F3" }}>
                  Etape {activeStep + 1}
                </strong>
                {" " + PROJECT_PRESENTATION_STEPS[activeStep].title}
              </Typography>
              <Description text={decriptions.ppt[currentStep]} />
              <Grid container sx={{ flex: 1, px: 3 }}>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: showSuggestion || isSupervisor ? "" : "none",
                    pr: "1rem",
                  }}
                >
                  {isSupervisor ? (
                    <strong>Votre suggestion:</strong>
                  ) : (
                    <strong>Suggestion du superviseur:</strong>
                  )}
                  <Box>
                    <SuggestionCard
                      mainContent={stepData?.explication}
                      setHasSuggestions={setHasSuggestions}
                      type={`projectdescription_${currentStep}`}
                      handleSuggestion={handleSuggestion}
                    />
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={showSuggestion || isSupervisor ? 6 : 12}
                  sx={{ position: "relative", pl: "1rem" }}
                >
                  {(isOwner || isSupervisor) && (
                    <>
                      <strong> Contenu:</strong>
                    </>
                  )}
                  {isOwner && (
                    <>
                      <IconButton
                        onClick={() => setShowSuggestion((c) => !c)}
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "-1.5rem",
                        }}
                        color="primary"
                        title="Voir la suggestion"
                      >
                        <Badge
                          badgeContent={hasSuggestions ? "!" : null}
                          color="error"
                        >
                          <Iconify
                            icon={
                              showSuggestion
                                ? "material-symbols:chevron-left-rounded"
                                : "material-symbols:chevron-right-rounded"
                            }
                          />
                        </Badge>
                      </IconButton>
                    </>
                  )}
                  <Box>
                    {!loading && (
                      <Editor
                        stepData={stepData}
                        selectedProject={selectedProject}
                        currentStep={currentStep}
                        createAction={createPresentationAction}
                        updateAction={updatePresentationAction}
                        setSavingStatus={setSavingStatus}
                      />
                    )}
                    {loading && <BoxLoader />}
                  </Box>
                </Grid>
              </Grid>

              <Box sx={{ display: "flex", flexDirection: "row", pt: 1 }}>
                <Button
                  size="small"
                  color="inherit"
                  variant="outlined"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1, borderRadius: "0" }}
                >
                  Précédent
                </Button>

                <Box sx={{ flex: "1 1 auto" }} />
                {activeStep === PROJECT_PRESENTATION_STEPS.length - 1 && (
                  <Button
                    onClick={() => navigate("/analyse-strategique-du-marche")}
                    size="small"
                    variant="contained"
                    sx={{ borderRadius: "0", textTransform: "none" }}
                  >
                    Passer à l'étape suivante
                    <Iconify icon="ant-design:caret-right-filled" />
                  </Button>
                )}
                {activeStep !== PROJECT_PRESENTATION_STEPS.length - 1 && (
                  <>
                    {" "}
                    {/* <Button
                      variant="outlined"
                      color="inherit"
                      onClick={handleNext}
                      sx={{ mr: 1, borderRadius: "0" }}
                      size="small"
                    >
                      Ignorer
                    </Button> */}
                    <Button
                      onClick={handleNext}
                      size="small"
                      variant="outlined"
                      sx={{ borderRadius: "0" }}
                    >
                      {activeStep === PROJECT_PRESENTATION_STEPS.length - 1
                        ? "Terminer"
                        : "Suivant"}
                    </Button>
                  </>
                )}
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </Page>
  );
}

export default ProjectDescription;
