import {
  Badge,
  BadgeRoot,
  Button,
  Grid,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CommentWrapper from "src/components/comments/CommentWrapper";
import Description from "src/components/common/Description";
import SuggestionCard from "src/components/common/SuggestionCard";
import Iconify from "src/components/Iconify";
import Page from "src/components/Page";
import Editor from "src/components/project-description/Editor";
import SavingStatus from "src/components/SavingStatus";
import SwotEditor from "src/components/Secteur/SwotEditor";
import useIsMentor from "src/hooks/useIsMentor";
import BoxLoader from "src/router/components/BoxLoader";
import {
  createSecteurAction,
  getSecteur,
  setSavingSecteurStatus,
  setStepSecteur,
  updateSecteurAction,
} from "src/stores/secteur/secteurSlice";
import { SECTEUR_STEPS } from "src/utils/constants";
import decriptions from "../utils/descriptions.json";

function SecteurPresentation() {
  const location=useLocation();
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );

  const { currentStep, secteurElements, swot, savingStatus, loading } =
    useSelector((state) => state.secteur);
  const stepData = useMemo(
    () =>
      currentStep === "analyseswot"
        ? swot
        : secteurElements.find((item) => item.presentation === currentStep),
    [currentStep, secteurElements, swot]
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (selectedProject !== -1) {
      dispatch(getSecteur(selectedProject));
      if (location.state?.step) {
        dispatch(setStepSecteur(location.state.step));
      }else
     { dispatch(setStepSecteur(SECTEUR_STEPS[0].value));}
    }
  }, [dispatch, location, selectedProject]);

  const activeStep =
    currentStep === -1
      ? SECTEUR_STEPS.length
      : SECTEUR_STEPS.findIndex((step) => step.value === currentStep);

  const setActiveStep = (step) => {
    if (step === SECTEUR_STEPS.length) dispatch(setStepSecteur(-1));
    else dispatch(setStepSecteur(SECTEUR_STEPS[step].value));
  };
  const handleNext = async () => {
    await changeStep(activeStep + 1);
  };

  const handleBack = async () => {
    await changeStep(activeStep - 1);
  };
  const changeStep = async (step) => {
    await dispatch(getSecteur(selectedProject)).unwrap();

    setActiveStep(step);
  };
  const handleReset = () => {
    setActiveStep(0);
  };
  return (
    <Page
      title="Analyse stratégique du marché"
      style={{
        border: "1px solid #ECECEC",
        borderRadius: "10px",
        padding: " 1rem ",
        height: "98%",
      }}
    >
      <Grid container spacing={1} sx={{ height: "100%" }}>
        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <SavingStatus savingStatus={savingStatus}>
            <CommentWrapper currentPage={`secteurdescription_${currentStep}`} />
          </SavingStatus>

          <Box sx={{ pt: 1, px: 3 }}>
            <Stepper activeStep={activeStep}>
              {SECTEUR_STEPS.map((label, index) => {
                return (
                  <Step key={index}>
                    <StepLabel
                      style={{ cursor: "pointer" }}
                      onClick={() => changeStep(index)}
                      title={label.title}
                    />
                  </Step>
                );
              })}
            </Stepper>
          </Box>
          {activeStep === SECTEUR_STEPS.length ? (
            <Box sx={{ p: 3 }}>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </Box>
          ) : (
            <>
              <Typography sx={{ my: 1, px: 3, fontSize: "0.8em" }}>
                <strong style={{ color: "#2196F3" }}>
                  Etape {activeStep + 1}
                </strong>
                {" " + SECTEUR_STEPS[activeStep].title}
              </Typography>
              <Description text={decriptions.secteur[currentStep]} />

              {loading ? (
                <BoxLoader />
              ) : (
                <Box sx={{ flex: 1, px: 3 }}>
                  {currentStep === "analyseswot" ? (
                    <SwotEditor stepData={stepData} />
                  ) : (
                    <Other stepData={stepData} />
                  )}
                </Box>
              )}

              <Box sx={{ display: "flex", flexDirection: "row", pt: 1 }}>
                <Button
                  size="small"
                  color="inherit"
                  variant="outlined"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1, borderRadius: "0" }}
                >
                  Précédent
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {activeStep === SECTEUR_STEPS.length - 1 && (
                  <Button
                    onClick={() => navigate("/strategie-de-commercialisation")}
                    size="small"
                    variant="contained"
                    sx={{ borderRadius: "0" ,textTransform: "none"}}
                  >
                    Passer à l'étape suivante
                    <Iconify icon="ant-design:caret-right-filled" />
                  </Button>
                )}
                {activeStep !== SECTEUR_STEPS.length - 1 && (
                  <>
                    {/* <Button
                      variant="outlined"
                      color="inherit"
                      onClick={handleNext}
                      sx={{ mr: 1, borderRadius: "0" }}
                      size="small"
                    >
                      Ignorer
                    </Button> */}
                    <Button
                      onClick={handleNext}
                      size="small"
                      variant="outlined"
                      sx={{ borderRadius: "0" }}
                    >
                      {activeStep === SECTEUR_STEPS.length - 1
                        ? "Terminer"
                        : "Suivant"}
                    </Button>
                  </>
                )}
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </Page>
  );
}

export default SecteurPresentation;

const Other = ({ stepData }) => {
  const [showSuggestion, setShowSuggestion] = useState(false);
  const dispatch = useDispatch();
  const { isSupervisor, isOwner } = useIsMentor();
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const { currentStep } = useSelector((state) => state.secteur);
  const handleSuggestion = async (value) => {
    await dispatch(
      updateSecteurAction({
        ...stepData,
        project_id: selectedProject,
        presentation: currentStep,
        explication: value,
      })
    ).unwrap();
    dispatch(getSecteur(selectedProject));
  };
  const [hasSuggestions, setHasSuggestions] = useState(false);

  return (
    <Grid container sx={{ flex: 1, px: 3 }}>
      <Grid
        item
        xs={6}
        sx={{
          pr: "1rem",
          display: showSuggestion || isSupervisor ? "" : "none",
        }}
      >
        {isSupervisor ? (
          <strong>Votre suggestion:</strong>
        ) : (
          <strong>Suggestion du superviseur:</strong>
        )}
        <Box>
          <SuggestionCard
            mainContent={stepData?.explication}
            setHasSuggestions={setHasSuggestions}
            type={`secteurdescription_${currentStep}`}
            handleSuggestion={handleSuggestion}
          />
        </Box>
      </Grid>

      <Grid
        item
        xs={showSuggestion || isSupervisor ? 6 : 12}
        sx={{ position: "relative", pl: "1rem" }}
      >
        {(isOwner || isSupervisor) && (
          <>
            <strong> Contenu:</strong>
          </>
        )}
        {isOwner && (
          <>
            <IconButton
              onClick={() => setShowSuggestion((c) => !c)}
              sx={{
                position: "absolute",
                top: "50%",
                left: "-1.5rem",
              }}
              color="primary"
              title="Voir la suggestion"
            >
              <Badge badgeContent={hasSuggestions ? "!" : null} color="error">
                <Iconify
                  icon={
                    showSuggestion
                      ? "material-symbols:chevron-left-rounded"
                      : "material-symbols:chevron-right-rounded"
                  }
                />
              </Badge>
            </IconButton>
          </>
        )}
        <Box>
          <Editor
            stepData={stepData}
            selectedProject={selectedProject}
            currentStep={currentStep}
            createAction={createSecteurAction}
            updateAction={updateSecteurAction}
            setSavingStatus={setSavingSecteurStatus}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
