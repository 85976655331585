import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// @mui
// import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  // const theme = useTheme();

  // const PRIMARY_LIGHT = theme.palette.primary.light;

  // const PRIMARY_MAIN = theme.palette.primary.main;

  // const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // const logo = <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />
    // <img style={{ width: 200, height: 100, objectFit:"contain",...sx, }} src="static/icons/logo.png" alt="Funder"/>

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
    <svg
      width="111"
      height="19"
      viewBox="0 0 111 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.475 4.325V0.499999H0.75V18H5.7V12H13.425V8.175H5.7V4.325H14.475ZM24.865 18.35C30.04 18.35 33.09 15.425 33.09 10.2V0.499999H28.215V10.05C28.215 13.05 26.965 14.25 24.915 14.25C22.84 14.25 21.59 13.05 21.59 10.05V0.499999H16.64V10.2C16.64 15.425 19.69 18.35 24.865 18.35ZM48.2434 0.499999V9.825L40.5184 0.499999H36.4434V18H41.2934V8.675L49.0184 18H53.0934V0.499999H48.2434ZM56.585 18H64.86C70.66 18 74.635 14.625 74.635 9.25C74.635 3.875 70.66 0.499999 64.86 0.499999H56.585V18ZM61.535 14.05V4.45H64.66C67.635 4.45 69.635 6.225 69.635 9.25C69.635 12.275 67.635 14.05 64.66 14.05H61.535ZM82.1393 14.175V10.975H89.9143V7.275H82.1393V4.325H90.9643V0.499999H77.2393V18H91.2893V14.175H82.1393ZM104.861 18H110.161L106.386 12.5C108.561 11.45 109.811 9.55 109.811 6.975C109.811 2.975 106.811 0.499999 102.036 0.499999H94.0361V18H98.9861V13.35H101.686L104.861 18ZM104.811 6.975C104.811 8.575 103.786 9.525 101.736 9.525H98.9861V4.4H101.736C103.786 4.4 104.811 5.35 104.811 6.975Z"
        fill="#2196F3"
      />
    </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
//  {/* <svg width="111" height="19" viewBox="0 0 111 19" fill="none" xmlns="http://www.w3.org/2000/svg">
//       <path d="M14.475 4.325V0.499999H0.75V18H5.7V12H13.425V8.175H5.7V4.325H14.475ZM24.865 18.35C30.04 18.35 33.09 15.425 33.09 10.2V0.499999H28.215V10.05C28.215 13.05 26.965 14.25 24.915 14.25C22.84 14.25 21.59 13.05 21.59 10.05V0.499999H16.64V10.2C16.64 15.425 19.69 18.35 24.865 18.35ZM48.2434 0.499999V9.825L40.5184 0.499999H36.4434V18H41.2934V8.675L49.0184 18H53.0934V0.499999H48.2434ZM56.585 18H64.86C70.66 18 74.635 14.625 74.635 9.25C74.635 3.875 70.66 0.499999 64.86 0.499999H56.585V18ZM61.535 14.05V4.45H64.66C67.635 4.45 69.635 6.225 69.635 9.25C69.635 12.275 67.635 14.05 64.66 14.05H61.535ZM82.1393 14.175V10.975H89.9143V7.275H82.1393V4.325H90.9643V0.499999H77.2393V18H91.2893V14.175H82.1393ZM104.861 18H110.161L106.386 12.5C108.561 11.45 109.811 9.55 109.811 6.975C109.811 2.975 106.811 0.499999 102.036 0.499999H94.0361V18H98.9861V13.35H101.686L104.861 18ZM104.811 6.975C104.811 8.575 103.786 9.525 101.736 9.525H98.9861V4.4H101.736C103.786 4.4 104.811 5.35 104.811 6.975Z" fill="#2196F3"/>
//       </svg> */}

//       {/* <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
//         <defs>
//           <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
//             <stop offset="0%" stopColor={PRIMARY_DARK} />
//             <stop offset="100%" stopColor={PRIMARY_MAIN} />
//           </linearGradient>
//           <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
//             <stop offset="0%" stopColor={PRIMARY_LIGHT} />
//             <stop offset="100%" stopColor={PRIMARY_MAIN} />
//           </linearGradient>
//           <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
//             <stop offset="0%" stopColor={PRIMARY_LIGHT} />
//             <stop offset="100%" stopColor={PRIMARY_MAIN} />
//           </linearGradient>
//         </defs>

//         <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
//           <path
//             fill="url(#BG1)"
//             d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
//           />
//           <path
//             fill="url(#BG2)"
//             d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
//           />
//           <path
//             fill="url(#BG3)"
//             d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
//           />
//         </g>
//       </svg> */}
//     // </img>
