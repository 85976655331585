import {
  Badge,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProjectAction,
  getMyProjects,
  selectProject,
} from "src/stores/projects/projectsSlice";
import { BASE_URL } from "src/utils/constants";
import Iconify from "src/components/Iconify";
import Page from "src/components/Page";
import { useNavigate } from "react-router-dom";
import CreateProjectModal from "src/components/Projects/CreateProjectModal";

export default function ListProjects() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { projects, status } = useSelector((state) => state.projects);
  const { user } = useSelector((state) => state.user);

  const [createModalOpen, setcreateModalOpen] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const deleteDialogOpen = projectToDelete !== null;

  const getProjectInvitation = (project) => {
    const invitation = project.invitations?.find(
      (invitation) => invitation.email === user.email
    );
    return invitation;
  };
  useEffect(() => {
    dispatch(getMyProjects());
  }, [dispatch]);
  const getColor = (value) => {
    if (value >= 0.8) {
      return "success";
    }
    if (value >= 0.6) {
      return "warning";
    }
    return "error";
  };
  const selectBMC = (pk) => {
    dispatch(selectProject(pk));
    navigate("/canvas");
  };
  const selectBP = (pk) => {
    dispatch(selectProject(pk));
    navigate("/fiche-projet");
  };

  return (
    <Page title="Projects">
      <Container>
        {status === "loading" && <div>Chargement...</div>}
        {status === "error" && <div>Erreur</div>}
        {status === "success" && (
          <>
            <Typography variant="h4">
              {user.mentor ? "Projets" : "Mes Projets"}
            </Typography>
            <Typography variant="p" component="h4" sx={{ mb: 5 }}>
              {projects.length
                ? "Choisissez un projet ou créez-en un nouveau"
                : "Vous n'avez pas encore de projets. Créez-en un pour commencer."}
            </Typography>
            <div style={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3} sx={{ padding: 0 }}>
                  <Card sx={{ height: "100%", minHeight: "200px" }}>
                    <CardActionArea
                      onClick={() => setcreateModalOpen(true)}
                      sx={{
                        height: "100%",
                      }}
                    >
                      <CardContent sx={{ textAlign: "center" }}>
                        <Iconify
                          icon="ant-design:plus-outlined"
                          sx={{ width: 32, height: 32 }}
                        ></Iconify>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                {projects.map((project) => (
                  <Grid item xs={6} md={3} key={project.pk}>
                    <Card sx={{ height: "100%", position: "relative" }}>
                      {/* <CardActionArea
                        onClick={() => select(project.pk)}
                        sx={{ height: "100%" }}
                      > */}

                      <IconButton
                        color="error"
                        size="small"
                        onClick={() => setProjectToDelete(project.pk)}
                        sx={{
                          position: "absolute",
                          top: "1em",
                          right: "1em",
                        }}
                      >
                        <Iconify icon="bi:trash"></Iconify>
                      </IconButton>
                      <CardMedia
                        component="img"
                        height="140"
                        sx={{ objectFit: "contain" }}
                        image={BASE_URL + project.picture}
                        alt={project.name}
                      />
                      <CardContent>
                        <h3 style={{ display: "flex", alignItems: "center" }}>
                          {project.role === "mentor" && (
                            <>
                              <Chip
                                label="Mentor"
                                size="small"
                                sx={{ mr: 1 }}
                                color="success"
                              />
                            </>
                          )}
                          {project.role === "supervisor" && (
                            <>
                              <Chip
                                label="Superviseur"
                                size="small"
                                sx={{ mr: 1 }}
                                color="warning"
                              />
                            </>
                          )}

                          {project.role === "owner" && (
                            <>
                              {getProjectInvitation(project) && (
                                <Chip
                                  label={
                                    getProjectInvitation(project).type ===
                                    "MEMBER"
                                      ? "Membre"
                                      : "Mentor"
                                  }
                                  size="small"
                                  sx={{ mr: 1 }}
                                  color="success"
                                />
                              )}
                              {project.owner === user.pk && (
                                <Chip
                                  label="Propriétaire"
                                  size="small"
                                  sx={{ mr: 1 }}
                                  color="primary"
                                />
                              )}
                            </>
                          )}

                          {project.name}
                        </h3>

                        {/* {project.owner !== user.pk && (
                            <>
                              <Typography
                                variant="p"
                                component="span"
                                sx={{ fontWeight: "bold" }}
                              >
                                Porteur de projet:{" "}
                              </Typography>
                              <Typography variant="span" component="span">
                                {project.mentorship_request?.project_owner.email}{" "}
                              </Typography>
                              <Chip
                                label="Mentor"
                                color="primary"
                                size="small"
                                sx={{ mt: 2 }}
                              />
                            </>
                          )} */}
                        <LinearProgress
                          sx={{ marginTop: "1rem" }}
                          variant="determinate"
                          color={getColor(project.completion)}
                          value={project.completion * 100}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            mt: 2,
                          }}
                        >
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => selectBMC(project.pk)}
                          >
                            <Iconify
                              icon="codicon:table"
                              sx={{ width: 16, height: 16, mr: 1 }}
                            ></Iconify>
                            Canvas
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => selectBP(project.pk)}
                          >
                            <Iconify
                              icon="akar-icons:book-open"
                              sx={{ width: 16, height: 16, mr: 1 }}
                            ></Iconify>
                            Business Plan
                          </Button>
                        </Box>
                      </CardContent>
                      {/* </CardActionArea> */}
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </div>
          </>
        )}
      </Container>
      <CreateProjectModal
        open={createModalOpen}
        setcreateModalOpen={setcreateModalOpen}
      />
      <Dialog open={deleteDialogOpen} onClose={() => setProjectToDelete(null)}>
        <DialogTitle>Voulez-vous vraiment supprimer ce projet ?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Cette action est irréversible
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setProjectToDelete(null)} disabled={deleting}>
            Annuler
          </Button>
          <Button
            onClick={async () => {
              setDeleting(true);
              await dispatch(deleteProjectAction(projectToDelete)).unwrap();
              setProjectToDelete(null);
              setDeleting(false);
            }}
            autoFocus
            variant="contained"
            color="error"
            disabled={deleting}
          >
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}
