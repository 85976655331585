import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Iconify from "src/components/Iconify";
import Page from "src/components/Page";
import Amortissement from "src/components/PF/Amortissement";
import EcheancierComponent from "src/components/PF/Echeancier";
import Finacement from "src/components/PF/Finacement";
import Reinvestissement from "src/components/PF/Reinvestissement";
import Rentabilite from "src/components/PF/Rentabilite";
import DCrecap from "src/components/recap/DCrecap";
import RHrecap from "src/components/recap/RHrecap";
import RMrecap from "src/components/recap/RMRecap";
import axiosInstance from "src/libs/axios";
import { getBP, updateBP } from "src/stores/bp/bpSlice";
import { bpRecalc } from "src/utils/bp/CA";

function PF() {
  const dispatch = useDispatch();
  const [pdfErrors, setPdfErrors] = useState([]);
  const {
    financement,
    amortissements,
    Echeancier,
    rentabilite,
    investissements,
  } = useSelector((state) => state.bp);
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const oldData = useSelector((state) => state.bp.textDataArray);

  const projects = useSelector((state) => state.projects.projects);
  const currentProject = projects.find(
    (project) => project.pk === selectedProject
  );

  const [value, setValue] = useState(0);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const { user } = useSelector((state) => state.user);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  useEffect(() => {
    dispatch(getBP(selectedProject));

    dispatch(
      updateBP({
        projectId: selectedProject,
        payload: bpRecalc(oldData),
      })
    );
  }, [dispatch, selectedProject]);
  const getPDF = async () => {
    try {
      setPdfErrors([]);
      setLoadingPDF(true);
      const response = await axiosInstance.get(`/pdf/${selectedProject}/`, {
        responseType: "arraybuffer",
      });
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      window.open(url, "_blank");
      setLoadingPDF(false);
    } catch (error) {
      alert("Erreur lors de la génération du PDF");
      setLoadingPDF(false);
    }
  };
  const checkPDF = () => {
    const errors = [];
    if (
      currentProject.legal_form === "SARL" ||
      currentProject.legal_form === "SA"
    ) {
      currentProject.invitations
        .filter((invitation) => invitation.acceptedStatus === "ACCEPTED")
        .forEach((invitation) => {
          if (!invitation.profile.first_name || !invitation.profile.last_name) {
            errors.push(`Le profil de ${invitation.email} n'est pas complet`);
          }
        });
      if (user)
        if (
          !user.first_name ||
          !user.last_name ||
          !user.education_other ||
          !user.cin ||
          !user.phone_number ||
          !user.address
        ) {
          errors.push(`Votre profil n'est pas complet`);
        }
    }
    if (errors.length) setPdfErrors(errors);
    else getPDF();
  };
  return (
    <Page
      title="Etude de faisabilité"
      style={{
        border: "1px solid #ECECEC",
        borderRadius: "10px",
        padding: " 1rem ",
        height: "98%",
      }}
    >
      <Grid container spacing={1} sx={{ height: "100%" }}>
        <Grid item md={12}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Financement" {...a11yProps(0)} />
                <Tab label="Amortissements" {...a11yProps(1)} />
                <Tab label="Réinvestissement " {...a11yProps(2)} />
                {currentProject?.finance_mode === "Crédit" && (
                  <Tab label="Echeancier" {...a11yProps(3)} />
                )}
                <Tab label="Rentabilité" {...a11yProps(4)} />
                <Tab label="Ressource humaine" {...a11yProps(5)} />
                <Tab label="Ressource matériel" {...a11yProps(6)} />
                <Tab label="Dépenses courantes" {...a11yProps(7)} />
                <LoadingButton
                  loading={loadingPDF}
                  onClick={checkPDF}
                  size="small"
                  variant="contained"
                  sx={{ ml: "auto", my: 1 }}
                  title="Télécharger votre BP en format pdf"
                >
                  <Iconify
                    icon="carbon:generate-pdf"
                    sx={{ width: 20, height: 20, m: 0, p: 0 }}
                  />
                </LoadingButton>
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Finacement
                data={financement}
                investissements={investissements}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Amortissement data={amortissements} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Reinvestissement />
            </TabPanel>
            {currentProject?.finance_mode === "Crédit" && (
              <TabPanel value={value} index={3}>
                <EcheancierComponent data={Echeancier} />
              </TabPanel>
            )}
            <TabPanel
              value={value}
              index={currentProject?.finance_mode === "Crédit" ? 4 : 3}
            >
              <Rentabilite data={rentabilite} />
            </TabPanel>
            <TabPanel
              value={value}
              index={currentProject?.finance_mode === "Crédit" ? 5 : 4}
            >
              <RHrecap />
            </TabPanel>
            <TabPanel
              value={value}
              index={currentProject?.finance_mode === "Crédit" ? 6 : 5}
            >
              <RMrecap />
            </TabPanel>
            <TabPanel
              value={value}
              index={currentProject?.finance_mode === "Crédit" ? 7 : 6}
            >
              <DCrecap />
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={pdfErrors.length > 0}
        onClose={() => setPdfErrors([])}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Profils incomplets"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {pdfErrors.map((error) => (
              <li>{error}</li>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={getPDF}>Voir le PDF</Button>
          <Button onClick={() => setPdfErrors([])}>Fermer</Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}

export default PF;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
