import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

function Synthesis({setActiveStep}) {
  const projects = useSelector((state) => state.projects.projects);
  const projectId = useSelector((state) => state.projects.selectedProject);
  const currentProject = projects.find((project) => project.pk === projectId);
  return currentProject ? (
    <Box sx={{ p: 1 }}>
      <Typography variant="h6" sx={{ mb: 1 }} style={{ cursor: "pointer" }} onClick={()=>{setActiveStep(6)}}>
        Synthèse
      </Typography>
      {currentProject.name && (
        <>
          <Typography variant="body1" style={{ cursor: "pointer" }} onClick={()=>{setActiveStep(0)}}>Dénomination social</Typography>
          <Typography variant="body2" sx={{ color: "#999999" }}>
            {currentProject.name}
          </Typography>
        </>
      )}
      {currentProject.legal_form && (
        <>
          <Typography variant="body1" style={{ cursor: "pointer" }} onClick={()=>{setActiveStep(1)}}>Forme juridique</Typography>
          <Typography variant="body2" sx={{ color: "#999999" }}>
            {currentProject.legal_form}
          </Typography>
        </>
      )}
      {currentProject.governorate && (
        <>
          <Typography variant="body1" style={{ cursor: "pointer" }} onClick={()=>{setActiveStep(2)}}>Governorat</Typography>
          <Typography variant="body2" sx={{ color: "#999999" }}>
            {currentProject.governorate}
          </Typography>
        </>
      )}
      {currentProject.delegation && (
        <>
          <Typography variant="body1" style={{ cursor: "pointer" }} onClick={()=>{setActiveStep(2)}}>Delegation</Typography>
          <Typography variant="body2" sx={{ color: "#999999" }}>
            {currentProject.delegation}
          </Typography>
        </>
      )}
      {currentProject.nat_category && (
        <>
          <Typography variant="body1" style={{ cursor: "pointer" }} onClick={()=>{setActiveStep(3)}}>Catégorie NAT</Typography>
          <Typography variant="body2" sx={{ color: "#999999" }}>
            {currentProject.nat_category}
          </Typography>
          <Typography variant="body2" sx={{ color: "#999999" }}>
            {currentProject.NAT_title}
          </Typography>
        </>
      )}
      {currentProject.funding_method &&
        currentProject.funding_method !== "none" && (
          <>
            <Typography variant="body1" style={{ cursor: "pointer" }} onClick={()=>{setActiveStep(4)}}>Regime d'investissement</Typography>
            <Typography variant="body2" sx={{ color: "#999999" }}>
              {currentProject.funding_method}
            </Typography>
          </>
        )}
      {currentProject.project_study_duration && (
        <>
          <Typography variant="body1" style={{ cursor: "pointer" }} onClick={()=>{setActiveStep(5)}}>Durée de l'étude</Typography>
          <Typography variant="body2" sx={{ color: "#999999" }}>
            {currentProject.project_study_duration} ans
          </Typography>
        </>
      )}
      {currentProject.finance_mode && (
        <>
          <Typography variant="body1" style={{ cursor: "pointer" }} onClick={()=>{setActiveStep(6)}}>Mode de financement</Typography>
          <Typography variant="body2" sx={{ color: "#999999" }}>
            {currentProject.finance_mode}
          </Typography>
        </>
      )}
    </Box>
  ) : (
    <></>
  );
}

export default Synthesis;
