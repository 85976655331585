import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "src/libs/axios";

export const getBMCByProjectIdAction = createAsyncThunk(
  "bmc/getBMCByProjectId",
  async (projectId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/bmc/bmc-get/${projectId}/`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateNoteAction = createAsyncThunk(
  "bmc/updateNoteAction",
  async (note, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/bmc/bmc-update/${note.pk}/`, {
        bmc_type: note.bmc_type,
        explication: { text: note.text, color: note.color, id: note.id },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const createNoteAction = createAsyncThunk(
  "bmc/createNoteAction",
  async (note, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/bmc/bmc-create/`, note);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const deleteNoteAction = createAsyncThunk(
  "bmc/deleteNoteAction",
  async (note, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        `/bmc/bmc-destroy/${note.pk}/`
      );
      dispatch(deleteNote({ type: note.type, pk: note.pk }));
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  segment: [],
  proposition: [],
  distribution: [],
  relation: [],
  revenue: [],
  activite: [],
  ressource: [],
  partenaire: [],
  cout: [],
  currentStep: "segment",
  selected: null,
  savingStatus: "done",
};

const bmcSlice = createSlice({
  name: "bmc",
  initialState,
  extraReducers: {
    [getBMCByProjectIdAction.fulfilled]: (state, action) => {
      const bmc = action.payload;
      const bmcGrouped = bmc.reduce((acc, cur) => {
        if (!acc[cur.bmc_type]) {
          acc[cur.bmc_type] = [];
        }
        acc[cur.bmc_type].push({ ...cur.explication, pk: cur.pk });
        return acc;
      }, {});
      state.segment = [];
      state.proposition = [];
      state.distribution = [];
      state.relation = [];
      state.revenue = [];
      state.activite = [];
      state.ressource = [];
      state.partenaire = [];
      state.cout = [];
      Object.keys(bmcGrouped).forEach((key) => {
        state[key] = bmcGrouped[key];
      });
    },
    [createNoteAction.fulfilled]: (state, action) => {
      const note = action.payload;
      state[note.bmc_type].push({ ...note.explication, pk: note.pk });
    },
    [updateNoteAction.pending]: (state) => {
      state.savingStatus = "saving";
    },
    [updateNoteAction.fulfilled]: (state) => {
      state.savingStatus = "done";
    },
    [updateNoteAction.rejected]: (state) => {
      state.savingStatus = "error";
    },
  },

  reducers: {
    setStep: (state, action) => {
      state.currentStep = action.payload;
    },
    addNote: (state, action) => {
      state[action.payload.type].push(action.payload.note);
    },
    deleteNote: (state, action) => {
      state[action.payload.type] = state[action.payload.type].filter(
        (note) => note.pk !== action.payload.pk
      );
    },
    updateNote: (state, action) => {
      //find the note in the state by id
      state.savingStatus = "unsaved";

      const note = state[action.payload.type].find(
        (note) => note.id === action.payload.note.id
      );
      //update the note
      note.text = action.payload.note.text;
      if (action.payload.note.color) {
        note.color = action.payload.note.color;
      }
    },
  },
});
export default bmcSlice.reducer;
export const { setStep, addNote, updateNote, deleteNote } = bmcSlice.actions;
