import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Iconify from "../Iconify";
import * as Yup from "yup";
import { tableCellClasses } from "@mui/material/TableCell";
import { getBP } from "src/stores/bp/bpSlice";
import useIsMentor from "src/hooks/useIsMentor";
import axiosInstance from "src/libs/axios";

function Tasks({ data, post }) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TableData data={data} post={post} />
      </Grid>
    </Grid>
  );
}

export default Tasks;

const TableData = ({ data, post }) => {
  const { isMentor, isSupervisor } = useIsMentor();
  const [itemToDelete, setItemToDelete] = useState(null);
  const [itemToEdit, setItemToEdit] = useState(null);
  return (
    <TableContainer
      sx={{ maxHeight: "400px", overflow: "auto", position: "relative" }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <StyledTableRow sx={{ position: "sticky", top: 0, zIndex: 100 }}>
            <StyledTableCell>Intitulé de la tâche</StyledTableCell>
            <StyledTableCell align="center">
              Nombre des employés impliqué
            </StyledTableCell>
            <StyledTableCell align="center">
              Département associé à la tâche
            </StyledTableCell>
            <StyledTableCell align="center">
              Temps consacré en %
            </StyledTableCell>
            {!isMentor && !isSupervisor && (
              <StyledTableCell align="center">Actions</StyledTableCell>
            )}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <StyledTableRow key={item.id}>
              <StyledTableCell>{item.name}</StyledTableCell>
              <StyledTableCell align="center">
                {item.nbEmployee}
              </StyledTableCell>
              <StyledTableCell align="center">
                Département {item.departement==="rnd"?"recherche et développement":item.departement}
              </StyledTableCell>
              <StyledTableCell align="center">{item.temps}%</StyledTableCell>

              {!isMentor && !isSupervisor && (
                <StyledTableCell align="right">
                  <IconButton
                    title="Effacer"
                    color="error"
                    sx={{ mr: 1 }}
                    onClick={() => {
                      setItemToDelete(item.id);
                    }}
                  >
                    <Iconify
                      icon="fa6-solid:trash-can"
                      sx={{ height: 16, width: 16 }}
                    />
                  </IconButton>
                  <IconButton
                    title="modifier"
                    onClick={() => setItemToEdit(item)}
                  >
                    <Iconify
                      icon="akar-icons:edit"
                      sx={{ height: 18, width: 18 }}
                    />
                  </IconButton>
                </StyledTableCell>
              )}
            </StyledTableRow>
          ))}
          <StyledTableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              position: "sticky",
              bottom: 0,
              zIndex: 100,
              backgroundColor: "#fff",
            }}
          >
            <StyledTableCell>
              {!isMentor && !isSupervisor && (
                <IconButton
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    setItemToEdit({
                      name: "",
                      nbEmployee: 0,
                      departement: null,
                      temps: 0,
                      post: post,
                    })
                  }
                >
                  <Iconify icon="ant-design:plus-outlined" />
                </IconButton>
              )}
            </StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
      <DeleteDialaog
        setItemToDelete={setItemToDelete}
        itemToDelete={itemToDelete}
      />
      {itemToEdit && (
        <ProductModal setItemToEdit={setItemToEdit} itemToEdit={itemToEdit} />
      )}
    </TableContainer>
  );
};
const ProductModal = ({ itemToEdit, setItemToEdit }) => {
  const isEdit = !!itemToEdit.id;
  const open = !!itemToEdit;
  const handleClose = () => {
    setItemToEdit(null);
  };
  const dispatch = useDispatch();
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Ce champ est obligatoire"),
    nbEmployee: Yup.number().required("Ce champ est obligatoire"),
    departement: Yup.string().required("Ce champ est obligatoire").nullable(),
    temps: Yup.number().required("Ce champ est obligatoire"),
  });
  const formik = useFormik({
    initialValues: {
      name: itemToEdit.name,
      nbEmployee: itemToEdit.nbEmployee,
      departement: itemToEdit.departement,
      temps: itemToEdit.temps,
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      if (isEdit) {
        await axiosInstance.patch(`/business/tasks/${itemToEdit.id}/`, values);
      } else {
        await axiosInstance.post(`/business/tasks/`, {
          ...values,
          post: itemToEdit.post,
        });
      }
      await dispatch(getBP(selectedProject)).unwrap();
      setIsSubmitting(false);
      handleClose();
    },
  });
  const { handleSubmit, getFieldProps, touched, errors } = formik;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Card>
          <CardContent>
            <FormikProvider value={formik}>
              <Typography variant="h6">
                {!isEdit ? "Nouvelle tâche" : "Modifier la tâche"}
              </Typography>
              <Divider sx={{ mb: 5, mt: 1 }} />
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  sx={{ mb: 2 }}
                  autoComplete="task-name"
                  type="text"
                  label="Intitulé de la tâche"
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  fullWidth
                  sx={{ mb: 2 }}
                  autoComplete="nbEmployee"
                  type="number"
                  label="Nombre d'employés"
                  {...getFieldProps("nbEmployee")}
                  error={Boolean(touched.nbEmployee && errors.nbEmployee)}
                  helperText={touched.nbEmployee && errors.nbEmployee}
                />

                <FormControl
                  sx={{ mb: 2 }}
                  fullWidth
                  {...getFieldProps("departement")}
                  error={Boolean(touched.departement && errors.departement)}
                >
                  <InputLabel id="departement-label">Département</InputLabel>
                  <Select
                    labelId="departement-label"
                    id="departement"
                    value={formik.values.departement}
                    label="Département"
                    onChange={(e) => {
                      formik.setFieldValue("departement", e.target.value);
                    }}
                  >
                    {DEPARTEMENT.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {touched.departement && errors.departement}
                  </FormHelperText>
                </FormControl>
                <TextField
                  fullWidth
                  sx={{ mb: 2 }}
                  autoComplete="temps"
                  type="number"
                  label="Temps"
                  {...getFieldProps("temps")}
                  error={Boolean(touched.temps && errors.temps)}
                  helperText={touched.temps && errors.temps}
                />

                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <LoadingButton
                    sx={{ mt: 3 }}
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {!isEdit ? "Ajouter" : "Modifier"}
                  </LoadingButton>
                </Box>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

const DeleteDialaog = ({ setItemToDelete, itemToDelete }) => {
  const [isSubmitting, setisSubmitting] = useState(false);
  const dispatch = useDispatch();
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );

  const open = itemToDelete !== null;
  const handleClose = () => {
    setItemToDelete(null);
  };
  const deleteProduct = async () => {
    setisSubmitting(true);
    await axiosInstance.delete(`/business/tasks/${itemToDelete}/`);
    await dispatch(getBP(selectedProject)).unwrap();
    setisSubmitting(false);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Supprimer la tâche"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Êtes-vous sûr de vouloir supprimer cette tâche ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isSubmitting}>
          Annuler
        </Button>
        <Button
          color="error"
          onClick={deleteProduct}
          autoFocus
          disabled={isSubmitting}
        >
          Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DEPARTEMENT = [
  { value: "production", label: "Production" },
  { value: "marketing", label: "Marketing" },
  { value: "commercial", label: "Commercial" },
  { value: "rnd", label: "Recherche et développement" },
];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    lineHeight: 1.4,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,

  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: theme.palette.common.white,
    fontWeight: "bold",
  },
}));
