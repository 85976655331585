import {
  DesktopDatePicker,
  LoadingButton,
  LocalizationProvider,
} from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { EDUCATION_LEVELS, GOVERNORATS } from "src/utils/constants";
import { useState } from "react";
import Iconify from "../Iconify";

const ValidationSchema = Yup.object().shape({
  first_name: Yup.string().required("Champs requis"),
  last_name: Yup.string().required("Champs requis"),
  education_other: Yup.string().required("Champs requis"),
  birthdate: Yup.date()
    .typeError("Date invalide")
    .required("Champs requis")
    .nullable(),
  gender: Yup.string().required("Champs requis"),
  address: Yup.string().required("Champs requis"),
  phone_number: Yup.string()
    .length(8, "Téléphone doit avoir 8 chiffres")
    .trim()
    .matches("^[0-9]+$", "Téléphone doit avoir 8 chiffres")
    .required("Champs requis"),
  cin: Yup.string().length(8, "CIN doit avoir 8 chiffres")
  .trim().required("Champs requis"),
});

function ProfileForm({ submit, children }) {
  const { user } = useSelector((state) => state.user);
  const [CV, setCV] = useState(null);
  const [changingCV, setChangingCV] = useState(false);
  const handleCVChange = (e) => {
    setCV(e.target.files[0]);
  };
  const formik = useFormik({
    initialValues: {
      first_name: user.first_name || "",
      last_name: user.last_name || "",
      birthdate: user.birthdate || "",
      gender: user.gender || "",
      address: user.address || "",
      phone_number: user.phone_number || "",
      governorate: user.governorate || "",
      education: user.education || "",
      education_other: user.education_other || "",
      about: user.about || "",
      cin: user.cin || "",
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      if (CV) {
        await submit({
          ...values,
          birthdate: formatDate(values.birthdate),
          resume: CV,
        });
      } else {
        await submit({ ...values, birthdate: formatDate(values.birthdate) });
      }
    },
  });
  const formatDate = (date) => {
    if (typeof date === "string") {
      return date;
    }

    return date.toISOString().split("T")[0];
  };
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Grid container sx={{ flex: 1 }} spacing={10}>
          <Grid item xs={4} sx={{ p: 3 }}>
            <Stack spacing={3}>
              {children}
              <TextField
                fullWidth
                autoComplete="first_name"
                type="text"
                label="Prénom"
                {...getFieldProps("first_name")}
                error={Boolean(touched.first_name && errors.first_name)}
                helperText={touched.first_name && errors.first_name}
              />
              <TextField
                fullWidth
                autoComplete="last_name"
                type="text"
                label="Nom"
                {...getFieldProps("last_name")}
                error={Boolean(touched.last_name && errors.last_name)}
                helperText={touched.last_name && errors.last_name}
              />
            </Stack>
          </Grid>
          <Grid item xs={4} sx={{ p: 3 }}>
            <Stack spacing={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date de naissance"
                  inputFormat="dd/MM/yyyy"
                  value={formik.values.birthdate}
                  onChange={(value) => {
                    formik.setFieldValue("birthdate", value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.birthdate && errors.birthdate)}
                      helperText={touched.birthdate && errors.birthdate}
                    />
                  )}
                />
              </LocalizationProvider>
              <FormControl fullWidth>
                <InputLabel id="gender" error={touched.gender && errors.gender}>
                  Genre
                </InputLabel>
                <Select
                  fullWidth
                  autoComplete="gender"
                  label="Genre"
                  {...getFieldProps("gender")}
                  error={Boolean(touched.gender && errors.gender)}
                >
                  <MenuItem value="M">Homme</MenuItem>
                  <MenuItem value="F">Femme</MenuItem>
                </Select>
                {touched.gender && errors.gender && (
                  <FormHelperText error>Adresse requis</FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth>
                <InputLabel
                  id="governorate"
                  error={touched.governorate && errors.governorate}
                >
                  Gouvernorat
                </InputLabel>
                <Select
                  fullWidth
                  autoComplete="governorate"
                  label="Gouvernorat"
                  {...getFieldProps("governorate")}
                  error={Boolean(touched.governorate && errors.governorate)}
                >
                  {GOVERNORATS.map((gov) => (
                    <MenuItem value={gov}>{gov}</MenuItem>
                  ))}
                </Select>
                {touched.governorate && errors.governorate && (
                  <FormHelperText error>Gouvernorat requis</FormHelperText>
                )}
              </FormControl>
              <TextField
                multiline
                maxRows={2}
                minRows={2}
                fullWidth
                autoComplete="address"
                type="text"
                label="Adresse"
                {...getFieldProps("address")}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              />
              <TextField
                fullWidth
                autoComplete="phone_number"
                type="tel"
                label="Téléphone"
                {...getFieldProps("phone_number")}
                error={Boolean(touched.phone_number && errors.phone_number)}
                helperText={touched.phone_number && errors.phone_number}
              />
            </Stack>
          </Grid>
          <Grid item xs={4} sx={{ p: 3 }}>
            <Stack spacing={3}>
            <TextField
             
                fullWidth
                autoComplete="cin"
                type="text"
                label="CIN"
                {...getFieldProps("cin")}
                error={Boolean(
                  touched.cin && errors.cin
                )}
                helperText={touched.cin && errors.cin}
              />
              <FormControl fullWidth>
                <InputLabel
                  id="education"
                  error={touched.education && errors.education}
                >
                  Education
                </InputLabel>
                <Select
                  fullWidth
                  autoComplete="education"
                  label="Education"
                  {...getFieldProps("education")}
                  error={Boolean(touched.education && errors.education)}
                >
                  {EDUCATION_LEVELS.map((edu) => (
                    <MenuItem value={edu.value}>{edu.title}</MenuItem>
                  ))}
                </Select>
                {touched.education && errors.education && (
                  <FormHelperText error>Education requis</FormHelperText>
                )}
              </FormControl>
              <TextField
                multiline
                maxRows={2}
                minRows={2}
                fullWidth
                autoComplete="education_other"
                type="text"
                label="Qualifications"
                {...getFieldProps("education_other")}
                error={Boolean(
                  touched.education_other && errors.education_other
                )}
                helperText={touched.education_other && errors.education_other}
              />
              <TextField
                multiline
                maxRows={5}
                minRows={5}
                fullWidth
                autoComplete="about"
                type="text"
                label="A propos de vous"
                {...getFieldProps("about")}
                error={Boolean(touched.about && errors.about)}
                helperText={touched.about && errors.about}
              />

              {user.resume && !changingCV ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid rgba(145, 158, 171, 0.32)",
                    padding: "0.2rem 1rem",
                    borderRadius: "8px",
                  }}
                >
                  <Link href={user.resume} target="_blank" rel="noreferrer">
                    CV
                  </Link>
                  <IconButton
                    onClick={() => setChangingCV(true)}
                    sx={{ ml: "auto" }}
                  >
                    <Iconify icon="akar-icons:edit" />
                  </IconButton>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid rgba(145, 158, 171, 0.32)",
                    padding: "0.2rem 1rem",
                    borderRadius: "8px",
                  }}
                >
                  <Button variant="contained" component="label">
                    {CV ? CV.name : "Ajouter votre CV"}
                    <input
                      hidden
                      accept="application/pdf"
                      type="file"
                      onChange={handleCVChange}
                    />
                  </Button>
                  <IconButton
                    onClick={() => {
                      setChangingCV(false);
                      setCV(null);
                    }}
                    sx={{ ml: "auto" }}
                  >
                    <Iconify icon="clarity:cancel-line" />
                  </IconButton>
                </div>
              )}
            </Stack>
          </Grid>
        </Grid>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mt: "auto",
          }}
        >
          <LinearProgress
            sx={{ flex: 1, mr: 20 }}
            variant="determinate"
            value={user.profile_completion * 100}
            color={
              user.profile_completion < 0.5
                ? "error"
                : user.profile_completion < 0.8
                ? "warning"
                : "success"
            }
          />
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Enregistrer
          </LoadingButton>
        </Box>
      </Form>
    </FormikProvider>
  );
}

export default ProfileForm;
