import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Slide,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BASE_URL } from "src/utils/constants";
import Page from "src/components/Page";
import {
  getMentorshiprequests,
  handleMentorRequest,
} from "src/stores/mentorship/mentorshipSlice";

export default function MentorRequests() {
  const dispatch = useDispatch();
  const { mentorshipRequests, loadingStatus } = useSelector(
    (state) => state.mentorship
  );
  const [dialogData, setDialogData] = useState({
    title: "",
    description: "",
    open: false,
    handleConfirm: () => {},
    handleClose: () => {},
  });

  useEffect(() => {
    dispatch(getMentorshiprequests());
  }, [dispatch]);
  const handleRequest = (value) => {
    dispatch(
      handleMentorRequest({
        pk: value.pk,
        action: value.action,
      })
    );
  };

  return (
    <Page title="Mentorship">
      <ConfirmDialog {...dialogData} />

      <Container>
        {loadingStatus === "loading" && <div>Chargement...</div>}
        {loadingStatus === "error" && <div>Erreur</div>}
        {loadingStatus === "success" && (
          <>
            <Typography variant="h4">Demandes de mentoring</Typography>
            <Typography variant="p" component="h4" sx={{ mb: 5 }}>
              Vous avez {mentorshipRequests.length} demande(s) de mentoring
            </Typography>
            <div style={{ width: "100%" }}>
              <Grid container spacing={2}>
                {mentorshipRequests.map((req) => (
                  <Grid item xs={6} md={3} key={req.pk}>
                    <Card sx={{ height: "100%" }}>
                      <CardMedia
                        component="img"
                        height="140"
                        sx={{ objectFit: "contain" }}
                        image={
                          req.project_picture.includes(BASE_URL)
                            ? req.project_picture
                            : BASE_URL + req.project_picture
                        }
                        alt={req.project_owner.email}
                      />
                      <CardContent>
                        <p>
                          {" "}
                          <span style={{ fontWeight: "bold" }}>Projet: </span>
                          {req.project_name}
                          <br />
                          <span style={{ fontWeight: "bold" }}>
                            Porteur de projet:{" "}
                          </span>
                          {req.project_owner.email}
                        </p>
                    
                      </CardContent>
                      <CardActions>
                        <Box sx={{p:2,pt:0,display:"flex",justifyContent:"space-between",width:"100%"}}>
                        <Button
                          color="error"
                          onClick={() =>
                            setDialogData({
                              title: "Refuser la demande",
                              description: `Voulez-vous vraiment refuser la demande de mentoring de ${req.project_owner.email} pour le projet ${req.project_name} ?`,
                              open: true,
                              handleConfirm: () => handleRequest({ pk: req.pk, action: "reject" }),
                              handleClose: () => setDialogData({ ...dialogData, open: false }),
                            })

                            
                          }
                        >
                          Refuser
                        </Button>

                        <Button
                          color="primary"
                          onClick={() =>
                            setDialogData({
                              title: "Accepter la demande",
                              description: `Voulez-vous vraiment accepter la demande de mentoring de ${req.project_owner.email} pour le projet ${req.project_name} ?`,
                              open: true,
                              handleConfirm: () => handleRequest({ pk: req.pk, action: "accept" }),
                              handleClose: () => setDialogData({ ...dialogData, open: false }),
                            })
                            
                          }
                        >
                          Accepter
                        </Button>
                        </Box>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </div>
          </>
        )}
      </Container>
    </Page>
  );
}

const ConfirmDialog = ({
  title,
  description,
  open,
  handleClose,
  handleConfirm,
}) => {
  const confirm = () => {
    handleConfirm();
    handleClose();
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="error">
          Annuler
        </Button>
        <Button onClick={confirm} variant="contained" color="primary">
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});