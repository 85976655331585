import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";

import Page from "src/components/Page";
import { useNavigate } from "react-router-dom";
import Iconify from "src/components/Iconify";

export default function CanvasPicker() {
  const navigate = useNavigate();

  const CANVAS_TYPES = [
    {
      name: "BMC",
      icon: "codicon:table",
      img: "/images/bmc.png",
      path: "/bmc",
    },
    {
      name: "Lean Canvas",
      icon: "codicon:table",
      img: "/images/lean.png",
      path: "/lean",
    },
    {
      name: "VP Canvas",
      icon: "codicon:table",
      img: "/images/vp.png",
      path: "/vp",
    },
    {
      name: "SWOT",
      icon: "codicon:table",
      img: "/images/swot.png",
      path: "/swot",
    },
    {
      name: "Empathie Map Canvas",
      icon: "codicon:table",
      img: "/images/empathie.png",
      path: "/empathie",
    },
    {
      name: "Persona Canvas",
      icon: "codicon:table",
      img: "/images/persona.png",
      path: "/persona",
    },
  ];

  return (
    <Page title="Canvas">
      <Container>
        <>
          <Typography variant="h4">Canvas</Typography>
          <Typography variant="p" component="h4" sx={{ mb: 5 }}>
            Liste des canvas
          </Typography>
          <div style={{ width: "100%" }}>
            <Grid container spacing={2}>
              {CANVAS_TYPES.map((type) => (
                <Grid item xs={6} md={4} key={type.name}>
                  <Card sx={{ height: "100%" }}>
                    <CardActionArea
                      onClick={() => navigate(type.path)}
                      sx={{ height: "100%" }}
                    >
                      <CardMedia
                        component="img"
                        height="160"
                        sx={{ objectFit: "contain",p:2 }}
                        image={type.img}
                        alt={type.name}
                      />
                      <CardContent>
                        <h3 style={{ textAlign:"center" }}>
                          {type.name}
                        </h3>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        </>
      </Container>
    </Page>
  );
}
