import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Iconify from "../Iconify";
import useIsMentor from "src/hooks/useIsMentor";
import {
  createCanvasEntryAction,
  deleteCanvasEntryAction,
  updateCanvasEntryAction,
} from "src/stores/canvas/canvasSlice";

function SwotEditor() {
  const data = useSelector((state) => state.canvas.data);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const open = selectedEntry !== null;
  const s = useMemo(() => {
    return data.filter((item) => item.type === "strength");
  }, [data]);
  const w = useMemo(() => {
    return data.filter((item) => item.type === "weakness");
  }, [data]);
  const o = useMemo(() => {
    return data.filter((item) => item.type === "opportunity");
  }, [data]);
  const t = useMemo(() => {
    return data.filter((item) => item.type === "threat");
  }, [data]);
  const { isMentor, isSupervisor } = useIsMentor();

  return (
    <>
      {open && (
        <EntryModal
          open={open}
          selectedEntry={selectedEntry}
          onClose={() => setSelectedEntry(null)}
        />
      )}
      <Grid container sx={{ height: "90%" }} columns={13}>
        <Grid item xs={1} sx={{ position: "relative", border: 1 }}>
          <p
            style={{
              position: "absolute",
              textAlign: "center",
              top: "50%",
              left: "50%",
              transformOrigin: "top left",
              transform: "rotate(270deg) translate(-50%, -50%)",
            }}
          >
            Origine interne (organisationnelle)
          </p>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{ backgroundColor: "#b1dd9e", borderTop: 1, borderBottom: 1 }}
        >
          <b>
            <p style={{ textAlign: "center", padding: "0.5em" }}>Forces</p>
          </b>
          {!isMentor && !isSupervisor && (
            <AddEntry type="strength" setSelectedEntry={setSelectedEntry} />
          )}

          <List
            dense
            sx={{
              width: "100%",
              overflow: "auto",
              height: "7rem",
            }}
            className="custom-scrollbar"
          >
            {s.map((item) => (
              <ListItem sx={{ m: 0, mb: 0, p: 0, px: 2 }}>
                <span style={{ marginRight: "0.5em" }}>&#8226;</span>
                <ListItemText
                  primary={item.explication}
                  secondary={null}
                  key={item.id}
                  sx={{ m: 0, p: 0 }}
                />
                {!isMentor && !isSupervisor && (
                  <IconButton
                    onClick={() => setSelectedEntry(item)}
                    sx={{ ml: "auto" }}
                    color="primary"
                    title="Modifier"
                  >
                    <Iconify
                      icon="akar-icons:edit"
                      sx={{ width: 14, height: 14 }}
                    />
                  </IconButton>
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={6} sx={{ backgroundColor: "#FFD580", border: 1 }}>
          <b>
            <p style={{ textAlign: "center", padding: "0.5em" }}>Faiblesses</p>
          </b>
          {!isMentor && !isSupervisor && (
            <AddEntry type="weakness" setSelectedEntry={setSelectedEntry} />
          )}
          <List
            className="custom-scrollbar"
            dense
            sx={{
              width: "100%",
              overflow: "auto",
              height: "7rem",
            }}
          >
            {w.map((item) => (
              <ListItem sx={{ m: 0, mb: 0, p: 0, px: 2 }}>
                <span style={{ marginRight: "0.5em" }}>&#8226;</span>

                <ListItemText
                  primary={item.explication}
                  secondary={null}
                  key={item.id}
                  sx={{ m: 0, p: 0 }}
                />
                {!isMentor && !isSupervisor && (
                  <IconButton
                    onClick={() => setSelectedEntry(item)}
                    sx={{ ml: "auto" }}
                    color="primary"
                    title="Modifier"
                  >
                    <Iconify
                      icon="akar-icons:edit"
                      sx={{ width: 14, height: 14 }}
                    />
                  </IconButton>
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{ position: "relative", border: 1, borderTop: 0 }}
        >
          <p
            style={{
              textAlign: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              transformOrigin: "top left",
              transform: "rotate(270deg) translate(-50%, -50%)",
            }}
          >
            Origine externe (environnementale)
          </p>
        </Grid>
        <Grid item xs={6} sx={{ backgroundColor: "#7a9f79", borderBottom: 1 }}>
          <b>
            <p style={{ textAlign: "center", padding: "0.5em" }}>
              Opportunités
            </p>
          </b>
          {!isMentor && !isSupervisor && (
            <AddEntry type="opportunity" setSelectedEntry={setSelectedEntry} />
          )}

          <List
            className="custom-scrollbar"
            dense
            sx={{
              width: "100%",
              overflow: "auto",
              height: "7rem",
            }}
          >
            {o.map((item) => (
              <ListItem sx={{ m: 0, mb: 0, p: 0, px: 2 }}>
                <span style={{ marginRight: "0.5em" }}>&#8226;</span>

                <ListItemText
                  sx={{ m: 0, p: 0 }}
                  primary={item.explication}
                  secondary={null}
                  key={item.id}
                />
                {!isMentor && !isSupervisor && (
                  <IconButton
                    onClick={() => setSelectedEntry(item)}
                    sx={{ ml: "auto" }}
                    color="primary"
                    title="Modifier"
                  >
                    <Iconify
                      icon="akar-icons:edit"
                      sx={{ width: 14, height: 14 }}
                    />
                  </IconButton>
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{ backgroundColor: "#ff8347", border: 1, borderTop: 0 }}
        >
          <b>
            <p style={{ textAlign: "center", padding: "0.5em" }}>Menaces</p>
          </b>
          {!isMentor && !isSupervisor && (
            <AddEntry type="threat" setSelectedEntry={setSelectedEntry} />
          )}
          <List
            className="custom-scrollbar"
            dense
            sx={{
              width: "100%",
              overflow: "auto",
              height: "7rem",
            }}
          >
            {t.map((item) => (
              <ListItem sx={{ m: 0, p: 0, px: 2 }}>
                <span style={{ marginRight: "0.5em" }}>&#8226;</span>

                <ListItemText
                  sx={{ m: 0, p: 0 }}
                  primary={item.explication}
                  secondary={null}
                  key={item.id}
                />
                {!isMentor && !isSupervisor && (
                  <IconButton
                    onClick={() => setSelectedEntry(item)}
                    sx={{ ml: "auto" }}
                    color="primary"
                    title="Modifier"
                  >
                    <Iconify
                      icon="akar-icons:edit"
                      sx={{ width: 14, height: 14 }}
                    />
                  </IconButton>
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </>
  );
}

export default SwotEditor;

const AddEntry = ({ type, setSelectedEntry }) => {
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const handleOpen = () => {
    setSelectedEntry({
      id: "new",
      explication: "",
      type: type,
      project_id: selectedProject,
      canvasType: "swot",
      color: "#3f51b5",
    });
  };
  return (
    <Button fullWidth size="small" color="primary" onClick={handleOpen}>
      Ajouter
    </Button>
  );
};
const EntrySchema = Yup.object().shape({
  explication: Yup.string().required("Ce champs est obligatoire"),
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
};
const EntryModal = ({ open, selectedEntry, onClose }) => {
  const [loading, setLoading] = useState(false);
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      explication: selectedEntry.explication,
    },
    validationSchema: EntrySchema,
    onSubmit: async (values) => {
      setLoading(true);

      await dispatch(
        selectedEntry.id === "new"
          ? createCanvasEntryAction({
              ...selectedEntry,
              ...values,
            })
          : updateCanvasEntryAction({
              ...selectedEntry,
              ...values,
            })
      ).unwrap();
      setLoading(false);

      onClose();
    },
  });
  const handleDelete = async () => {
    setLoading(true);
    await dispatch(
      deleteCanvasEntryAction({ id: selectedEntry.id, canvasType: "swot" })
    ).unwrap();
    setLoading(false);
    onClose();
  };
  const { handleSubmit, getFieldProps, touched, errors } = formik;
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Card>
          <CardContent>
            <FormikProvider value={formik}>
              <Typography variant="h6">
                {selectedEntry.id === "new"
                  ? "Nouvelle entrée"
                  : "Modifier entrée"}
              </Typography>
              <Divider sx={{ mb: 5, mt: 1 }} />
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  autoComplete="explication"
                  type="text"
                  label="Contenu"
                  {...getFieldProps("explication")}
                  error={Boolean(touched.explication && errors.explication)}
                  helperText={touched.explication && errors.explication}
                />
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <LoadingButton
                    sx={{ mt: 3, mr: 3 }}
                    type="button"
                    color="error"
                    variant="contained"
                    loading={loading}
                    onClick={handleDelete}
                  >
                    Effacer
                  </LoadingButton>

                  <LoadingButton
                    sx={{ mt: 3 }}
                    type="submit"
                    variant="contained"
                    loading={loading}
                  >
                    {selectedEntry.id === "new" ? "Ajouter" : "Modifier"}
                  </LoadingButton>
                </Box>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};
