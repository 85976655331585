import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useIsMentor from "src/hooks/useIsMentor";
import { updateProject } from "src/stores/projects/projectsSlice";

function Step5() {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects.projects);
  const projectId = useSelector((state) => state.projects.selectedProject);
  const currentProject = projects.find((project) => project.pk === projectId);
  const {isMentor,isSupervisor}=useIsMentor()

  const handleChange = (event) => {
    dispatch(
      updateProject({ ...currentProject, funding_method: event.target.value })
    );
  };
  return (
    <FormControl sx={{ minWidth: "300px", mt: 3 }}>
      <InputLabel htmlFor="component-outlined">
        Regime d'investissement
      </InputLabel>
      <Select
        readOnly={isMentor||isSupervisor}
        labelId="forme-juridique-label"
        id="forme-juridique"
        value={currentProject ? currentProject.funding_method : ""}
        label="Regime d'investissement"
        onChange={handleChange}
      >
        <MenuItem value="Totalement exportateur">
          Totalement exportateur
        </MenuItem>
        <MenuItem value="Partiellement exportateur">
          Partiellement exportateur
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default Step5;
