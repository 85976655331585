export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_DEV
    : process.env.REACT_APP_API_URL;

export const COLORS = [
  {
    id: 1,
    primary: "#3f51b5",
    accent: "#fff",
  },
  {
    id: 2,
    primary: "#009688",
    accent: "#fff",
  },
  {
    id: 3,
    primary: "#4caf50",
    accent: "#fff",
  },
  {
    id: 4,
    primary: "#ff9800",
    accent: "#000",
  },
  {
    id: 5,
    primary: "#ff5722",
    accent: "#fff",
  },
  {
    id: 6,
    primary: "#9c27b0",
    accent: "#fff",
  },
];

export const getAccentColor = (color) => {
  const colorObj = COLORS.find((c) => c.primary === color);
  return colorObj.accent;
};
export const BMC_STEPS = [
  { title: "Segments", value: "segment" },
  { title: "Proposition de valeur", value: "proposition" },
  { title: "Canaux de distribution", value: "distribution" },
  { title: "Relation client", value: "relation" },
  { title: "Structure de revenus", value: "revenue" },
  { title: "Activités clés", value: "activite" },
  { title: "Ressources clés", value: "ressource" },
  { title: "Partenaires clés", value: "partenaire" },
  { title: "Structure de coûts", value: "cout" },
];
export const LEAN_STEPS = [
  { title: "Segments de clientèles", value: "segment" },
  { title: "Utilisateurs pionniers", value: "pionniers" },
  { title: "Probléme", value: "probleme" },
  { title: "Alternatives existantes ", value: "alternatives" },
  { title: "Solution", value: "solution" },
  { title: "Proposition de valeur unique", value: "proposition" },
  { title: "Votre <<Pitch>>! ", value: "pitch" },
  { title: "Avantage compétitif", value: "avantage" },
  { title: "Canaux", value: "canaux" },
  { title: "Indicateurs de performance", value: "indicateur" },
  { title: "Coûts", value: "couts" },
  { title: "Sources de revenus", value: "revenus" },
  { title: "Sommaire", value: "summary" },
];

export const VP_STEPS = [
  { title: "Jobs to be done", value: "jobs" },
  { title: "Pains", value: "pains" },
  { title: "Gains", value: "gains" },
  { title: "Produits & services", value: "produit" },
  { title: "Pain Relievers", value: "painReliever" },
  { title: "Gain Creators", value: "gainsCreator" },
  { title: "Sommaire", value: "summary" },
];
export const FICHE_STEPS = [
  { title: "Dénomination social", value: "name" },
  { title: "Forme juridique", value: "legal_form" },
  { title: "Investisseurs", value: "Investisseurs" },
  { title: "Equipe fondatrice", value: "team" },
  { title: "Governorat & délégation", value: "governorate_delegation" },
  { title: "Secteur", value: "nat_category_NAT_title" },
  { title: "Regime d'investissement ", value: "funding_method" },
  { title: "Durée de l'étude", value: "project_study_duration" },
  { title: "Mode de financement", value: "Mode de financement" },
];
export const PROJECT_PRESENTATION_STEPS = [
  { title: "Présentation du projet", value: "presentation" },
  { title: "Mission (Produits, Services)", value: "mission" },
  { title: "Objectifs", value: "objectifs" },
  { title: "Description des produits et/ou services", value: "description" },
  { title: "Partenaires", value: "partentaires" },
  { title: "Certification", value: "certification" },
  { title: "Apports en innovations", value: "apports" },
  { title: "Facteurs d’attractivité", value: "attractivite" },
  { title: "Facteurs clé du succès", value: "succes" },
  { title: "Impact du Projet", value: "impact" },
  { title: "Calendrier de réalisation", value: "calendrier" },
  { title: "Réglementation du secteur", value: "reglementation" },
  { title: "Concours des fonds spéciaux", value: "concours" },
];
export const SECTEUR_STEPS = [
  { title: "Présentation du secteur", value: "presentation" },
  { title: "Marché potentiel", value: "marchepot" },
  { title: "Marché cible", value: "marchecib" },
  { title: "Analyse de l’offre et de la concurrence", value: "analyseoffre" },
  { title: "Analyse SWOT", value: "analyseswot" },
];

export const PERSONA_STEPS = [
  { title: "Profil", value: "profil" },
  { title: "Contexte", value: "context" },
  { title: "Attentes", value: "attente" },
  { title: "Sentiments", value: "sentiment" },
  { title: "Problèmes", value: "probleme" },
  { title: "Outils", value: "outil" },
  { title: "Ressources", value: "resource" },
  { title: "Sommaire", value: "summary" },
];

export const EMPATHY_STEPS = [
  { title: "Ce que votre persona Entend", value: "entend" },
  { title: "Ce que votre persona pense et ressent", value: "pense" },
  { title: "Ce que votre persona Voit", value: "voit" },
  { title: "Ce que votre persona Dit et fait", value: "dit" },
  { title: "Problème : peurs, Frustrations et Obstacles", value: "probleme" },
  { title: "Besoins Envie", value: "besoin" },
  { title: "Sommaire", value: "summary" },
];

export const GOVERNORATS = [
  "Béja",
  "Ben Arous",
  "Bizerte",
  "Gabès",
  "Gafsa",
  "Jendouba",
  "Kairouan",
  "Kasserine",
  "Kébili",
  "Ariana",
  "la Manouba",
  "Mahdia",
  "Médenine",
  "Monastir",
  "Nabeul",
  "Sfax",
  "Sidi Bouzid",
  "Siliana",
  "Sousse",
  "Tataouine",
  "Tozeur",
  "Tunis",
  "Zaghouan",
  "Kef",
];
export const DELEGATIONS = [
  {
    governorat: "Béja",
    delegations: [
      "Béja Nord",
      "Nefza",
      "Medjez el-Bab",
      "Béja Sud",
      "Testour",
      "Téboursouk",
      "Amdoun",
      "Goubellat",
      "Thibar",
    ],
  },
  {
    governorat: "Ben Arous",
    delegations: [
      "Fouchana",
      "El Mourouj",
      "Mornag",
      "Mohamedia",
      "Radès",
      "Medina Jedida",
      "Hammam Lif",
      "Ben Arous",
      "Ezzahra",
      "Bou Mhel el-Bassatine",
      "Hammam Chott",
      "Mégrine",
    ],
  },
  {
    governorat: "Bizerte",
    delegations: [
      "Bizerte Nord",
      "Menzel Bourguiba",
      "Ras Jebel",
      "Mateur",
      "Bizerte Sud",
      "Sejnane",
      "Menzel Jemil",
      "Joumine",
      "Ghezala",
      "El Alia",
      "Zarzouna",
      "Tinja",
      "Ghar El Melh",
      "Utique",
    ],
  },
  {
    governorat: "Gabès",
    delegations: [
      "El Hamma",
      "Gabès Sud",
      "Mareth",
      "Gabès Médina",
      "Gabès Ouest",
      "Métouia",
      "Ghannouch",
      "Nouvelle Matmata",
      "Menzel El Habib",
      "Matmata",
    ],
  },
  {
    governorat: "Gafsa",
    delegations: [
      "Gafsa Sud",
      "Métlaoui",
      "Sened",
      "El Ksar",
      "Moularès",
      "Redeyef",
      "El Guettar",
      "Belkhir",
      "Mdhilla",
      "Gafsa Nord",
      "Sidi Aïch",
    ],
  },
  {
    governorat: "Kairouan",
    delegations: [
      "Kairouan Nord",
      "Kairouan Sud",
      "Bou Hajla",
      "Sbikha",
      "Haffouz",
      "Nasrallah",
      "Oueslatia",
      "Hajeb El Ayoun",
      "Chebika",
      "El Alâa",
      "Echrarda",
    ],
  },
  {
    governorat: "Kasserine",
    delegations: [
      "Sbeïtla",
      "Kasserine Nord",
      "Fériana",
      "Sbiba",
      "Foussana",
      "Thala",
      "Majel Bel Abbès",
      "Kasserine Sud",
      "Ezzouhour",
      "El Ayoun",
      "Hassi El Ferid",
      "Jedelienne",
      "Haïdra",
    ],
  },
  {
    governorat: "Jendouba",
    delegations: [
      "Jendouba Sud",
      "Ghardimaou",
      "Fernana",
      "Tabarka",
      "Jendouba Nord",
      "Balta-Bou Aouane",
      "Aïn Draham",
      "Bou Salem",
      "Oued Meliz",
    ],
  },
  {
    governorat: "Kébili",
    delegations: [
      "Kébili Sud",
      "Kébili Nord",
      "Souk Lahad",
      "Douz Nord",
      "Douz Sud",
      "Faouar",
    ],
  },
  {
    governorat: "l'Ariana",
    delegations: [
      "Ariana Ville",
      "La Soukra",
      "Ettadhamen",
      "Raoued",
      "Mnihla",
      "Kalâat el-Andalous",
      "Sidi Thabet",
    ],
  },
  {
    governorat: "Manouba",
    delegations: [
      "Douar Hicher",
      "Oued Ellil",
      "La Manouba",
      "Tebourba",
      "Djedeida",
      "Mornaguia",
      "El Batan",
      "Borj El Amri",
    ],
  },
  {
    governorat: "Mahdia",
    delegations: [
      "Mahdia",
      "Ksour Essef",
      "Essouassi",
      "El Jem",
      "Sidi Alouane",
      "Bou Merdes",
      "Chorbane",
      "Chebba",
      "Ouled Chamekh",
      "Melloulèche",
      "Hebira",
    ],
  },
  {
    governorat: "Médenine",
    delegations: [
      "Zarzis",
      "Ben Gardane",
      "Djerba - Houmt Souk",
      "Djerba - Midoun",
      "Médenine Nord",
      "Médenine Sud",
      "Beni Khedache",
      "Djerba - Ajim",
      "Sidi Makhlouf",
    ],
  },
  {
    governorat: "Monastir",
    delegations: [
      "Monastir",
      "Moknine",
      "Jemmal",
      "Ksar Hellal",
      "Téboulba",
      "Ksibet el-Médiouni",
      "Bembla",
      "Zéramdine",
      "Sayada-Lamta-Bou Hajar",
      "Sahline",
      "Ouerdanine",
      "Bekalta",
      "Beni Hassen",
    ],
  },
  {
    governorat: "Nabeul",
    delegations: [
      "Hammamet",
      "Korba",
      "Nabeul",
      "Menzel Temime",
      "Grombalia",
      "Kélibia",
      "Soliman",
      "Dar Chaâbane El Fehri",
      "El Haouaria",
      "Béni Khiar",
      "Béni Khalled",
      "Menzel Bouzelfa",
      "Bou Argoub",
      "El Mida",
      "Takelsa",
      "Hammam Ghezèze",
    ],
  },
  {
    governorat: "Sfax",
    delegations: [
      "Sfax Ville",
      "Sfax Ouest",
      "Sfax Sud",
      "Sakiet Eddaïer",
      "Sakiet Ezzit",
      "Bir Ali Ben Khalifa",
      "Thyna",
      "Jebiniana",
      "El Hencha",
      "Agareb",
      "Menzel Chaker",
      "Mahrès",
      "Skhira",
      "El Amra",
      "Graïba",
      "Kerkennah",
    ],
  },
  {
    governorat: "Sidi Bouzid",
    delegations: [
      "Sidi Bouzid Ouest",
      "Regueb",
      "Sidi Bouzid Est",
      "Jilma",
      "Bir El Hafey",
      "Sidi Ali Ben Aoun",
      "Menzel Bouzaiane",
      "Mezzouna",
      "Meknassy",
      "Ouled Haffouz",
      "Souk Jedid",
      "Cebbala Ouled Asker",
    ],
  },
  {
    governorat: "Siliana",
    delegations: [
      "Makthar",
      "Rouhia",
      "Siliana Sud",
      "Siliana Nord",
      "El Krib",
      "Bou Arada",
      "Gaâfour",
      "Kesra",
      "Sidi Bou Rouis",
      "Bargou",
      "El Aroussa",
    ],
  },
  {
    governorat: "Sousse",
    delegations: [
      "M'saken",
      "Sousse Riadh",
      "Sousse Jawhara",
      "Kalâa Kebira",
      "Sousse Sidi Abdelhamid",
      "Enfida",
      "Hammam Sousse",
      "Sousse Médina",
      "Kalâa Seghira",
      "Akouda",
      "Bouficha",
      "Sidi Bou Ali",
      "Kondar",
      "Sidi El Hani",
      "Hergla",
    ],
  },
  {
    governorat: "Tataouine",
    delegations: [
      "Tataouine Nord",
      "Tataouine Sud",
      "Ghomrassen",
      "Smâr",
      "Remada",
      "Bir Lahmar",
      "Dehiba",
    ],
  },
  {
    governorat: "Tozeur",
    delegations: ["Tozeur", "Degache", "Nefta", "Tameghza", "Hazoua"],
  },
  {
    governorat: "Tunis",
    delegations: [
      "Hraïria",
      "El Kabaria",
      "Sidi Hassine",
      "La Marsa",
      "Le Bardo",
      "El Omrane supérieur",
      "Le Kram",
      "El Menzah",
      "El Omrane",
      "Ezzouhour",
      "Bab El Bhar",
      "Cité El Khadra",
      "Séjoumi",
      "El Ouardia",
      "Bab Souika",
      "Sidi El Béchir",
      "La Goulette",
      "Médina",
      "Djebel Jelloud",
      "Ettahrir",
      "Carthage",
    ],
  },
  {
    governorat: "Zaghouan",
    delegations: [
      "El Fahs",
      "Zaghouan",
      "Nadhour",
      "Bir Mcherga",
      "Zriba",
      "Saouaf",
    ],
  },
  {
    governorat: "Kef",
    delegations: [
      "Kef Est",
      "Dahmani",
      "Tajerouine",
      "Kef Ouest",
      "Nebeur",
      "Sers",
      "Sakiet Sidi Youssef",
      "El Ksour",
      "Kalaat Senan",
      "Jérissa",
      "Kalâat Khasba",
    ],
  },
];

export const NAT = [
  { title: "", sousCat: [] },
  {
    title: "cat 1",
    sousCat: ["sous cat 1", "sous cat 2", "sous cat 3"],
  },
  {
    title: "cat 2",
    sousCat: ["sous cat 1", "sous cat 2", "sous cat 3"],
  },
];

export const EDUCATION_LEVELS = [
  {
    title: "Autre",
    value: -1,
  },
  {
    title: "License",
    value: 1,
  },
  {
    title: "Master",
    value: 2,
  },
  {
    title: "Doctorat",
    value: 3,
  },
];

export const SDC_STEPS = [
  { title: "Objectif part de marché – prévisions des ventes", value: "CA" },

  {
    title: "Processus de commercialisation",
    value: "Organisation commerciale et stratégie fonctionnelle",
  },
  {
    title: "Ressource humaine affecté au département commercial",
    value: "Ressource humaine affecté au département commercial",
  },
  {
    title: "Investissement matériel et immatériel dédiée au département commercial",
    value: "Ressource matériel affecté au département commercial",
  },
  {
    title: "Dépenses courantes communes du département commercial",
    value: "Dépenses courantes du département commercial",
  },
  { title: "Stratégies de Marketing", value: "Stratégies de Marketing" },

  {
    title: "Communication et Stratégie évenementielle",
    value: "Communication et Stratégie évenementielle",
  },
  {
    title: "Ressource humaine affecté au département Marketing",
    value: "Ressource humaine affecté au département Marketing",
  },
  {
    title: "Investissement matériel et immatériel dédiée au département marketing",
    value: "Ressource matériel affecté au département Marketing",
  },
  {
    title: "Dépenses courantes communes du département Marketing",
    value: "Dépenses courantes du département Marketing",
  },
  {
    title: "Canaux et strategie de distribution",
    value: "Canaux et strategie de distribution",
  },
];

export const PO_STEPS = [
  {
    title:
      "Processus de production – de prestation de service et immobilisations",
    value:
      "Processus de production – de prestation de service et immobilisations",
  },
  {
    title: "Achats consommés prévisionnel",
    value: "Achats consommés prévisionnel",
  },

  {
    title: "Ressource humaine affecté au département Porduction",
    value: "Ressource humaine affecté au département Porduction",
  },
  {
    title: "Investissement matériel et immatériel dédiée au département production",
    value: "Ressource matériel affecté au département Production",
  },
  {
    title: "Dépenses courantes communes du département Production",
    value: "Dépenses courantes du département Production",
  },
  {
    title: "Capacité de production",
    value: "Capacité de production",
  },
  {
    title: "Approvisionnement",
    value: "Approvisionnement",
  },
  {
    title: "Local - Implantation & Aménagements",
    value: "Local - Implantation & Aménagements",
  },
  {
    title: "Normes et autorisations",
    value: "Normes et autorisations",
  },
  {
    title: "Impact sur l’environnement",
    value: "Impact sur l’environnement",
  },
];
export const RND_STEPS = [
  {
    title: "Veille technologique",
    value: "Veille technologique",
  },
  {
    title: "Développement des technologies spécifique et prototypage",
    value: "Développement des technologies spécifique et prototypage",
  },
  {
    title: "Protection juridique",
    value: "Protection juridique",
  },
  {
    title: "Partenaire Recherche & développement",
    value: "Partenaire Recherche & développement",
  },
  {
    title:
      "Ressource Humaine affectées au département recherche et développement",
    value:
      "Ressource Humaine affectées au département recherche et développement",
  },
  {
    title:
      "Investissement matériel et immatériel dédiée au département recherche et développement",
    value:
      "Ressource Matériel communes affectées au département recherche et développement",
  },
  {
    title:
      "Dépenses courantes communes du département recherche et développement",
    value:
      "Dépenses courantes communes du département recherche et développement",
  },
];

export const CEP_ORDER = [
  "CHIFFRE D'AFFAIRES",
  "ACHATS CONSOMMÉS",
  "MARGE BRUTE",
  "CHARGES DE PERSONNEL HORS CHARGES FISCALES",
  "AUTRES CHARGES DEXPLOITATION",
  "RESULTAT BRUT DEXPLOITATION (EBITDA)",
  "DOTATIONS AUX AMORTISSEMENTS",
  "RESULTAT AVANT INTERET ET IMPOT (EBIT)",
  "CHARGES D'INTERET",
  "RESULTAT AVANT IMPOTS",
  "RESULTAT IMPOSABLE",
  "MONTANT DE L'IS",
  "RESULTAT NET",
  "CASHFLOW NET",
  "CASHFLOW ACTUALISÉ",
];

export const getCEP = (data) => {
  let orderedData = [];
  CEP_ORDER.forEach((key) => {
    orderedData.push(data.find((d) => d.name === key));
  });
  return orderedData;
};

export const GOV_COORDs = {
  Béja: [9.35, 36.55],
  "Ben Arous": [10.25, 36.6],
  Bizerte: [9.68, 37],
  Gabès: [9.85, 33.75],
  Gafsa: [8.8, 34.35],
  Jendouba: [8.7, 36.55],
  Kairouan: [9.9, 35.55],
  Kasserine: [8.8, 35.1],
  Kébili: [8.8, 33.2],
  "l'Ariana": [10.15, 36.9],
  Manouba: [9.87, 36.75],
  Mahdia: [10.95, 35.25],
  Médenine: [11.25, 33],
  Monastir: [10.8, 35.55],
  Nabeul: [10.7, 36.6],
  Sfax: [10.4, 34.75],
  "Sidi Bouzid": [9.6, 34.75],
  Siliana: [9.4, 36],
  Sousse: [10.4, 35.74],
  Tataouine: [9.9, 31.9],
  Tozeur: [8, 33.85],
  Tunis: [10.16, 36.75],
  Zaghouan: [10, 36.25],
  Kef: [8.7, 36],
};
