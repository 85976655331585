import Grid from "@mui/material/Grid";
import { LeanViewItem } from "./LeanViewItem";

import { LEAN_STEPS } from "src/utils/constants";
import { useSelector } from "react-redux";

export default function LeanView({steps}) {

  const currentStep = useSelector(state=>state.canvas.currentStep)
  const isFinalStep = currentStep === steps.length-1;

  return (
    <>
      <Grid container spacing={0} columns={15}>
        <Grid item md={3}>
          <Grid>
            <LeanViewItem
              icon="mdi:spider-web"
              borderStyle={{
                border: "1px solid black",
                borderBottom: "none",
              }}
              content={{
                title: LEAN_STEPS[2].title,
                height: isFinalStep ? "180px" : "135px",
                index: 2,
              }}
              limit="1"
            ></LeanViewItem>
          </Grid>
          <Grid>
            <LeanViewItem
              // icon="el:link"
              borderStyle={{
                border: "1px solid black",
                borderTop: "1px dashed #a9a9a9",
              }}
              content={{
                title: LEAN_STEPS[3].title,
                height: isFinalStep ? "180px" : "135px",
                index: 3,
              }}
              limit="1"
            ></LeanViewItem>
          </Grid>
        </Grid>

        <Grid item md={3}>
          <Grid>
            <LeanViewItem
              icon="teenyicons:bulb-on-solid"
              borderStyle={{
                border: "1px solid black",
                borderRight: "none",
                borderLeft: "none",
              }}
              limit="1"
              content={{
                title: LEAN_STEPS[4].title,
                height: isFinalStep ? "180px" : "135px",
                index: 4,
              }}
            ></LeanViewItem>
          </Grid>
          <Grid>
            <LeanViewItem
              icon="material-symbols:bar-chart"
              borderStyle={{
                borderBottom: "1px solid black",
              }}
              limit="1"
              content={{
                title: LEAN_STEPS[9].title,
                height: isFinalStep ? "180px" : "135px",
                index: 9,
              }}
            ></LeanViewItem>
          </Grid>
        </Grid>
        <Grid item md={3}>
          <Grid>
            <LeanViewItem
              icon="bxs:gift"
              borderStyle={{
                border: "1px solid black",
                borderBottom: "none",
              }}
              content={{
                title: LEAN_STEPS[5].title,
                height: isFinalStep ? "180px" : "135px",
                index: 5,
              }}
              limit="1"
            ></LeanViewItem>
          </Grid>
          <Grid>
            <LeanViewItem
              // icon="ant-design:heart-filled"
              borderStyle={{
                border: "1px solid black",
                borderTop: "1px dashed #a9a9a9",
              }}
              content={{
                title: LEAN_STEPS[6].title,
                height: isFinalStep ? "180px" : "135px",
                index: 6,
              }}
              limit="1"
            ></LeanViewItem>
          </Grid>
        </Grid>
        <Grid item md={3}>
          <Grid>
            <LeanViewItem
              icon="material-symbols:lock"
              borderStyle={{
                border: "1px solid black",
                borderLeft: "none",
              }}
              content={{
                title: LEAN_STEPS[7].title,
                height: isFinalStep ? "180px" : "135px",
                index: 7,
              }}
              limit="1"
            ></LeanViewItem>
          </Grid>
          <Grid>
            <LeanViewItem
              icon="bi:megaphone"
              borderStyle={{
                border: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
              }}
              content={{
                title: LEAN_STEPS[8].title,
                height: isFinalStep ? "180px" : "135px",
                index: 8,
              }}
              limit="1"
            ></LeanViewItem>
          </Grid>
        </Grid>
        <Grid item md={3}>
          <Grid>
            <LeanViewItem
              icon="bi:people-fill"
              borderStyle={{
                border: "1px solid black",
                borderBottom: "none",
                borderLeft: "none",
              }}
              limit="1"
              content={{
                title: LEAN_STEPS[0].title,
                height: isFinalStep ? "180px" : "135px",
                index: 0,
              }}
            ></LeanViewItem>
          </Grid>
          <Grid>
            <LeanViewItem
              // icon="bi:people-fill"
              borderStyle={{
                border: "1px solid black",
                borderTop: "1px dashed #a9a9a9",
                borderLeft: "none",
              }}
              limit="1"
              content={{
                title: LEAN_STEPS[1].title,
                height: isFinalStep ? "180px" : "135px",

                index: 1,
              }}
            ></LeanViewItem>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item md={6}>
          <LeanViewItem
            orientation="row"
            icon="emojione-monotone:money-with-wings"
            borderStyle={{
              border: "1px solid black",
              borderTop: "none",
            }}
            content={{
              title: LEAN_STEPS[10].title,
              height: "120px",
              index: 10,
            }}
            limit="3"
          ></LeanViewItem>
        </Grid>
        <Grid item md={6}>
          <LeanViewItem
            orientation="row"
            icon="iconoir:piggy-bank"
            borderStyle={{
              border: "1px solid black",
              borderTop: "none",
              borderLeft: "none",
            }}
            limit="3"
            content={{
              title: LEAN_STEPS[11].title,
              height: "120px",
              index: 11,
            }}
          ></LeanViewItem>
        </Grid>
      </Grid>
    </>
  );
}
