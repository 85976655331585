import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import Page from "src/components/Page";
import { useEffect, useMemo } from "react";
import {  PERSONA_STEPS } from "src/utils/constants";
import SavingStatus from "src/components/SavingStatus";
import useIsMentor from "src/hooks/useIsMentor";
import CommentWrapper from "src/components/comments/CommentWrapper";
import { getCanvasEntriesAction, setStep } from "src/stores/canvas/canvasSlice";
import NoteView from "src/components/canvas/NoteView";
import PersonaView from "src/components/personaView/PersonaView";

export default function PersonaCanvas() {
  const dispatch = useDispatch();
  const { isMentor, isSupervisor } = useIsMentor();
  const steps = useMemo(
    () =>
      isMentor || isSupervisor
        ? [PERSONA_STEPS[PERSONA_STEPS.length - 1]]
        : PERSONA_STEPS,
    [isMentor, isSupervisor]
  );

  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const currentStep = useSelector((state) => state.canvas.currentStep);
  const activeStep = steps[currentStep];

  const savingStatus = useSelector((state) => state.canvas.savingStatus);
  const data = useSelector((state) => state.canvas.data);
  const dataArray = useMemo(
    () => data.filter((item) => item.type === steps[currentStep].value),
    [currentStep, data, steps]
  );
  useEffect(() => {
    if (selectedProject !== -1) {
      dispatch(
        getCanvasEntriesAction({
          canvasType: "persona",
          project_id: selectedProject,
        })
      );
    }
  }, [selectedProject, dispatch, currentStep]);

  useEffect(() => {
    dispatch(setStep(0));
  }, [dispatch, selectedProject]);
  return (
    <Page
      title="Persona Canvas"
      style={{
        border: "1px solid #ECECEC",
        borderRadius: "10px",
        padding: " 1rem ",
        height: "98%",
      }}
    >
      <SavingStatus savingStatus={savingStatus}>
        {activeStep && (
          <CommentWrapper currentPage={`persona_${activeStep.value}`} />
        )}
      </SavingStatus>

      {!(isMentor || isSupervisor) && (
        <>
          {" "}
          <Box sx={{ px: 3 }}>
            <Stepper activeStep={currentStep}>
              {steps.map((label, index) => {
                return (
                  <Step key={index}>
                    <StepLabel
                      style={{ cursor: "pointer" }}
                      onClick={() => dispatch(setStep(index))}
                      title={label.title}
                    ></StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
          {activeStep && (
            <>
              <Typography sx={{ my: 1, px: 3, fontSize: "0.8em" }}>
                <strong style={{ color: "#2196F3" }}>
                  Etape {currentStep + 1}
                </strong>
                {" " + activeStep.title}
              </Typography>
              {/* <Description text={decriptions.bmc[currentStep]} /> */}
            </>
          )}
        </>
      )}

      <Grid
        container
        spacing={1}
        sx={{ mt: currentStep === steps.length - 1 ? 1 : 0 }}
      >
        {currentStep !== steps.length - 1 && (
          <Grid item md={5}>
            {/* add notes view */}
            <NoteView
              type="persona"
              currentStep={activeStep.value}
              data={dataArray}
            />
          </Grid>
        )}
        <Grid item md={currentStep !== steps.length - 1 ? 7 : 12}>
          <PersonaView steps={steps}/>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 1 }}>
        <Button
          size="small"
          color="inherit"
          variant="outlined"
          disabled={currentStep === 0}
          onClick={() => dispatch(setStep(currentStep - 1))}
          sx={{ mr: 1, borderRadius: "0" }}
        >
          Précédent
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />

        {currentStep !== steps.length - 1 && (
          <Button
            onClick={() => dispatch(setStep(currentStep + 1))}
            size="small"
            variant="outlined"
            sx={{ borderRadius: "0" }}
          >
            Suivant
          </Button>
        )}
      </Box>
    </Page>
  );
}
