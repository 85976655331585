import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Drawer, IconButton } from "@mui/material";
// mock
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
//
import navConfig, { navConfigOrg } from "./NavConfig";
import Iconify from "src/components/Iconify";
import { useDispatch, useSelector } from "react-redux";
import { toggleLeftNav, toggleRightNav } from "src/stores/global/NavConfigSlice";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const openedMixin = (theme) => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
  },
}));
const TestDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  // const [isCollapsed, setIsCollapsed] = useState(false);
  const isDesktop = useResponsive("up", "lg");
  const dispatch = useDispatch();
  const {  isLeftNavOpen } = useSelector(
    (state) => state.layout
  );
  const isCollapsed = !isLeftNavOpen;
  const setIsCollapsed = (value) => dispatch(toggleLeftNav(!value));
  const {user}=useSelector((state)=>state.user);
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    dispatch(toggleRightNav(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      {
        user.role==="ORGANIZATION"?
        <NavSection navConfig={navConfigOrg} isCollapsed={isCollapsed} />:
        <NavSection navConfig={navConfig} isCollapsed={isCollapsed} />

      }

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, pt: 10 },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <TestDrawer
          open={!isCollapsed}
          variant="permanent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: isCollapsed ? "center" : "flex-end",

              m: 1,
              mt: 10,
            }}
          >
            <IconButton
              onClick={() => setIsCollapsed(!isCollapsed)}
              sx={{
                borderRadius: "10px",
                width: isCollapsed ? "100%" : 48,
                height: 48,
              }}
            >
              <Iconify
                icon={
                  isCollapsed
                    ? "akar-icons:chevron-right"
                    : "akar-icons:chevron-left"
                }
              />
            </IconButton>
          </Box>

          {renderContent}
        </TestDrawer>
      )}
    </RootStyle>
  );
}
