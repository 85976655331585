import { Box, Button, Fab } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { exportComponentAsJPEG } from "react-component-export-image";
import { useSelector } from "react-redux";
import Iconify from "src/components/Iconify";
import Page from "src/components/Page";
import GenderDistribution from "src/components/stats/GenderDistribution";
import NatDistribution from "src/components/stats/NatDistribution";
import Pregion from "src/components/stats/Pregion";
import WorkImpact from "src/components/stats/WorkImpact";
import axiosInstance from "src/libs/axios";
import BoxLoader from "src/router/components/BoxLoader";

function Stats() {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const { selectedCohort } = useSelector((state) => state.cohort);
  const fetchStats = (id) => {
    axiosInstance
      .get("/cohort/stats/" + id)
      .then((res) => {
        setStats(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (selectedCohort !== -1) {
      fetchStats(selectedCohort);
    }
  }, [selectedCohort]);
  const [view, setView] = useState(0);
  const ref = useRef();
  const download = () => {
    exportComponentAsJPEG(ref, {
      fileName: getFileName(),
    });
  };
  const getFileName = () => {
    switch (view) {
      case 0:
        return "Répartition des projets accompagnés par région ";
      case 1:
        return "Démographie porteurs de projet accompagnés";
      case 2:
        return "Répartition des projets accompagnés selon secteur d'activité NAT 2009";
      case 3:
        return "Impact employabilité";
      default:
        return "Rapport cohorte";
    }
  };

  return loading ? (
    <BoxLoader />
  ) : (
    <Page
      title="Rapport cohorte"
      style={{
        border: "1px solid #ECECEC",
        borderRadius: "10px",
        padding: " 1rem ",
        height: "98%",
      }}
    >
      <Fab
        color="primary"
        aria-label="download"
        size="small"
        sx={{ position: "fixed", bottom: 24, right: 24 }}
        onClick={download}
      >
        <Iconify icon="mdi:download" />
      </Fab>
      <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", gap: 4 }}>
          <Button
            disabled={view === 0}
            onClick={() => setView(0)}
            size="small"
            variant="contained"
          >
            Projets par région
          </Button>
          <Button
            disabled={view === 1}
            onClick={() => setView(1)}
            size="small"
            variant="contained"
          >
            Démographie
          </Button>
          <Button
            disabled={view === 2}
            onClick={() => setView(2)}
            size="small"
            variant="contained"
          >
            Projets par secteur NAT
          </Button>
          <Button
            disabled={view === 3}
            onClick={() => setView(3)}
            size="small"
            variant="contained"
          >
            Impact employabilité
          </Button>
        </Box>
        <Box
          sx={{ flex: "1", display: "flex", flexDirection: "column" }}
          ref={ref}
        >
         
          <Box sx={{ flex: "1" }}>
            {view === 0 && <Pregion data={stats} />}
            {view === 1 && <GenderDistribution data={stats} />}
            {view === 2 && <NatDistribution data={stats} />}
            {view === 3 && <WorkImpact data={stats} />}
          </Box>
        </Box>
      </Box>
    </Page>
  );
}

export default Stats;
