import Grid from "@mui/material/Grid";
import { GridViewItem } from "./GridViewItem";

import { useSelector } from "react-redux";

export default function BmcView({ activeStep }) {
  const segment = useSelector((store) => store.bmc.segment);
  const partenaire = useSelector((store) => store.bmc.partenaire);
  const activite = useSelector((store) => store.bmc.activite);
  const proposition = useSelector((store) => store.bmc.proposition);
  const relation = useSelector((store) => store.bmc.relation);
  const distribution = useSelector((store) => store.bmc.distribution);
  const cout = useSelector((store) => store.bmc.cout);
  const revenue = useSelector((store) => store.bmc.revenue);
  const ressource = useSelector((store) => store.bmc.ressource);
  const isFinalStep = !activeStep;

  return (
    <>
      <Grid container spacing={0} columns={15}>
        <Grid item md={3}>
          <GridViewItem
            icon="el:link"
            activeStep={activeStep}
            borderStyle={{
              border: "1px solid black",
            }}
            content={{
              title: "Partenaires clés",
              height: isFinalStep ? "360px" : "270px",
              color: "#CEE8BF",
              text: partenaire,
            }}
            limit="3"
          ></GridViewItem>
        </Grid>

        <Grid item md={3}>
          <Grid>
            <GridViewItem
              icon="akar-icons:check"
              activeStep={activeStep}
              borderStyle={{
                border: "1px solid black",
                borderRight: "none",
                borderLeft: "none",
              }}
              limit="1"
              content={{
                title: "Activités clés",
                height: isFinalStep ? "180px" : "135px",
                color: "#F7C4B6",
                text: activite,
              }}
            ></GridViewItem>
          </Grid>
          <Grid>
            <GridViewItem
              icon="icon-park-solid:factory-building"
              activeStep={activeStep}
              borderStyle={{
                borderBottom: "1px solid black",
              }}
              limit="1"
              content={{
                title: "Ressources clés",
                height: isFinalStep ? "180px" : "135px",
                color: "#FAE2EC",
                text: ressource,
              }}
            ></GridViewItem>
          </Grid>
        </Grid>
        <Grid item md={3}>
          <GridViewItem
            icon="bxs:gift"
            activeStep={activeStep}
            borderStyle={{
              border: "1px solid black",
            }}
            content={{
              title: "Proposition de valeur",
              height: isFinalStep ? "360px" : "270px",
              color: "#FCF9CE",
              text: proposition,
            }}
            limit="3"
          ></GridViewItem>
        </Grid>
        <Grid item md={3}>
          <Grid>
            <GridViewItem
              icon="ant-design:heart-filled"
              borderStyle={{
                border: "1px solid black",
                borderLeft: "none",
              }}
              activeStep={activeStep}
              content={{
                title: "Relation client",
                height: isFinalStep ? "180px" : "135px",
                color: "#ECF6F9",
                text: relation,
              }}
              limit="1"
            ></GridViewItem>
          </Grid>
          <Grid>
            <GridViewItem
              icon="fa6-solid:truck"
              activeStep={activeStep}
              borderStyle={{
                border: "1px solid black",
                borderTop: "none",
                borderLeft: "none",
              }}
              content={{
                title: "Canaux de distribution",
                height: isFinalStep ? "180px" : "135px",
                color: "#BCBCCD",
                text: distribution,
              }}
              limit="1"
            ></GridViewItem>
          </Grid>
        </Grid>
        <Grid item md={3}>
          <GridViewItem
            icon="bi:people-fill"
            borderStyle={{
              border: "1px solid black",
              borderLeft: "none",
            }}
            limit="3"
            activeStep={activeStep}
            content={{
              title: "Segments",
              height: isFinalStep ? "360px" : "270px",
              color: "#E3D3E6",

              text: segment,
            }}
          ></GridViewItem>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item md={6}>
          <GridViewItem
            orientation="row"
            icon="ion:pricetag"
            activeStep={activeStep}
            borderStyle={{
              border: "1px solid black",
              borderTop: "none",
            }}
            content={{
              title: "Structure de coûts",
              height: "120px",
              color: "#C0B5B9",
              text: cout,
            }}
            limit="3"
          ></GridViewItem>
        </Grid>
        <Grid item md={6}>
          <GridViewItem
            orientation="row"
            icon="clarity:coin-bag-solid"
            activeStep={activeStep}
            borderStyle={{
              border: "1px solid black",
              borderTop: "none",
              borderLeft: "none",
            }}
            limit="3"
            content={{
              title: "Structure de revenus",
              height: "120px",
              color: "#C0BCB7",
              text: revenue,
            }}
          ></GridViewItem>
        </Grid>
      </Grid>
    </>
  );
}
