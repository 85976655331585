import * as React from "react";
import NotesList from "src/components/stickynotes/NotesList";

export default function Notes({ activeStep }) {
  return (
    <div
      className="container"
      style={{
        maxWidth: "#960px",
        marginRight: "auto",
        marginLeft: "auto",
        paddingRight: "15px",
        paddingLeft: "15px",
        maxHeight: "350px",
        overflowY: "auto",
      }}
    >
      <NotesList activeStep={activeStep} />
    </div>
  );
}
