import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "src/utils/constants";
import Iconify from "src/components/Iconify";
import Page from "src/components/Page";
import { useNavigate } from "react-router-dom";
import { getCohortsAction, selectCohort } from "src/stores/cohort/cohortSlice";
import CreateCohortModal from "src/components/cohort/createCohortModal";

export default function Cohortes() {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const { cohorts } = useSelector((state) => state.cohort);
  const [createModalOpen, setcreateModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getCohortsAction());
  }, [dispatch]);
  const select = (cohort) => {
    dispatch(selectCohort(cohort));
    navigate("/organisation/cohorte");
  };
  return (
    <Page title="Cohortes">
      <Container>
        {cohorts.loading && <div>Chargement...</div>}
        {!cohorts.loading && (
          <>
            <Typography variant="h4">Cohortes</Typography>
            <Typography variant="p" component="h4" sx={{ mb: 5 }}>
              {cohorts.data.length
                ? "Choisissez une cohorte ou créez-en une nouvelle"
                : "Vous n'avez pas encore de cohorte. Créez-en une pour commencer."}
            </Typography>
            <div style={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3} sx={{ padding: 0 }}>
                  <Card sx={{ height: "100%", minHeight: "200px" }}>
                    <CardActionArea
                      onClick={() => setcreateModalOpen(true)}
                      sx={{
                        height: "100%",
                      }}
                    >
                      <CardContent sx={{ textAlign: "center" }}>
                        <Iconify
                          icon="ant-design:plus-outlined"
                          sx={{ width: 32, height: 32 }}
                        ></Iconify>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                {cohorts.data.map((cohort) => (
                  <Grid item xs={6} md={3} key={cohort.id}>
                    <Card sx={{ height: "100%" }}>
                      <CardActionArea
                        onClick={() => select(cohort.id)}
                        sx={{ height: "100%" }}
                      >
                        <CardMedia
                          component="img"
                          height="140"
                          sx={{ objectFit: "contain" }}
                          image={BASE_URL + cohort.image}
                          alt={cohort.name}
                        />
                        <CardContent>
                          <h3 style={{ display: "flex", alignItems: "center" }}>
                            {cohort.name}
                          </h3>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </div>
          </>
        )}
      </Container>
      <CreateCohortModal
        open={createModalOpen}
        setcreateModalOpen={setcreateModalOpen}
      />
    </Page>
  );
}
