import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useIsMentor from "src/hooks/useIsMentor";
import { createNoteAction } from "src/stores/bmc/bmcSlice";
import { COLORS } from "src/utils/constants";
import Iconify from "../Iconify";
import Note from "./Note";

export default function NotesList() {
  const dispatch = useDispatch();
  const currentStep = useSelector((state) => state.bmc.currentStep);
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const {isMentor,isSupervisor}=useIsMentor()

  const notes = useSelector((store) => store.bmc[currentStep]) || [];
  const addNewNote = () => {
    //get random color from COLORS array
    const color = COLORS[Math.floor(Math.random() * COLORS.length)];
    dispatch(
      createNoteAction({
        project_id: selectedProject,
        bmc_type: currentStep,
        explication: { id: Date.now(), color: color.primary, text: "" },
      })
    );
  };
  return (
    <div
      className="notes-list"
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
      }}
    >
    {!isMentor&&!isSupervisor&&notes.length<6&&  <Box
        onClick={addNewNote}
        title="Ajouter un post-it"
        sx={{
          border: "1px dashed #ccc",
          height: "150px",
          m: 1,

          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            cursor: "pointer",
            background: "#eee",
          },
        }}
      >
        <Iconify
          icon="ant-design:plus-outlined"
          sx={{ width: 36, height: 36 }}
        />
      </Box>}
      {notes.map((note, index) => (
        <Note key={index} note={note} />
      ))}
    </div>
  );
}
