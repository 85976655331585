import { Box, Button } from '@mui/material';
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';

const YearSelector = ({ currentYear, setYear }) => {
    const selectedProject = useSelector(
      (state) => state.projects.selectedProject
    );
    const projects = useSelector((state) => state.projects.projects);
    const project = projects.find((p) => p.pk === selectedProject);
    const years = useMemo(() => {
      const years = [];
      for (let i = 1; i <= project?.project_study_duration; i++) {
        years.push(i);
      }
      return years;
    }, [project?.project_study_duration]);
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {years.map((year) => {
          return (
            <Button
              key={year}
              size="small"
              color="primary"
              variant={currentYear === year ? "contained" : "outlined"}
              onClick={() => setYear(year)}
              sx={{
                py: 0.5,
                px: 0,
                mb: 0.5,
                lineHeight: 1.4,
                fontSize: "0.75em",
              }}
            >
              Année <br />
              {year}
            </Button>
          );
        })}
      </Box>
    );
  };

export default YearSelector