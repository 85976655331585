import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  OutlinedInput,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Iconify from "src/components/Iconify";
import axiosInstance from "src/libs/axios";
import { getMyProjects } from "src/stores/projects/projectsSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    lineHeight: 1.4,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function InvestorsTable() {
  const dispatch = useDispatch();

  const projects = useSelector((state) => state.projects.projects);
  const projectId = useSelector((state) => state.projects.selectedProject);
  const currentProject = projects.find((project) => project.pk === projectId);

  const [name, setName] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [amount, setAmount] = useState(0);

  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedInvestor, setSelectedInvestor] = useState(null);
  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setSelectedInvestor(null);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    axiosInstance
      .post("/projects/investor/", {
        name,
        amount,
        percentage,
        project: projectId,
      })
      .then((res) => {
        dispatch(getMyProjects());
        alert("Investisseur ajouté avec succès");

        setName("");
        setPercentage(0);
        setAmount(0);
      })
      .catch((err) => {
        alert("Une erreur s'est produite");
      });
  };
  const handleDelete = () => {
    axiosInstance
      .delete(`/projects/investor/${selectedInvestor}/`)
      .then((res) => {
        dispatch(getMyProjects());
        alert("Investisseur supprimée avec succès");
      })
      .catch((err) => {
        alert("Une erreur s'est produite");
      })
      .finally(() => {
        handleClose();
      });
  };
  const handleEdit = (data) => {
    axiosInstance
      .patch(`/projects/investor/${data.id}/`, {
        percentage: data.percentage,
        amount: data.amount,
        name: data.name,
      })
      .then((res) => {
        dispatch(getMyProjects());
        handleClose();
      });
  };
  return (
    <>
      <br />
      <br />

      <h4>Ajouter des investisseurs</h4>
      <hr />
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "1rem 0",
        }}
      >
        <FormControl sx={{ minWidth: "300px" }} size="small">
          <InputLabel htmlFor="component-outlined">Dénomination</InputLabel>
          <OutlinedInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            id="name"
            name="name"
            label="Dénomination"
          />
        </FormControl>
        <FormControl sx={{ minWidth: "100px" }} size="small">
          <InputLabel htmlFor="component-outlined">
            Pourcentage des parts
          </InputLabel>
          <OutlinedInput
            value={percentage}
            onChange={(e) => {
              if (e.target.value > 100) setPercentage(100);
              else if (e.target.value < 0) setPercentage(0);
              else setPercentage(e.target.value);
            }}
            max={100}
            min={0}
            type="number"
            id="percentage"
            name="percentage"
            label="Pourcentage des parts"
          />
        </FormControl>
        <FormControl sx={{ minWidth: "100px" }} size="small">
          <InputLabel htmlFor="component-outlined">
            Montant de l'investissement
          </InputLabel>
          <OutlinedInput
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            type="number"
            id="amount"
            name="amount"
            label="Montant de l'investissement"
          />
        </FormControl>

        <Button
          sx={{
            textTransform: "none",
          }}
          type="submit"
          variant="contained"
          endIcon={<Iconify icon="cib:telegram-plane" />}
        >
          Valider
        </Button>
      </form>
      <h4>Investisseurs</h4>
      <hr />
      <Table sx={{ my: 3 }}>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Dénomination</StyledTableCell>
            <StyledTableCell>Pourcentage des parts</StyledTableCell>
            <StyledTableCell>Montant de l'investissement</StyledTableCell>
            <StyledTableCell align="right">Actions</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {currentProject.investors.map((investor) => (
            <StyledTableRow key={investor.id}>
              <StyledTableCell>{investor.name}</StyledTableCell>
              <StyledTableCell>{investor.percentage} %</StyledTableCell>

              <StyledTableCell>
                {parseFloat(investor.amount)} TND
              </StyledTableCell>
              <StyledTableCell align="right">
                <Button
                  size="small"
                  variant="contained"
                  color="warning"
                  style={{ marginRight: "1rem" }}
                  startIcon={<Iconify icon="material-symbols:edit" />}
                  onClick={() => {
                    setSelectedInvestor(investor);
                    setOpenEdit(true);
                  }}
                >
                  Modifier
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  startIcon={<Iconify icon="mdi:delete" />}
                  onClick={() => {
                    setSelectedInvestor(investor.id);
                    setOpen(true);
                  }}
                >
                  Effacer
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Supprimer l'investisseur ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Voulez-vous vraiment supprimer cet investisseur ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={handleDelete} autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      <EditDialogue
        open={openEdit}
        handleClose={handleClose}
        data={selectedInvestor}
        handleEdit={handleEdit}
      />
    </>
  );
}

const EditDialogue = ({ open, handleClose, handleEdit, data }) => {
  const [name, setName] = useState("");
  const [amount, setAmount] = useState(0);
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    if (data) {
      setPercentage(data.percentage);
      setName(data.name);
      setAmount(data.amount);
    }
  }, [data]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Modifier l'investisseur"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Veuillez modifier les informations de l'investisseur
        </DialogContentText>
        <br />
        <FormControl fullWidth sx={{mb:3}}>
          <InputLabel htmlFor="component-outlined">Dénomination</InputLabel>
          <OutlinedInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            id="name"
            name="name"
            label="Dénomination"
          />
        </FormControl>
        <FormControl fullWidth sx={{mb:3}}>
          <InputLabel htmlFor="component-outlined">
            Pourcentage des parts
          </InputLabel>
          <OutlinedInput
            value={percentage}
            onChange={(e) => {
              if (e.target.value > 100) setPercentage(100);
              else if (e.target.value < 0) setPercentage(0);
              else setPercentage(e.target.value);
            }}
            max={100}
            min={0}
            type="number"
            id="percentage"
            name="percentage"
            label="Pourcentage des parts"
          />
        </FormControl>
        <FormControl fullWidth sx={{mb:3}}>
          <InputLabel htmlFor="component-outlined">
            Montant de l'investissement
          </InputLabel>
          <OutlinedInput
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            type="number"
            id="amount"
            name="amount"
            label="Montant de l'investissement"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
        <Button
          onClick={() =>
            handleEdit({
              id: data.id,
              percentage,
              name,
              amount,
            })
          }
          autoFocus
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};
