import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { useDispatch } from "react-redux";
// material
import {
  Stack,
  TextField,
  Snackbar,
  Alert,
  Slide,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import {  requestPasswordReset } from "src/stores/auth/userSlice";

// ----------------------------------------------------------------------

export default function PassResetForm({setSuccess}) {
  /**
   * hooks instanciation
   */
  const dispatch = useDispatch();
  /**
   * selectores
   */

  /**
   * states
   */
  const [error, setError] = useState(false);


  /**
   * validators
   */
  const ResetSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email invalide")
      .required("Email requis"),
    
  });
  /**
   * formik
   */
  const formik = useFormik({
    initialValues: {
      email: "",
     
    },
    validationSchema: ResetSchema,
    onSubmit: async (values) => {
      try {
        await dispatch(
          requestPasswordReset({ email: values.email })
        ).unwrap();
        setSuccess(true);
      } catch (error) {
        setError("Une erreur est survenue");
      }
    },
  });

  /**
   * helpers
   */
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleCloseSnackbar = () => {
    setError(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!error}
        onClose={handleCloseSnackbar}
        message={error}
        autoHideDuration={3000}
        TransitionProps={{ onExited: handleCloseSnackbar }}
        TransitionComponent={(props) => <Slide {...props} direction="down" />}
        key={"topcenter"}
      >
        <Alert
          variant="filled"
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email"
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

          
          </Stack>

         

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ mt: 3 }}
          >
            Envoyer le lien de réinitialisation
          </LoadingButton>
        </Form>
      </FormikProvider>
    </>
  );
}
