import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import Iconify from "../Iconify";
import { tableCellClasses } from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import { FormatCurrency } from "src/utils/formatNumber";

function RMrecap() {
  const navigate = useNavigate();
  const [typeRM, setTypeRM] = useState("marketing");
  const handleEdit = () => {
    if (typeRM === "marketing") {
      navigate("/strategie-de-commercialisation", {
        state: { step: "Ressource matériel affecté au département Marketing" },
      });
    } else if (typeRM === "commercial") {
      navigate("/strategie-de-commercialisation", {
        state: { step: "Ressource matériel affecté au département commercial" },
      });
    } else if (typeRM === "production") {
      navigate("/plan-de-production-et-d-organisation", {
        state: { step: "Ressource matériel affecté au département Production" },
      });
    } else if (typeRM === "local") {
      navigate("/plan-de-production-et-d-organisation", {
        state: { step: "Local - Implantation & Aménagements" },
      });
    } else if (typeRM === "rnd") {
      navigate("/recherche-et-developpement", {
        state: {
          step: "Ressource Matériel communes affectées au département recherche et développement",
        },
      });
    }
  };
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <FormControl sx={{ minWidth: "200px" }}>
          <InputLabel htmlFor="component-outlined">Département/Type</InputLabel>
          <Select
            size="small"
            value={typeRM}
            label="Département/Type"
            onChange={(e) => setTypeRM(e.target.value)}
          >
            <MenuItem value="marketing">Marketing</MenuItem>
            <MenuItem value="commercial">Commercial</MenuItem>
            <MenuItem value="production">Production</MenuItem>
            <MenuItem value="local">
              Local - Implantation & Aménagements
            </MenuItem>
            <MenuItem value="rnd">R&D</MenuItem>
          </Select>
        </FormControl>
        <IconButton color="primary" title="Modifier" onClick={handleEdit}>
          <Iconify icon="akar-icons:edit" />
        </IconButton>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TableData typeRM={typeRM} />
        </Grid>
      </Grid>
    </>
  );
}

export default RMrecap;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    lineHeight: 1.4,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: theme.palette.common.white,
    fontWeight: "bold",
  },
}));
const TableData = ({ typeRM }) => {
  const RM = useSelector((state) => state.bp.RM);
  const RH = useSelector((state) => state.bp.RH);
  const rmDataArray = useMemo(() => {
    let arr=RM[typeRM]
    RH[typeRM]?.forEach((item) => {
      arr=[...arr,...item.rm]
    })
    return arr;
  }, [RH, RM, typeRM]);
   

  return (
    <TableContainer
      sx={{ maxHeight: "400px", overflow: "auto", position: "relative" }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <StyledTableRow sx={{ position: "sticky", top: 0, zIndex: 100 }}>
            <StyledTableCell>Nom</StyledTableCell>
            <StyledTableCell align="center">Type matériel</StyledTableCell>
            <StyledTableCell align="center">Prix unitaire</StyledTableCell>
            <StyledTableCell align="center">Quantité</StyledTableCell>
            <StyledTableCell align="center">Prix total</StyledTableCell>
            {/* <StyledTableCell align="center">Actions</StyledTableCell> */}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rmDataArray.map((item) => (
            <StyledTableRow key={item.id}>
              <StyledTableCell>{item.name}</StyledTableCell>
              <StyledTableCell align="center">{item.table}</StyledTableCell>
              <StyledTableCell align="center">
                {(parseFloat(item.unit_price))}
              </StyledTableCell>
              <StyledTableCell align="center">
                {(parseFloat(item.quantity))}
              </StyledTableCell>
              <StyledTableCell align="center">
                {FormatCurrency(parseFloat(item.unit_price) * parseFloat(item.quantity))}
              </StyledTableCell>
              {/* <StyledTableCell align="right"></StyledTableCell> */}
            </StyledTableRow>
          ))}
          <StyledTableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              position: "sticky",
              bottom: 0,
              zIndex: 100,
              backgroundColor: "#fff",
            }}
          >
            <StyledTableCell></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="center">
              Total:{" "}
              {FormatCurrency(rmDataArray.reduce((acc, cur) => {
                return (
                  acc + parseFloat(cur.unit_price) * parseFloat(cur.quantity)
                );
              }, 0))}
              DT
            </StyledTableCell>
            {/* <StyledTableCell align="right"></StyledTableCell> */}
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
