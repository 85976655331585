import React from "react";
import { useSelector } from "react-redux";

import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { FormatCurrency } from "src/utils/formatNumber";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    lineHeight: 1.4,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
   
  },
}));

function Amortissement({ data }) {
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const projects = useSelector((state) => state.projects.projects);
  const currentProject = projects.find(
    (project) => project.pk === selectedProject
  );
  const duration = currentProject.project_study_duration;
  const years = Array.from(Array(duration).keys());
  return (
    <TableContainer
      sx={{ maxHeight: "500px", overflow: "auto", position: "relative" }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <StyledTableRow sx={{ position: "sticky", top: 0, zIndex: 100 }}>
            <StyledTableCell>Nom</StyledTableCell>
            {years.map((item, index) => (
              <StyledTableCell align="center" key={index}>
                Année {item + 1}
              </StyledTableCell>
            ))}
            <StyledTableCell align="center">Total</StyledTableCell>
            <StyledTableCell align="center">VCN</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>{item.name}</StyledTableCell>
              {years.map((year, index) => (
                <StyledTableCell align="center" key={index}>
                  {FormatCurrency(parseFloat(item["year_" + (year + 1)]))}
                </StyledTableCell>
              ))}
              <StyledTableCell align="center">
                {/* {parseFloat(item.total)} */}
                {FormatCurrency(Math.round(years.reduce((prev,curr)=>prev+parseFloat(item["year_" + (curr + 1)]),0)))}
              </StyledTableCell>
              <StyledTableCell align="center">
                {FormatCurrency(Math.round(parseFloat(item.total)-years.reduce((prev,curr)=>prev+parseFloat(item["year_" + (curr + 1)]),0)))}
                {/* {parseFloat(item.vcn)} */}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Amortissement;
