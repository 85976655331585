import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "src/libs/axios";

const initialState = {
  user: null,
  status: "loading",
  error: false,
};

export const loginAction = createAsyncThunk(
  "auth/loginAction",
  async (data, { rejectWithValue }) => {
    try {
      const rs = await axiosInstance.post("/rest-auth/login/", data);
      return rs.data;
    } catch (error) {
      if (error.response.data.non_field_errors) {
        if (
          error.response.data.non_field_errors.includes(
            "Unable to log in with provided credentials."
          )
        ) {
          return rejectWithValue("Email ou mot de passe incorrect");
        }
        if (
          error.response.data.non_field_errors.includes(
            "User account is disabled."
          )
        ) {
          return rejectWithValue("Votre compte est désactivé");
        }
        if (
          error.response.data.non_field_errors.includes(
            "E-mail is not verified."
          )
        ) {
          return rejectWithValue("Votre compte n'est pas vérifié");
        }
        return rejectWithValue("Une erreur est survenue");
      }
    }
  }
);
export const refreshTokenAction = createAsyncThunk(
  "auth/refreshTokenAction",
  async (_,{rejectWithValue}) => {
    try {
      const rs = await axiosInstance.post("/rest-auth/token/refresh/");
      return rs.data;
    } catch (error) {
      return rejectWithValue("Une erreur est survenue");
    }
  }
);
export const getUserDataAction = createAsyncThunk(
  "auth/getUserDataAction",
  async (_,{rejectWithValue}) => {
    try {
      const rs = await axiosInstance.get("/profiles/my/");
      return rs.data;
    } catch (error) {
      return rejectWithValue("Une erreur est survenue");
    }
  }
);
export const logoutAction = createAsyncThunk(
  "auth/logoutAction",
  async (_, { rejectWithValue }) => {
    try {
      const rs = await axiosInstance.post("/rest-auth/logout/");
      return rs.data;
    } catch (error) {
      return rejectWithValue("Une erreur est survenue");
    }
  }
);

export const updateProfile = createAsyncThunk(
  "auth/updateProfile",
  async (data, { rejectWithValue }) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    try {
      const rs = await axiosInstance.patch(
        `/me/update-my-profile/${data.id}/`,
        formData
      );
      return rs.data;
    } catch (error) {
      return rejectWithValue("Une erreur est survenue");
    }
  }
);

export const requestPasswordReset = createAsyncThunk(
  "auth/requestPasswordReset",
  async (data, { rejectWithValue }) => {
    try {
      const rs = await axiosInstance.post(`/rest-auth/password/reset/`, data);
      return rs.data;
    } catch (error) {
      return rejectWithValue("Une erreur est survenue");
    }
  }
);
export const confirmPasswordReset = createAsyncThunk(
  "auth/confirmPasswordReset",
  async (data, { rejectWithValue }) => {
    try {
      const rs = await axiosInstance.post(
        `/rest-auth/password/reset/confirm/`,
        {
          new_password1: data.password1,
          new_password2: data.password2,
          uid: data.uid,
          token: data.token,
        }
      );
      return rs.data;
    } catch (error) {
      if (error.response.data.token.includes("Invalid value")) {
        return rejectWithValue(
          "Le lien de réinitialisation de mot de passe est invalide"
        );
      }
      return rejectWithValue("Une erreur est survenue");
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: {
    [loginAction.fulfilled]: (state, action) => {
      state.status = "success";
      state.user = action.payload.user.profile;
      state.error = false;
    },
    [loginAction.rejected]: (state) => {
      state.status = "failed";
      state.error = true;
      state.user = null;
    },

    [refreshTokenAction.fulfilled]: (state) => {
      // state.status = "success";
      state.error = false;
    },
    [refreshTokenAction.rejected]: (state) => {
      state.status = "failed";
      state.error = true;
    },
    [getUserDataAction.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.status = "success";
      state.error = false;
    },
    [getUserDataAction.rejected]: (state, action) => {
      state.user = null;
      state.status = "failed";
      state.error = true;
    },
    [logoutAction.fulfilled]: (state) => {
      state.status = "failed";
      state.error = false;
      state.user = null;
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.user = action.payload;
    },
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    }
  },
});
export const { setUser } = userSlice.actions;
export default userSlice.reducer;
