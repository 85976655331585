import axiosInstance from "src/libs/axios";
import { LEAN_STEPS } from "src/utils/constants";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getCanvasEntriesAction = createAsyncThunk(
  "canvas/getCanvasEntriesAction",
  // data : {canvasType: "lean",project_id: 1}

  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/canvas/canvas/${data.canvasType}/${data.project_id}/`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createCanvasEntryAction = createAsyncThunk(
  "canvas/createCanvasEntryAction",
  // data : {canvasType: "lean", type: "segment",project_id: 1, explication: "test"}
  async (data, { rejectWithValue }) => {
    try {
      console.log("data", data);
      const response = await axiosInstance.post(
        `/canvas/canvas/${data.canvasType}/`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteCanvasEntryAction = createAsyncThunk(
  "canvas/deleteCanvasEntryAction",
  // data : {canvasType: "lean", pk: 1}
  async (data, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(
        `/canvas/canvas/${data.canvasType}/${data.id}/`
      );
      return data.id;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateCanvasEntryAction = createAsyncThunk(
  "canvas/updateCanvasEntryAction",
  // data : {canvasType: "lean",type:"segment", pk: 1, explication: "test"}
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/canvas/canvas/${data.canvasType}/${data.id}/`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  data: [],
  currentStep: 0,
  savingStatus: "done",
};

const canvasSlice = createSlice({
  name: "canvas",
  initialState,
  reducers: {
    setStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setSavingStatus: (state, action) => {
      state.savingStatus = action.payload;
    },
  },
  extraReducers: {
    [getCanvasEntriesAction.pending]: (state) => {
      state.data = [];
    },
    [getCanvasEntriesAction.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
    [getCanvasEntriesAction.rejected]: (state) => {
      state.data = [];
    },

    [createCanvasEntryAction.fulfilled]: (state, action) => {
      state.data = [...state.data, action.payload];
    },

    [deleteCanvasEntryAction.fulfilled]: (state, action) => {
      state.data = state.data.filter((item) => item.id !== action.payload);
    },

    [updateCanvasEntryAction.fulfilled]: (state, action) => {
      if (action.payload.canvasType === "swot") {
        state.data = state.data.map((item) => {
          if (item.pk === action.payload.pk) {
            return action.payload;
          }
          return item;
        });
      }
    },
  },
});

export default canvasSlice.reducer;
export const { setStep } = canvasSlice.actions;
