import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "src/libs/axios";

export const getComments = createAsyncThunk(
  "comments/getComments",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/comments/comments/${data.projectId}/${data.pageType}/`,);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const commentSlice = createSlice({
  name: "comments",
  initialState: {
    comments: [],
    loading: false,
    error: null,
  },
  extraReducers: {
    [getComments.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getComments.fulfilled]: (state, action) => {
      state.loading = false;
      state.comments = action.payload;
    },
    [getComments.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default commentSlice.reducer;
