import { Box } from "@mui/material";
import React from "react";

function Description({ text }) {
  return text ? (
    <Box
      sx={{
        bgcolor: "#ECECEC",
        borderRadius: "5px",
        p: 1,
        mb: 1,
        mx: 3,
        fontSize: "0.8em",
      }}
      dangerouslySetInnerHTML={{ __html: text }}
    >
      {/* {text} */}
    </Box>
  ) : (
    <></>
  );
}

export default Description;
