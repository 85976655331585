import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Modal,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Iconify from "../Iconify";
import * as Yup from "yup";
import { tableCellClasses } from "@mui/material/TableCell";
import { updateBP } from "src/stores/bp/bpSlice";
import { deleteDCArrayObject, updateDCArrayObject } from "src/utils/bp/DC";
import useIsMentor from "src/hooks/useIsMentor";
import { FormatCurrency } from "src/utils/formatNumber";

function DC({ currentStep }) {
  const typeRM =
    currentStep === "Dépenses courantes du département Marketing"
      ? "d-marketing"
      : currentStep === "Dépenses courantes du département commercial"
      ? "d-commercial"
      : currentStep ===
        "Dépenses courantes communes du département recherche et développement"
      ? "d-rnd"
      : "d-production";
  const [year, setYear] = useState(1);

  const [selectedItem, setSelectedItem] = useState(null);
  const handleAjouter = () => {
    setSelectedItem({
      isNew: true,
      name: "",
      fixed_amount_1: 0,
      percentage_of_ca_1: 0,
      type: typeRM,
    });
  };
  return (
    <>
      {selectedItem && (
        <ProductModal
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          typeRM={typeRM}
          year={year}
        />
      )}
      <Grid container spacing={1}>
        <Grid item xs={1}>
          <YearSelector currentYear={year} setYear={setYear} />
        </Grid>
        <Grid item xs={11}>
          <TableData
            handleAjouter={handleAjouter}
            setSelectedItem={setSelectedItem}
            typeRM={typeRM}
            year={year}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default DC;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    lineHeight: 1.4,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: theme.palette.common.white,
    fontWeight: "bold",
  },
}));
const TableData = ({ setSelectedItem, handleAjouter, typeRM, year }) => {
  const DC = useSelector((state) => state.bp.DC);
  const rmDataArray = DC[typeRM];
  const [itemToDelete, setItemToDelete] = useState(null);
  const { isMentor, isSupervisor } = useIsMentor();

  return (
    <TableContainer
      sx={{ maxHeight: "400px", overflow: "auto", position: "relative" }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <StyledTableRow sx={{ position: "sticky", top: 0, zIndex: 100 }}>
            <StyledTableCell>Nature des charges</StyledTableCell>
            <StyledTableCell align="center">
              Montant charge fixe
            </StyledTableCell>
            <StyledTableCell align="center">
              Pourcentage chiffre d'affaire
            </StyledTableCell>
            <StyledTableCell align="center">Total</StyledTableCell>
            {!isMentor && !isSupervisor && (
              <StyledTableCell align="center">Actions</StyledTableCell>
            )}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rmDataArray.map((item) => (!parseFloat(item["fixed_amount_" + year])&&!parseFloat(item["percentage_of_ca_" + year]))?<></>:(
            <StyledTableRow key={item.id}>
              <StyledTableCell>{item.name}</StyledTableCell>
              <StyledTableCell align="center">
                {parseFloat(item["fixed_amount_" + year])}
              </StyledTableCell>
              <StyledTableCell align="center">
                {parseFloat(item["percentage_of_ca_" + year])}% (
                {FormatCurrency(parseFloat(item["variable_amount_" + year]))})
              </StyledTableCell>
              <StyledTableCell align="center">
                {FormatCurrency(parseFloat(item["total_" + year]))}
              </StyledTableCell>

              {!isMentor && !isSupervisor && (
                <StyledTableCell align="right">
                  <IconButton
                    title="Effacer"
                    color="error"
                    sx={{ mr: 1 }}
                    onClick={() => {
                      setItemToDelete(item.pk);
                    }}
                  >
                    <Iconify
                      icon="fa6-solid:trash-can"
                      sx={{ height: 16, width: 16 }}
                    />
                  </IconButton>
                  <IconButton
                    title="modifier"
                    onClick={() => setSelectedItem(item)}
                  >
                    <Iconify
                      icon="akar-icons:edit"
                      sx={{ height: 18, width: 18 }}
                    />
                  </IconButton>
                </StyledTableCell>
              )}
            </StyledTableRow>
          ))}
          <StyledTableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              position: "sticky",
              bottom: 0,
              zIndex: 100,
              backgroundColor: "#fff",
            }}
          >
            <StyledTableCell>
              {!isMentor && !isSupervisor && (
                <IconButton
                  variant="outlined"
                  color="primary"
                  onClick={handleAjouter}
                >
                  <Iconify icon="ant-design:plus-outlined" />
                </IconButton>
              )}
            </StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="center">
              Total:{" "}
              {FormatCurrency(
                rmDataArray.reduce((acc, cur) => {
                  return acc + parseFloat(cur['total_' + year]);
                }, 0)
              )}
              DT
            </StyledTableCell>
            {!isMentor && !isSupervisor && (
              <StyledTableCell align="right"></StyledTableCell>
            )}
          </StyledTableRow>
        </TableBody>
      </Table>
      <DeleteDialaog
        setItemToDelete={setItemToDelete}
        itemToDelete={itemToDelete}
      />
    </TableContainer>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
};
const ProductModal = ({ selectedItem, setSelectedItem, typeRM, year }) => {
  const dispatch = useDispatch();
  const allDCdataObj = useSelector((state) => state.bp.DC);
  const allDCdataArray = [
    ...allDCdataObj.rest,
    ...allDCdataObj["d-marketing"],
    ...allDCdataObj["d-commercial"],
    ...allDCdataObj["d-production"],
    ...allDCdataObj["d-rnd"],
    ...allDCdataObj.other,
  ];

  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const projects = useSelector((state) => state.projects.projects);
  const project = projects.find((p) => p.pk === selectedProject);
  const years = Array.from(Array(project.project_study_duration).keys()).map(
    (i) => i + 1
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Ce champ est obligatoire"),
    percentage_of_ca_1: Yup.number().required("Ce champ est obligatoire"),
    fixed_amount_1: Yup.number().required("Ce champ est obligatoire"),
  });
  const open = selectedItem !== null;
  const isNewProduct = !!selectedItem.isNew;
  const closeModal = () => {
    setSelectedItem(null);
  };
  const formik = useFormik({
    initialValues: {
      name: selectedItem.name,
      percentage_of_ca_1: parseFloat(
        selectedItem["percentage_of_ca_" + year] ?? 0
      ),
      fixed_amount_1: parseFloat(selectedItem["fixed_amount_" + year] ?? 0),
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      const newDataObj = {
        ...selectedItem,
        isNew: !!selectedItem.isNew,
        type: typeRM,
        name: values.name,
        ["percentage_of_ca_" + year]: values.percentage_of_ca_1,
        ["fixed_amount_" + year]: values.fixed_amount_1,
      };
      if (isNewProduct) {
        years.forEach((y) => {
          if (y > year) {
            newDataObj["percentage_of_ca_" + y] =
              newDataObj["percentage_of_ca_" + year];
            newDataObj["fixed_amount_" + y] = newDataObj["fixed_amount_" + year];
          }
        });
      }
      setIsSubmitting(true);
      const updateArray = updateDCArrayObject(allDCdataArray, newDataObj);

      await dispatch(
        updateBP({
          projectId: selectedProject,
          payload: updateArray,
        })
      ).unwrap();

      setIsSubmitting(false);
      closeModal();
    },
  });
  const { handleSubmit, getFieldProps, touched, errors } = formik;

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Card>
          <CardContent>
            <FormikProvider value={formik}>
              <Typography variant="h6">
                {isNewProduct ? "Nouvelle entrée" : "Modifier entrée"}
              </Typography>
              <Divider sx={{ mb: 5, mt: 1 }} />
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  sx={{ mb: 2 }}
                  autoComplete="post-name"
                  type="text"
                  label="Nature des charges"
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />

                <TextField
                  fullWidth
                  sx={{ mb: 2 }}
                  type="number"
                  autoComplete="fixed_amount_1"
                  label="Montant charge fixe"
                  {...getFieldProps("fixed_amount_1")}
                  error={Boolean(
                    touched.fixed_amount_1 && errors.fixed_amount_1
                  )}
                  helperText={touched.fixed_amount_1 && errors.fixed_amount_1}
                />
                <TextField
                  fullWidth
                  sx={{ mb: 2 }}
                  autoComplete="product-quantity"
                  type="number"
                  label="Pourcentage chiffre d'affaire"
                  {...getFieldProps("percentage_of_ca_1")}
                  error={Boolean(
                    touched.percentage_of_ca_1 && errors.percentage_of_ca_1
                  )}
                  helperText={
                    touched.percentage_of_ca_1 && errors.percentage_of_ca_1
                  }
                />

                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <LoadingButton
                    sx={{ mt: 3 }}
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {isNewProduct ? "Ajouter" : "Modifier"}
                  </LoadingButton>
                </Box>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

const DeleteDialaog = ({ setItemToDelete, itemToDelete }) => {
  const [isSubmitting, setisSubmitting] = useState(false);
  const dispatch = useDispatch();
  const allDCdataObj = useSelector((state) => state.bp.DC);
  const allDCdataArray = [
    ...allDCdataObj.rest,
    ...allDCdataObj["d-marketing"],
    ...allDCdataObj["d-commercial"],
    ...allDCdataObj["d-production"],
    ...allDCdataObj["d-rnd"],
    ...allDCdataObj.other,
  ];

  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );

  const open = itemToDelete !== null;
  const handleClose = () => {
    setItemToDelete(null);
  };
  const deleteProduct = async () => {
    setisSubmitting(true);
    await handleDelete(itemToDelete);
    setisSubmitting(false);
    handleClose();
  };
  const handleDelete = async (id) => {
    dispatch(
      updateBP({
        projectId: selectedProject,
        payload: deleteDCArrayObject(allDCdataArray, id),
      })
    ).unwrap();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Supprimer l'entrée"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Êtes-vous sûr de vouloir supprimer cette entrée ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isSubmitting}>
          Annuler
        </Button>
        <Button
          color="error"
          onClick={deleteProduct}
          autoFocus
          disabled={isSubmitting}
        >
          Effacer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const YearSelector = ({ currentYear, setYear }) => {
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const projects = useSelector((state) => state.projects.projects);
  const project = projects.find((p) => p.pk === selectedProject);
  const years = useMemo(() => {
    const years = [];
    for (let i = 1; i <= project?.project_study_duration; i++) {
      years.push(i);
    }
    return years;
  }, [project?.project_study_duration]);
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {years.map((year) => {
        return (
          <Button
            key={year}
            size="small"
            color="primary"
            variant={currentYear === year ? "contained" : "outlined"}
            onClick={() => setYear(year)}
            sx={{
              py: 0.5,
              px: 0,
              mb: 0.5,
              lineHeight: 1.4,
              fontSize: "0.75em",
            }}
          >
            Année <br />
            {year}
          </Button>
        );
      })}
    </Box>
  );
};
