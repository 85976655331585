import * as Yup from "yup";
import YupPassword from "yup-password";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
  Slide,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import Iconify from "../../../components/Iconify";
import axiosInstance from "src/libs/axios";
// import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GOVERNORATS } from "src/utils/constants";
YupPassword(Yup);

// ----------------------------------------------------------------------

export default function RegisterForm() {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email("Email invalide").required("Email requis"),
    password1: Yup.string()
      .required("Mot de passe requis")
      .min(
        8,
        "Mot de passe trop court - doit contenir au moins 8 caractères. Au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial"
      )
      .minLowercase(1, "Doit contenir au moins une lettre minuscule")
      .minUppercase(1, "Doit contenir au moins une lettre majuscule")
      .minNumbers(1, "Doit contenir au moins un chiffre")
      .minSymbols(1, "Doit contenir au moins un caractère spécial"),
    password2: Yup.string().oneOf(
      [Yup.ref("password1"), null],
      "Les mots de passe ne correspondent pas"
    ),
    first_name: Yup.string().required("Prénom requis"),
    last_name: Yup.string().required("Nom requis"),
    governorate: Yup.string().required("Gouvernorat requis"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password1: "",
      password2: "",
      first_name: "",
      last_name: "",
      governorate: "",
      mentor: false,
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      try {
        await axiosInstance.post("/rest-auth/registration/", {
          email: values.email,
          password1: values.password1,
          password2: values.password2,
          mentor: values.mentor,
          first_name: values.first_name,
          last_name: values.last_name,
          governorate: values.governorate,
        });
        alert("Votre compte a été créé avec succès");
        navigate("/");
      } catch (error) {
        if (error.response.status === 400) {
          setError(Object.values(error.response.data).join(", "));
        } else {
          setError("Une erreur est survenue");
        }
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const handleCloseSnackbar = () => {
    setError(false);
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!error}
        onClose={handleCloseSnackbar}
        message={error}
        autoHideDuration={3000}
        TransitionProps={{ onExited: handleCloseSnackbar }}
        TransitionComponent={(props) => <Slide {...props} direction="down" />}
        key={"topcenter"}
      >
        <Alert
          variant="filled"
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={2}>
            {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}> */}
            {/* <TextField
              fullWidth
              label="First name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            /> */}

            {/* <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            /> */}
            {/* </Stack> */}
          <Stack spacing={3} direction='row'>

            <TextField
              fullWidth
              autoComplete="first_name"
              type="text"
              label="Prénom"
              {...getFieldProps("first_name")}
              error={Boolean(touched.first_name && errors.first_name)}
              helperText={touched.first_name && errors.first_name}
            />
            <TextField
              fullWidth
              autoComplete="last_name"
              type="text"
              label="Nom"
              {...getFieldProps("last_name")}
              error={Boolean(touched.last_name && errors.last_name)}
              helperText={touched.last_name && errors.last_name}
            />
            </Stack>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email"
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <FormControl fullWidth>
              <InputLabel
                id="governorate"
                error={touched.governorate && errors.governorate}
              >
                Gouvernorat
              </InputLabel>
              <Select
                fullWidth
                autoComplete="governorate"
                label="Gouvernorat"
                {...getFieldProps("governorate")}
                error={Boolean(touched.governorate && errors.governorate)}
              >
                {GOVERNORATS.map((gov) => (
                  <MenuItem value={gov}>{gov}</MenuItem>
                ))}
              </Select>
              {touched.governorate && errors.governorate && (
                <FormHelperText error>Gouvernorat requis</FormHelperText>
              )}
            </FormControl>
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label="Mot de passe"
              {...getFieldProps("password1")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password1 && errors.password1)}
              helperText={touched.password1 && errors.password1}
            />
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label="Confirmation du mot de passe"
              {...getFieldProps("password2")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password2 && errors.password2)}
              helperText={touched.password2 && errors.password2}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              S'inscrire
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
