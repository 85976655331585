import { Box } from "@mui/system";
import React from "react";
import parse from "html-react-parser";
import { getAccentColor } from "src/utils/constants";

export const VPViewItem = ({ note ,location:{top,left}}) => {
  return (
    <Box
      sx={{
        background: note.color,
        color: getAccentColor(note.color),
        p: 1,
        wordBreak: "break-word",
        position: "absolute",
        top:top,
        left:left,
        fontSize: "10px",
        height: "10em",
        width: "10em",
        overflow: "auto",
        pointerEvents: "auto",
        boxShadow:
          "  0px 3px 2px rgba(0, 0, 0, 0.16), 0px 3px 2px rgba(0, 0, 0, 0.23); ",
      }}
    >
      <p > {parse(note.explication)}</p>
    </Box>
  );
};
