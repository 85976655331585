import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { createCanvasEntryAction } from "src/stores/canvas/canvasSlice";
import { COLORS } from "src/utils/constants";
import Iconify from "../Iconify";
import Note from "./Note";

export default function NoteView({ type, data, currentStep ,max=6}) {
  const dispatch = useDispatch();
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const addNewNote = () => {
    const color = COLORS[Math.floor(Math.random() * COLORS.length)];
    dispatch(
      createCanvasEntryAction({
        project_id: selectedProject,
        canvasType: type,
        type: currentStep,
        explication: "",
        color: color.primary,
      })
    );
  };
  return (
    <div
      className="container"
      style={{
        maxWidth: "#960px",
        marginRight: "auto",
        marginLeft: "auto",
        paddingRight: "15px",
        paddingLeft: "15px",
        maxHeight: "350px",
        overflowY: "auto",
      }}
    >
      <div
        className="notes-list"
        style={{
          display: "grid",
          gridTemplateColumns: max===3?"1fr 1fr":"1fr 1fr 1fr",
        }}
      >
        {data.length < max && (
          <Box
            onClick={addNewNote}
            title="Ajouter un post-it"
            sx={{
              border: "1px dashed #ccc",
              height: "150px",
              m: 1,

              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                cursor: "pointer",
                background: "#eee",
              },
            }}
          >
            <Iconify
              icon="ant-design:plus-outlined"
              sx={{ width: 36, height: 36 }}
            />
          </Box>
        )}
        {data.map((note, index) => (
          <Note key={note.id} note={note} />
        ))}
      </div>
    </div>
  );
}
