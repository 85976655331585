import { useMemo, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
} from "@mui/material";
// components
import MenuPopover from "../../components/MenuPopover";
// mocks_
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "src/stores/auth/userSlice";
import { BASE_URL } from "src/utils/constants";
import Iconify from "src/components/Iconify";

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const { user } = useSelector((state) => state.user);
  const [open, setOpen] = useState(null);
  const dispatch = useDispatch();
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const handleLogout = () => {
    setOpen(null);
    dispatch(logoutAction());
  };
  const accountType =()=> {
    if (user?.role === "USER") return "Porteur de projet";
    if (user?.role === "MENTOR") return "Mentor";
    if (user?.role === "ORGANIZATION") return "Structure d'accompagnement/établissement d'enseignement supérieur";
  }
  const MENU_OPTIONS = useMemo(() =>user.role==="ORGANIZATION"?(
    [{
      label: "Cohortes",
      icon: "fluent:people-team-24-regular",
      linkTo: "/organisation/cohortes",
    },
    {
      label: "Paramètres",
      icon: "material-symbols:settings",
      linkTo: "/parametres",
    },
  ]

  ):([
    {
      label: "Projets",
      icon: "icons8:parallel-tasks",
      linkTo: "/projets",
    },
    {
      label: "Profile",
      icon: "ant-design:user-outlined",
      linkTo: "/profile",
    },
    {
      label: "Paramètres",
      icon: "material-symbols:settings",
      linkTo: "/parametres",
    },
    
  ]) , [user.role])
  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={BASE_URL +user.picture} alt="photoURL" />
      </IconButton>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user.first_name && user.first_name}{" "}
            {user.last_name && user.last_name}
          </Typography>
         
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user.email}
          </Typography>
          <Typography variant="subtitle2" noWrap title={accountType()}>
           {accountType()}
          </Typography>

        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Iconify icon={option.icon} sx={{ mr: 1 ,width:20,height:20}} />
                {option.label}
              </Box>
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Iconify icon="ic:twotone-logout" sx={{ mr: 1 }} />
            Déconnexion
          </Box>
        </MenuItem>
      </MenuPopover>
    </>
  );
}
