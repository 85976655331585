// component
import { FICHE_STEPS, PO_STEPS, PROJECT_PRESENTATION_STEPS, RND_STEPS, SDC_STEPS, SECTEUR_STEPS } from "src/utils/constants";
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name,wh=18) => <Iconify icon={name} width={18} height={18} />;

const navConfig = [
  // {
  //   title: "BMC",
  //   path: "/bmc",
  //   icon: getIcon("codicon:table"),
  // },
  {
    title: "Fiche projet",
    path: "/fiche-projet",
    icon: getIcon("clarity:list-line"),
    steps:FICHE_STEPS
  },
  // {
  //   title: "Business plan",
  //   icon: getIcon("akar-icons:book-open"),
  //   children: [
      {
        title: "Description du projet",
        path: "/description-projet",
        icon: getIcon("akar-icons:file",16),
        steps:PROJECT_PRESENTATION_STEPS
      },
      {
        title: "Analyse stratégique du marché",
        path: "/analyse-strategique-du-marche",
        icon: getIcon("bi:graph-up-arrow",16),
        steps:SECTEUR_STEPS
      },
      {
        title: "Stratégie de commercialisation",
        path: "/strategie-de-commercialisation",
        icon: getIcon("fa6-solid:shop",16),
        steps:SDC_STEPS
      },
      {
        title: "Plan de production et d’organisation",
        path: "/plan-de-production-et-d-organisation",
        icon: getIcon("icon-park-outline:chart-graph",16),
        steps:PO_STEPS
      },
      {
        title: "Recherche et développement",
        path: "/recherche-et-developpement",
        icon: getIcon("iconoir:brain-research",16),
        steps:RND_STEPS
      },
      {
        title: "Etude de faisabilité",
        path: "/etude-de-faisabilite",
        icon: getIcon("fe:money",16),
      },
  //   ],
  // },
  {
    title: "Mentorship",
    path: "/mentorship",
    icon: getIcon("carbon:help-desk"),
    isNotMentor: true,
  },
];
export const navConfigOrg=[
  {
    title: "Les membres de la cohorte",
    path: "/organisation/cohorte",
    icon: getIcon("la:users"),
  },
  {
    title:"Rapport cohorte",
    path:"/organisation/rapport-cohorte",
    icon:getIcon("mdi:report-arc")
  }
]

export default navConfig;
