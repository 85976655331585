import { Box, Container } from "@mui/material";
import React from "react";

const BoxLoader = () => {
  return (
    <Container maxWidth={false}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Spinner />
      </Box>
    </Container>
  );
};

const Spinner = () => {
  return (
    <div className="spinner" style={{ scale: "0.5" }}>
      <img
        src="/static/icons/logo.png"
        alt="logo"
        style={{ width: "200px", height: "100px", objectFit: "contain" }}
      />

      <div className="spinner-sector spinner-sector-blue"></div>
      <div className="spinner-sector spinner-sector-blue-2"></div>
    </div>
  );
};

export default BoxLoader;
