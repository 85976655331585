import React from "react";
import { useSelector } from "react-redux";
import { FICHE_STEPS } from "src/utils/constants";
import { Step1, Step2, Step3, Step4, Step5, Step6,Step7,Step8 } from "./steps";
import Step9 from "./steps/Step9";
function FicheProjetView() {
  const currentStep = useSelector((state) => state.projects.ficheProjetStep);

  switch (currentStep) {
    case FICHE_STEPS[0].value:
      return <Step1 />;
    case FICHE_STEPS[1].value:
      return <Step2 />;
    case FICHE_STEPS[2].value:
      return <Step9 />;
    case FICHE_STEPS[3].value:
      return <Step8 />;
    case FICHE_STEPS[4].value:
      return <Step3 />;
    case FICHE_STEPS[5].value:
      return <Step4 />;
    case FICHE_STEPS[6].value:
      return <Step5 />;
    case FICHE_STEPS[7].value:
      return <Step6 />;
    case FICHE_STEPS[8].value:
      return <Step7 />;
    default:
      return <div>Unknown step</div>;
  }
}
export default FicheProjetView;
