import PropTypes from "prop-types";
import { useState, useRef, useEffect } from "react";
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Divider,
  Typography,
  IconButton,
  ListItemButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
// utils
// components
import Iconify from "../../components/Iconify";
import Scrollbar from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";

import axiosInstance from "src/libs/axios";
import { useDispatch, useSelector } from "react-redux";
import { getMyInvitations } from "src/stores/cohort/cohortSlice";

export default function NotificationsOrg() {
  const dispatch = useDispatch();
  const invitations = useSelector((state) => state.cohort.invitations);

  useEffect(() => {
    dispatch(getMyInvitations());
  }, [dispatch]);

  const anchorRef = useRef(null);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{ width: 46, height: 46 }}
      >
        <Badge badgeContent={invitations.data.length} color="error">
          <Iconify icon="ant-design:mail-twotone" width={28} height={28} />
        </Badge>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Invitations</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Vous avez {invitations.data.length} notifications
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
          <List disablePadding>
            {invitations.data.map((request) => (
              <NotificationItem
                key={request.id}
                notification={request}
                refresh={() => dispatch(getMyInvitations())}
              />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: "dashed" }} />
      </MenuPopover>
    </>
  );
}

function NotificationItem({ notification, refresh }) {
  const { projects } = useSelector((state) => state.projects);
  const [selectedProject, setSelectedProject] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleAccepter = () => {
    if (notification.type === "porteur" && !selectedProject) {
      return;
    }
    const type =
      notification.type === "supervisor"
        ? "supervisors"
        : notification.type === "mentor"
        ? "mentors"
        : "porteurs";
    const body = {
      status: "accepted",
    };
    if (type === "porteurs") {
      body.project = selectedProject;
    }
    axiosInstance
      .patch(`/cohort/${type}/${notification.id}/`, body)
      .then((res) => {
        refresh();
        handleClose();
      });
  };
  const handleRefuser = () => {
    const type =
      notification.type === "supervisor"
        ? "supervisors"
        : notification.type === "mentor"
        ? "mentors"
        : "porteurs";
    axiosInstance
      .patch(`/cohort/${type}/${notification.id}/`, {
        status: "rejected",
      })
      .then((res) => {
        refresh();
        handleClose();
      });
  };

  return (
    <>
      <ListItemButton
        onClick={() => setOpen(true)}
        sx={{
          py: 1.5,
          px: 2.5,
          mt: "1px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <small>
            Vous êtes invité à rejoindre la cohorte{" "}
            <strong>{notification.cohort.name} </strong>
            en tant que <strong>{getName(notification.type)}</strong>
          </small>
        </Box>
      </ListItemButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Voulez-vous accepter cette invitation ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Vous êtes invité à rejoindre la cohorte{" "}
            <strong>{notification.cohort.name} </strong>
            en tant que <strong>{getName(notification.type)}</strong>
          </DialogContentText>
          {notification.type === "porteur" && (
            <>
             <small>*Vous devez choisir un projet avant d'accepter l'invitation</small>
              <FormControl fullWidth sx={{ mt: 3 }}>
                <InputLabel id="label">Projet*</InputLabel>

                <Select
                  labelId="label"
                  id="data"
                  value={selectedProject}
                  label="Projet*"
                  onChange={(e) => setSelectedProject(e.target.value)}
                >
                  {projects.map((project) => (
                    <MenuItem value={project.pk} key={project.key}>
                      {project.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRefuser} color="error">Refuser</Button>
          <Button onClick={handleAccepter} autoFocus>
            Accepter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
const getName = (type) => {
  switch (type) {
    case "supervisor":
      return "superviseur";
    case "mentor":
      return "mentor";
    case "porteur":
      return "porteur de projet";
    default:
      return "Invité";
  }
};
