import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import LogoOnlyLayout2 from "src/layouts/LogoOnlyLayout2";
import Cgu from "src/pages/Cgu";
import FirstLogin from "src/pages/FirstLogin";
import PageLoader from "./PageLoader";

const ProtectedRoute = ({ children,roles }) => {
  const user = useSelector((state) => state.user);
  if (user.status === "loading") {
    return <PageLoader />;
  }
  if (user.status === "failed") {
    return <Navigate to="/login" replace />;
  }
  if(user.status==="success"&&!user.user.isDocumentOneAccepted){
    return <LogoOnlyLayout2><Cgu/></LogoOnlyLayout2>;
  }
  if (user.status === "success"&&user.user.is_first_login) {
    return <LogoOnlyLayout2><FirstLogin/></LogoOnlyLayout2>;
  }
  if (user.status === "success"&&roles.includes(user.user.role)) {
    return children;
  }
  if(user.user.role==="ORGANIZATION") return <Navigate to="/organisation/cohortes" replace />;

  return <Navigate to="/" replace />;
};

export default ProtectedRoute;
