import { Box } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import axiosInstance from "src/libs/axios";
import SuggestionEditor from "./SuggestionEditor";

function SuggestionCard({
  type,
  handleSuggestion,
  setHasSuggestions,
  mainContent,
}) {
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState("");
  useEffect(() => {
    if(!setHasSuggestions) return
    if (content && content !== mainContent) {
      setHasSuggestions(true);
    }
    else{
      setHasSuggestions(false);
    }
  }, [content, mainContent]);

  const fetchContent = async (type, projectId) => {
    setLoading(true);
    const res = await axiosInstance.get(
      `/comments/suggestions/${projectId}/${type}/`
    );
    if (res.data.content) {
      setContent(res.data.content);
    } else {
      setContent("");
    }
    setLoading(false);
  };
  useEffect(() => {
    // const controller = new AbortController();
    fetchContent(type, selectedProject);
    return () => {
      // controller.abort();
    };
  }, [selectedProject, type]);

  return loading ? (
    <p>chargement...</p>
  ) : (
    <Box>
      <SuggestionEditor
        type={type}
        project={selectedProject}
        content={content}
        handleSuggestion={handleSuggestion}
        hasSuggestions={content && content !== mainContent}
      />
    </Box>
  );
}

export default SuggestionCard;
