import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { usePrompt } from "src/hooks/useBlocker";
import useIsMentor from "src/hooks/useIsMentor";
import Iconify from "./Iconify";

function SavingStatus({ savingStatus, children }) {
  usePrompt(
    "Vous avez des modifications non sauvegardées. Voulez-vous vraiment quitter la page ?",
    savingStatus !== "done"
  );
  const { isOwner } = useIsMentor();
  return (
    <Box
      sx={{
        display: "flex",
        mb: 1,
        pl: 4,
        pr: 5,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {isOwner ? (
        <>
          {savingStatus === "done" && (
            <Typography
              variant="p"
              component="p"
              sx={{ p: 0.5 }}
              color="green"
              display="flex"
              alignItems="center"
            >
              Enregistré
              <Iconify
                icon="akar-icons:check"
                sx={{ ml: 0.5, width: "1.2em", height: "1.2em" }}
              />
            </Typography>
          )}
          {savingStatus === "unsaved" && (
            <Typography
              variant="p"
              component="p"
              sx={{ p: 0.5 }}
              color="gray"
              display="flex"
              alignItems="center"
            >
              Non enregistré
              <Iconify
                icon="ci:error-outline"
                sx={{ ml: 0.5, width: "1.2em", height: "1.2em" }}
              />
            </Typography>
          )}
          {savingStatus === "saving" && (
            <Typography
              variant="p"
              component="p"
              sx={{ p: 0.5 }}
              color="primary"
              display="flex"
              alignItems="center"
            >
              Enregistrement en cours
              <CircularProgress color="inherit" size={16} sx={{ ml: 0.5 }} />
            </Typography>
          )}
          {savingStatus === "error" && (
            <Typography
              variant="p"
              component="p"
              sx={{ p: 0.5 }}
              color="red"
              display="flex"
              alignItems="center"
            >
              Erreur
              <Iconify
                icon="bi:x-circle"
                sx={{ ml: 0.5, width: "1.2em", height: "1.2em" }}
              />
            </Typography>
          )}
        </>
      ) : (
        <div></div>
      )}

      {children}
    </Box>
  );
}

export default SavingStatus;
