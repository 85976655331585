import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, OutlinedInput, styled, Table, TableBody, TableCell, tableCellClasses, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Iconify from "src/components/Iconify";
import axiosInstance from "src/libs/axios";
import { getCohortByIdAction } from "src/stores/cohort/cohortSlice";

function Step1() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const { cohort } = useSelector((state) => state.cohort);
  const [selectedInvitation, setSelectedInvitation] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setSelectedInvitation(null);
  };
  const handleDelete = () => {
    axiosInstance
      .delete("/cohort/supervisors/"+selectedInvitation+"/")
      .then((res) => {
        dispatch(getCohortByIdAction(cohort.data.id));

        handleClose();
      })
      .catch((err) => {
        handleClose();
      });
  };
  const handleSubmit = async(e) => {
    e.preventDefault();
    axiosInstance
      .post("/cohort/supervisors/", {
        email: email,
        cohort: cohort.data.id,
      })
      .then((res) => {
        dispatch(getCohortByIdAction(cohort.data.id));
        alert("Invitation envoyée avec succès");

        setEmail("");
      })
      .catch((err) => {
        alert("La personne a déjà été invitée");
        setEmail("");
      });
  };
  return <Box sx={{px:3,mt:3}}>
     
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "1rem 0",
        }}
      >
        <FormControl sx={{ minWidth: "300px" }} size="small">
          <InputLabel htmlFor="component-outlined">
            Email du superviseur à inviter
          </InputLabel>
          <OutlinedInput
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            id="email"
            name="email"
            label="Email du superviseur à inviter"
          />
        </FormControl>

        <Button
          sx={{
            textTransform: "none",
          }}
          type="submit"
          variant="contained"
          endIcon={<Iconify icon="cib:telegram-plane" />}
        >
          Envoyer une invitation
        </Button>
      </form>
      <h4>Invitations</h4>
      <hr />
      <Table sx={{ my: 3 }} >
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell>Statut</StyledTableCell>
            <StyledTableCell>Mentors</StyledTableCell>
            <StyledTableCell align="right">Actions</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {cohort.data.supervisors.map((invit) => (
            <StyledTableRow key={invit.id}>
              <StyledTableCell>{invit.email}</StyledTableCell>
              <StyledTableCell>
                <Status status={invit.status} />
              </StyledTableCell>
              <StyledTableCell>{invit.mentors.length}</StyledTableCell>

              <StyledTableCell align="right">
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  startIcon={<Iconify icon="mdi:delete" />}
                  onClick={() => {
                    setSelectedInvitation(invit.id);
                    setOpen(true);
                  }}
                >
                  Effacer
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Supprimer l'invitation ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Voulez-vous vraiment supprimer cette invitation ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={
            handleDelete} autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>

    
  </Box>;
}

export default Step1;
const Status = ({ status }) => {
  if (status.toLowerCase() === "pending") {
    return <Chip color="warning" label="En attente" size="small" />;
  }
  if (status.toLowerCase() === "accepted") {
    return <Chip color="success" label="Accepté" size="small" />;
  }
  if (status.toLowerCase() === "rejected") {
    return <Chip color="error" label="Rejeté" size="small" />;
  }
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    lineHeight: 1.4,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));