import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import PageLoader from "./PageLoader";

const AuthLoader = ({ children }) => {
  const user = useSelector((state) => state.user);

  if (user.status === "loading") {
    return <PageLoader />;
  }
  if (user.status === "success") {
    return <Navigate to="/" />;
  }

  return children;
};

export default AuthLoader;
