import Grid from "@mui/material/Grid";
import { PersonaViewItem } from "./PersonaViewItem";

import { PERSONA_STEPS } from "src/utils/constants";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import Iconify from "../Iconify";

export default function PersonaView({ steps }) {
  const currentStep = useSelector((state) => state.canvas.currentStep);
  const isFinalStep = currentStep === steps.length - 1;

  return (
    <>
      <Grid container spacing={0} columns={12}>
        <Grid item md={1}>
          <Box
            sx={{
              border: "1px solid black",
              borderBottom: "none",
              height: isFinalStep ? "180px" : "135px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Iconify
              icon="iconoir:profile-circled"
              sx={{
                height: "50px",
                width: "50px",
              }}
            />
          </Box>
        </Grid>

        <Grid item md={5}>
          <Grid>
            <PersonaViewItem
              borderStyle={{
                border: "1px solid black",
                borderBottom: "none",
                borderLeft: "none",
              }}
              content={{
                title: PERSONA_STEPS[0].title,
                height: isFinalStep ? "180px" : "135px",
                index: 0,
              }}
              orientation="row"
              limit="3"
            ></PersonaViewItem>
          </Grid>
        </Grid>

        <Grid item md={6}>
          <Grid>
            <PersonaViewItem
              borderStyle={{
                border: "1px solid black",
                borderLeft: "none",
              }}
              orientation="row"
              limit="4"
              content={{
                title: PERSONA_STEPS[1].title,
                height: isFinalStep ? "180px" : "135px",
                index: 1,
              }}
            ></PersonaViewItem>
          </Grid>
        </Grid>
        <Grid item md={3}>
          <Grid>
            <PersonaViewItem
              borderStyle={{
                border: "1px solid black",
                borderBottom: "none",
              }}
              content={{
                title: PERSONA_STEPS[2].title,
                height: isFinalStep ? "180px" : "135px",
                index: 2,
              }}
              limit="3"
              orientation="row"
            ></PersonaViewItem>
          </Grid>
        </Grid>
        <Grid item md={3}>
          <Grid>
            <PersonaViewItem
              borderStyle={{
                border: "1px solid black",
                borderBottom: "none",
                borderLeft: "none",
              }}
              content={{
                title: PERSONA_STEPS[3].title,
                height: isFinalStep ? "180px" : "135px",
                index: 3,
              }}
              limit="3"
              orientation="row"
            ></PersonaViewItem>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <Grid>
            <PersonaViewItem
              borderStyle={{
                border: "1px solid black",
                borderLeft: "none",
                borderTop: "none",
                borderBottom: "none",
              }}
              content={{
                title: PERSONA_STEPS[5].title,
                height: isFinalStep ? "180px" : "135px",
                index: 5,
              }}
              limit="4"
              orientation="row"
            ></PersonaViewItem>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <Grid>
            <PersonaViewItem
              borderStyle={{
                border: "1px solid black",
              }}
              limit="4"
              orientation="row"
              content={{
                title: PERSONA_STEPS[4].title,
                height: isFinalStep ? "180px" : "135px",
                index: 4,
              }}
            ></PersonaViewItem>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <Grid>
            <PersonaViewItem
              borderStyle={{
                border: "1px solid black",
                borderLeft: "none",
              }}
              limit="4"
              orientation="row"
              content={{
                title: PERSONA_STEPS[6].title,
                height: isFinalStep ? "180px" : "135px",
                index: 6,
              }}
            ></PersonaViewItem>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
