import { LoadingButton } from "@mui/lab";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Modal,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateBP, updateBPWithoutGet } from "src/stores/bp/bpSlice";
import { textDataObject, updateProductArrayObject } from "src/utils/bp/CA";
import Iconify from "../Iconify";
import * as Yup from "yup";
import CAEditor from "../BP/CAEditor";
import { tableCellClasses } from "@mui/material/TableCell";
import BoxLoader from "src/router/components/BoxLoader";
import useIsMentor from "src/hooks/useIsMentor";
import SuggestionCard from "../common/SuggestionCard";
import { FormatCurrency } from "src/utils/formatNumber";

const generateProduct = (overrides) => ({
  name: "",
  quantity_1: 0,
  unit_price_1: 0,
  quantity_2: 0,
  unit_price_2: 0,
  quantity_3: 0,
  unit_price_3: 0,
  quantity_4: 0,
  unit_price_4: 0,
  quantity_5: 0,
  unit_price_5: 0,
  quantity_6: 0,
  unit_price_6: 0,
  quantity_7: 0,
  unit_price_7: 0,
  price_evolution_2: 0,
  quantity_evolution_2: 0,
  price_evolution_3: 0,
  quantity_evolution_3: 0,
  price_evolution_4: 0,
  quantity_evolution_4: 0,
  price_evolution_5: 0,
  quantity_evolution_5: 0,
  price_evolution_6: 0,
  quantity_evolution_6: 0,
  price_evolution_7: 0,
  quantity_evolution_7: 0,
  start_year: 1,
  end_year: 7,
  ...overrides,
});
function CA() {
  const dispatch = useDispatch();
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const projects = useSelector((state) => state.projects.projects);
  const project = projects.find((p) => p.pk === selectedProject);
  const oldData = useSelector((state) => state.bp.textDataArray);
  const { description } = useSelector((state) => state.bp.OPM);
  const loading = useSelector((state) => state.bp.loading);
  const { isMentor, isSupervisor, isOwner } = useIsMentor();

  const [year, setYear] = useState(1);
  const [product, setProduct] = useState(null);

  const textDataHandler = (data) => {
    const obj = textDataObject(oldData, data, "OPM");
    dispatch(updateBPWithoutGet({ projectId: selectedProject, payload: obj }));
  };
  const suggHandler = async (data) => {
    const obj = textDataObject(oldData, data, "OPM");
    await dispatch(
      updateBP({ projectId: selectedProject, payload: obj })
    ).unwrap();
  };
  const [showSuggestion, setShowSuggestion] = useState(false);
  const handleSuggestion = async (value) => {
    await suggHandler(value);
    setShowSuggestion(false);
  };
  const [hasSuggestions, setHasSuggestions] = useState(false);
  return (
    <>
      {product && (
        <ProductModal product={product} setProduct={setProduct} year={year} />
      )}
      <Grid container spacing={1}>
        <Grid item xs={5}>
          {loading ? (
            <BoxLoader />
          ) : (
            <Grid container sx={{ px: 3, position: "relative" }}>
              {isOwner && (
                <>
                  <IconButton
                    onClick={() => setShowSuggestion((c) => !c)}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "-1.5rem",
                    }}
                    color="primary"
                    title="Voir la suggestion"
                  >
                    <Badge
                      badgeContent={hasSuggestions ? "!" : null}
                      color="error"
                    >
                      <Iconify
                        icon={
                          showSuggestion
                            ? "material-symbols:chevron-left-rounded"
                            : "material-symbols:chevron-right-rounded"
                        }
                      />
                    </Badge>
                  </IconButton>
                </>
              )}
              {isMentor && (
                <Grid item xs={12}>
                  <div
                    style={{ maxHeight: "350px", overflow: "auto" }}
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></div>
                </Grid>
              )}

              {
                <Grid
                  item
                  xs={12}
                  sx={{ display: showSuggestion || isSupervisor ? "" : "none" }}
                >
                  {isSupervisor ? (
                    <strong>Votre suggestion:</strong>
                  ) : (
                    <strong>Suggestion du superviseur:</strong>
                  )}
                  <Box sx={{ height: "350px" }}>
                    <SuggestionCard
                      mainContent={description}
                      setHasSuggestions={setHasSuggestions}
                      type={`CA`}
                      handleSuggestion={handleSuggestion}
                    />
                  </Box>
                </Grid>
              }
              <Grid
                item
                xs={12}
                sx={{
                  display: showSuggestion || isSupervisor ? "none" : "",
                }}
              >
                {(isOwner || isSupervisor) && (
                  <>
                    <strong> Contenu:</strong>
                  </>
                )}

                {isOwner && (
                  <Box>
                    <CAEditor
                      initialData={description}
                      dataHandler={textDataHandler}
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={7}>
          <Grid container columns={20} spacing={4}>
            <Grid item xs={2}>
              <YearSelector currentYear={year} setYear={setYear} />
            </Grid>
            <Grid item xs={18}>
              <TableProducts
                year={year}
                handleAjouter={() =>
                  setProduct(
                    generateProduct({
                      start_year: year,
                      end_year: project.project_study_duration,
                    })
                  )
                }
                setProduct={setProduct}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CA;

const TableProducts = ({ year, handleAjouter, setProduct }) => {
  const dispatch = useDispatch();
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );

  const OPM = useSelector((state) => state.bp.OPM);
  const productsToShow = useMemo(
    () =>
      OPM.productArray.filter(
        (p) => year >= p.start_year && year <= p.end_year
      ),
    [OPM, year]
  );
  const [productToDelete, setProductToDelete] = useState(null);
  const handleDelete = async (id) => {
    const newArr = OPM.productArray.filter((p) => p.pk !== id);

    const obj = updateProductArrayObject([...newArr]);
    await dispatch(
      updateBP({ projectId: selectedProject, payload: obj })
    ).unwrap();
  };
  const { isMentor, isSupervisor } = useIsMentor();

  return (
    <TableContainer
      sx={{ maxHeight: "400px", overflow: "auto", position: "relative" }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <StyledTableRow sx={{ position: "sticky", top: 0, zIndex: 100 }}>
            <StyledTableCell>Nom</StyledTableCell>
            <StyledTableCell align="center">
              Prix de vente unitaire (Evolution%)
            </StyledTableCell>
            <StyledTableCell align="center">
              Quantité (Evolution%)
            </StyledTableCell>
            <StyledTableCell align="center">Prix total</StyledTableCell>
            {!isMentor && !isSupervisor && (
              <StyledTableCell align="center">Actions</StyledTableCell>
            )}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {productsToShow.map((product) => (
            <StyledTableRow key={product.pk}>
              <StyledTableCell>{product.name}</StyledTableCell>
              <StyledTableCell align="center">
                {parseFloat(product["unit_price_" + year])} (
                {year === product.start_year
                  ? 0
                  : parseFloat(product["price_evolution_" + year] ?? 0)}
                %)
              </StyledTableCell>
              <StyledTableCell align="center">
                {parseFloat(product["quantity_" + year])} (
                {year === product.start_year
                  ? 0
                  : parseFloat(product["quantity_evolution_" + year] ?? 0)}
                %)
              </StyledTableCell>
              <StyledTableCell align="center">
                {FormatCurrency(parseFloat(product["total_price_" + year]))}
              </StyledTableCell>
              {!isMentor && !isSupervisor && (
                <StyledTableCell align="right">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <IconButton
                      title="Effacer"
                      color="error"
                      sx={{ mr: 1 }}
                      onClick={() => {
                        setProductToDelete(product.pk);
                      }}
                    >
                      <Iconify
                        icon="fa6-solid:trash-can"
                        sx={{ height: 16, width: 16 }}
                      />
                    </IconButton>
                    <IconButton
                      title="modifier"
                      onClick={() => {
                        setProduct(product);
                      }}
                    >
                      <Iconify
                        icon="akar-icons:edit"
                        sx={{ height: 18, width: 18 }}
                      />
                    </IconButton>
                  </div>
                </StyledTableCell>
              )}
            </StyledTableRow>
          ))}
          <StyledTableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              position: "sticky",
              bottom: 0,
              zIndex: 100,
              backgroundColor: "#fff",
            }}
          >
            <StyledTableCell>
              {!isMentor && !isSupervisor && (
                <IconButton
                  variant="outlined"
                  color="primary"
                  onClick={handleAjouter}
                >
                  <Iconify icon="ant-design:plus-outlined" />
                </IconButton>
              )}
            </StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            <StyledTableCell align="center">
              Total: {getPrixTotalYear(productsToShow, year)}
            </StyledTableCell>
            {!isMentor && !isSupervisor && (
              <StyledTableCell align="right"></StyledTableCell>
            )}
          </StyledTableRow>
        </TableBody>
      </Table>
      <DeleteDialaog
        productToDelete={productToDelete}
        setProductToDelete={setProductToDelete}
        handleDelete={handleDelete}
      />
    </TableContainer>
  );
};

const YearSelector = ({ currentYear, setYear }) => {
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const projects = useSelector((state) => state.projects.projects);
  const project = projects.find((p) => p.pk === selectedProject);
  const years = useMemo(() => {
    const years = [];
    for (let i = 1; i <= project?.project_study_duration; i++) {
      years.push(i);
    }
    return years;
  }, [project?.project_study_duration]);
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {years.map((year) => {
        return (
          <Button
            key={year}
            size="small"
            color="primary"
            variant={currentYear === year ? "contained" : "outlined"}
            onClick={() => setYear(year)}
            sx={{
              py: 0.5,
              px: 0,
              mb: 0.5,
              lineHeight: 1.4,
              fontSize: "0.75em",
            }}
          >
            Année <br />
            {year}
          </Button>
        );
      })}
    </Box>
  );
};

const getPrixTotalYear = (products, year) => {
  return FormatCurrency(
    products.reduce((acc, product) => {
      return acc + parseFloat(product["total_price_" + year]);
    }, 0)
  );
};

const ProductModal = ({ product, setProduct, year }) => {
  const isNewProduct = !product.pk;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
  };
  const dispatch = useDispatch();
  const open = product !== null;
  const OPM = useSelector((state) => state.bp.OPM);

  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const projects = useSelector((state) => state.projects.projects);
  const project = projects.find((p) => p.pk === selectedProject);

  const handleAdd = async (values) => {
    let obj;

    obj = updateProductArrayObject([
      ...OPM.productArray,
      {
        ...product,
        name: values.name,
        ["quantity_" + values.start_year]: values.quantity,
        ["unit_price_" + values.start_year]: values.unitPrice,
        start_year: values.start_year,
        end_year: values.end_year,
      },
    ]);

    await dispatch(
      updateBP({ projectId: selectedProject, payload: obj })
    ).unwrap();
  };
  const handleUpdate = async (values) => {
    const newArr = OPM.productArray.filter((p) => p.pk !== product.pk);
    let obj;
    if (year === product.start_year) {
      obj = updateProductArrayObject([
        ...newArr,
        {
          ...product,
          name: values.name,
          ["quantity_" + values.start_year]: values.quantity,
          ["unit_price_" + values.start_year]: values.unitPrice,
          start_year: values.start_year,
          end_year: values.end_year,
        },
      ]);
    } else {
      obj = updateProductArrayObject([
        ...newArr,
        {
          ...product,
          name: values.name,
          ["quantity_evolution_" + year]: values.quantity,
          ["price_evolution_" + year]: values.unitPrice,
          ["quantity_" + values.start_year]: product["quantity_"+product.start_year ],
          ["unit_price_" + values.start_year]: product["unit_price_" + product.start_year],
          start_year: values.start_year,
          end_year: values.end_year,
        },
      ]);
    }
    await dispatch(
      updateBP({ projectId: selectedProject, payload: obj })
    ).unwrap();
  };
  const productToShow = useMemo(() => {
    if (isNewProduct)
      return {
        name: "",
        quantity: 0,
        unitPrice: 0,
        start_year: product.start_year,
        end_year: product.end_year,
      };
    if (year === product.start_year) {
      return {
        name: product.name,
        quantity: product["quantity_" + product.start_year],
        unitPrice: product["unit_price_" + product.start_year],
        start_year: product.start_year,
        end_year: product.end_year,
      };
    } else {
      return {
        name: product.name,
        quantity: product["quantity_evolution_" + year],
        unitPrice: product["price_evolution_" + year],
        start_year: product.start_year,
        end_year: product.end_year,
      };
    }
  }, [isNewProduct, product, year]);
  const [isSubmitting, setisSubmitting] = useState(false);

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Ce champs est obligatoire"),
    quantity: Yup.number().required("Ce champs est obligatoire"),
    unitPrice: Yup.number().required("Ce champs est obligatoire"),
    start_year: Yup.number()
      .required("Ce champs est obligatoire")
      .min(1, "L'année de début doit être supérieure ou égale à 1")
      .max(
        project.project_study_duration,
        "L'année de début doit être inférieure ou égale à la durée du projet"
      ),

    end_year: Yup.number()
      .when("start_year", (start_year) => {
        if (start_year) {
          return Yup.number().min(
            start_year,
            "L'année de fin doit être supérieure ou égale à l'année de début du produit"
          );
        }
      })
      .max(
        project.project_study_duration,
        "L'année de fin doit être inférieure ou égale à la durée du projet"
      )
      .required("Ce champs est obligatoire"),
  });

  const formik = useFormik({
    initialValues: {
      name: productToShow.name,
      quantity: productToShow.quantity,
      unitPrice: productToShow.unitPrice,
      start_year: productToShow.start_year,
      end_year: productToShow.end_year,
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      setisSubmitting(true);
      if (isNewProduct) {
        await handleAdd(values);
      } else {
        await handleUpdate(values);
      }
      setisSubmitting(false);
      closeModal();
    },
  });
  const { handleSubmit, getFieldProps, touched, errors } = formik;
  const closeModal = () => {
    setProduct(null);
    formik.resetForm();
  };
  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Card>
          <CardContent>
            <FormikProvider value={formik}>
              <Typography variant="h6">
                {isNewProduct ? "Nouveau produit" : "Modifier produit"}
              </Typography>
              <Divider sx={{ mb: 5, mt: 1 }} />
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <TextField
                    sx={{ mb: 2 }}
                    autoComplete="product-name"
                    type="text"
                    label="Nom du produit"
                    {...getFieldProps("name")}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <TextField
                    sx={{ mb: 2 }}
                    autoComplete="product-quantity"
                    type="number"
                    label={
                      year === product.start_year
                        ? "Quantité"
                        : "Evolution de la quantité (%)"
                    }
                    {...getFieldProps("quantity")}
                    error={Boolean(touched.quantity && errors.quantity)}
                    helperText={touched.quantity && errors.quantity}
                  />
                  <TextField
                    type="number"
                    sx={{ mb: 2 }}

                    autoComplete="product-uprice"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    label={
                      year === product.start_year
                        ? "Prix de vente unitaire"
                        : "Evolution du prix unitaire (%)"
                    }
                    {...getFieldProps("unitPrice")}
                    error={Boolean(touched.unitPrice && errors.unitPrice)}
                    helperText={touched.unitPrice && errors.unitPrice}
                  />
                  <Stack direction="row" spacing={2}>
                  <TextField
                    type="number"
                    sx={{ mb: 2 }}

                    autoComplete="start_year"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    label="Année de début"
                    {...getFieldProps("start_year")}
                    error={Boolean(touched.start_year && errors.start_year)}
                    helperText={touched.start_year && errors.start_year}
                  />
                  <TextField
                    type="number"
                    autoComplete="end_year"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    label="Année de fin"
                    {...getFieldProps("end_year")}
                    error={Boolean(touched.end_year && errors.end_year)}
                    helperText={touched.end_year && errors.end_year}
                  />
                  </Stack>

                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <LoadingButton
                      sx={{ mt: 3 }}
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      {isNewProduct ? "Ajouter" : "Modifier"}
                    </LoadingButton>
                  </Box>
                </Box>
              </Form>
            </FormikProvider>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

const DeleteDialaog = ({
  handleDelete,
  setProductToDelete,
  productToDelete,
}) => {
  const [isSubmitting, setisSubmitting] = useState(false);
  const open = productToDelete !== null;
  const handleClose = () => {
    setProductToDelete(null);
  };
  const deleteProduct = async () => {
    setisSubmitting(true);
    await handleDelete(productToDelete);
    setisSubmitting(false);
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Supprimer le produit"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Êtes-vous sûr de vouloir supprimer ce produit ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isSubmitting}>
          Annuler
        </Button>
        <Button
          color="error"
          onClick={deleteProduct}
          autoFocus
          disabled={isSubmitting}
        >
          Effacer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    lineHeight: 1.4,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    fontWeight: "bold",
  },
}));
