import { Box, Step, StepLabel, Stepper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Step1 from "src/components/cohort/stepper/Step1";
import Step2 from "src/components/cohort/stepper/Step2";
import Step3 from "src/components/cohort/stepper/Step3";
import Page from "src/components/Page";
import { getCohortByIdAction } from "src/stores/cohort/cohortSlice";
const steps = [
  "Ajout superviseurs",
  "Ajout mentors",
  "Ajout porteur de projet",
];
function Cohort() {
  const dispatch = useDispatch();
  const { cohorts, selectedCohort, cohort } = useSelector(
    (state) => state.cohort
  );
  const currentCohort = cohorts.data.find(
    (cohort) => cohort.id === selectedCohort
  );
  const [activeStep, setActiveStep] = useState(0);
  useEffect(() => {
    if (currentCohort) {
      dispatch(getCohortByIdAction(currentCohort.id));
    }
  }, [currentCohort, dispatch]);

  if (!cohort.data) return <Page title="chargment"></Page>;
  return (
    <Page
      title={"Cohorte: " + cohort.data.name}
      style={{
        border: "1px solid #ECECEC",
        borderRadius: "10px",
        padding: " 1rem ",
        height: "98%",
      }}
    >
      <Box sx={{ px: 3 }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={index}>
                <StepLabel
                  style={{ cursor: "pointer" }}
                  onClick={() => setActiveStep(index)}
                  title={label}
                ></StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>

      <Typography sx={{ my: 1, px: 3, fontSize: "0.8em" }}>
        <strong style={{ color: "#2196F3" }}>Etape {activeStep + 1}</strong>
        <strong>{" " + steps[activeStep]}</strong>
      </Typography>
      {activeStep === 0 && <Step1/>}
      {activeStep === 1 && <Step2/>}
      {activeStep === 2 && <Step3/>}
    </Page>
  );
}

export default Cohort;
