import { Box, Container } from "@mui/material";
import React from "react";
import Page from "src/components/Page";

const PageLoader = () => {
  return (
    <Page title="Chargement...">
      <Container maxWidth={false}>
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Spinner />
        </Box>
      </Container>
    </Page>
  );
};

const Spinner = () => {
  return (
    <div className="spinner">
      <img
        src="/static/icons/logo.png"
        alt="logo"
        style={{ width: "200px", height: "100px", objectFit: "contain" }}
      />
      <div className="spinner-sector spinner-sector-blue"></div>
      <div className="spinner-sector spinner-sector-blue-2"></div>
    </div>
  );
};

export default PageLoader;
