import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useIsMentor from "src/hooks/useIsMentor";
import { updateProject } from "src/stores/projects/projectsSlice";

function Step2() {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects.projects);
  const projectId = useSelector((state) => state.projects.selectedProject);
  const currentProject = projects.find((project) => project.pk === projectId);
  const {isMentor,isSupervisor} = useIsMentor();

  const handleChange = (event) => {
    dispatch(
      updateProject({ ...currentProject, legal_form: event.target.value })
    );
  };
  return (
    <FormControl sx={{ minWidth: "300px", mt: 3 }}>
      <InputLabel htmlFor="component-outlined">Forme juridique</InputLabel>
      <Select
        readOnly={isMentor||isSupervisor}
        labelId="forme-juridique-label"
        id="forme-juridique"
        value={currentProject ? currentProject.legal_form : ""}
        label="Forme juridique"
        onChange={handleChange}
      >
        <MenuItem value="SI">Personne physique</MenuItem>
        <MenuItem value="SUARL">SUARL</MenuItem>
        <MenuItem value="SARL">SARL</MenuItem>
        <MenuItem value="SA">SA</MenuItem>
      </Select>
    </FormControl>
  );
}

export default Step2;
