import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "src/libs/axios";

const initialState = {
  projects: [],
  selectedProject: parseInt(localStorage.getItem("projectSelected"), 10) || -1,
  status: "loading",
  ficheProjetStep: "name",
};

export const getMyProjects = createAsyncThunk(
  "projects/getMyProjects",
  async () => {
    try {
      const rs = await axiosInstance.get("/projects/my/");
      const more= await axiosInstance.get("/cohort/projects/");
      const mentored=more.data.mentored_projects.map((p)=>({...p,role:"mentor"}))
      const supervised=more.data.supervised_projects.map((p)=>({...p,role:"supervisor"}))
      const owned=rs.data.map((p)=>({...p,role:"owner"}))
      const projects=[...mentored,...supervised,...owned]
      // remove projects with same id
      const uniqueProjects = projects.filter((thing, index, self) =>
        index === self.findIndex((t) => t.pk === thing.pk)
      );
      return uniqueProjects
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
export const getMentoredProjects = createAsyncThunk(
  "projects/getMentoredProjects",
  async () => {
    try {
      const rs = await axiosInstance.get("/mentorship/mentoredprojects/");
      return rs.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const createProject = createAsyncThunk(
  "projects/createProject",
  async (data) => {
    try {
      const rs = await axiosInstance.post("/projects/", data);
      return rs.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
export const updateProjectAction = createAsyncThunk(
  "projects/updateProjectAction",
  async (data) => {
    try {
      const newData = {
        name: data.name,
        legal_form: data.legal_form,
        governorate: data.governorate,
        delegation: data.delegation,
        nat_category: data.nat_category,
        NAT_title: data.NAT_title,
        funding_method: data.funding_method,
        project_study_duration: data.project_study_duration,
        finance_mode: data.finance_mode,
        is_investements: data.is_investements,
      };
      const rs = await axiosInstance.patch(`/projects/${data.pk}/`, newData);
      return rs.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const deleteProjectAction = createAsyncThunk(
  "projects/deleteProjectAction",
  async (pk,{rejectWithValue}) => {
    try {
      const rs = await axiosInstance.delete(`/projects/${pk}/`);
      return pk;
    } catch (error) {
      return rejectWithValue("error");
    }
  }
);

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  extraReducers: {
    [getMyProjects.fulfilled]: (state, action) => {
      state.projects = action.payload;
      state.status = "success";
    },
    [getMyProjects.rejected]: (state) => {
      state.status = "error";
    },
    [getMentoredProjects.fulfilled]: (state, action) => {
      state.projects = action.payload;
      state.status = "success";
    },
    [getMentoredProjects.rejected]: (state) => {
      state.status = "error";
    },
    [createProject.fulfilled]: (state, action) => {
      state.projects.push(action.payload);
    },
    [updateProjectAction.fulfilled]: (state, action) => {
      const index = state.projects.findIndex(
        (project) => project.pk === action.payload.pk
      );
      state.projects[index] = action.payload;
    },
    [deleteProjectAction.fulfilled]: (state, action) => {
      const index = state.projects.findIndex(
        (project) => project.pk === action.payload
      );
      state.projects.splice(index, 1);
    }
   
  },
  reducers: {
    selectProject: (state, action) => {
      state.selectedProject = action.payload;
      localStorage.setItem("projectSelected", action.payload);
    },
    updateProject: (state, action) => {
      const index = state.projects.findIndex(
        (project) => project.pk === action.payload.pk
      );
      state.projects[index] = action.payload;
    },
    setStepFiche: (state, action) => {
      state.ficheProjetStep = action.payload;
    },
  },
});
export default projectsSlice.reducer;
export const { selectProject, setStepFiche, updateProject } =
  projectsSlice.actions;
