import React, { useCallback, useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';


import { debounce } from "lodash";
import axiosInstance from "src/libs/axios";
import { setSavingStatusBP } from "src/stores/bp/bpSlice";
import { useDispatch } from "react-redux";
import useIsMentor from "src/hooks/useIsMentor";

const editorConfig = {
  extraPlugins: [uploadPlugin],

 
};
function Editor({ dataHandler, stepData }) {
  const dispatch = useDispatch();
  const {isMentor,isSupervisor} = useIsMentor();
  const [editor, setEditor] = useState(null);
  const [value, setValue] = useState("");
  useEffect(() => {
    if (stepData) {
      if(editor) editor.setData(stepData.value||"");
      setValue(stepData.value||"");
    }
  }, [editor, stepData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleEditorData = useCallback(
    debounce((value) => {
      dataHandler(value);
    }, 500),
    [dataHandler]
  );

  const handleChange = (value) => {
    if(value===stepData?.value) return;
    setValue(value);
    dispatch(setSavingStatusBP("unsaved"));

    handleEditorData(value);
  };

  return (
    <>
      {(isMentor||isSupervisor) ? (
        <div style={{maxHeight:"350px",overflow:"auto"}} dangerouslySetInnerHTML={{__html:value}}/>
      ) : (
        <CKEditor
          config={editorConfig}
          editor={ClassicEditor}
          data={value}
          onReady={(editor) => {
            setEditor(editor);
            editor.editing.view.change((writer) => {
              writer.setStyle(
                "height",
                "300px",
                editor.editing.view.document.getRoot()
              );
            });
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            handleChange(data);
          }}
        />
      )}
    </>
  );
}

export default Editor;

function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        const body = new FormData();
        loader.file.then((file) => {
          body.append("file", file);
          axiosInstance
            .post("/files/upload/", body)
            .then((res) => res.data)
            .then((res) => {
              resolve({
                default: res.file,
              });
            })
            .catch((err) => {
              reject(err);
            });
        });
      });
    },
  };
}
function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}
