import { Button } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Page from "src/components/Page";
import axiosInstance from "src/libs/axios";
import { setUser } from "src/stores/auth/userSlice";

function Cgu() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [isEnd, setIsEnd] = useState(false);
  const handleAccept = async () => {
    setLoading(true);

    const { data } = await axiosInstance.patch(
      "/me/update-my-profile/" + user.pk + "/",
      {
        isDocumentOneAccepted: true,
      }
    );
    dispatch(setUser(data));

    setLoading(false);
  };

  return (
    <Page title="CGU">
      <Box
        sx={{
          height: "80vh",
          width: "70vw",
          mx: "auto",
          mt: "10vh",
          p: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow: 4,
          overflow: "hidden",
        }}
      >
        <h4>
          <strong>CONDITIONS GÉNÉRALES D'UTILISATION</strong>
        </h4>
        <Doc1 setIsEnd={setIsEnd} />
        <Button
          variant="contained"
          color="primary"
          sx={{ textTransform: "none", mt: 3 }}
          onClick={handleAccept}
          disabled={!isEnd || loading}
        >
          Lu et approuvé
        </Button>
      </Box>
    </Page>
  );
}

export default Cgu;

const Doc1 = ({ setIsEnd }) => {
  const ref = useRef(null);
  const observedRef = useRef(null);
  function onIntersection(entries, opts) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIsEnd(true);
      } else {
        // setIsEnd(false);
      }
    });
  }
  useEffect(() => {
    const curr = observedRef.current;
    const observer = new IntersectionObserver(onIntersection, {
      root: ref.current, // default is the viewport
      threshold: 0.5, // percentage of target's visible area. Triggers "onIntersection"
    });
    observer.observe(curr);

    return () => {
      observer.unobserve(curr);
    };
  }, [setIsEnd]);

  return (
    <Box
      ref={ref}
      sx={{
        width: "100%",
        flex: 1,
        overflow: "auto",
        mt: 3,
        textAlign: "justify",
        fontSize: "0.8em",
        p: 3,
      }}
    >
      <p>
        En accédant au présent site et aux informations qu'il contient,
        l'utilisateur reconnaît avoir pris connaissance des présentes conditions
        générales d'utilisation et s'engage à les respecter. L'éditeur se
        réserve le droit de modifier la présente notice à tout moment et
        notamment afin de l'adapter aux évolutions du site. L'utilisateur
        s'engage donc à consulter la présente notice régulièrement.
      </p>
      <br />
      <p>
        Elle s'applique à tous les utilisateurs de notre site web, application,
        ou autres services, à moins que ces derniers ne soient couverts par les
        conditions générales d'utilisation. Ces conditions générales
        d'utilisation s'appliquent spécifiquement aux utilisateurs suivants.
      </p>
      <br />

      <ul style={{ listStyle: "inside", marginLeft: "1rem" }}>
        <li>Client particulier : FUNDER GO</li>
        <li>Client professionnel : FUNDER Pro</li>
      </ul>
      <br />

      <p>
        Toues les personnes soumis à ces conditions générales d'utilisation sont
        dénommées ci-après « utilisateurs »
      </p>
      <br />

      <p>
        <strong>
          L'utilisateur est réputé accepter cette dernière version à chaque
          nouvelle inscription au Site. 
        </strong>
      </p>
      <br />
      <br />
      <p>
        <strong>Conditions d'accès</strong>
      </p>
      <br />
      <p>
        Ce site est soumis à la loi Tunisienne. La majorité des contenus et
        services du présent sont proposés en français. Les documents,
        informations et offres de services diffusés sur le site sont destinés
        aussi bien aux personnes bancarisées, qu'aux non-bancarisées. Le présent
        est accessible 24 heures sur 24, 7 jours sur 7, sauf cas de force
        majeure, fait(s) de tiers et/ou travaux de maintenance et interventions
        nécessaires au bon fonctionnement du site. Dans ce cas, cette
        interruption sera notifiée au moyen d'un avertissement figurant sur la
        page d'accueil ou par tout autre procédé. Cette interruption ne peut en
        aucun cas engager la responsabilité de l'éditeur et n'ouvre droit à
        aucune indemnité.
      </p>
      <br />
      <p>
        Pour pouvoir utiliser nos services, vous devez vous vous connecter à
        notre site web en tant que client particulier ou professionnel. Cela
        nécessite une inscription.si vous souhaitez vous inscrire en tant que
        particulier ou professionnel, vous devez fournir les données
        personnelles ci-dessous :
      </p>
      <br />
      <ul style={{ listStyle: "inside", marginLeft: "1rem" }}>
        <li>Adresse e-mail</li>
        <li>Mot de passe</li>
        <li>Numéro de téléphone</li>
        <li>Nom</li>
        <li>Nom de l'entreprise</li>
        <li>Gouvernorat</li>
        <li>Identifiant unique</li>
        <li>Profession</li>
      </ul>
      <br />
      <p>
        <strong>Compte de particulier</strong>
      </p>
      <br />

      <p>
        Une inscription à un compte particulier est nécessaire pour vous fournir
        les fonctionnalités et outils de votre tableau de bord et pour vous
        permettre d'élaborer votre business plan d'une façon automatique. En
        outre, l'enregistrement est requis pour la conclusion du ____________ et
        pour la communication en rapport avec les services proposés sur la
        plate-forme.
      </p>
      <br />
      <p>
        <strong>Compte de professionnel</strong>
      </p>
      <br />

      <p>
        Une inscription à un compte professionnel est nécessaire afin de vous
        fournir les fonctionnalités et outils de votre tableau de bord et vous
        permettre d'élaborer votre business plan d'une façon automatique. De
        plus, vous pouvez commander des services ___________ ou obtenir des
        informations sur d'autres services proposés sur notre plateforme. Vous
        pouvez également communiquer avec nous et nos sous-traitants via la
        plateforme.
        <br />
        De plus, vous avez la possibilité de gérer votre compte après votre
        inscription.
      </p>
      <br />

      <p>
        <strong>Tarification</strong>
      </p>
      <br />

      <p>
        Nous utilisons des services de paiement tiers, selon le mode de paiement
        que vous choisissez.
        <br />
        L'accès au présent site est gratuit pour …. Mois , hors la fourniture
        d'accès internet et les communications téléphoniques dont les coûts sont
        facturés directement par les opérateurs.
        <br />
        L'utilisateur s'engage à payer la société Funde sarl propriétaire de la
        plateforme FUNDER en totalité et dans les délais précisés lors de la
        demande.
      </p>
      <br />

      <p>
        <strong>Informations mises à disposition</strong>
      </p>

      <p>
        <strong>
          <i>Les informations sur des produits et services</i>
        </strong>
      </p>
      <br />
      <p>
        Les services de mise en relation entre la plateforme et les
        particulier/professionnel présentés sur ce site sont destinés à être
        offerts et utilisés selon les conditions contractuelles et les tarifs en
        vigueur.
        <br />
        L'utilisateur de GOGIPLAN s'engage à fournir des informations
        authentiques sur sa propre personne.
        <br />
        GOGIPLAN se réserve le droit d'accepter ou de refuser de fournir son
        service aux clients utilisateurs de ces services.
      </p>
      <br />
      <p>
        <strong>
          GOGIPLAN, afin de maintenir l'exactitude des informations sur des
          services diffusées sur ce site, se réserve le droit d'en corriger le
          contenu, à tout moment et sans préavis.{" "}
          <i>
            Les autres informations à caractère général fournies à titre non
            contractuel
          </i>
        </strong>
      </p>
      <br />
      <p>
        Les autres informations à caractère général (économiques, financières,
        juridiques, sur la vie quotidienne, vie des entreprises, vie des
        associations…) sont fournies à titre non contractuel et n'ont qu'une
        valeur indicative ou informative. Malgré tous les soins apportés à
        l'actualisation de ces informations et à leurs contenus, les documents
        mis en ligne sont susceptibles de contenir des inexactitudes techniques
        et des erreurs typographiques qui ne sauraient engager la responsabilité
        de FUNDER, de quelque manière que ce soit. L'internaute est le seul
        responsable de l'utilisation qu'il peut faire de ces informations à
        caractère général.
      </p>
      <br />
      <p>
        <strong>Usage du site internet par l'utilisateur</strong>
      </p>
      <br />
      <p>
        L'utilisateur est informé que l'utilisation du réseau internet ne
        garantit pas le secret des correspondances ; il lui appartient de
        prendre toutes les mesures appropriées afin de protéger ses propres
        données et/ou outils (Consultez la Rubrique « Sécurité »)
      </p>
      <br />
      <p>
        <strong>
          <i>
            L'utilisateur s'engage à respecter l'intégrité du site et s'interdit
            d'entraver ou forcer le fonctionnement, de modifier, d'altérer ou de
            supprimer frauduleusement les contenus accessibles via le site et
            d'introduire frauduleusement des données sur le site.
          </i>
        </strong>
      </p>
      <br />
      <p>En conséquence, l'éditeur :</p>
      <br />
      <ul style={{ listStyle: "inside", marginLeft: "1rem" }}>
        <li>
          ne saurait être tenu responsable des dommages qui pourraient
          éventuellement être subis, à l'occasion d'une visite sur son site, par
          l'environnement technique de l'utilisateur et notamment, ses
          ordinateurs, logiciels, équipements réseaux (modems, téléphones, etc…)
          et tout autre matériel utilisé pour accéder à ou utiliser le service
          et/ou les informations.
        </li>
        <li>
          ne peut être tenu responsable en cas de mauvaise utilisation du site
          par l'utilisateur.
        </li>
        <li>
          ne peut en aucun cas être tenu pour responsable de la fiabilité de la
          transmission des données, des temps d'accès, des éventuelles
          restrictions d'accès sur le réseau internet ou les réseaux qui lui
          sont connectés.
        </li>
        <li>
          ne saurait être responsable en cas d'interruption des réseaux d'accès,
          d'indisponibilité totale ou partielle du Site résultant notamment de
          l'opérateur de télécommunications, en cas d'erreur de transmission ou
          de problèmes liés à la sécurité des transmissions, en cas de
          défaillances du matériel de réception ou de votre ligne téléphonique.
        </li>
      </ul>
      <br />
      <p>
        <strong>Droits de Propriété intellectuelle</strong>
      </p>
      <br />
      <p>
        Le présent site doit être considéré comme un tout indissociable. Le
        présent site et l'ensemble des éléments y figurant (informations,
        données, texte, sons, images, dessins, graphismes, signes distinctifs,
        logos, marques,…) sont la propriété exclusive de l'éditeur ou de ses
        partenaires. L'ensemble de ces éléments est soumis aux dispositions du
        Code de la propriété intellectuelle et, à ce titre, est protégé contre
        toute utilisation non autorisée par la loi ou les présentes conditions
        d'utilisation. La reproduction de ces éléments n'est autorisée qu'à des
        fins d'information et/ou de copies ou reproductions destinées à un usage
        strictement privé et personnel. Toute autre reproduction, représentation
        ou diffusion, en tout ou partie du contenu de ce site, sur quelque
        support ou par tout procédé que ce soit, est interdite. Le non-respect
        de cette interdiction constitue une contrefaçon susceptible d'engager la
        responsabilité civile et pénale du contrefacteur. Il est notamment
        strictement interdit d'utiliser ou de reproduire le nom «FUNDER» et/ou
        son logo, seuls ou associés, à quelque titre que ce soit, et notamment à
        des fins publicitaires, sans l'accord préalable écrit de FUNDER. De
        même, le téléchargement ou toute autre forme de copie d'un logiciel ou
        d'informations présentes sur le site de FUNDER ne confèrent aucun
        droit sur ceux-ci. Il est strictement interdit de les reproduire (en
        tout ou partie), de les transmettre (électroniquement ou de quelque
        autre manière) et de les modifier. Il est également interdit d'utiliser
        le site de FUNDER à des fins publiques ou commerciales, sans
        l'autorisation écrite préalable de FUNDER.
      </p>
      <br />
      <p>
        <strong>Liens hypertextes</strong>
      </p>

      <br />
      <p>
        Le présent site comporte notamment des informations mises à disposition
        par des sociétés externes ou des liens hypertextes vers d'autres sites
        qui n'ont pas été développés par la société FUNDER. L'existence, sur
        le présent site et application mobile de FUNDER, d'un lien hypertexte
        conduisant à un autre site ne constitue pas une validation de cet autre
        site ou de son contenu.
      </p>
      <br />
      <p>
        <strong>
          <i>
            FUNDER n'assume par conséquent aucune responsabilité de ces sites,
            leur contenu, publicités, produits ou services disponibles sur ou à
            partir de ces sites.
          </i>
        </strong>
      </p>
      <br />
      <p>
        Par ailleurs, la responsabilité de FUNDER ne saurait être engagée
        quant aux informations, opinions et recommandations formulées par ces
        tiers. Tout lien vers le présent site doit faire l'objet d'une
        autorisation expresse et préalable FUNDER, lequel se réserve la
        possibilité de faire supprimer ce lien à tout moment.
      </p>
      <br />
      <p>
        <strong>INFORMATIQUES ET LIBERTÉS</strong>
      </p>

      <p>
        <strong>Politique de protection des données</strong>
      </p>
      <br />
      <p>
        Les utilisateurs du présent site et application mobile sont susceptibles
        de communiquer, à FUNDER, des données à caractère personnel les
        concernant en renseignant les formulaires ou questionnaires présents sur
        le site
      </p>
      <br />
      <p>
        <strong>Responsable du traitement et finalités</strong>
      </p>
      <br />
      <p>
        Les données à caractère personnel collectées dans le cadre du présent
        site le sont par FUNDER, éditeur du site, en sa qualité de responsable
        du traitement et seront utilisées pour les finalités suivantes :
        traitement de la demande d'informations, établissement de statistiques,
        réalisation d'actions de prospection et traitement de votre réclamation.
      </p>

      <br />
      <p>
        <strong>Caractéristiques sur l'usage et la collecte de données</strong>
      </p>
      <br />
      <p>
        Vous n'êtes pas obligé de répondre à tout ou partie des questions qui
        vous sont posées. Le caractère obligatoire de la communication des
        informations est indiqué par la présence d'un astérisque.
        <br />
        Les destinataires du traitement d'une réclamation sont le service
        relation clientèle ainsi que les personnes habilités au sein de FUNDER
        et des entités financières pour le traitement de la réclamation.
      </p>
      <br />
      <p>
        <strong>Modalités d'exercice de vos droits</strong>
      </p>
      <br />
      <p>
        Vous pouvez, à tout moment vous opposer au traitement des informations
        vous concernant, y accéder, les faire rectifier, en adressant un
        courrier électronique au webmaster ( Nous Contacter), à tout moment,
        vous opposer à la communication de vos données à des tiers ou à leur
        utilisation par le responsable de traitement, à des fins commerciales,
        dans les conditions ci-dessus indiquées.
      </p>
      <br />
      <p>
        <strong>Utilisation de vos données à des fins de prospection</strong>
      </p>
      <br />
      <p>
        Conformément à la réglementation applicable, toute prospection par
        automate d'appel, courrier électronique ou fax, ainsi que toute cession
        ou location des données à des fins de prospection utilisant ces moyens,
        ne seront réalisées qu'avec le consentement préalable de l'utilisateur
        concerné à recevoir des prospections via ce canal ou/et à la cession de
        ses données. Si vous acceptez de recevoir de tels messages de
        prospection en cochant les cases d'acceptation prévues à cet effet sur
        le présent site ou lors de l'inscription à l'application mobile, il vous
        sera possible à tout moment de revenir sur ce consentement, et notamment
        chacun des courriers électroniques de prospection vous offrira la
        possibilité de vous opposer pour le futur à l'envoi de nouveaux
        courriers électroniques de prospection. Toute prospection par courrier
        postal ou téléphone sans automate d'appel ne pourra être réalisée
        qu'après avoir mis en mesure l'utilisateur concerné de s'y opposer avant
        la validation définitive de ses réponses (lors de la collecte de ses
        coordonnées postales et téléphoniques). Si vous ne vous opposez pas à
        recevoir de tels messages de prospection en cochant ou décochant les
        cases prévues à cet effet sur le présent site ou dans la commande mon
        compte sur l'application mobile, il vous sera possible par la suite, à
        tout moment et sans frais, d'exercer votre droit d'opposition.
      </p>
      <br />
      <p>
        <strong>Cookies</strong>
      </p>
      <br />
      <p>
        Nous souhaitons implanter un cookie dans votre ordinateur et Smartphone.
        Un cookie ne nous permet pas de vous identifier ; en revanche, il
        enregistre des informations relatives à la navigation de votre
        ordinateur sur notre site (les pages que vous avez consultées, la date
        et l'heure de la consultation, etc.) que nous pourrons lire lors de vos
        visites ultérieures. La durée de conservation de ces informations dans
        votre ordinateur est de 1 an.
        <br />
        FUNDER peut-être amenée à implanter des « cookies » dans votre mobile
        à des fins de communication entre votre smartphone et nos serveurs. Vous
        pouvez vous y opposer en modifiant les paramètres correspondants de
        votre smartphone.
      </p>
      <br />
      <p>
        <strong>TRAITEMENT DES RÉCLAMATIONS</strong>
      </p>
      <p>
        <strong>
          FUNDER est particulièrement attentive aux remarques et réclamations
          de ses clients.
        </strong>
      </p>
      <br />
      <p>
        En cas de désaccord, d'incompréhension ou d'insatisfaction concernant le
        domaine FUNDER sera ravi d'être votre interlocuteur.
      </p>
      <br ref={observedRef} />
    </Box>
  );
};
