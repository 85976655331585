import { Box } from "@mui/material";
import React from "react";
import Chart from "react-apexcharts";

function GenderDistribution({ data }) {
  const male = data.porteurByGender.find((el) => el.owner__gender === "M");
  const female = data.porteurByGender.find((el) => el.owner__gender === "F");
  const series = [male ? male.count : 0, female ? female.count : 0];
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center",mt:2 }}
    >
       <Box sx={{ display: "flex", gap: 4, p: 2 }}>
            <h4>
              <span style={{ color: "#900C3F", fontSize: "3em" }}>
                {data.projectsCount}
              </span>{" "}
              Projets accompagnés
            </h4>
            <h4>
              <span style={{ color: "#900C3F", fontSize: "3em" }}>
                {data.SupervisorsCount}
              </span>{" "}
              Superviseurs
            </h4>
            <h4>
              <span style={{ color: "#900C3F", fontSize: "3em" }}>
                {data.mentorsCount}
              </span>{" "}
              Mentors
            </h4>
          </Box>
      <Chart
        options={{
          labels: ["Homme", "Femme"],
        }}
        type="donut"
        width="400"
        series={series}
      />
      <h3 style={{ margin: "1rem 0 " }}>
        Démographie porteurs de projet accompagnés{" "}
      </h3>
    </Box>
  );
}

export default GenderDistribution;
