import {  Box, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { BASE_URL } from "src/utils/constants";
import Iconify from "../Iconify";

function AvatarChanger({ setNewImage }) {
  const [previewImage, setPreviewImage] = useState(null);
  const { user } = useSelector((state) => state.user);

  const avatarSrc = previewImage ? previewImage : `${BASE_URL}${user.picture}`;

  const previewUploadedImage = (e) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      setPreviewImage(event.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
    setNewImage(e.target.files[0]);
  };
  return (
    <Box
      className="funder-avatar-container"
      sx={{
        position: "relative",
        mb: 5,
        width: "100%",
        backgroundColor: "rgba(0,0,0,0.1)",
      }}
    >
      <IconButton
        className="funder-avatar-btn"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          opacity: 0,
          zIndex: 1,
          width: "100%",
          height: "100%",
          borderRadius: "0",
        }}
        color="primary"
        aria-label="upload picture"
        component="label"
      >
        <input
          hidden
          accept="image/*"
          type="file"
          onChange={previewUploadedImage}
        />
        <Iconify icon="ic:outline-add-photo-alternate" />
      </IconButton>
      <img
        className="funder-avatar"
        src={avatarSrc}
        alt="your profile"
        style={{
          width: "100%",
          height: "200px",
          objectFit: "contain",
          mx: "auto",
          borderRadius: "0",
        }}
      />
      {/* <Avatar
        className="funder-avatar"
        src={avatarSrc}
        alt="your profile picture"
        sx={{
          width: "100%",
          height: 200,
          objectFit: "contain",
          mx: "auto",
          borderRadius: "0",
        }}
      /> */}
    </Box>
  );
}

export default AvatarChanger;
