import { Box } from "@mui/material";
import React from "react";

function ColorCircle({ primary, accent }) {
  return (
    <Box
      sx={{
        backgroundColor: primary,
        borderRadius: "50%",
        height: 20,
        width: 20,
      }}
    />
  );
}

export default ColorCircle;
