import {
  Avatar,
  Badge,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Modal,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "src/libs/axios";
import { getComments } from "src/stores/comments/commentSlice";
import { toggleRightNav } from "src/stores/global/NavConfigSlice";
import { BASE_URL } from "src/utils/constants";
import Iconify from "../Iconify";
const DRAWER_WIDTH = 450;

function CommentWrapper({ currentPage }) {
  const dispatch = useDispatch();
  const currentProject = useSelector((state) => state.projects.selectedProject);
  const { comments } = useSelector((state) => state.comments);
  const { isRightNavOpen } = useSelector((state) => state.layout);

  useEffect(() => {
    dispatch(getComments({ projectId: currentProject, pageType: currentPage }));

    const interval = setInterval(() => {
      dispatch(
        getComments({ projectId: currentProject, pageType: currentPage })
      );
    }, 30000);
    return () => clearInterval(interval);
  }, [currentPage, currentProject, dispatch]);
  // const [open, setOpen] = useState(false);
  const open = isRightNavOpen;
  const setOpen = (value) => {
    dispatch(toggleRightNav(value));
  };

  return (
    <div style={{ position: "relative", height: "2rem " }}>
      <IconButton
      
        color={open ? "success" : "primary"}
        onClick={() => setOpen(!open)}
        sx={{
          width: 24,
          height: 24,
          position: "absolute",
          right: 0,
          top: "30%",
        }}
      >
        <Badge badgeContent={comments.length} color="error">
          <Iconify icon="codicon:comment" width={24} height={24} />
        </Badge>
      </IconButton>
      <TestDrawer
        open={open}
        onClose={() => setOpen(false)}
        variant="permanent"
        anchor="right"
        BackdropProps={{ invisible: true }}
        PaperProps={{
          sx: { width: DRAWER_WIDTH, pt: 10 },
        }}
      >
        {open && <CommentContent currentPage={currentPage} />}
      </TestDrawer>
    </div>
  );
}

export default CommentWrapper;

const CommentContent = ({ currentPage }) => {
  const { comments } = useSelector((state) => state.comments);

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ flex: 1, overflow: "auto", padding: "0.5rem" }}>
        {comments.map((comment) => (
          <Comment comment={comment} key={comment.id} />
        ))}
      </div>
      <CommentActions currentPage={currentPage} />
    </div>
  );
};

const Comment = ({ comment }) => {
  const { user } = useSelector((state) => state.user);
  return (
    <div
      style={{
        border: "1px solid #ECECEC",
        borderRadius: "5px",
        padding: "1rem",
        marginBottom: "1rem",
        borderLeft:
          user.pk === comment.user.pk
            ? "5px solid #3f51b5"
            : "5px solid #ECECEC",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "0.5rem",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            sx={{ width: 28, height: 28, mr: 1 }}
            src={`${BASE_URL}${comment.user.picture}`}
          />
          {user.pk === comment.user.pk ? (
            <div style={{ fontWeight: "bold" }}>You</div>
          ) : (
            <div style={{ fontWeight: "bold" }}>
              Mentor {comment.user.first_name} {comment.user.last_name}
            </div>
          )}
        </div>
        <div
          style={{
            color: "#5A5A5A",
          }}
        >
          {formatDateTime(comment.date)}
        </div>
      </div>
      {comment.file && <FileViewer file={comment.file} />}
      <p>{comment.content}</p>
    </div>
  );
};
const FileViewer = ({ file }) => {
  const fileType = getFileTypeFromUrl(file);
  const fileLink = `${BASE_URL}${file}`;
  switch (fileType) {
    case "image":
      return (
        <img
          src={fileLink}
          alt="file"
          style={{
            margin: "1rem 0",
            width: "100%",
            height: "5rem",
            objectFit: "contain",
          }}
        />
      );
    case "video":
      return (
        <video
          src={fileLink}
          controls
          style={{
            maxWidth: "100%",
            maxHeight: "10rem",
            objectFit: "contain",
            margin: "1rem 0",
          }}
        />
      );
    case "audio":
      return (
        <audio
          src={fileLink}
          controls
          style={{
            maxWidth: "100%",
            maxHeight: "10rem",
            margin: "1rem 0",
          }}
        />
      );
    default:
      return (
        <p
          style={{
            padding: "0.5rem",
            border: "1px solid #ccc",
            margin: "1rem 0",
          }}
        >
          <a href={fileLink} target="_blank" rel="noreferrer">
            {file.split("/").pop()}
          </a>
        </p>
      );
  }
};
const CommentActions = ({ currentPage }) => {
  const dispatch = useDispatch();
  const currentProject = useSelector((state) => state.projects.selectedProject);
  const [comment, setComment] = useState("");
  const [attachement, setAttachement] = useState(null);
  const [sending, setSending] = useState(false);
  const fileRef = useRef(null);

  const submitComment = async () => {
    if (!comment && !attachement) return;
    const formData = new FormData();
    formData.append("content", comment);
    formData.append("type", currentPage);
    formData.append("project", currentProject);
    if (attachement) {
      formData.append("file", attachement);
    }
    try {
      setSending(true);
      await axiosInstance.post(`/comments/comments/`, formData);
      dispatch(
        getComments({ projectId: currentProject, pageType: currentPage })
      );
      setSending(false);
      setComment("");
      setAttachement(null);
      fileRef.current.value = "";
    } catch (err) {
      setSending(false);

      console.log(err);
    }
  };
  return (
    <form style={{ display: "flex", marginTop: "0.5rem" }}>
      <AttachementModal
        attachement={attachement}
        setAttachement={setAttachement}
      />

      <div style={{ flex: 1 }}>
        <CustomTextField
          color="primary"
          fullWidth
          id="outlined-basic"
          variant="outlined"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </div>

      <Button
        color="primary"
        variant="contained"
        onClick={submitComment}
        disabled={sending}
        type="submit"
        sx={{ borderRadius: 0 }}
      >
        <Iconify icon="akar-icons:send" />
      </Button>
    </form>
  );
};
const VoiceRecorder = ({ setAttachement }) => {
  const [recording, setRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [timer, setTimer] = useState(0);
  const startRecording = () => {
    const chunks = [];
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.start();
        let interval;
        setRecorder(mediaRecorder);
        mediaRecorder.onstart = () => {
          setRecording(true);
          interval = setInterval(() => {
            setTimer((timer) => timer + 1);
          }, 1000);
        };
        mediaRecorder.ondataavailable = (e) => {
          chunks.push(e.data);
          if (mediaRecorder.state === "inactive") {
            const blob = new Blob(chunks, { type: "audio/mp3" });
            const file = new File([blob], "audio.mp3", { type: blob.type });
            setAttachement(file);
            interval && clearInterval(interval);
            stream.getTracks().forEach(function (track) {
              track.stop();
            });
          }
        };
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const stopRecording = () => {
    recorder.stop();
    setRecording(false);
  };

  return recording ? (
    <Button
      color="error"
      variant="contained"
      onClick={stopRecording}
      disabled={!recording}
      sx={{
        borderRadius: 0,
        width: 200,
        height: 200,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {String(Math.floor(timer / 60)).padStart(2, "0")}:
      {String(timer % 60).padStart(2, "0")}
      <Iconify icon="fad:armrecording" sx={{ width: 70, height: 70 }} />
    </Button>
  ) : (
    <Button
      color="primary"
      variant="outlined"
      onClick={startRecording}
      sx={{ borderRadius: 0, width: 200, height: 200 }}
    >
      <Iconify icon="bytesize:microphone" sx={{ width: 70, height: 70 }} />
    </Button>
  );
};

const AttachementModal = ({ attachement, setAttachement }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const fileRef = useRef(null);
  return (
    <>
      <Button
        color={attachement ? "success" : "primary"}
        variant={attachement ? "contained" : "outlined"}
        sx={{ borderRadius: 0 }}
        id="attachement-modal"
        onClick={handleOpen}
      >
        {attachement ? (
          <Iconify icon="carbon:view" width={24} height={24} />
        ) : (
          <Iconify
            icon="material-symbols:attach-file-add-rounded"
            width={24}
            height={24}
          />
        )}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
            }}
          >
            <Iconify icon="carbon:close" width={24} height={24} />
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 3 }}
          >
            Ajouter un fichier ou un enregistrement
          </Typography>
          {attachement ? (
            <FilePreview
              attachement={attachement}
              setAttachement={setAttachement}
              fileRef={fileRef}
              handleClose={handleClose}
            />
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Box sx={{ justifyContent: "center", display: "flex" }}>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => fileRef.current.click()}
                    sx={{ borderRadius: 0, width: 200, height: 200 }}
                  >
                    <Iconify
                      icon="uiw:file-add"
                      sx={{ width: 70, height: 70 }}
                    />
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ justifyContent: "center", display: "flex" }}>
                  <VoiceRecorder
                    attachement={attachement}
                    setAttachement={setAttachement}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </Modal>

      <input
        type="file"
        onChange={(e) => setAttachement(e.target.files[0])}
        hidden
        ref={fileRef}
      />
    </>
  );
};

const FilePreview = ({ attachement, setAttachement, fileRef, handleClose }) => {
  const type = getFileTypeFromUrl(attachement.name);
  const resetAttachement = () => {
    setAttachement(null);
    fileRef.current.value = "";
  };
  const getPreview = () => {
    switch (type) {
      case "video":
        return (
          <video
            src={URL.createObjectURL(attachement)}
            controls
            style={{ width: "100%" }}
          />
        );
      case "image":
        return (
          <img
            src={URL.createObjectURL(attachement)}
            alt="preview"
            style={{ maxHeight: "20vw", objectFit: "contain" }}
          />
        );
      case "audio":
        return (
          <audio
            src={URL.createObjectURL(attachement)}
            controls
            style={{ width: "100%" }}
          />
        );
      default:
        return <h1>{attachement.name}</h1>;
    }
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {getPreview()}
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          size="large"
          color="error"
          variant="contained"
          sx={{ borderRadius: 0, mt: 2 }}
          onClick={resetAttachement}
        >
          <Iconify icon="akar-icons:trash-can" sx={{ height: 24, width: 24 }} />
        </Button>
        <Button
          size="large"
          color="primary"
          variant="contained"
          sx={{ borderRadius: 0, mt: 2 }}
          onClick={handleClose}
        >
          <Iconify icon="line-md:confirm" sx={{ height: 24, width: 24 }} />
        </Button>
      </Box>
    </div>
  );
};

//styles
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const CustomTextField = styled(TextField)(() => ({
  "& fieldset": {
    borderRadius: 0,
  },
}));

//utils
const getFileTypeFromUrl = (url) => {
  const ext = url.split(".").pop();
  if (["jpg", "jpeg", "png", "gif"].includes(ext)) {
    return "image";
  } else if (["mp4", "avi", "mov"].includes(ext)) {
    return "video";
  } else if (["mp3", "wav"].includes(ext)) {
    return "audio";
  } else {
    return "file";
  }
};

const formatDateTime = (date) => {
  const d = new Date(date);
  return format(d, "'Le' dd/MM/yyyy 'à' HH:mm");
};

const openedMixin = (theme) => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0,
});
const TestDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    width: 0,
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
