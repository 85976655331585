import axiosInstance from "src/libs/axios";
import { getCAData } from "src/utils/bp/CA";
import { getDCData } from "src/utils/bp/DC";
import { getEcheancierData } from "src/utils/bp/Echeancier";
import { getRMData } from "src/utils/bp/RM";
import { getCEP } from "src/utils/constants";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
  OPM: {
    productArray: [],
    ACP:[],
    description: "",
  },
  RH: {
    commercial: [],
    marketing: [],
    production: [],
    rnd: [],
  },
  RM: {
    commercial: [],
    marketing: [],
    production: [],
    local: [],
    rnd: [],
    other: [],
  },
  DC: {
    "d-commercial": [],
    "d-marketing": [],
    "d-production": [],
    "d-rnd": [],
    other: [],
    rest: [],
    
  },
  financement: [],
  investissements: [],
  amortissements: [],
  rentabilite: {
    cep: [],
    van: 0,
    tri: 0,
  },
  Echeancier: [],
  textDataArray: [],
  currentStep: "CA",
  savingStatus: "done",
  loading: true,
  first: true,
};

export const getBP = createAsyncThunk(
  "bp/getBP",
  async (pk, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/business/${pk}/`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateBP = createAsyncThunk(
  "bp/updateBP",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.put(
        `/business/${data.projectId}/`,
        data.payload
      );
      await dispatch(getBP(data.projectId)).unwrap();
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateBPWithoutGet = createAsyncThunk(
  "bp/updateBPWithoutGet",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/business/${data.projectId}/`,
        data.payload
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
const bpSlice = createSlice({
  name: "bp",
  initialState,
  extraReducers: {
    [getBP.pending]: (state) => {
    
        state.loading = true;
       
      
    },
    [getBP.fulfilled]: (state, action) => {
      state.OPM = getCAData(action.payload);
      state.Echeancier = getEcheancierData(action.payload);
      state.financement =
        action.payload["Investissements et financement"][
          "Schema de financement"
        ];
      state.investissements =
        action.payload["Investissements et financement"][
          "Schema dinvestissement"
        ];
      state.amortissements =
        action.payload["Tableau damortissement"][
          "Dotations aux amortissements"
        ];

      state.rentabilite.cep =getCEP( action.payload["Etude de rentabilite"][
        "Compte dexploitation previsionnel"
      ])
       
      state.rentabilite.van = action.payload["Etude de rentabilite"][
        "Indicateurs de rentabilite"
      ].find((item) => item.name === "VAN");
      state.rentabilite.tri = action.payload["Etude de rentabilite"][
        "Indicateurs de rentabilite"
      ].find((item) => item.name === "TRI");
      state.textDataArray = action.payload["extras"]["text"];
      state.RH.rnd = action.payload["Charges de personnel"]["Salaires"]
        .filter((data) => data.type === "rnd")
        .map((data, idx) => ({
          ...data,
          id: "rnd" + idx,
        }));
      state.RH.commercial = action.payload["Charges de personnel"]["Salaires"]
        .filter((data) => data.type === "commercial")
        .map((data, idx) => ({
          ...data,
          id: "c" + idx,
        }));
      state.RH.marketing = action.payload["Charges de personnel"]["Salaires"]
        .filter((data) => data.type === "marketing")
        .map((data, idx) => ({
          ...data,
          id: "m" + idx,
        }));
      state.RH.production = action.payload["Charges de personnel"]["Salaires"]
        .filter((data) => data.type === "production")
        .map((data, idx) => ({
          ...data,
          id: "p" + idx,
        }));
      state.RM = getRMData(action.payload["Investissements"]);
      state.DC = getDCData(
        action.payload["Autres charges dexploitation"]["Autres charges"]
      );
      state.loading = false;
    },
    [updateBPWithoutGet.pending]: (state) => {
      state.savingStatus = "saving";
    },
    [updateBPWithoutGet.fulfilled]: (state) => {
      state.savingStatus = "done";
    },
    [updateBPWithoutGet.rejected]: (state) => {
      state.savingStatus = "error";
    },
  },
  reducers: {
    setStepBP(state, action) {
      state.currentStep = action.payload;
    },
    setSavingStatusBP: (state, action) => {
      state.savingStatus = action.payload;
    },
    
  },
});
export default bpSlice.reducer;
export const { setStepBP,setSavingStatusBP } = bpSlice.actions;
