import PropTypes from "prop-types";
import { set, sub } from "date-fns";
import { noCase } from "change-case";
import { faker } from "@faker-js/faker";
import { useState, useRef, useEffect } from "react";
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from "@mui/material";
// utils
import { fToNow } from "../../utils/formatTime";
// components
import Iconify from "../../components/Iconify";
import Scrollbar from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";
import { useDispatch, useSelector } from "react-redux";
import {
  getMentorshiprequests,
  handleMentorRequest,
} from "src/stores/mentorship/mentorshipSlice";
import { BASE_URL } from "src/utils/constants";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

const NOTIFICATIONS = [
  {
    id: faker.datatype.uuid(),
    title: "Your order is placed",
    description: "waiting for shipping",
    avatar: null,
    type: "order_placed",
    createdAt: set(new Date(), { hours: 10, minutes: 30 }),
    isUnRead: true,
  },
  {
    id: faker.datatype.uuid(),
    title: faker.name.findName(),
    description: "answered to your comment on the Minimal",
    avatar: "/static/mock-images/avatars/avatar_2.jpg",
    type: "friend_interactive",
    createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
    isUnRead: true,
  },
  {
    id: faker.datatype.uuid(),
    title: "You have new message",
    description: "5 unread messages",
    avatar: null,
    type: "chat_message",
    createdAt: sub(new Date(), { days: 1, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
  {
    id: faker.datatype.uuid(),
    title: "You have new mail",
    description: "sent from Guido Padberg",
    avatar: null,
    type: "mail",
    createdAt: sub(new Date(), { days: 2, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
  {
    id: faker.datatype.uuid(),
    title: "Delivery processing",
    description: "Your order is being shipped",
    avatar: null,
    type: "order_shipped",
    createdAt: sub(new Date(), { days: 3, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
];

export default function NotificationsPopover() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mentorshipRequests } = useSelector((state) => state.mentorship);
  useEffect(() => {
    dispatch(getMentorshiprequests());
  }, [dispatch]);
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{ width: 46, height: 46 }}
      >
        <Badge badgeContent={mentorshipRequests.length} color="error">
          <Iconify icon="fa6-regular:handshake" width={28} height={28} />
        </Badge>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Demandes de mentoring</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Vous avez {mentorshipRequests.length} demande(s) de mentoring
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
          <List disablePadding>
            {mentorshipRequests.slice(0, 3).map((request) => (
              <NotificationItem key={request.pk} notification={request} />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple onClick={() => navigate("/mentor")}>
            Voire toutes les demandes
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification }) {
  const dispatch = useDispatch();
  const handleRequest = (value) => {
    dispatch(
      handleMentorRequest({
        pk: value.pk,
        action: value.action,
      })
    );
  };
  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
      }}
    >
      <ListItemAvatar>
        <Avatar
          sx={{ bgcolor: "background.neutral", width: 56, height: 56, mr: 2 }}
        >
          <img
            alt={notification.project_name}
            src={
              notification.project_picture.includes(BASE_URL)
                ? notification.project_picture
                : BASE_URL + notification.project_picture
            }
          />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Box sx={{ ml: 1 }}>
            <span style={{ fontWeight: "bold" }}>Projet: </span>
            {notification.project_name}
            <br />
            <span style={{ fontWeight: "bold" }}>Porteur de projet: </span>
            {notification.project_owner.email}
          </Box>
        }
        secondary={
          <Box sx={{ mt: 1 }}>
            <Button
              color="error"
              size="small"
              onClick={() =>
                handleRequest({
                  pk: notification.pk,
                  action: "reject",
                })
              }
            >
              Refuser
            </Button>

            <Button
              color="primary"
              size="small"
              sx={{ ml: 1 }}
              onClick={() =>
                handleRequest({
                  pk: notification.pk,
                  action: "accept",
                })
              }
            >
              Accepter
            </Button>
          </Box>
        }
      />
    </ListItemButton>
  );
}
