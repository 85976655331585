import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useIsMentor from "src/hooks/useIsMentor";
import { updateProject } from "src/stores/projects/projectsSlice";

function Step7() {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects.projects);
  const projectId = useSelector((state) => state.projects.selectedProject);
  const currentProject = projects.find((project) => project.pk === projectId);
  const {isMentor,isSupervisor} = useIsMentor();

  const handleChange = (event) => {
    dispatch(
      updateProject({ ...currentProject, finance_mode: event.target.value })
    );
  };
  return (
    <FormControl sx={{ minWidth: "300px", mt: 3 }}>
      <InputLabel htmlFor="Financement ">Mode de financement</InputLabel>
      <Select
        readOnly={isMentor||isSupervisor}
        labelId="financement-label"
        id="Financement "
        value={currentProject ? currentProject.finance_mode : ""}
        label="Mode de financement"
        onChange={handleChange}
      >
        <MenuItem value="Crédit">Crédit </MenuItem>
        <MenuItem value="Auto-financement">Auto-financement</MenuItem>
      </Select>
    </FormControl>
  );
}

export default Step7;
