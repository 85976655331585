const { createSlice } = require("@reduxjs/toolkit");

const NavConfigSlice = createSlice({
    name: 'navConfig',
    initialState: {
        isRightNavOpen: false,
        isLeftNavOpen: false,
    },
    reducers: {
        toggleRightNav: (state,action) => {
            if(action.payload){
                state.isLeftNavOpen=false
            }
            state.isRightNavOpen = action.payload;
        },
        toggleLeftNav: (state,action) => {
            if(action.payload){
                state.isRightNavOpen=false
            }
            state.isLeftNavOpen = action.payload;
        }
    }
});
            

export const { toggleRightNav, toggleLeftNav } = NavConfigSlice.actions;
export default NavConfigSlice.reducer;