import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
  useNavigate,
} from "react-router-dom";
// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
//
import Iconify from "./Iconify";
import useResponsive from "src/hooks/useResponsive";
import { useSelector } from "react-redux";
import useIsMentor from "src/hooks/useIsMentor";
import { Fragment } from "react";

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  fontSize: "0.7em",
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  marginBottom: "0.5em",
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 18,
  height: 18,
  color: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const ListItemIconStyleCollapsed = styled(ListItemIcon)({
  width: "100%",
  height: 18,
  minWidth: 18,
  color: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active, isCollapsed: isCollapsedProp,setOpen,open }) {
  const navigate = useNavigate();
  const currentStep=useLocationToStep();
  const { isSupervisor, isOwner } = useIsMentor();
  const projects = useSelector((state) => state.projects.projects);
  const projectId = useSelector((state) => state.projects.selectedProject);
  const currentProject = projects.find((project) => project.pk === projectId);

  const theme = useTheme();
  const isDesktop = useResponsive("up", "lg");
  const isCollapsed = isCollapsedProp && isDesktop;
  const isActiveRoot = active(item.path);

  const { title, path, icon, info, children, steps } = item;

  // const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    open?setOpen(false):setOpen(path);
  };

  const activeRootStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
  };

  // const activeSubStyle = {
  //   color: "text.primary",
  //   fontWeight: "fontWeightMedium",
  // };
  if ((isOwner || (isSupervisor && title !== "Fiche projet")) && steps) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          title={title}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          {!isCollapsed && (
            <ListItemIconStyle title={title}>{icon && icon}</ListItemIconStyle>
          )}
          {isCollapsed && (
            <ListItemIconStyleCollapsed title={title}>
              {icon && icon}
            </ListItemIconStyleCollapsed>
          )}

          {!isCollapsed && (
            <>
              {" "}
              <ListItemText
                disableTypography
                primary={title}
                sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
              />
              {info && info}
            </>
          )}
          {!isCollapsed && (
            <Iconify
              icon={
                open
                  ? "eva:arrow-ios-downward-fill"
                  : "eva:arrow-ios-forward-fill"
              }
              sx={{ width: 16, height: 16, mr: 1 }}
            />
          )}
          {isCollapsed && (
            <Iconify
              icon={
                open
                  ? "eva:arrow-ios-upward-fill"
                  : "eva:arrow-ios-downward-fill"
              }
              sx={{
                width: 16,
                height: 16,
                position: "absolute",
                bottom: 0,
                right: "50%",
                transform: "translateX(50%)",
              }}
            />
          )}
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {steps
              .filter(
                (step) =>
                  !(
                    (step.title === "Equipe fondatrice"||step.title==="Investisseurs") &&
                    (["SUARL", "SI"].includes(currentProject?.legal_form)||!currentProject?.legal_form)
                  )
              )
              .map((step, index) => {
                const { title } = step;
                // const isActiveSub = active(path);
                const isActiveSub = active(path)&&currentStep===step.value;

                return (
                  <ListItemStyle
                    title={title}
                    key={title}
                    // component={RouterLink}
                    // to={path}
                    onClick={() => {
                      navigate(path, {
                        state: {
                          step: step.value,
                        },
                      });
                    }}
                    sx={{
                      fontSize: "0.65em",
                      ...(isActiveSub && activeRootStyle),
                    }}
                  >
                    {!isCollapsed && (
                      <ListItemIconStyle sx={{ pl: 2 }}>
                        {/* {icon && icon} */}
                        {index + 1} -
                      </ListItemIconStyle>
                    )}
                    {isCollapsed && (
                      <ListItemIconStyleCollapsed>
                        {/* {icon && icon} */}
                        {index + 1}
                      </ListItemIconStyleCollapsed>
                    )}
                    {!isCollapsed && (
                      <ListItemText
                        disableTypography
                        primary={title}
                        sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                      />
                    )}
                  </ListItemStyle>
                );
              })}
          </List>
        </Collapse>
      </>
    );
  }
  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          {!isCollapsed && (
            <ListItemIconStyle title={title}>{icon && icon}</ListItemIconStyle>
          )}
          {isCollapsed && (
            <ListItemIconStyleCollapsed title={title}>
              {icon && icon}
            </ListItemIconStyleCollapsed>
          )}

          {!isCollapsed && (
            <>
              {" "}
              <ListItemText disableTypography primary={title} />
              {info && info}
            </>
          )}
          {!isCollapsed && (
            <Iconify
              icon={
                open
                  ? "eva:arrow-ios-downward-fill"
                  : "eva:arrow-ios-forward-fill"
              }
              sx={{ width: 16, height: 16, mr: 1 }}
            />
          )}
          {isCollapsed && (
            <Iconify
              icon={
                open
                  ? "eva:arrow-ios-upward-fill"
                  : "eva:arrow-ios-downward-fill"
              }
              sx={{
                width: 16,
                height: 16,
                position: "absolute",
                bottom: 0,
                right: "50%",
                transform: "translateX(50%)",
              }}
            />
          )}
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path, icon } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    fontSize: "0.7em",
                    ...(isActiveSub && activeRootStyle),
                  }}
                >
                  {!isCollapsed && (
                    <ListItemIconStyle title={title}>
                      {icon && icon}
                    </ListItemIconStyle>
                  )}
                  {isCollapsed && (
                    <ListItemIconStyleCollapsed title={title}>
                      {icon && icon}
                    </ListItemIconStyleCollapsed>
                  )}
                  {!isCollapsed && (
                    <ListItemText disableTypography primary={title} />
                  )}
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      onClick={handleOpen}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      {!isCollapsed && (
        <ListItemIconStyle title={title}>{icon && icon}</ListItemIconStyle>
      )}
      {isCollapsed && (
        <ListItemIconStyleCollapsed title={title}>
          {icon && icon}
        </ListItemIconStyleCollapsed>
      )}
      {!isCollapsed && (
        <>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ isCollapsed, navConfig, ...other }) {
  const { pathname } = useLocation();
  const { isMentor, isSupervisor } = useIsMentor();
  const [open, setOpen] = useState(false);
  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  return (
    <Box {...other} sx={{ height: "100%" }}>
      <List
        disablePadding
        sx={{ p: 1, height: "100%", display: "flex", flexDirection: "column" }}
      >
        {navConfig.map((item) =>
          item.isNotMentor && (isMentor || isSupervisor) ? (
            <Fragment key={item.title}></Fragment>
          ) : item.isNotMentor ? (
            <Fragment key={item.title}>
              <Box sx={{ flexGrow: 1 }} />
              <div>
                <NavItem
                  setOpen={setOpen}
                  open={open===item.path}
                  key={item.title}
                  item={item}
                  active={match}
                  isCollapsed={isCollapsed}
                />
              </div>
            </Fragment>
          ) : (
            <div key={item.title}>
              <NavItem item={item} active={match} isCollapsed={isCollapsed} 
                setOpen={setOpen}
                open={open===item.path}
                />
            </div>
          )
        )}
      </List>
    </Box>
  );
}

const useLocationToStep = () => {
  const ficheProjetStep = useSelector(
    (state) => state.projects.ficheProjetStep
  );
  const projectPresentationStep = useSelector(
    (state) => state.projectPresentation.currentStep
  );
  const secteurCurrentStep = useSelector((state) => state.secteur.currentStep);
  const bpCurrentStep = useSelector((state) => state.bp.currentStep);
  const { pathname } = useLocation();
  const [currentStep, setCurrentStep] = useState(null);
  useEffect(() => {
    switch (pathname) {
      case "/fiche-projet":
        setCurrentStep(ficheProjetStep);
        break;
      case "/description-projet":
        setCurrentStep(projectPresentationStep);
        break;
      case "/analyse-strategique-du-marche":
        setCurrentStep(secteurCurrentStep);
        break;
      case "/strategie-de-commercialisation":
      case "/plan-de-production-et-d-organisation":
      case "/recherche-et-developpement":
        setCurrentStep(bpCurrentStep);
        break;
      default:
        setCurrentStep(null);
        break;
    }
  }, [
    bpCurrentStep,
    ficheProjetStep,
    pathname,
    projectPresentationStep,
    secteurCurrentStep,
  ]);

  return currentStep;
};
