import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useIsMentor from "src/hooks/useIsMentor";
import { updateProject } from "src/stores/projects/projectsSlice";

function Step6() {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects.projects);
  const projectId = useSelector((state) => state.projects.selectedProject);
  const currentProject = projects.find((project) => project.pk === projectId);
  const {isMentor,isSupervisor}=useIsMentor()

  const handleChange = (event) => {
    dispatch(
      updateProject({
        ...currentProject,
        project_study_duration: event.target.value,
      })
    );
  };
  return (
    <FormControl sx={{ minWidth: "300px", mt: 3 }}>
      <InputLabel htmlFor="DE">Durée de l'étude</InputLabel>
      <Select
        readOnly={isMentor||isSupervisor}
        labelId="DE-label"
        id="DE"
        value={currentProject ? currentProject.project_study_duration : ""}
        label="Durée de l'étude"
        onChange={handleChange}
      >
        <MenuItem value={3}>3 ans</MenuItem>
        <MenuItem value={5}>5 ans</MenuItem>
        <MenuItem value={7}>7 ans</MenuItem>
      </Select>
    </FormControl>
  );
}

export default Step6;
