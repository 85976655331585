import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";

import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createProject,
  getMyProjects,
  selectProject,
} from "src/stores/projects/projectsSlice";
import { useNavigate } from "react-router-dom";
import Iconify from "../Iconify";
import projectImg from "./project.jpg";
import axiosInstance from "src/libs/axios";

function CreateProjectModal({ open, setcreateModalOpen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [img, setImg] = useState(null);
  const [blob, setBlob] = useState(null);

  const [isSubmitting, setisSubmitting] = useState(false);

  const ProjectSchema = Yup.object().shape({
    name: Yup.string().required("Ce champs est obligatoire"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: ProjectSchema,
    onSubmit: async (values) => {
      setisSubmitting(true);
      try {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("picture", img);
        // const rs = await dispatch(createProject(formData)).unwrap();
        const rs = await axiosInstance.post("/projects/", formData, {
          responseType: "arraybuffer",
        });
        const url = window.URL.createObjectURL(
          new Blob([rs.data], { type: "application/pdf" })
        );
        setBlob(url);
      } catch (error) {}
      setisSubmitting(false);
    },
  });
  const { handleSubmit, getFieldProps, touched, errors } = formik;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
  };
  const styleNDA = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70vw",
  };
  const handleClose = () => {
    dispatch(getMyProjects());
    setBlob(null);
    setcreateModalOpen(false);
    formik.resetForm();
    setisSubmitting(false);
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        if (!blob) {
          handleClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={blob ? styleNDA : style}>
        {blob ? (
          <Card>
            <CardContent>

              
              <iframe
                src={blob}
                title="nda"
                style={{
                  height: "80vh",
                  width: "100%",
                  outline: "none",
                  border: "none",
                }}
              />
              <Box sx={{ display: "flex", justifyContent: "space-between",alignItems:"center" }}>
              <p>
                <b>
                Un email contenant le NDA vous a été envoyé
                </b>
              </p>
                <LoadingButton
                  sx={{ mt: 3 }}
                  onClick={handleClose}
                  variant="contained"
                 
                >
                  Marquer comme lu
                </LoadingButton>
              </Box>
            </CardContent>
          </Card>
        ) : (
          <Card>
            <CardContent>
              <FormikProvider value={formik}>
                <Typography variant="h6">Nouveau projet</Typography>
                <Divider sx={{ mb: 5, mt: 1 }} />
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    autoComplete="project-name"
                    type="text"
                    label="Dénomination social du projet"
                    {...getFieldProps("name")}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <ImageUploader setImg={setImg} />

                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    
                    <LoadingButton
                      sx={{ mt: 3 }}
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      Ajouter
                    </LoadingButton>
                  </Box>
                </Form>
              </FormikProvider>
            </CardContent>
          </Card>
        )}
      </Box>
    </Modal>
  );
}

export default CreateProjectModal;
const ImageUploader = ({ setImg }) => {
  const [previewImage, setPreviewImage] = useState(null);

  const avatarSrc = previewImage;

  const getDefaultFile = (e) => {
    fetch(e)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], e, { type: blob.type });
        setImg(file);
        setPreviewImage(e);
      });
  };
  const previewUploadedImage = (e) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      setPreviewImage(event.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
    setImg(e.target.files[0]);
  };
  useEffect(() => {
    getDefaultFile(projectImg);
  }, []);
  return (
    <Box
      className="funder-avatar-container"
      sx={{
        position: "relative",
        my: 5,
        width: "100%",
        backgroundColor: "rgba(0,0,0,0.1)",
      }}
    >
      <IconButton
        className="funder-avatar-btn"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          opacity: 0,
          zIndex: 1,
          width: "100%",
          height: "100%",
          borderRadius: "0",
        }}
        color="primary"
        aria-label="upload picture"
        component="label"
      >
        <input
          hidden
          accept="image/*"
          type="file"
          onChange={previewUploadedImage}
        />
        <Iconify icon="ic:outline-add-photo-alternate" />
      </IconButton>
      <img
        className="funder-avatar"
        src={avatarSrc}
        alt="your profile"
        style={{
          width: "100%",
          height: "200px",
          objectFit: "contain",
          mx: "auto",
          borderRadius: "0",
        }}
      />
    </Box>
  );
};
