import {
    ComposableMap,
    Geographies,
    Geography,
    Marker,
  } from "react-simple-maps";
import { GOV_COORDs } from "src/utils/constants";
  
  const MapOfTunisia = ({
    countByRegion
  }) => {
    const geoUrl ="/TUN-ADM1.topojson"
    const markers = countByRegion.map((region) => {
      return {
        name: region.count,
        coordinates: GOV_COORDs[region.governorate],
      };
    })
  
    return (
      <ComposableMap
        style={{ height: "100%",pointerEvents:"none" }}
        projection="geoAzimuthalEqualArea"
        projectionConfig={{
          rotate: [-15, -33, 0],
          scale: 3500,
        }}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill="#bbdaa4"
                stroke="#829872"
              />
            ))
          }
        </Geographies>
        {markers.map(({ name, coordinates,}) => (
          <Marker key={name} coordinates={coordinates}>
            <text
              textAnchor="middle"
              
              style={{
                fontFamily: "sans-serif",
                fill: "#900C3F",
                fontWeight:"bolder",
                fontSize: "8px",
              }}
            >
              {name}
            </text>
          </Marker>
        ))}

      </ComposableMap>
    );
  };
  
  export default MapOfTunisia;
  