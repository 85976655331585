import {
    Box,
    Button,
    Grid,
    Step,
    StepLabel,
    Stepper,
    Typography,
  } from "@mui/material";
  import { useEffect } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import Page from "src/components/Page";
  import { getBP, setStepBP } from "src/stores/bp/bpSlice";
  import { RND_STEPS } from "src/utils/constants";
//   import decriptions from "src/utils/descriptions.json";
//   import Description from "src/components/common/Description";
  import TextBP from "src/components/startDeCommercialisation/TextBP";
  import RH from "src/components/startDeCommercialisation/RH";
  import RM from "src/components/startDeCommercialisation/RM";
  import { useLocation, useNavigate } from "react-router-dom";
  import SavingStatus from "src/components/SavingStatus";
  import CommentWrapper from "src/components/comments/CommentWrapper";
  import Iconify from "src/components/Iconify";
  import DC from "src/components/startDeCommercialisation/DC";
  
  export default function RND() {
    const dispatch = useDispatch();
    const navigate= useNavigate();
    const location = useLocation();
    const { currentStep, savingStatus } = useSelector((state) => state.bp);
    const { status } = useSelector((state) => state.projects);
    const selectedProject = useSelector(
      (state) => state.projects.selectedProject
    );
  
    const activeStep =
      currentStep === -1
        ? RND_STEPS.length
        : RND_STEPS.findIndex((step) => step.value === currentStep);
  
    const setActiveStep = (step) => {
      if (step === RND_STEPS.length) dispatch(setStepBP(-1));
      else dispatch(setStepBP(RND_STEPS[step].value));
    };
    const handleNext = async () => {
      setActiveStep(activeStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep(activeStep - 1);
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };
  
    useEffect(() => {
      if (selectedProject !== -1) {
        dispatch(getBP(selectedProject));
        if (location.state?.step) {
          dispatch(setStepBP(location.state.step));
        } else {
          dispatch(setStepBP(RND_STEPS[0].value));
        }
      }
    }, [dispatch, selectedProject, location]);
  
    useEffect(() => {
      if (location.state?.step) {
        dispatch(setStepBP(location.state.step));
      } else {
        dispatch(setStepBP(RND_STEPS[0].value));
      }
    }, [dispatch, location]);
  
    useEffect(() => {
      if (selectedProject !== -1) {
        dispatch(getBP(selectedProject));
      }
    }, [dispatch, selectedProject, currentStep]);
    return (
      <Page
        title="Recherche et développement"
        style={{
          border: "1px solid #ECECEC",
          borderRadius: "10px",
          padding: " 1rem ",
          height: "98%",
        }}
      >
        <Grid container spacing={1} sx={{ height: "100%" }}>
          <Grid
            item
            md={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <SavingStatus savingStatus={savingStatus}>
              <CommentWrapper currentPage={`rnd_${currentStep}`} />
            </SavingStatus>
  
            <Box sx={{ pt: 1, px: 3 }}>
              <Stepper activeStep={activeStep}>
                {RND_STEPS.map((label, index) => {
                  return (
                    <Step key={index}>
                       <StepLabel style={{ cursor: "pointer" }} onClick={() => setActiveStep(index)} title={label.title}/>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
            {activeStep === RND_STEPS.length ? (
              <Box sx={{ p: 3 }}>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </Box>
            ) : (
              <>
                <Typography sx={{ my: 1, px: 3, fontSize: "0.8em" }}>
                  <strong style={{ color: "#2196F3" }}>
                    Etape {activeStep + 1}
                  </strong>
                  {" " + RND_STEPS[activeStep]?.title}
                </Typography>
                {/* <Description text={decriptions.PO[currentStep]} /> */}
  
                {status === "success" && (
                  <Box sx={{ flex: 1, px: 3 }}>
                    <ComponentSwitcher currentStep={currentStep} />
                  </Box>
                )}
  
                <Box sx={{ display: "flex", flexDirection: "row", pt: 1 }}>
                  <Button
                    size="small"
                    color="inherit"
                    variant="outlined"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1, borderRadius: "0" }}
                  >
                    Précédent
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  {activeStep === RND_STEPS.length - 1 && <Button
                    onClick={() => navigate("/etude-de-faisabilite")}
                    size="small"
                    variant="contained"
                    sx={{ borderRadius: "0" ,textTransform: "none"}}
                  >
                    Passer à l'étape suivante
                    <Iconify icon="ant-design:caret-right-filled" />
                  </Button>}
                  {activeStep !== RND_STEPS.length - 1 && (
                    <>
                      {" "}
                      {/* <Button
                        variant="outlined"
                        color="inherit"
                        onClick={handleNext}
                        sx={{ mr: 1, borderRadius: "0" }}
                        size="small"
                      >
                        Ignorer
                      </Button> */}
                      <Button
                        onClick={handleNext}
                        size="small"
                        variant="outlined"
                        sx={{ borderRadius: "0" }}
                      >
                        {activeStep === RND_STEPS.length - 1
                          ? "Terminer"
                          : "Suivant"}
                      </Button>
                    </>
                  )}
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Page>
    );
  }
  
  const ComponentSwitcher = ({ currentStep }) => {
    if (isRHComponent(currentStep)) return <RH currentStep={currentStep} />;
    if (isRMComponent(currentStep)) return <RM currentStep={currentStep} />;
    if (isDCComponent(currentStep)) return <DC currentStep={currentStep} />;
  
    return <TextBP currentStep={currentStep} />;
  };
   
  const isRHComponent = (currentStep) => {
    return currentStep === "Ressource Humaine affectées au département recherche et développement";
  };
  const isDCComponent = (currentStep) => {
    return currentStep === "Dépenses courantes communes du département recherche et développement";
  };
  const isRMComponent = (currentStep) => {
    return currentStep === "Ressource Matériel communes affectées au département recherche et développement";
  };
  
