export const getCAData = (bp) => {
  return {
    ACP: bp["Achats consommes HT"]["Achats consommes"],
    productArray: bp["Chiffre daffaires HT"]["Chiffre daffaires"],
    description:
      bp["extras"]["text"].find((text) => text.type === "OPM")?.value || "",
  };
};

export const updateProductArrayObject = (data) => {
  return {
    sheet: "Chiffre daffaires HT",
    table: "Chiffre daffaires",
    data: data.map((product) => {
      return {
        pk: product.pk ?? null,
        name: product.name,
        quantity_1: parseFloat(product["quantity_" + product.start_year]),
        unit_price_1: parseFloat(product["unit_price_" + product.start_year]),
        price_evolution_2: parseFloat(product.price_evolution_2),
        quantity_evolution_2: parseFloat(product.quantity_evolution_2),
        price_evolution_3: parseFloat(product.price_evolution_3),
        quantity_evolution_3: parseFloat(product.quantity_evolution_3),
        price_evolution_4: parseFloat(product.price_evolution_4),
        quantity_evolution_4: parseFloat(product.quantity_evolution_4),
        price_evolution_5: parseFloat(product.price_evolution_5),
        quantity_evolution_5: parseFloat(product.quantity_evolution_5),
        price_evolution_6: parseFloat(product.price_evolution_6),
        quantity_evolution_6: parseFloat(product.quantity_evolution_6),
        price_evolution_7: parseFloat(product.price_evolution_7),
        quantity_evolution_7: parseFloat(product.quantity_evolution_7),
        start_year: product.start_year,
        end_year: product.end_year,
        is_editable: true,
      };
    }),
  };
};
export const updateSubProductArrayObject = (data) => {
  //data=[{ 'name','quantity_1','unit_price_1','price_evolution_2','quantity_evolution_2'...}]
  return {
    sheet: "Achats consommes HT",
    table: "Achats consommes",
    data: data.map((product) => {
      return {
        pk: product.pk ?? null,
        name: product.name,
        parent: product.parent,
        quantity_1: parseFloat(product["quantity_" + product.start_year]),
        unit_price_1: parseFloat(product["unit_price_" + product.start_year]),
        price_evolution_2: parseFloat(product.price_evolution_2),
        quantity_evolution_2: parseFloat(product.quantity_evolution_2),
        price_evolution_3: parseFloat(product.price_evolution_3),
        quantity_evolution_3: parseFloat(product.quantity_evolution_3),
        price_evolution_4: parseFloat(product.price_evolution_4),
        quantity_evolution_4: parseFloat(product.quantity_evolution_4),
        price_evolution_5: parseFloat(product.price_evolution_5),
        quantity_evolution_5: parseFloat(product.quantity_evolution_5),
        price_evolution_6: parseFloat(product.price_evolution_6),
        quantity_evolution_6: parseFloat(product.quantity_evolution_6),
        price_evolution_7: parseFloat(product.price_evolution_7),
        quantity_evolution_7: parseFloat(product.quantity_evolution_7),
        start_year: product.start_year,
        end_year: product.end_year,
        is_editable: true,
      };
    }),
  };
};

export const textDataObject = (oldData, text, type) => {
  const filteredData = oldData
    .map((data) => ({
      type: data.type,
      name: data.name,
      value: data.value,
      is_editable: true,
      pk: data.pk,
    }))
    .filter((data) => data.type !== type);

  return {
    sheet: "extras",
    table: "text",
    data: [
      ...filteredData,
      { type, value: text, is_editable: true, name: type },
    ],
  };
};

export const updateRHArrayObject = (oldData, data) => {
  const dataWithNeededAttrs = {
    pk: data.pk,
    name: data.name,
    type: data.type,
    contract_type: data.contract_type,
    number_months_1: data.number_months_1,
    number_1: data.number_1,
    gross_salary_1: data.gross_salary_1,
    number_months_2: data.number_months_2,
    number_2: data.number_2,
    gross_salary_2: data.gross_salary_2,
    number_months_3: data.number_months_3,
    number_3: data.number_3,
    gross_salary_3: data.gross_salary_3,
    number_months_4: data.number_months_4,
    number_4: data.number_4,
    gross_salary_4: data.gross_salary_4,
    number_months_5: data.number_months_5,
    number_5: data.number_5,
    gross_salary_5: data.gross_salary_5,
    number_months_6: data.number_months_6,
    number_6: data.number_6,
    gross_salary_6: data.gross_salary_6,
    number_months_7: data.number_months_7,
    number_7: data.number_7,
    gross_salary_7: data.gross_salary_7,
    is_editable: true,
  };
  const cleanedData = oldData
    .filter((item) => item.pk !== data.pk)
    .map((data) => ({
      pk: data.pk,
      name: data.name,
      type: data.type,
      contract_type: data.contract_type,
      number_months_1: data.number_months_1,
      number_1: data.number_1,
      gross_salary_1: data.gross_salary_1,
      number_months_2: data.number_months_2,
      number_2: data.number_2,
      gross_salary_2: data.gross_salary_2,
      number_months_3: data.number_months_3,
      number_3: data.number_3,
      gross_salary_3: data.gross_salary_3,
      number_months_4: data.number_months_4,
      number_4: data.number_4,
      gross_salary_4: data.gross_salary_4,
      number_months_5: data.number_months_5,
      number_5: data.number_5,
      gross_salary_5: data.gross_salary_5,
      number_months_6: data.number_months_6,
      number_6: data.number_6,
      gross_salary_6: data.gross_salary_6,
      number_months_7: data.number_months_7,
      number_7: data.number_7,
      gross_salary_7: data.gross_salary_7,
      is_editable: true,
    }));
  return {
    table: "Salaires",
    sheet: "Charges de personnel",
    data: [...cleanedData, dataWithNeededAttrs],
  };
};
export const deleteRHArrayObject = (oldData, pk) => {
  const cleanedData = oldData
    .filter((item) => item.pk !== pk)
    .map((data) => ({
      pk: data.pk,
      name: data.name,
      type: data.type,
      contract_type: data.contract_type,
      number_months_1: data.number_months_1,
      number_1: data.number_1,
      gross_salary_1: data.gross_salary_1,
      number_months_2: data.number_months_2,
      number_2: data.number_2,
      gross_salary_2: data.gross_salary_2,
      number_months_3: data.number_months_3,
      number_3: data.number_3,
      gross_salary_3: data.gross_salary_3,
      number_months_4: data.number_months_4,
      number_4: data.number_4,
      gross_salary_4: data.gross_salary_4,
      number_months_5: data.number_months_5,
      number_5: data.number_5,
      gross_salary_5: data.gross_salary_5,
      number_months_6: data.number_months_6,
      number_6: data.number_6,
      gross_salary_6: data.gross_salary_6,
      number_months_7: data.number_months_7,
      number_7: data.number_7,
      gross_salary_7: data.gross_salary_7,
      is_editable: true,
    }));
  return {
    table: "Salaires",
    sheet: "Charges de personnel",
    data: [...cleanedData],
  };
};

export const bpRecalc = () => {
  return {
    sheet: "useless",
    table: "none",
    data: [],
  };
};
