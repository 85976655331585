import {
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Page from "src/components/Page";
import CA from "src/components/startDeCommercialisation/CA";
import { getBP, setStepBP } from "src/stores/bp/bpSlice";
import { SDC_STEPS } from "src/utils/constants";
import decriptions from "src/utils/descriptions.json";
import Description from "src/components/common/Description";
import TextBP from "src/components/startDeCommercialisation/TextBP";
import RH from "src/components/startDeCommercialisation/RH";
import RM from "src/components/startDeCommercialisation/RM";
import DC from "src/components/startDeCommercialisation/DC";
import { useLocation, useNavigate } from "react-router-dom";
import SavingStatus from "src/components/SavingStatus";
import CommentWrapper from "src/components/comments/CommentWrapper";
import Iconify from "src/components/Iconify";
import BoxLoader from "src/router/components/BoxLoader";

export default function StratDeCommercialisation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { currentStep, savingStatus } = useSelector((state) => state.bp);
  const [loading, setLoading] = useState(false);
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );

  const activeStep =
    currentStep === -1
      ? SDC_STEPS.length
      : SDC_STEPS.findIndex((step) => step.value === currentStep);

  const setActiveStep = (step) => {
    if (step === SDC_STEPS.length) dispatch(setStepBP(-1));
    else dispatch(setStepBP(SDC_STEPS[step].value));
  };
  const handleNext = async () => {
    setLoading(true);
    await dispatch(getBP(selectedProject)).unwrap();
    setTimeout(() => {
      setLoading(false);
    }, 100);

    setActiveStep(activeStep + 1);
  };

  const handleBack = async () => {
    setLoading(true);
    await dispatch(getBP(selectedProject)).unwrap();
    setTimeout(() => {
      setLoading(false);
    }, 100);

    setActiveStep(activeStep - 1);
  };
  const handleChange = async (step) => {
    setLoading(true);
    await dispatch(getBP(selectedProject)).unwrap();
    setTimeout(() => {
      setLoading(false);
    }, 100);

    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    if (selectedProject !== -1) {
      dispatch(getBP(selectedProject));
      if (location.state?.step) {
        dispatch(setStepBP(location.state.step));
      } else {
        dispatch(setStepBP(SDC_STEPS[0].value));
      }
    }
  }, [dispatch, location, selectedProject]);
  useEffect(() => {
    if (location.state?.step) {
      dispatch(setStepBP(location.state.step));
    } else {
      dispatch(setStepBP(SDC_STEPS[0].value));
    }
  }, [dispatch, location]);

  useEffect(() => {
    if (selectedProject !== -1) {
      dispatch(getBP(selectedProject));
    }
  }, [dispatch, selectedProject]);
  return (
    <Page
      title="Stratégie de commercialisation"
      style={{
        border: "1px solid #ECECEC",
        borderRadius: "10px",
        padding: " 1rem ",
        height: "98%",
      }}
    >
      <Grid container spacing={1} sx={{ height: "100%" }}>
        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <SavingStatus savingStatus={savingStatus}>
            <CommentWrapper currentPage={`stratdecom_${currentStep}`} />
          </SavingStatus>

          <Box sx={{ pt: 1, px: 3 }}>
            <Stepper activeStep={activeStep}>
              {SDC_STEPS.map((label, index) => {
                return (
                  <Step key={index}>
                    <StepLabel
                      style={{ cursor: "pointer" }}
                      onClick={() => handleChange(index)}
                      title={label.title}
                    />
                  </Step>
                );
              })}
            </Stepper>
          </Box>
          {activeStep === SDC_STEPS.length ? (
            <Box sx={{ p: 3 }}>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </Box>
          ) : (
            <>
              <Typography sx={{ my: 1, px: 3, fontSize: "0.8em" }}>
                <strong style={{ color: "#2196F3" }}>
                  Etape {activeStep + 1}
                </strong>
                {" " + SDC_STEPS[activeStep]?.title}
              </Typography>
              <Description text={decriptions.SDC[currentStep]} />

              {!loading&& (
                <Box sx={{ flex: 1, px: 3 }}>
                  <ComponentSwitcher currentStep={currentStep} />
                </Box>
              )}
              {loading && <BoxLoader />}


              <Box sx={{ display: "flex", flexDirection: "row", pt: 1 }}>
                <Button
                  size="small"
                  color="inherit"
                  variant="outlined"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1, borderRadius: "0" }}
                >
                  Précédent
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {activeStep === SDC_STEPS.length - 1 && (
                  <Button
                    onClick={() =>
                      navigate("/plan-de-production-et-d-organisation")
                    }
                    size="small"
                    variant="contained"
                    sx={{ borderRadius: "0",textTransform: "none" }}
                  >
                    Passer à l'étape suivante
                    <Iconify icon="ant-design:caret-right-filled" />
                  </Button>
                )}
                {activeStep !== SDC_STEPS.length - 1 && (
                  <>
                    {/* <Button
                      variant="outlined"
                      color="inherit"
                      onClick={handleNext}
                      sx={{ mr: 1, borderRadius: "0" }}
                      size="small"
                    >
                      Ignorer
                    </Button> */}
                    <Button
                      onClick={handleNext}
                      size="small"
                      variant="outlined"
                      sx={{ borderRadius: "0" }}
                    >
                      {activeStep === SDC_STEPS.length - 1
                        ? "Terminer"
                        : "Suivant"}
                    </Button>
                  </>
                )}
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </Page>
  );
}

const ComponentSwitcher = ({ currentStep }) => {
  if (currentStep === "CA") return <CA />;
  if (isTextComponent(currentStep)) return <TextBP currentStep={currentStep} />;
  if (isRHComponent(currentStep)) return <RH currentStep={currentStep} />;
  if (isRMComponent(currentStep)) return <RM currentStep={currentStep} />;
  if (isDCComponent(currentStep)) return <DC currentStep={currentStep} />;
  return <h1>default</h1>;
};

const isTextComponent = (currentStep) => {
  const arr = [
    "Organisation commerciale et stratégie fonctionnelle",
    "Stratégies de Marketing",
    "Communication et Stratégie évenementielle",
    "Canaux et strategie de distribution",
  ];
  return arr.includes(currentStep);
};

const isRHComponent = (currentStep) => {
  const arr = [
    "Ressource humaine affecté au département Marketing",
    "Ressource humaine affecté au département commercial",
  ];
  return arr.includes(currentStep);
};
const isRMComponent = (currentStep) => {
  const arr = [
    "Ressource matériel affecté au département commercial",
    "Ressource matériel affecté au département Marketing",
  ];
  return arr.includes(currentStep);
};

const isDCComponent = (currentStep) => {
  const arr = [
    "Dépenses courantes du département commercial",
    "Dépenses courantes du département Marketing",
  ];
  return arr.includes(currentStep);
};

