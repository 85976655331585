import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  OutlinedInput,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Iconify from "src/components/Iconify";
import axiosInstance from "src/libs/axios";
import { getMyProjects } from "src/stores/projects/projectsSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    lineHeight: 1.4,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function Step8() {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects.projects);
  const projectId = useSelector((state) => state.projects.selectedProject);
  const currentProject = projects.find((project) => project.pk === projectId);
  const invitations = [...currentProject?.invitations].sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });
  const [email, setEmail] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedInvitation, setSelectedInvitation] = useState(null);

  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setSelectedInvitation(null);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    axiosInstance
      .post("/projects/invite/", {
        email: email,
        project: projectId,
        percentage: percentage,
        type: "MEMBER",
      })
      .then((res) => {
        dispatch(getMyProjects());
        alert("Invitation envoyée avec succès");

        setEmail("");
      })
      .catch((err) => {
        alert("La personne a déjà été invitée");
        setEmail("");
      });
  };
  const handleDelete = () => {
    axiosInstance
      .delete(`/projects/invitations/${selectedInvitation}/`)
      .then((res) => {
        dispatch(getMyProjects());
        alert("Invitation supprimée avec succès");
      })
      .catch((err) => {
        alert("Une erreur s'est produite");
      })
      .finally(() => {
        handleClose();
      });
  };
  const handleEdit = (data) => {
    axiosInstance
      .patch(`/projects/invitations/${data.pk}/`, {
        percentage: data.percentage,
      })
      .then((res) => {
        dispatch(getMyProjects());
        handleClose();
      });
  };
  return (
    <>
      <h4>Inviter des membres</h4>
      <hr />
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "1rem 0",
        }}
      >
        <FormControl sx={{ minWidth: "300px" }} size="small">
          <InputLabel htmlFor="component-outlined">
            Email du membre à inviter
          </InputLabel>
          <OutlinedInput
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            id="email"
            name="email"
            label="Email du membre à inviter"
          />
        </FormControl>
        <FormControl sx={{ minWidth: "100px" }} size="small">
          <InputLabel htmlFor="component-outlined">
            Pourcentage des parts
          </InputLabel>
          <OutlinedInput
            value={percentage}
            onChange={(e) => {
              if (e.target.value > 100) setPercentage(100);
              else if (e.target.value < 0) setPercentage(0);
              else setPercentage(e.target.value);
            }}
            max={100}
            min={0}
            type="number"
            id="percentage"
            name="percentage"
            label="Pourcentage des parts"
          />
        </FormControl>

        <Button
          sx={{
            textTransform: "none",
          }}
          type="submit"
          variant="contained"
          endIcon={<Iconify icon="cib:telegram-plane" />}
        >
          Envoyer une invitation
        </Button>
      </form>
      <h4>Equipe Fondatrice</h4>
      <hr />
      <Table sx={{ my: 3 }}>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Nom complet</StyledTableCell>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell>Statut</StyledTableCell>
            <StyledTableCell>Pourcentage</StyledTableCell>
            <StyledTableCell align="right">Actions</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {invitations.map((invit) => (
            <StyledTableRow key={invit.pk}>
              <StyledTableCell>{invit.profile.first_name?invit.profile.first_name:"_ "} {invit.profile.last_name?invit.profile.last_name:"_ "}</StyledTableCell>
              <StyledTableCell>{invit.email}</StyledTableCell>
              <StyledTableCell>
                <Status status={invit.acceptedStatus} />
              </StyledTableCell>
              <StyledTableCell>{invit.percentage}%</StyledTableCell>
              <StyledTableCell align="right">
                <Button
                  size="small"
                  variant="contained"
                  color="warning"
                  style={{ marginRight: "1rem" }}
                  startIcon={<Iconify icon="material-symbols:edit" />}
                  onClick={() => {
                    setSelectedInvitation(invit);
                    setOpenEdit(true);
                  }}
                >
                  Pourcentage
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  startIcon={<Iconify icon="mdi:delete" />}
                  onClick={() => {
                    setSelectedInvitation(invit.pk);
                    setOpen(true);
                  }}
                >
                  Effacer
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Supprimer l'invitation ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Voulez-vous vraiment supprimer cette invitation ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={handleDelete} autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      <EditDialogue
        open={openEdit}
        handleClose={handleClose}
        data={selectedInvitation}
        handleEdit={handleEdit}
      />
    </>
  );
}

export default Step8;
const Status = ({ status }) => {
  if (status.toLowerCase() === "pending") {
    return <Chip color="warning" label="En attente" size="small" />;
  }
  if (status.toLowerCase() === "accepted") {
    return <Chip color="success" label="Accepté" size="small" />;
  }
  if (status.toLowerCase() === "rejected") {
    return <Chip color="error" label="Rejeté" size="small" />;
  }
};

const EditDialogue = ({ open, handleClose, handleEdit, data }) => {
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    if (data) {
      setPercentage(data.percentage);
    }
  }, [data]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Modifier le pourcentage des parts"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Choisir le pourcentage des parts pour cette invitation
        </DialogContentText>
        <br/>

        <FormControl fullWidth>
          <InputLabel htmlFor="component-outlined">
            Pourcentage des parts
          </InputLabel>
          <OutlinedInput
            value={percentage}
            onChange={(e) => {
              if (e.target.value > 100) setPercentage(100);
              else if (e.target.value < 0) setPercentage(0);
              else setPercentage(e.target.value);
            }}
            max={100}
            min={0}
            type="number"
            id="percentage"
            name="percentage"
            label="Pourcentage des parts"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
        <Button
          onClick={() =>
            handleEdit({
              pk: data.pk,
              percentage,
            })
          }
          autoFocus
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};
