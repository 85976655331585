import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useIsMentor from "src/hooks/useIsMentor";
import { updateProject } from "src/stores/projects/projectsSlice";
import NAT from "src/utils/NAT.json"

function Step4() {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects.projects);
  const projectId = useSelector((state) => state.projects.selectedProject);
  const currentProject = projects.find((project) => project.pk === projectId);
  const {isMentor,isSupervisor}=useIsMentor()

  const handleChangeNATCategory = (event) => {
    dispatch(
      updateProject({ ...currentProject, nat_category: event.target.value })
    );
  };
  const handleChangeNATTitle = (event) => {
    dispatch(
      updateProject({ ...currentProject, NAT_title: event.target.value })
    );
  };
  return (
    <Box sx={{display:"flex",flexDirection:"column"}}>
         

      <FormControl sx={{ mt:3 }}>
        <InputLabel htmlFor="NAT-cat">
          Intitulé de l'activité selon la NAT 2009 (catégorie)
        </InputLabel>
        <Select
          readOnly={isMentor||isSupervisor}
          labelId="NAT-cat-label"
          id="NAT-cat"
          value={currentProject ? currentProject.nat_category : ""}
          label="Intitulé de l'activité selon la NAT 2009 (catégorie)"
          onChange={handleChangeNATCategory}
        >
          {Object.keys(NAT).map((cat) => (
            <MenuItem value={cat} key={cat}>
              {cat}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ mt:3 }}>
        <InputLabel htmlFor="NAT-scat">
          Intitulé de l'activité selon la NAT 2009 (sous catégorie)
        </InputLabel>
        <Select
          readOnly={isMentor||isSupervisor}
          labelId="NAT-scat-label"
          id="NAT-scat"
          value={currentProject ? currentProject.NAT_title : ""}
          label="Intitulé de l'activité selon la NAT 2009 (sous catégorie)"
          onChange={handleChangeNATTitle}
        >
          {NAT[currentProject.nat_category]?.map((scat) => (
            <MenuItem value={scat} key={scat}>
              {scat}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default Step4;
