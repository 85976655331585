import { VP_STEPS } from "src/utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import Iconify from "../Iconify";
import { setStep } from "src/stores/canvas/canvasSlice";
import { VPViewItem } from "./VPViewItem";
import useIsMentor from "src/hooks/useIsMentor";

export default function VPView() {
  const dispatch = useDispatch();
  const { isMentor, isSupervisor } = useIsMentor();

  const currentStep = useSelector((state) => state.canvas.currentStep);
  const changeStep = (step) => {
    if (!isMentor && !isSupervisor) {
      dispatch(setStep(step));
    }
  };
  const isCurrentStep = (step) => {
    if (!isMentor && !isSupervisor) {
      return currentStep === step;
    }
    return false;
  };
  return (
    <Box sx={{ display: "flex", mt: 5 }}>
      <Box sx={{ width: "350px", height: "350px", position: "relative" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
            zIndex: "1",
            pointerEvents: "none",
          }}
        >
          <Step4Notes />
          <Step5Notes />
          <Step6Notes />
        </Box>
        <Box
          onClick={() => {
            changeStep(3);
          }}
          sx={{
            width: "100%",
            height: "100%",
            background: "black",
            position: "absolute",
            top: "2px",
            left: "0",
            clipPath: "polygon(0% 0%,0% 100%,50% 50%)",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              background: isCurrentStep(3) ? "#F7DE3A" : "white",

              clipPath: "polygon(1% 2%,1% 98%,49% 50%)",
            }}
          ></Box>
        </Box>
        <Box
          onClick={() => {
            changeStep(5);
          }}
          sx={{
            width: "100%",
            height: "100%",
            background: "black",
            position: "absolute",
            top: "2px",
            cursor: "pointer",
            left: "0",
            clipPath: "polygon(0% 0%,50% 50%,100% 50%,100% 0%)",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              background:  isCurrentStep(5) ? "#F7DE3A" : "white",
              clipPath: "polygon(1% 1%,49% 49%,99% 49%,99% 1%)",
            }}
          ></Box>
        </Box>
        <Box
          onClick={() => {
            changeStep(4);
          }}
          sx={{
            cursor: "pointer",
            width: "100%",
            height: "100%",
            background: "black",
            position: "absolute",
            top: "2px",
            left: "0%",
            clipPath: "polygon(50% 50%,100% 50%,100% 100%,0% 100%)",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              background: isCurrentStep(4) ? "#F7DE3A" : "white",
              clipPath: "polygon(49% 49%,99% 49%,99% 99%,1% 99%)",
            }}
          ></Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            width: "40px",
            height: "40px",
            top: "calc( 50% - 20px)",
            left: "calc( 50% - 20px)",
            borderRadius: "50%",
            background: "white",
          }}
        >
          <Iconify
            icon="teenyicons:gift-outline"
            sx={{
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
        <Box
          style={{
            position: "absolute",
            top: "calc(50% - 5px)",
            left: "calc(100% - 1px)",
            width: "50px",
            height: "10px",
            background: "black",
            clipPath:
              "polygon(0 3px,calc(100% - 5px) 3px,calc(100% - 5px) 0,100% 50%,calc(100% - 5px) 100%,calc(100% - 5px) calc(100% - 3px),0 calc(100% - 3px))",
          }}
        />
      </Box>

      <Box
        sx={{
          width: "350px",
          height: "350px",
          position: "relative",
          borderRadius: "50%",
          border: "3px solid black",
          ml: "100px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
            borderRadius: "50%",
            overflow: "hidden",
          }}
        >
          <Box
            onClick={() => {
              changeStep(2);
            }}
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              cursor: "pointer",
              top: "0",
              left: "0",
              background:isCurrentStep(2) ? "#F7DE3A" : "white",
              clipPath: "polygon(0% 0%,100% 0%,50% 50% ,0% 50%)",
            }}
          ></Box>
          <Box
            onClick={() => {
              changeStep(1);
            }}
            sx={{
              width: "100%",
              cursor: "pointer",
              height: "100%",
              position: "absolute",
              top: "0",
              left: "0",
              background: isCurrentStep(1)? "#F7DE3A" : "white",
              clipPath: "polygon(0% 50%,50% 50%,100% 100% ,0% 100%)",
            }}
          ></Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: "0",
              left: "0",
              background: "black",
              clipPath: "polygon(100% 100%,100% 0%,50% 50%)",
            }}
          >
            <Box
              onClick={() => {
                changeStep(0);
              }}
              sx={{
                cursor: "pointer",
                width: "100%",
                height: "100%",
                background: isCurrentStep(0) ? "#F7DE3A" : "white",
                clipPath: "polygon(100% 99%,100% 1%, 51% 50%)",
              }}
            ></Box>
          </Box>
        </Box>

        <Box
          sx={{
            position: "absolute",
            width: "40px",
            height: "40px",
            top: "calc( 50% - 20px)",
            left: "calc( 50% - 20px)",
            borderRadius: "50%",
            background: "white",
          }}
        >
          <Iconify
            icon="iconoir:emoji-look-left"
            sx={{
              width: "100%",
              height: "100%",
            }}
          />
        </Box>

        <Box
          style={{
            position: "absolute",
            top: "calc(50% - 5px)",
            right: "calc(50% + 20px)",
            transform: "rotate(180deg)",
            width: "190px",
            height: "10px",
            background: "black",
            clipPath:
              "polygon(0 3px,calc(100% - 5px) 3px,calc(100% - 5px) 0,100% 50%,calc(100% - 5px) 100%,calc(100% - 5px) calc(100% - 3px),0 calc(100% - 3px))",
          }}
        />
        <Step1Notes />
        <Step2Notes />
        <Step3Notes />
      </Box>
    </Box>
  );
}

const Step1Notes = () => {
  const { data } = useSelector((state) => state.canvas);
  const dataArray = data.filter((item) => item.type === VP_STEPS[0].value);
  const locations = [
    { top: "125px", left: "calc(100% - 15em)" },
    { top: "calc(70px + 11em )", left: "calc(100% - 4em)" },
    { top: "70px", left: "calc(100% - 4em)" },
  ];
  return (
    <>
      {dataArray.slice(0, locations.length).map((item, index) => {
        const location = locations[index];
        return <VPViewItem key={item.id} note={item} location={location} />;
      })}
    </>
  );
};
const Step2Notes = () => {
  const { data } = useSelector((state) => state.canvas);
  const dataArray = data.filter((item) => item.type === VP_STEPS[1].value);
  const locations = [
    { top: "55%", left: "-5em" },
    { top: "65%", left: "6em" },
    { top: "75%", left: "17em" },
  ];
  return (
    <>
      {dataArray.slice(0, locations.length).map((item, index) => {
        const location = locations[index];
        return <VPViewItem key={item.id} note={item} location={location} />;
      })}
    </>
  );
};
const Step3Notes = () => {
  const { data } = useSelector((state) => state.canvas);
  const dataArray = data.filter((item) => item.type === VP_STEPS[2].value);
  const locations = [
    { top: "15%", left: "-5em" },
    { top: "5%", left: "6em" },
    { top: "-5%", left: "17em" },
  ];
  return (
    <>
      {dataArray.slice(0, locations.length).map((item, index) => {
        const location = locations[index];
        return <VPViewItem key={item.id} note={item} location={location} />;
      })}
    </>
  );
};
const Step4Notes = () => {
  const { data } = useSelector((state) => state.canvas);
  const dataArray = data.filter((item) => item.type === VP_STEPS[3].value);
  const locations = [
    { top: "15%", left: "-3em" },
    { top: "calc(15% + 12em)", left: "-3em" },
    { top: "30%", left: "7.1em" },
  ];
  return (
    <>
      {dataArray.slice(0, locations.length).map((item, index) => {
        const location = locations[index];
        return <VPViewItem key={item.id} note={item} location={location} />;
      })}
    </>
  );
};
const Step5Notes = () => {
  const { data } = useSelector((state) => state.canvas);
  const dataArray = data.filter((item) => item.type === VP_STEPS[4].value);
  const locations = [
    { top: "calc(100% - 15em)", left: "calc(100% - 11em)" },
    { top: "calc(100% - 12em)", left: "calc(100% - 22em)" },
    { top: "calc(100% - 6em)", left: "calc(100% - 33em)" },
  ];
  return (
    <>
      {dataArray.slice(0, locations.length).map((item, index) => {
        const location = locations[index];
        return <VPViewItem key={item.id} note={item} location={location} />;
      })}
    </>
  );
};
const Step6Notes = () => {
  const { data } = useSelector((state) => state.canvas);
  const dataArray = data.filter((item) => item.type === VP_STEPS[5].value);
  const locations = [
    { top: "4em", left: "calc(100% - 11em)" },
    { top: " -1em", left: "calc(100% - 22em)" },
    { top: " -5em", left: "calc(100% - 33em)" },
  ];
  return (
    <>
      {dataArray.slice(0, locations.length).map((item, index) => {
        const location = locations[index];
        return <VPViewItem key={item.id} note={item} location={location} />;
      })}
    </>
  );
};
