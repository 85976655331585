import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useIsMentor from "src/hooks/useIsMentor";
import { updateProject } from "src/stores/projects/projectsSlice";
import InvestorsTable from "../InvestorsTable";

function Step9() {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects.projects);
  const projectId = useSelector((state) => state.projects.selectedProject);
  const currentProject = projects.find((project) => project.pk === projectId);
  const { isMentor, isSupervisor } = useIsMentor();

  const handleChange = (event) => {
    dispatch(
      updateProject({
        ...currentProject,
        is_investements: event.target.value === "oui" ? true : false,
      })
    );
  };
  return (
    <>
      <FormControl sx={{ minWidth: "300px", mt: 3 }}>
        <FormLabel id="demo-row-radio-buttons-group-label">
          Avez vous pu obtenir un investissement de la part d'un investisseur:
          Business Angel, Ventures Capital ou autre ?
        </FormLabel>
        <RadioGroup
          readOnly={isMentor || isSupervisor}
          value={currentProject?.is_investements ? "oui" : "non"}
          onChange={handleChange}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel value="oui" control={<Radio />} label="Oui" />
          <FormControlLabel value="non" control={<Radio />} label="Non" />
        </RadioGroup>
      </FormControl>
      {currentProject?.is_investements && <InvestorsTable />}
    </>
  );
}

export default Step9;
