export const getRMData = (RM) => {
  const RMdata = {
    commercial: [],
    marketing: [],
    production: [],
    rnd: [],
    local: [],
    other: [],
  };
  let id = 0;
  Object.keys(RM).forEach((key) => {
    RM[key].forEach((item) => {
      if (item.post) RMdata.other.push({ id: id++, ...item });
      else {
        if (item.type === "commercial") {
          RMdata.commercial.push({ ...item, id: id++ });
        }
        if (item.type === "marketing") {
          RMdata.marketing.push({ ...item, id: id++ });
        }
        if (item.type === "production") {
          RMdata.production.push({ ...item, id: id++ });
        }
        if (item.type === "local") {
          RMdata.local.push({ ...item, id: id++ });
        }
        if (item.type === "rnd") {
          RMdata.rnd.push({ ...item, id: id++ });
        }
      }
    });
  });
  return RMdata;
};

export const updateRMArrayObject = (oldData, data) => {
  console.log("oldData", oldData);
  console.log("item", data);
  //remove data from oldData
  const oldDataFiltered = oldData.filter((item) => item.pk !== data.pk);
  //oldDatagroupedbytable
  const oldDatagroupedbytable = oldDataFiltered.reduce((acc, item) => {
    if (!acc[item.table]) {
      acc[item.table] = [];
    }
    const el={
      pk: item.pk,
      name: item.name,
      type: item.type,
      unit_price: item.unit_price,
      quantity: item.quantity,
      is_editable: true,
    }
    if(item.post) el.post=item.post
    acc[item.table].push(el);
    return acc;
  }, {});
  const dataWithNeededAttrs = {
    pk: data.pk,
    name: data.name,
    type: data.type,
    unit_price: data.unit_price,
    quantity: data.quantity,
    is_editable: true,
    
  };
  if(data.post) dataWithNeededAttrs.post=data.post

  if (oldDatagroupedbytable[data.table]) {
    oldDatagroupedbytable[data.table].push(dataWithNeededAttrs);
  } else {
    oldDatagroupedbytable[data.table] = [dataWithNeededAttrs];
  }

  return oldDatagroupedbytable;
};

export const deleteRMArrayObject = (oldData, itemToDelete) => {
 
  const oldDataFiltered = oldData.filter((item) => item.pk !== itemToDelete.pk);
  const tableDataToUpdate = oldDataFiltered.filter(
    (item) => item.table === itemToDelete.table
  );
  return {
    sheet: "Investissements",
    table: itemToDelete.table,
    data: tableDataToUpdate.map((item) => ({
      pk: item.pk,
      name: item.name,
      type: item.type,
      unit_price: item.unit_price,
      quantity: item.quantity,
      is_editable: true,
      post: item.post,
    })),
  };
};
